import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  makeStyles,
  Select,
  MenuItem,
} from "@material-ui/core"
import {
  Check,
  EditOutlined,
  DeleteOutline,
  ExpandLess,
  ExpandMore,
  SaveOutlined,
} from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import Autosuggest from "react-autosuggest"
import CustomAutocomplete from "../../Common/AutoComplete"
import mixpanel from "mixpanel-browser"
import { GenericSaveTemplate, TabSwitchingHelperText } from "../helper"
import { useRef } from "react";
import PrescriptionAutocomplete from "../../Common/PrescriptionAutocomplete"
import prescriptionSocket from "../../../socket"
import { labResultConstantData } from "../LabResults/labResultConstants"
import ReactDatetime from "react-datetime"
import moment from "moment"
import { useSelector } from "react-redux"


const useStyles = makeStyles((theme) => ({
  hoverChip: {
  //   width: "100%", 
  //   padding: "10px", 
  // //   cursor: "pointer", 
  //   marginBottom: 15, 
    borderRadius: 20,
    '&:focus': {
      border: "solid",
      borderColor: theme.palette.primary.main,
    },
  },
  inputDate: {
    width: "90%",
    "& input": {
      height: "57px",
      border: "1px solid #ccc",
      "&:hover": {
        borderColor: "black",
      },
    },
  },
}));


function GenericCardTable({
  cardTitle,
  suggestedTitle,
  suggestedTab,
  suggestedData = [],
  onSuggestedHoverLabel = "",
  suggestedDataLabel,
  tableHeaders,
  tableData = {},
  setTableData,
  cardKey,
  hello,
  tabSwitching = false,
  addOnFunction = null,
  setActiveIndex,
  activeIndex,
  padIndex,
  cardName = "Value",
  frequentOptions = [],
  doctorId = null,
  historicalLabTests = false,
  headerReq = true,
  headerIcon = null,
  savedTemplates = [],
  onSelectTemplate = null,
  onDeleteTemplate = null,
  onUpdateTemplate = null,
  selectIconText = 'sx',
  ...props
}) {
  const [state, setState] = useState({})
  const [addState, setAddState] = useState({})
  const [suggestExpand, setSuggestExpand] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const currentDate = moment.now()

  const isOH = useSelector((state) => state.user?.user?.ohID)

  useEffect(() => {
    if(activeIndex?.index === padIndex) {
      presAuotcompleteRef.current?.focus();
    }
  }, [activeIndex])

  const theme = useTheme()
  const classes = useStyles()

  useEffect(() => {
    if (!tableHeaders?.find((f) => (f?.required ? !state?.[f?.key] : false))) {
      setAddState({ ...addState, add: true })
    } else setAddState({...addState, add: false})
  }, [state])

  useEffect(() => {
    if (addOnFunction) addOnFunction(state, setState)
  }, [state?.name])

  const textFieldsRefs = useRef([]);

  const suggestedChipRefs = useRef([]);

  const presAuotcompleteRef = useRef();

  const [focusField, setFocusField] = useState("")  

  const getTableColumn = (field, index, includeHeader, val={}, rowIndex = 0)  => {

    // let fieldValue = val?.[field?.key] 
    let fieldValue = val?.[field?.key] ? val?.[field?.key] : ''
    

    switch (field?.type) {
      case "text": {
        return (
          <Grid container>
            {includeHeader? <Grid item xs={12}>
              <Typography style={{fontSize: "12px"}}>{field?.label}</Typography>
            </Grid> : null}
            <Grid item xs={12}>
              <TextField
                fullWidth
                // focused={index === 1 ? true : false}
                autoFocus={field.focus || (focusField === val?.[field?.dependencyKey] && (index === 1 || (cardKey === "labResults" && index === 2 && val?.unit))) ? true : false}
                InputProps={{
                  endAdornment: field?.endAdornment
                    ? field?.endAdornment
                    : null,
                }}
                placeholder={field?.placeholder}
                value={fieldValue}
                variant="outlined"
                disabled={
                  field?.dependencyKey ? !val?.[field?.dependencyKey] : false
                }
                onChange={(e) => {
                  updateTableData("edit", field, rowIndex, e.target.value, index)
                }}
                // inputProps={{ maxLength: field.limit ? field.limit : 50, fontSize: 16 }}
                inputProps={{ style: { fontSize: "12px" }, maxLength: isOH ? null : (field.limit ? field.limit : 160) }}

                onKeyDown={(e) => {
                  handleKeyDown(e, index, null, rowIndex)
                }}
                inputRef={(ref) => {
                  if(textFieldsRefs.current?.[rowIndex]?.length)
                    textFieldsRefs.current[rowIndex][index] = ref
                  else {
                    textFieldsRefs.current[rowIndex] = []
                    textFieldsRefs.current[rowIndex][index] = ref
                  }
                }}
              />
            </Grid>
          </Grid>
        )
      }

      case "radio": {
        return (
          <Grid container>
            {includeHeader?<Grid item xs={12}>
              <Typography style={{fontSize: "12px"}}>{field?.label}</Typography>
            </Grid> : null}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={fieldValue}
                  onChange={(e) => {
                    updateTableData("edit", field, rowIndex, e.target.value, index)
                  }}
                  onKeyDown={(e) => {
                    handleKeyDown(e, index, null, rowIndex)
                  }}
                  >
                  {field?.options?.map((v) => (
                    <FormControlLabel
                      value={v}
                      label={v}
                      innerRef={(ref) => {
                        if(textFieldsRefs.current?.[rowIndex]?.length)
                          textFieldsRefs.current[rowIndex][index] = ref
                        else {
                          textFieldsRefs.current[rowIndex] = []
                          textFieldsRefs.current[rowIndex][index] = ref
                        }
                      }}
                      disabled={
                        field?.dependencyKey
                          ? !val?.[field?.dependencyKey]
                          : false
                      }
                      control={<Radio color="primary" />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {field.helperText ? (
              <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
                <Typography color="secondary" style={{ fontSize: "14px" }}>
                  {" "}
                  {field.helperText}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        )
      }

      case "select": {

        let selectOptions = field?.options
        let freeSolo = field?.freeSolo
        if (field?.options?.length) {
          let checkForDuplicate = []

          selectOptions = [...new Set([...field?.options].filter(v => {
            if((field?.optionKey ? v?.[field?.optionKey]?.trim() : v?.trim()) && !(checkForDuplicate.includes(field?.optionKey ? v?.[field?.optionKey]?.trim() : v?.trim()))) {
              checkForDuplicate.push(field?.optionKey ? v?.[field?.optionKey]?.trim() : v?.trim())
              return true
            } else return null
          }))];
        }

        if(field?.fetchOptionManually) {
          if(!selectOptions?.find(option => option?.[field?.optionKey] === val?.[field?.key]) && val?.[field?.key])
            selectOptions.push({"name": val?.[field?.key]})
        }

        if(cardKey === "labResults" && field?.labResult === "unit"){
          let nameData = labResultConstantData?.[val?.['name']]
          if(nameData && nameData?.all_units?.length){
            nameData?.all_units?.map(v => {
              if(!selectOptions?.includes(v?.name))
              selectOptions.push(v?.['name'])
            })
          } else {
            selectOptions = []
            freeSolo = true
          }
        }

        return (
          <Grid container>
            {includeHeader?<Grid item xs={12}>
              <Typography style={{fontSize: "12px"}}>{field?.label}</Typography>
            </Grid>: null}
            <Grid item xs={12}>
              <Autocomplete
                options={selectOptions}
                getOptionLabel={(option) => field?.optionKey ? (option?.[field?.optionKey] ? option?.[field?.optionKey] : "") : option}
                freeSolo={freeSolo ? true : false}
                disabled={
                  field?.dependencyKey ? !val?.[field?.dependencyKey] : false
                }
                // openOnFocus={cardKey!=='labResults'}
                fullWidth
                
                value={field?.optionKey ? ({[field.optionKey]: fieldValue}) : fieldValue }
                onChange={(e, val) => {   

                  updateTableData("edit", field, rowIndex, field?.optionKey ? val?.[field?.optionKey] : (val? val : ""), index)

                }}
                // rows={1}
                // rowsMax={Infinity}
                // renderOption
                // disableCloseOnSelect={false}
                openOnFocus
                renderInput={(params) => (
                  <TextField
                    // helperText={
                    //   <Typography style={{ fontSize: "14px" }}>
                    //     Use arrow keys <i class="fas fa-angle-down"></i> &{" "}
                    //     <i class="fas fa-angle-up"></i>
                    //   </Typography>
                    // }
                    {...params}

                    fullWidth

                    // multiline
                    // rows={1}

                    placeholder={field?.placeholder}
                    variant="outlined"
                    autoFocus={field.focus || (focusField === val?.[field?.dependencyKey] && index === 1 && (cardKey !== "labResults" || freeSolo)) ? true : false}
                    // onChange={(e) => {
                    //   if (field.onChange) field.onChange(e.target.value, state, setState)
                    // }}

                    onChange={(e) => {
                      if(freeSolo)
                        updateTableData("edit", field, rowIndex, e?.target?.value, index)
                    }}
                    onKeyDown={(e) => {
                      // if (
                      //   e.key === "Enter" &&
                      //   addState?.add &&
                      //   e?.target?.value
                      // )
                      //   updateTableData()
                      if(e?.target?.value?.length || (e?.key !== "ArrowDown" && e?.key !== "ArrowUp"))
                      handleKeyDown(e, index, "select", rowIndex)
                    }}
                    
                    inputRef={(ref) => {
                      if(textFieldsRefs.current?.[rowIndex]?.length)
                        textFieldsRefs.current[rowIndex][index] = ref
                      else {
                        textFieldsRefs.current[rowIndex] = []
                        textFieldsRefs.current[rowIndex][index] = ref
                      }
                    }}
                    inputProps={{ ...params?.inputProps, style: { fontSize: "12px", textOverflow:"clip"} , value: fieldValue ? fieldValue : ""}}
                    // InputProps={{ ...params?.InputProps, style: { fontSize: "12px", textOverflow:"unset", overflow: "visible", whiteSpace: "normal", wordBreak: "break-word", maxHeight: "none" } }}
                    // multiline
                    // InputLabelProps={{
                    //   shrink: true,
                    //   style: {
                    //     whiteSpace: 'pre-wrap',
                    //   },
                    // }}
                    // InputProps={{
                    //   ...params?.InputProps,
                    //   style: {
                    //     textOverflow: 'break-word',
                    //     overflow: 'clip',
                    //     height: "auto",
                    //     wordBreak: "break-word",
                    //     wordWrap: "break-word",
                    //   },
                    // }}
                  />
                )}
              />
            </Grid>
          </Grid>
        )
      }

      case "unit-autocomplete":
        
      let units = [...field?.unitOptions]

      if(val?.name && index === 1 && field?.key === "doseQuantity") {
        let text = val?.name;
        let syrupUnits = ["ml", "teaspoon", "tablespoon", "bottle", "glassful", "cup"];
        let tabUnits = ["tablet", "capsule"];

        if(text?.toLowerCase()?.includes("gel") || text?.toLowerCase()?.includes("lotion") || text?.toLowerCase()?.includes("ointment") || text?.toLowerCase()?.includes("cream")){
          units = ["ml", "unit", "fingertip", "application"];
        } else if(text?.toLowerCase()?.includes("syrup") || (val?.prev?.doseQuantity ? (val?.prev?.name?.toLowerCase() === text?.toLowerCase() && syrupUnits.some(s => s.includes(val?.prev?.doseQuantity?.split(" ")?.[1]))) : false))
        units = syrupUnits;
        else if(text?.toLowerCase()?.includes("syring") || text?.toLowerCase()?.includes("inject"))
        units = ["ml", "vial", "iu", "unit", "ampule"]
        else if(text?.toLowerCase()?.includes("capsul") || text?.toLowerCase()?.includes("tab") || (val?.prev?.doseQuantity ? (val?.prev?.name?.toLowerCase() === text?.toLowerCase() && tabUnits.some(s => s.includes(val?.prev?.doseQuantity?.split(" ")?.[1]))) : false))
        units = tabUnits
      }
      
      let unitOptions = fieldValue && ![...field?.unitOptions]?.find(f => val?.[field?.key]?.toLowerCase()?.includes(f?.toLowerCase())) ? [...units].map(v => `${fieldValue} ${v}`) : [];

      return (
        <Grid container>
          {includeHeader?<Grid item xs={12}>
            <Typography style={{fontSize: "12px"}}>{field?.label}</Typography>
          </Grid>: null}
          <Grid item xs={12}>
            <Autocomplete
              options={unitOptions}
              getOptionLabel={(option) => field?.optionKey ? option?.[field?.optionKey] : option}
              freeSolo={field?.freeSolo ? true : false}
              disabled={
                field?.dependencyKey ? !val?.[field?.dependencyKey] : false
              }
              openOnFocus
              fullWidth
              
              value={fieldValue }
              onChange={(e, val) => {
                // console.log(e, val, '9897ppppppppppppppppppppppppp')
                updateTableData("edit", field, rowIndex, field?.optionKey ? val?.[field?.optionKey] : (val? val : ""), index)
              }}
              inputValue={fieldValue}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  // helperText={
                  //   <Typography style={{ fontSize: "14px" }}>
                  //     Use arrow keys <i class="fas fa-angle-down"></i> &{" "}
                  //     <i class="fas fa-angle-up"></i>
                  //   </Typography>
                  // }
                  {...params}
                  fullWidth
                  onChange={(e) => {
                    const re = new RegExp("^[0-9/.]*$")
                    if(field?.freeSolo && (re.test(e?.target?.value) || e?.nativeEvent?.inputType?.includes("delete")))
                      updateTableData("edit", field, rowIndex, e?.target?.value, index)
                  }}
                  placeholder={field?.placeholder}
                  variant="outlined"
                  autoFocus={field.focus || (focusField?.toLowerCase() === val?.[field?.dependencyKey]?.toLowerCase() && index === 1) ? true : false}
                  // onChange={(e) => {
                  //   if (field.onChange) field.onChange(e.target.value, state, setState)
                  // }}

                  onKeyDown={(e) => {
                    // if (
                    //   e.key === "Enter" &&
                    //   addState?.add &&
                    //   e?.target?.value
                    // )
                    //   updateTableData()
                    if((e?.target?.value?.length && field?.unitOptions?.find(f => e.target.value?.toLowerCase()?.includes(f?.toLowerCase()))) || (e?.key !== "ArrowDown" && e?.key !== "ArrowUp"))
                    handleKeyDown(e, index, null, rowIndex)
                  }}
                  
                  inputRef={(ref) => {
                    if(textFieldsRefs.current?.[rowIndex]?.length)
                      textFieldsRefs.current[rowIndex][index] = ref
                    else {
                      textFieldsRefs.current[rowIndex] = []
                      textFieldsRefs.current[rowIndex][index] = ref
                    }
                  }}
                inputProps={{ ...params?.inputProps, style: { fontSize: "12px" }, value: fieldValue ? fieldValue : "" }}

                />
              )}
            />
          </Grid>
        </Grid>
      )

      case "autocomplete":
        const onChange = (event, { newValue }) => {
          if (field.onChange) {
            field?.onChange(newValue, state, setState)
          } else {
            const temp = { ...state }
            temp[field?.key] = newValue
            setState({ ...temp })
          }
        }

        // Autosuggest will call this function every time you need to update suggestions.
        // You already implemented this logic above, so just use it.

        // Autosuggest will call this function every time you need to clear suggestions.
        return (
          <Grid container>
            {includeHeader?<Grid item xs={12}>
              <Typography style={{fontSize: "12px"}}>{field?.label}</Typography>
            </Grid>: null}
            <Grid item xs={12}>
              <CustomAutocomplete
                field={field}
                inputProps={{
                  placeholder: field.placeholder,
                  value: fieldValue,
                  onChange: onChange,
                  disabled: field?.dependencyKey
                    ? !val?.[field?.dependencyKey]
                    : false,
                }}
                inputRef={(ref) => {
                  if(textFieldsRefs.current?.[rowIndex]?.length)
                    textFieldsRefs.current[rowIndex][index] = ref
                  else {
                    textFieldsRefs.current[rowIndex] = []
                    textFieldsRefs.current[rowIndex][index] = ref
                  }
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e, index, "select", rowIndex)
                }}
                />
            </Grid>
          </Grid>
        )
      
      case "date": {

        return (
          <Grid container>
            {includeHeader? <Grid item xs={12}>
              <Typography style={{fontSize: "12px"}}>{field?.label}</Typography>
            </Grid> : null}
            <Grid item xs={12}>
              <TextField
                fullWidth
                // focused={index === 1 ? true : false}
                autoFocus={field.focus || (focusField === val?.[field?.dependencyKey] && (index === 1 || (cardKey === "labResults" && index === 2))) ? true : false}
                placeholder={field?.placeholder}
                InputProps={{
                  endAdornment: field?.endAdornment
                    ? field?.endAdornment
                    : null,
                  placeholder: field?.placeholder
                }}
                value={fieldValue ? fieldValue : null}
                type="date"
                variant="outlined"
                disabled={
                  field?.dependencyKey ? !val?.[field?.dependencyKey] : false
                }
                onChange={(e) => {
                  updateTableData("edit", field, rowIndex, e.target.value, index)
                }}
                // inputProps={{ maxLength: field.limit ? field.limit : 50, fontSize: 16 }}
                inputProps={{ style: { fontSize: "12px" } }}
                InputLabelProps={{
                  shrink: true,
                }}
                
                onKeyDown={(e) => {
                  handleKeyDown(e, index, null, rowIndex)
                }}
                inputRef={(ref) => {
                  if(textFieldsRefs.current?.[rowIndex]?.length)
                    textFieldsRefs.current[rowIndex][index] = ref
                  else {
                    textFieldsRefs.current[rowIndex] = []
                    textFieldsRefs.current[rowIndex][index] = ref
                  }
                }}
              />
            </Grid>
          </Grid>
        )
      }

      default:
        return <></>
    }
  }
  
  const updateTableData = (type = "add", fieldData, rowIndex, value, tempData, allD = null) => {
    if (type === 'add'){
      const temp = [...tableData[cardKey]]

      if(cardKey === "labResults" && type === 'add'){
        let nameKey = tempData?.name;
        let nameData = labResultConstantData[nameKey]

        if (nameData) {
          if(nameData?.labVitals && nameData?.labVitals?.length){
            nameData?.labVitals?.map((labVital) => {
              temp.push({"name": labVital?.unit_dislay_name, "unit": labVital?.unit?.name ? labVital.unit.name : "-", "date": moment?.utc(new Date())?.add(330, "minutes")?.format("yyyy-MM-DD")})
            });
            setFocusField(nameData?.labVitals?.[0]?.unit_dislay_name)
          } else {
            temp.push({"name": nameKey, "unit": nameData?.unit?.name ? nameData?.unit?.name : "-", "date": moment?.utc(new Date())?.add(330, "minutes")?.format("yyyy-MM-DD")})
            setFocusField(nameKey)
          }
        } else {
          temp.push({"name": nameKey, "date": moment?.utc(new Date())?.add(330, "minutes")?.format("yyyy-MM-DD")})
          setFocusField(tempData?.name)
        }
      } else {
        temp.push({ ...tempData })
        setFocusField(tempData?.name)
      }

      setTableData({ ...tableData, [cardKey]: [...temp] })
      
      setState({})
      setAddState({...addState, add: false})

      if (cardKey === "tests")
        presAuotcompleteRef.current.focus()

    } else if (type === "edit") {
        setTableData((prevData) => {
          const tempTableData = [...prevData[cardKey]];
          if(allD){
            tempTableData?.splice(rowIndex, 1)
            tempTableData.push({ ...allD })
          } else {
            tempTableData[rowIndex][fieldData.key] = value
            tempTableData[rowIndex]["_e_"] = true

            console.log(tempTableData[rowIndex][fieldData.key], '98979897checkkkkkkkkk')
          }

          return {...prevData, [cardKey]: [...tempTableData]}
        })
    }
  }


  const onEdit = (obj, idx) => {
    setState({ ...obj })
    setAddState({ add: true, index: idx })
  }

  const onDelete = (idx) => {
    const temp = [...tableData[cardKey]]
    temp.splice(idx, 1)
    setTableData({ ...tableData, [cardKey]: [...temp] })
  }

  function handleKeyDown(event, index, fieldType = null, rowIndex) {
    if (event.key === "ArrowRight" || (event?.key === "Enter")) {
      if (index < textFieldsRefs.current?.[rowIndex]?.length - 1)
        textFieldsRefs.current?.[rowIndex][index + 1].focus();
      else {
        if (tableData?.[cardKey]?.[rowIndex+1])
        textFieldsRefs.current?.[rowIndex+1]?.[index].focus();
        else{
          presAuotcompleteRef?.current?.focus();
        }
      }
    } else if (event.key === "ArrowLeft" && index > 0) {
      textFieldsRefs.current?.[rowIndex][index - 1].focus();
    } else if (event.key === "ArrowDown"){
      if(!fieldType){
        if (tableData?.[cardKey]?.[rowIndex+1])
        textFieldsRefs.current?.[rowIndex+1]?.[index].focus();
        else{
          presAuotcompleteRef?.current?.focus();
        }
      }
    } else if (event.key === "ArrowUp" && padIndex > 0) {
      if(!fieldType){
        if (tableData?.[cardKey]?.[rowIndex-1])
          textFieldsRefs.current?.[rowIndex-1][index].focus();
        else
          setActiveIndex({"index": padIndex - 1, "from": padIndex})
      }
    }
  }

  // console.log(tableData[cardKey], '98979897check')

  // useEffect(() => {
  //   if(state?.otherHookData?.temp) {
  //     let tempData = state?.otherHookData
  //     if(tempData?.['temp']) {
  //       updateTableData("edit", {'key': 'name'}, tempData['tempIdx'], null, null, tempData['temp'])
  //       // setState({})
  //     }
  //   }
  // }, [state])


  return (
    <>
      <Grid container>
        {headerReq ? <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          // direction="column"
          alignItems="center"
          style={{marginTop: "-10px"}}
        >
          <Grid item style={{display: "flex", alignItems: "center"}}>
            {headerIcon ? (headerIcon) : null}
            <Typography variant="h6" style={{fontWeight: "600", marginTop: "0.5%"}}>{cardTitle}</Typography>
          </Grid>

          {/* {tabSwitching ? (
            <Grid item>
              <TabSwitchingHelperText historicalLabTests={historicalLabTests} historicalData={props?.historicalData} />
            </Grid>
          ) : null} */}

          <Grid item style={{position: 'relative'}}>
            <GenericSaveTemplate data={tableData?.[cardKey]} savedTemplates={savedTemplates} cardTitle={cardName} 
              onDeleteTemplate={onDeleteTemplate}
              onSelectTemplate={onSelectTemplate}
              onUpdateTemplate={onUpdateTemplate}
              selectIconText={selectIconText}
            />
          </Grid>
        </Grid> : null}
        
        <Grid item xs={12}>
          <TableContainer
            style={{
              marginBottom: "0.5%",
              marginTop: "-0.5rem",
              overflow: "visible",
            }}
          >
            <Table>
             {false ? <TableHead>
                <TableRow style={{ verticalAlign: "bottom" }}>
                  {tableHeaders?.map((v, index) => (
                    <TableCell style={{width: v?.width}}>
                      {getTableColumn(v, index)}
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton
                      disabled={!addState?.add}
                      style={{
                        backgroundColor: addState?.add
                          ? theme.palette.primary.main
                          : "#D3D3D3",
                        color: "white",
                      }}
                      ref={(ref) => (textFieldsRefs.current[textFieldsRefs.current.length] = ref)}
                      onClick={updateTableData}
                    >
                      <Check />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead> : null}
             
              <TableBody style={{ backgroundColor: "#f8f9fe", marginTop: 10 }}>
                {/* {suggestedTab ? (<TableRow>
                  <TableCell colSpan={7}>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            alignItems={"center"}
            style={{ marginBottom: "0.5rem" }}
          >
            <Grid item xs={12}>
              <Typography style={{ opacity: 0.7 }}>{suggestedTitle}</Typography>
            </Grid>
            <Grid item container xs={12} md={12} spacing={1}>
              {suggestedData?.length ? (
                suggestedData?.length < 4 || suggestExpand ? (
                  suggestedData?.slice(0, 20)?.map((v, idx) => (
                    <Grid item xs={"auto"}>
                      <Chip
                        label={suggestedDataLabel ? v[suggestedDataLabel] : v}
                        onClick={() => onSuggestedClick(v)}
                        title={v[onSuggestedHoverLabel]}
                        style={{borderBlockColor: "red"}}
                        tabIndex="-1"
                        innerRef={(ref) => (suggestedChipRefs.current[idx] = ref)}
                        className={classes.hoverChip}
                        onKeyDown={(e) => {
                          handleKeyDown(e, idx, 'chip')
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  suggestedData?.slice(0, 6)?.map((v, idx) => (
                    <Grid item xs={"auto"}>
                      <Chip
                        label={suggestedDataLabel ? v[suggestedDataLabel] : v}
                        title={v[onSuggestedHoverLabel]}
                        tabIndex="-1"
                        onClick={() => onSuggestedClick(v)}
                        innerRef={(ref) => (suggestedChipRefs.current[idx] = ref)}
                        className={classes.hoverChip}
                        onKeyDown={(e) => {
                          handleKeyDown(e, idx, 'chip')
                        }}
                      />
                    </Grid>
                  ))
                )
              ) : (
                <Grid item xs={"auto"}>
                  <Chip label={"--"} style={{ width: "65px" }} tabIndex="-1" />
                </Grid>
              )}
              {suggestedData?.length ? (
                <Grid item xs={"auto"}>
                  <Chip
                    tabIndex="-1"
                    label={suggestExpand ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => setSuggestExpand(!suggestExpand)}
                  />
                </Grid>
              ) : null}
              
            </Grid>
          </Grid>
          </TableCell>
                </TableRow>
        ) : null} */}
                {tableData[cardKey]?.map((v, i) => (
                  <TableRow style={{backgroundColor: "white"}}>
                    {tableHeaders?.map((field, idx) => (
                      <TableCell style={{ padding: "4px", fontWeight: "bold", width: idx === 0 ? (field.medicines ? "23%" : field?.width) : field?.width }}>
                        {getTableColumn(field, idx, (i === 0 ? true : false), v, i)}
                      </TableCell>
                    ))}
                    <TableCell style={{ padding: "4px" }}>
                      {/* <IconButton onClick={() => onEdit(v, i)} tabIndex="-1">
                        <EditOutlined />
                      </IconButton> */}
                      <IconButton
                        tabIndex="-2"
                        style={{ color: "#EC7063" }}
                        onClick={() => onDelete(i)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <PrescriptionAutocomplete
            field={tableHeaders?.[0]}
            inputProps={{
              placeholder: `Start Typing ${cardName}`,
              value: state?.[tableHeaders?.[0]?.key] ? state?.[tableHeaders?.[0]?.key] : "",
              onChange: (e, value, other = null) => {
                if (tableHeaders?.[0]?.onChange) tableHeaders?.[0].onChange(value?.newValue, state, setState)
                else {
                  let temp = { ...state }
                  temp[tableHeaders?.[0]?.key] = value?.newValue

                  if(value?.otherData?.shortComposition){
                    temp['composition'] = value?.otherData?.shortComposition
                  }
                  
                  if (tableHeaders?.[0]?.medicines && other){
                    let tempIdx = null;
                    if (other) {
                      updateTableData("add", null, 0, null, temp)
                      tempIdx = tableData?.[cardKey]?.length
                    }

                    // let tempTimeout = false;
                    // setTimeout(() => {
                    //   tempTimeout = true;
                    // }, 5000)
                    
                    prescriptionSocket.emit("drug_autocomplete", {
                      name: value?.newValue?.toLowerCase(),
                      docId: doctorId,
                    })
              
                    prescriptionSocket.once("drug_autocomplete", (args) => {
                      if (args) {
                        temp = ({
                          ...state,
                          ...args,
                          // name: args?.name?.toUpperCase(),
                        })

                        const regex = /[^0-9]/;
                        if(temp?.duration && temp?.duration?.length && !regex.test(temp?.duration)) {
                          temp.duration = `${temp?.duration} Day`
                        }

                      } else {
                        temp[tableHeaders?.[0]?.key] = value?.newValue
                      }

                      if (other) {
                        if(!tableData?.[cardKey]?.[tempIdx]?._e_){
                          // setState({...temp, name: "", otherHookData: {
                          //   tempIdx: tempIdx,
                          //   temp: temp
                          // }})
                          updateTableData("edit", {"key": "name"}, tempIdx, null, null, temp)
                        }
                      }
                    })
                  } else {
                    setState({ ...temp })
                    if (other) 
                      updateTableData("add", null, 0, null, temp)
                  }

                }

              },
              disabled: false
            }}
            frequentOptions={[...frequentOptions]}
            suggestedOptions={suggestedData}
            inputRef={presAuotcompleteRef}
            onKeyDown={(e) => {
              if (e.key === "Enter"){
                // updateTableData()
              }
            }}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            tableData={tableData}
            textFieldsRefs={textFieldsRefs}
            cardKey={cardKey}
            cardState={state}
            setCardState={setState}
            />
        </Grid>
        
      </Grid>
    </>
  )
}

export default GenericCardTable
