import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import noMedicalHistory from "./../../../assets/img/consultation/NoMedicalHistory.png";
import { toast } from "react-toastify";
import customAxios from "../../../axios";
import OnlineAppointmentIcon from "./../../Dashboard/Home/OnlineAppointment.svg";
import OfflineAppointmentIcon from "./../../Dashboard/Home/Vector.svg";
import { Send, ViewAgenda, Visibility } from "@material-ui/icons";
function AppointmentHistory({ patientData, setLoading, loading }) {
  const [appointmentHistory, setAppointmentHistory] = useState([]);
  const tableHeaders = [
    { label: "Visited Date", id: "date" },
    { label: "Type", id: "appointmentType" },
    { label: "Mode", id: "isOnline" },
    { label: "Fee", id: "amount" },
    { label: "Prescription", id: "date" },
  ];
  useEffect(() => {
    setLoading(true);
    customAxios

      .get(
        `/appointments/day/all?patient_id=${patientData?._id}&type=all&status=completed`
      )
      .then((res) => {
        console.log("res54", res);
        setLoading(false);
        const temp = res?.data?.data;
        temp.reverse();
        setAppointmentHistory(temp);
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
      });
    //eslint-disable-next-line
  }, [patientData?._id]);

  const generatePdf = (appointmentId) => {
    setLoading(true);

    customAxios
      .get(`/consultation/get-prescription-pdf/${appointmentId}`)
      .then((res) => {
        if (res?.data?.data?.url) window.open(res.data.data.url);
        else toast.error("PDF does not exist");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
      });
  };
  const sendPrescriptionToNumber = (appointmentData) => {
    setLoading(true);
    customAxios
      .put(`consultation/send-prescription/${appointmentData?._id}`, {
        phoneNo: appointmentData?.patient?.phoneNo,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success(`Prescription successfully sent.`);
        } else {
          toast.error("Prescription does not exist");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured");
        setLoading(false);
      });
  };

  return (
    <Grid
      container
      style={{
        padding: "1rem",
        backgroundColor: "#fff",
        borderRadius: "0px 40px 0px 40px",
      }}
    >
      {appointmentHistory.length !== 0 ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" color="primary">
              Appointment History
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "2rem" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#f3f3f3" }}>
                    {tableHeaders.map((ele) => (
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {ele.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointmentHistory.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {moment.utc(data.date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {data.appointmentType === "normal"
                          ? "Regular"
                          : "Follow Up"}
                      </TableCell>
                      <TableCell>
                        {data?.isOnline ? (
                          <img
                            alt="something"
                            src={OnlineAppointmentIcon}
                          ></img>
                        ) : (
                          <img
                            alt="something"
                            src={OfflineAppointmentIcon}
                          ></img>
                        )}
                      </TableCell>
                      <TableCell>Rs. {data?.amount}</TableCell>
                      <TableCell>
                        {data?.prescriptionExists ? <Grid container spacing={1}>
                          <Grid item>
                            <Button
                              startIcon={<Visibility />}
                              color="primary"
                              variant="outlined"
                              title="View prescription for this appointment"
                              onClick={() => generatePdf(data?._id)}
                            >
                              View
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color="primary"
                              startIcon={<Send />}
                              title="Send prescription to patient's phone number"
                              variant="outlined"
                              onClick={() => {
                                sendPrescriptionToNumber(data);
                              }}
                            >
                              Resend
                            </Button>
                          </Grid>
                        </Grid> : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "2rem" }}>
          <img src={noMedicalHistory} alt="No record"></img>
          <Typography variant="h5">No Records Found.</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default AppointmentHistory;
