import {
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import customAxios from "../../../axios"
import defaultImage from "./../../../assets/pdf.png"
import noMedicalRecord from "./../../../assets/img/consultation/NoMedicalRecord.png"
import {
  Check,
  CodeOutlined,
  Publish,
  PublishRounded,
} from "@material-ui/icons"
import { colors } from "../../../assets/css/colors"
import { toast } from "react-toastify"
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab"
import TimelineView from "../TimelineView"
function MedicalRecord({ patientData, setLoading }) {
  const { id } = useParams()
  const [others, setOthers] = useState([])
  const [prescription, setPrescription] = useState([])
  const [reports, setReports] = useState([])
  const [state, setState] = useState({ documents: [], type: "", comment: "" })
  const checkDisabled = () => {
    if (!state.type) return true
    if (state?.documents?.length === 0) return true
    else return false
  }
  const [updater, setUpdater] = useState(false)
  const documentsRef = useRef(null)
  const handleSendLabReports = (state) => {
    setLoading(true)
    const docs = state?.documents.map((ele) => ({ ...ele, type: state?.type }))
    const tempObj = {
      ...state,
      documents: [...docs],
    }
    console.log("980", tempObj)
    customAxios
      .put(`/doctor/lab-reports/${patientData._id}`, {
        ...state,
        documents: [...docs],
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          setUpdater((prev) => !prev)
          toast.success("Files uploaded successfully")
          setState({ documents: [], type: "", comment: "" })
        } else {
          setLoading(false)
          toast.error("Some error occured.")
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Some error occured.")
      })
  }
  const uploadFiles = async (files) => {
    const tempArr = [...state.documents]
    if (files) {
      for (const file of files) {
        let reader = new FileReader()

        reader.onload = function (e) {
          tempArr.push({
            file: e.target.result,
            name: file.name,

            type: "Addtional Docs",
            extension: file.type,
          })

          setState({ ...state, documents: [...tempArr] })
        }

        reader.readAsDataURL(file)
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    customAxios
      .get(`/appointments/${id}`)
      .then((res) => {
        customAxios
          .get(`/appointments/get-medical-record/${patientData?._id}`)
          .then((res1) => {
            setLoading(false)
            if (res.data?.data?.documents?.length || res1?.data?.data?.length) {
              let temp = []

              if (res.data?.data?.documents?.length)
                temp = [...res.data?.data?.documents]
              if (res1?.data?.data?.length)
                temp = [...temp, ...res1?.data?.data]

              const reports = temp.filter((ele) => ele.type === "Lab Report")
              const prescription = temp.filter(
                (ele) => ele.type === "Prescription"
              )
              const others = temp.filter((ele) => ele.type === "Other")
              setReports([...reports])
              setPrescription([...prescription])
              setOthers([...others])
            }
          })
          .catch((err) => {
            setLoading(false)
            console.log("record error", err)
          })

        // setPatientName()
      })
      .catch((err) => {
        setLoading(false)

        console.log(err, "error")
      })
    //eslint-disable-next-line
  }, [updater])

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        style={{
          backgroundColor: "#fff",
          marginBottom: "1rem",
          padding: "1rem",
        }}
      >
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <Typography variant="h6">Send Document:</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer style={{ marginBottom: "1%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"35%"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>{"Document Type"}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          fullWidth
                          value={state.type}
                          placeholder="None"
                          onChange={(e) => {
                            const temp = { ...state }
                            temp["type"] = e.target.value
                            setState({ ...temp })
                          }}
                          variant="outlined"
                        >
                          <MenuItem value="Lab Report">Lab Report</MenuItem>
                          <MenuItem value="Prescription">Prescription</MenuItem>
                          <MenuItem value="Other">Other files</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell width={"45%"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>Comment for patient</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          placeholder={"None"}
                          variant="outlined"
                          value={state?.comment}
                          disabled={!state.type}
                          inputProps={{ maxLength: "60" }}
                          onChange={(e) => {
                            const temp = { ...state }
                            temp["comment"] = e.target.value
                            setState({ ...temp })
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={!state.type}
                      startIcon={<PublishRounded />}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        documentsRef.current.click()
                      }}
                    >
                      Documents
                    </Button>
                  </TableCell>

                  <TableCell>
                    <IconButton
                      disabled={checkDisabled()}
                      style={{
                        backgroundColor: checkDisabled()
                          ? "#D3D3D3"
                          : colors.primary,
                        color: "white",
                      }}
                      onClick={() => {
                        handleSendLabReports(state)
                      }}
                    >
                      <Check />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
        <input
          onChange={(e) => {
            uploadFiles(e.target.files)
          }}
          hidden
          label="additionalFiles"
          ref={documentsRef}
          title="FilesUploader"
          type="file"
          multiple
          accept="application/pdf"
        ></input>
        <Grid item xs={12} container>
          {state?.documents?.map((file, idx) => {
            console.log("files", file.name)
            return (
              <div className="m-1">
                <Chip
                  variant="outlined"
                  color="primary"
                  onDelete={() => {
                    const temp = [...state.documents]

                    temp.splice(idx, 1)
                    setState({ ...state, documents: [...temp] })
                  }}
                  label={file.name}
                ></Chip>
              </div>
            )
          })}
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          backgroundColor: "#fff",
          borderRadius: "0px 40px 0px 40px",
          padding: "1rem",
        }}
      >
        <Grid item xs={12}>
          <TimelineView patientId={patientData._id} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MedicalRecord
