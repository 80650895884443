import React, { useEffect, useState } from "react"
import { Button, Modal } from "reactstrap/lib"
import ReactDatetime from "react-datetime"

// reactstrap components
import {
	FormGroup,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
	Row,
} from "reactstrap"
import customAxios from "../../../axios"
import { toast } from "react-toastify"
import moment from "moment"

export default function CancelSlotModal({
	isOpen,
	setIsOpen,
	currentLocationId,
}) {
	const [state, setState] = useState({})
	const [beforeCancel, setBeforeCancel] = useState({})

	useEffect(() => {
		console.log(state)
	}, [state])

	const yesterday = moment().subtract(1, 'day');
	const disablePastDt = current => {
		return current.isAfter(yesterday);
	};

	const disableEndDt = current => {
		if (state.startDate) {
			return current.isAfter(state.startDate.format("YYYY-MM-DD"));
		}
	}

	function convert(str) {
		var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2)
		return [date.getFullYear(), mnth, day].join("-")
	}

	const onProceed = () => {
		let objToSend = {}
		objToSend.startDate = convert(state.startDate._d)
		objToSend.endDate = convert(state.endDate._d)
		objToSend.orgId = currentLocationId
		console.log(objToSend)
		customAxios
			.put("slots/before-cancel", objToSend)
			.then(res => {
				setBeforeCancel(res.data.data)
				console.log(res)
			})
			.catch(error => {
				console.log(error)
			})
	}

	useEffect(() => {
		console.log(beforeCancel)
	}, [beforeCancel])

	function isEmptyObject(obj) {
		return Object.keys(obj).length === 0
	}

	const onSumbit = () => {
		let objToSend = {}
		objToSend.startDate = convert(state.startDate._d)
		objToSend.endDate = convert(state.endDate._d)
		objToSend.orgId = currentLocationId
		console.log(objToSend)
		customAxios
			.put("slots/cancel-by-range", objToSend)
			.then(res => {
				console.log(res)
				setIsOpen(false)
				setState({})
				setBeforeCancel({})
				toast.success("Slots have been changed")
			})
			.catch(error => {
				console.log(error)
			})
	}
	const onCancel = () => {
		setIsOpen(false)
	}
	return (
		<Modal
			className="modal-dialog-centered modal-lg p-20 mt-10 mb-10"
			isOpen={isOpen}
			toggle={() => setIsOpen(false)}
			style={{ minWidth: "300px", minHeight: "300px", padding: "20px" }}>
			<div style={{ textAlign: "center", margin: "20px", marginTop: "30px" }}>
				<Row>
					<Col xs={6}>
						<div className="pb-4">Start Date</div>
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" />
									</InputGroupText>
								</InputGroupAddon>
								<ReactDatetime
									inputProps={{
										placeholder: "Date Picker Here",
									}}
									timeFormat={false}
									renderDay={(props, currentDate, selectedDate) => {
										let classes = props.className
										if (
											state.startDate &&
											state.endDate &&
											state.startDate._d + "" === currentDate._d + ""
										) {
											classes += " start-date"
										} else if (
											state.startDate &&
											state.endDate &&
											new Date(state.startDate._d + "") <
											new Date(currentDate._d + "") &&
											new Date(state.endDate._d + "") >
											new Date(currentDate._d + "")
										) {
											classes += " middle-date"
										} else if (
											state.endDate &&
											state.endDate._d + "" === currentDate._d + ""
										) {
											classes += " end-date"
										}
										return (
											<td {...props} className={classes}>
												{currentDate.date()}
											</td>
										)
									}}
									onChange={e => {
										console.log(e)
										setState({ startDate: e })
									}}
									isValidDate={disablePastDt}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					<Col xs={6}>
						<div className="pb-4">End Date</div>
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" />
									</InputGroupText>
								</InputGroupAddon>
								<ReactDatetime
									inputProps={{
										placeholder: "Date Picker Here",
									}}
									timeFormat={false}
									renderDay={(props, currentDate, selectedDate) => {
										let classes = props.className
										if (
											state.startDate &&
											state.endDate &&
											state.startDate._d + "" === currentDate._d + ""
										) {
											classes += " start-date"
										} else if (
											state.startDate &&
											state.endDate &&
											new Date(state.startDate._d + "") <
											new Date(currentDate._d + "") &&
											new Date(state.endDate._d + "") >
											new Date(currentDate._d + "")
										) {
											classes += " middle-date"
										} else if (
											state.endDate &&
											state.endDate._d + "" === currentDate._d + ""
										) {
											classes += " end-date"
										}
										return (
											<td {...props} className={classes}>
												{currentDate.date()}
											</td>
										)
									}}
									onChange={e => setState({ ...state, endDate: e })}
									isValidDate={disableEndDt}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
				</Row>
				<br />
				<div style={{ textAlign: "center" }}>
					<Button color="primary" type="button" onClick={onProceed}>
						Proceed
					</Button>
				</div>
				<div>
					{!isEmptyObject(beforeCancel) && (
						<>
							<text style={{ color: "red" }}>
								You will cancel {beforeCancel.bookedSlot} booked slots for
								{beforeCancel.patientCount} patients if you wish to proceed
							</text>
							<div>
								<Button onClick={onCancel}>Cancel</Button>
								<Button onClick={onSumbit}>OK</Button>
							</div>
						</>
					)}
				</div>
			</div>
		</Modal>
	)
}
