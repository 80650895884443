import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import customAxios from "../../../axios"
import {
  Grid,
  Typography,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Chip,
} from "@material-ui/core"
import moment from "moment"
import ManagePaymentDialog from "../../Dashboard/ManagePaymentDialog"
import GenericDialogForm from "../../CreatePrescription/GenericDialog"
import noRecords from "./../../../assets/img/consultation/NoMedicalHistory.png"

function TreatmentHistory({
  patientId,
  patientData,
  setLoading,
  setInvoiceData,
  setPaymentHistoryDialogOpen,
}) {
  const [tableData, setTableData] = useState([])
  const [managePaymentDialogOpen, setManagePaymentDialogOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [updater, setUpdater] = useState(false)
  const [editTreatmentModalOpen, setEditTreatmentModalOpen] = useState(false)
  const [endTreatmentDialogOpen, setEndTreatmentDialogOpen] = useState(false)
  useEffect(() => {
    getTreatmentHistory()
    //eslint-disable-next-line
  }, [patientId, updater])
  const getTreatmentHistory = () => {
    setLoading(true)
    customAxios
      .get(`/treatment/filter/all?patient_id=${patientId}`)
      .then((res) => {
        if (res.data.success) {
          setLoading(false)

          setTableData(res.data.data.reverse().slice())
        } else toast.error("Some error occured")
      })
      .catch((err) => {
        setLoading(false)

        toast.error("Some error occured")
      })
  }

  return (
    <Grid container style={{ padding: "1rem" }}>
      {tableData.length ? (
        <Grid item xs={12}>
          <TableContainer style={{ width: "100%" }}>
            <Table style={{ width: "100%" }}>
              <TableHead>
                <TableRow
                  style={{
                    background: "aliceblue",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  <TableCell>Date</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>No. of visits</TableCell>
                  <TableCell>Payments</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => {
                  return (
                    <TableRow>
                      <TableCell style={{ maxWidth: "23%" }}>
                        {moment.utc(row.date).format("DD MMM YYYY hh:MM A ")}
                      </TableCell>
                      <TableCell>
                        {row.name}

                        {row.treatmentCompleted ? (
                          <Chip
                            label="Completed"
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: "0.25em",
                              marginLeft: "0.3rem",
                              display: "inherit",
                            }}
                          ></Chip>
                        ) : null}
                      </TableCell>
                      <TableCell>{row?.appointments?.length}</TableCell>
                      <TableCell>
                        Total-
                        <span style={{ fontWeight: "700" }}>
                          ₹{row.totalAmount}
                        </span>
                        <br />
                        Paid-
                        <span style={{ fontWeight: "700", color: "green" }}>
                          ₹{row.currentPaid}
                        </span>
                        <br />
                        Remaining-
                        <span style={{ fontWeight: "700", color: "red" }}>
                          ₹{row.totalAmount - row.currentPaid}
                        </span>
                      </TableCell>
                      <TableCell style={{ maxWidth: "33%" }}>
                        {" "}
                        <Grid container spacing={2}>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<i class="fas fa-pencil"></i>}
                              onClick={() => {
                                setEditTreatmentModalOpen(true)
                                setModalData({
                                  name: row.name,
                                  totalAmount: row.totalAmount,
                                  treatmentId: row._id,
                                })
                              }}
                            >
                              {" "}
                              Edit
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<i class="fas fa-money-bill-wave"></i>}
                              onClick={() => {
                                if (row.appointmentInvoice) {
                                  setInvoiceData(row.appointmentInvoice)
                                  setPaymentHistoryDialogOpen(true)
                                } else {
                                  toast.error(
                                    "No payment history found for this procedure"
                                  )
                                }
                              }}
                            >
                              {" "}
                              View payments
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              variant="outlined"
                              style={{ border: "1px solid red", color: "red" }}
                              // startIcon={<i class="fas fa-money-bill-wave"></i>}
                              onClick={() => {
                                setModalData({
                                  appointmentId:
                                    row?.appointments?.[
                                      row?.appointments?.length - 1
                                    ]?._id,
                                  treatmentId: row._id ? row._id : null,
                                  patientId: patientId,
                                  patient: patientData,
                                  invoiceData: [],
                                  docId:
                                    row?.appointments?.[
                                      row?.appointments?.length - 1
                                    ]?.docId,
                                })
                                setManagePaymentDialogOpen(true)
                              }}
                            >
                              Manage Payments
                            </Button>
                          </Grid>
                          {!row.treatmentCompleted ? (
                            <Grid item>
                              <Button
                                variant="outlined"
                                style={{
                                  border: "1px solid green",
                                  color: "green",
                                }}
                                startIcon={<i class="fas fa-check-circle"></i>}
                                onClick={() => {
                                  setEndTreatmentDialogOpen(true)
                                  setModalData({ ...row })
                                }}
                              >
                                Mark as Complete
                              </Button>
                            </Grid>
                          ) : null}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}
      {editTreatmentModalOpen ? (
        <GenericDialogForm
          dialogContent={[
            {
              label: "Name",
              key: "name",
              otherProps: { inputProps: { maxLength: 40 } },
            },
            { label: "Total Cost", key: "totalAmount" },
          ]}
          formData={modalData}
          dialogTitle={"Edit Procedure"}
          open={editTreatmentModalOpen}
          setOpen={setEditTreatmentModalOpen}
          handleClose={() => {
            setEditTreatmentModalOpen(false)
            setModalData(null)
          }}
          handleSubmit={(data) => {
            customAxios
              .put(`/treatment/?treatment_id=${data.treatmentId}`, data)
              .then((res) => {
                if (res.data.success) {
                  toast.success("Procedure updated successfully")
                  setEditTreatmentModalOpen(false)
                  setUpdater((prev) => !prev)
                } else {
                  toast.error("Some error occured")
                }
              })
              .catch((err) => toast.error("Some error occured"))
          }}
        />
      ) : null}
      {managePaymentDialogOpen ? (
        <ManagePaymentDialog
          managePaymentDialogOpen={managePaymentDialogOpen}
          setManagePaymentDialogOpen={setManagePaymentDialogOpen}
          modalData={modalData}
          setUpdater={setUpdater}
        />
      ) : null}
      {endTreatmentDialogOpen ? (
        <GenericDialogForm
          open={endTreatmentDialogOpen}
          dialogTitle={"Complete Treatment"}
          handleClose={() => {
            setEndTreatmentDialogOpen(false)
            setModalData(null)
          }}
          message={
            <Grid item xs={12}>
              {modalData?.totalAmount != modalData?.currentPaid ? (
                <Typography variant="h6">
                  Payments for this treatment are still{" "}
                  <span style={{ color: "red" }}>pending.</span>
                </Typography>
              ) : null}
              <Typography variant="h6">
                Are you sure you want to mark this treatment as complete?
              </Typography>
            </Grid>
          }
          handleSubmit={() => {
            customAxios
              .put(
                `/treatment/?treatment_id=${modalData._id}&mark_complete=true`,
                { name: modalData.name, totalAmount: modalData.totalAmount }
              )
              .then((res) => {
                if (res.data.success) {
                  setUpdater((prev) => !prev)

                  toast.success("Procedure completed successfully")
                } else {
                  toast.error("Some error occured")
                }
              })
              .catch((err) => {
                toast.error("Some error occured")
              })
          }}
        />
      ) : null}
      {tableData.length === 0 ? (
        <Grid
          item
          xs={12}
          style={{
            height: "60vh",
            justifyContent: "center",
            alignItems: "center",
          }}
          container
          direction="column"
        >
          <img
            src={noRecords}
            style={{ width: "400px", objectFit: "contain" }}
            alt="empty"
          />
          <Typography variant="h6">No records found.</Typography>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default TreatmentHistory
