import { Avatar, Button, Grid, Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import customAxios from "../../../axios"
import GenericCardTable from "../GenericCardTable"
import GenericDialogForm from "../GenericDialog"
import { medicalTestsForSearch } from "./../Constants/index"
import { medicalTestsOH } from "./../Constants/ohTestList"
import { v4 as uuidv4 } from 'uuid';


function Tests({ state, setState, docId, setActiveIndex, activeIndex, padIndex, frequentData }) {
  const [suggestedTests, setSuggestedTests] = useState([])
  const user = useSelector((state) => state.user)
  useEffect(() => {
    const testsLength = state?.tests?.length
    if (testsLength) {
      let suggestedState = []
      let querytests = state.tests.map((ele) => ele.name)
      // console.log("resd", user.user)
      customAxios
        .put(`/ai/tests`, {
          names: querytests.reverse(),
          doc_id: docId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            suggestedState = suggestedState.concat(res?.data?.data)
            suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState.filter(
              (symp) =>
                !state?.tests?.find(
                  (f) => f?.name?.toLowerCase() === symp.toLowerCase()
                )
            )
            setSuggestedTests([...suggestedState])
          }
        })
    }
  }, [state?.tests])

  const autoCompleteTest = (cardState, setCardState) => {
    if (cardState?.name) {
      let similarTest = medicalTestsForSearch.filter((v) => v?.toLowerCase()?.includes(cardState?.name?.trim()?.toLowerCase()));

      setSuggestedTests([...similarTest]);
    } else {
      setSuggestedTests([...medicalTestsForSearch])
    }
  }

  const cardTableHeaders = [
    {
      label: "Test",
      key: "name",
      placeholder: "e.g Full Body Checkup",
      type: "select",
      freeSolo: true,
      options: [...new Set([...(frequentData?.frequentTests ? Object.values(frequentData?.frequentTests) : []), ...(user?.user?.ohID ? Object.keys(medicalTestsOH) : medicalTestsForSearch)])],
      required: true,
      width: "75%",
      showRetailPriceOH: user?.user?.ohID ? true : false
    },
  ]

  useEffect(() => {
    getRxGroup()
  }, [])

  const [openDialog, setOpenDialog] = useState({})
  const [selectedRxGroup, setSelectedRxGroup] = useState({})
  const [doctorRxGroup, setDoctorRxGroup] = useState([])
  const getRxGroup = () => {
    customAxios.get("doctor/rx-group?type=tests").then((res) => {
      if (res?.data?.success) setDoctorRxGroup([...res?.data?.data])
    })
  }

  const addRxDialogContent = [
    {
      label: "Name",
      key: "name",
      placeholder: "eg. Fever",
      type: "text",
      required: true,
      md: 12,
    },
    {
      errorKey: "Atleast one option",
      key: "rxMeds",
      type: "checkbox",
      options: [...state.tests],
      optionKey: "name",
      required: true,
    },
  ]

  const selectMedsDialogContent = [
    {
      label: selectedRxGroup?.["name"],
      key: "rxMeds",
      type: "checkbox",
      options: selectedRxGroup?.["rxMeds"]
        ? [...selectedRxGroup?.["rxMeds"]]
        : [],
      optionKey: "name",
    },
  ]

  const addRxGroup = (rxData, isEdit = false) => {
    customAxios.put("doctor/rx-group?type=tests", rxData).then((res) => {
      setOpenDialog({})
      toast.success("Template Saved Successfuly!")
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const removeRxGroup = (rxData) => {
    customAxios.put("doctor/delete-rx-group?type=tests", rxData).then((res) => {
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const chooseRxGroup = (rxData) => {
    setSelectedRxGroup({ ...rxData })
    setOpenDialog({ selectMeds: true })
  }

  const handleSelectMeds = (rxData) => {
    if (rxData?.rxMeds && rxData?.rxMeds?.length) {
      const temp = [...state.tests]
      rxData?.rxMeds?.map((f) => {
        const check = state.tests.findIndex((val) => val?.name === f?.name)
        if (check === -1) temp.push({ ...f })
        else temp.splice(check, 1, { ...f })
      })
      setState({ ...state, tests: [...temp] });
      setOpenDialog({})
    }
  }

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Test Details:"}
        suggestedTitle={"Suggested Tests"}
        suggestedData={suggestedTests}
        tableHeaders={cardTableHeaders}
        tableData={state}
        suggestedTab={suggestedTests.length}
        cardKey="tests"
        cardName="Test Name"
        setTableData={setState}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        padIndex={padIndex}

        onSelectTemplate={handleSelectMeds}
        onDeleteTemplate={removeRxGroup}
        onUpdateTemplate={addRxGroup}
        selectIconText={"Tx"}
        savedTemplates={doctorRxGroup}

        // addOnFunction={autoCompleteTest}
        frequentOptions={frequentData?.frequentTests ? Object.values(frequentData?.frequentTests) : []}
        headerIcon={(<Avatar style={{
                fontSize: 18,
                width: 30,
                height: 30, 
                marginRight: 10, 
                color: "#5e72e4", 
                backgroundColor: "white",
                marginBottom: "10px",
                marginTop: "10px",
              }}>
        <i className="fas fa-flask-vial"></i>
      </Avatar>)}
      />
      {/* <Grid container>
        <Grid
          item
          xs={6}
          style={{ display: "flex", flex: "flex-wrap", alignItems: "center" }}
        >
          <Typography variant="body2">
            * Choose from templates
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setOpenDialog({ chooseRx: true })}
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>

        <Grid item container xs={6} justifyContent="flex-end">
          {state.tests?.length ? (
            <Grid item>
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={() => setOpenDialog({ addRx: true })}
              >
                <Typography variant="body2">Create template</Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid> */}
      {openDialog?.chooseRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Choose Template"}
          dialogContent={[...doctorRxGroup]}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={chooseRxGroup}
          showDialogAction={false}
          isList={true}
          editListItem={true}
          removeListItem={true}
          onEditItem={(data) =>
            setOpenDialog({ editRx: true, data: { ...data, rxMeds: data?.rxMeds?.map(v => ({...v, uuid: uuidv4()}))} })
          }
          onRemoveItem={removeRxGroup}
        />
      ) : null}
      {openDialog?.selectMeds ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Select tests"}
          dialogContent={selectMedsDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={handleSelectMeds}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{
            rxMeds: selectedRxGroup?.["rxMeds"]?.filter((f) => {
              const check = state?.tests?.find(
                (val) => val?.name === f?.name
              )
              if (check) return false
              else return true
            }),
          }}
        />
      ) : null}
      {openDialog?.addRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Create Template"}
          dialogContent={addRxDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={addRxGroup}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{ rxMeds: [...state?.tests] }}
        />
      ) : null}
      {openDialog?.editRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Update Template"}
          dialogContent={[
            { ...addRxDialogContent[0] },
            {
              ...addRxDialogContent[1],
              type: "checkbox_and_edit",
              options: [...openDialog?.data?.rxMeds],
            },
          ]}
          handleCancel={() => setOpenDialog({ chooseRx: true })}
          handleSubmit={(data) =>
            addRxGroup({ ...data, prevName: openDialog?.data?.name }, true)
          }
          editOptions={(val, med, idx) => {
            let tempData = {...openDialog?.data}
            tempData.rxMeds[idx] = {...med, name: val}

            setOpenDialog({...openDialog, data: {...tempData}})
          }}
          cancelButtonText={"Cancel"}
          submitButtonText={"Update"}
          formData={{ ...openDialog?.data }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Tests
