import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Radio, RadioGroup, Dialog, DialogTitle, IconButton, DialogContent, DialogActions, CircularProgress
} from "@material-ui/core"
import { ArrowBack, Close, Create, GetApp, Print, Receipt, Replay, Translate } from "@material-ui/icons"
import React, { useRef, useState } from "react"
import { toast } from "react-toastify"
// import AWS from "aws-sdk"


import { createLogicalAnd } from "typescript"
import prescriptionSocket from "../../../socket"
import Invoice from "../Invoice"
import AWS from "aws-sdk"
import GenericDialogForm from "./../../CreatePrescription/GenericDialog/index"
import customAxios from "../../../axios"
import whatsappLogo from "./../../../assets/img/icons/common/whatsapp.png"
import { useDispatch } from "react-redux"

import {SET_USER_LANG} from "../../../redux/constants/index"
import { useEffect } from "react"

import { Document, Page, pdfjs } from 'react-pdf';
import { pdfjsWorker } from 'pdfjs-dist/build/pdf.worker.entry';
import { range } from "lodash"
import ManagePaymentDialog from "../../Dashboard/ManagePaymentDialog"
import { useParams } from "react-router"

function EndConsultation({
  appointmentData,
  setActiveStep,
  state,
  setState,
  setLoading,
  requiredKeys = [],
  handleEndConsultation,
  printModalData,
  setPrintModalData,
  loader
}) {
  const [invoiceOpen, setInvoiceOpen] = useState(false)
  const [sendToPatient, setSendToPatient] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [dialogOpen, setDialogOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [paymentModalData, setPaymentModalData] = useState({})
  const [managePaymentDialogOpen, setManagePaymentDialogOpen] = useState(false)
  const [invoiceNo, setInvoiceNo] = useState(null)
  var reg = new RegExp("^[0-9]*$")
  
  const handleDownloadInvoice = (data, download = false, print = false) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    // AWS.config.update({
    //   accessKeyId: "AKIAUSXZZ7HZK47LXEVL",
    //   secretAccessKey: "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",
    // })
    // var s3 = new AWS.S3()
    // let params = {
    //   Bucket: "paperplane-s3",
    //   Key: `${data.location + data._id}`,
    // }
    customAxios
      .put(`consultation/send-invoice/${appointmentData._id}`)
      .then((res) => {
        if (res.data.success) {
          toast.success(`Invoice sent successfully .`)
        } else {
          toast.error("Some error occured while sending the invoice.")
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Some error occured")
      })

      fetch(data?.url)
      .then((response) => {
        if (response.ok) {
          let blob = response.blob();
          if (download) {
            a.href = window.URL.createObjectURL(blob)
            a.download = `${appointmentData?.patient?.name}_(#${appointmentData?.patient?.code})_Invoice`
            a.click()
            window.URL.revokeObjectURL(data?.url)
          }
          if (print) {
            let pdfw = window.open(window.URL.createObjectURL(blob), "_blank")
            // you need to call print after window loads like this
            if (pdfw) {
              pdfw.onload = function () {
                pdfw.print()
              }
            } else {
              window.alert("Please allow popups")
            }
          }
          setLoading(false)
        }})

    // s3.getObject(params, (err, res) => {
    //   let blob = new Blob([res.Body], { type: res.ContentType })
    //   if (download) {
    //     a.href = window.URL.createObjectURL(blob)
    //     a.download = `${appointmentData?.patient?.name}_(#${appointmentData?.patient?.code})_Invoice`
    //     a.click()
    //     window.URL.revokeObjectURL(data?.url)
    //   }
    //   if (print) {
    //     let pdfw = window.open(window.URL.createObjectURL(blob), "_blank")
    //     // you need to call print after window loads like this
    //     if (pdfw) {
    //       pdfw.onload = function () {
    //         pdfw.print()
    //       }
    //     } else {
    //       window.alert("Please allow popups")
    //     }
    //   }
    //   setLoading(false)
    // })
  }

  const checkDisableDownload = (state) => {
    let error = {}
    requiredKeys.map((v) => {
      if (!state[v.key] || !state[v.key].length) {
        error = { check: true, message: `${v.label} is required` }
      }
    })
    if (error?.check) {
      return true
    }
    return false
  }

  const radioHeaders = [
    { label: "English", value: "en" },
    { label: "Hindi", value: "hi" },
    { label: "Marathi", value: "mr" },
  ]

  const dispatch = useDispatch()

  const [googleReviewLoader, setGoogleReviewLoader] = useState(false)  

  const handleSendGoogleLink = () => {
    setGoogleReviewLoader(true)
    customAxios
    .get(`/patients/send-message/${appointmentData?.patient?.phoneNo}?message_type=review_link`, {})
    .then((res) => {
      setGoogleReviewLoader(false)
      if (res?.data?.success) {
        toast.success("Sent Successfuly!")
      } else {
        toast.error("Some error occured")
      }
    })
    .catch((err) => toast.error("Some error occured"))
  }

  return (
    <Grid container>
      <Grid item xs={12} md={6} style={{ display: "flex", flex: "flex-wrap" }}>
        <Typography variant="h5" style={{ fontWeight: "600" }}>
          End
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          style={{ marginLeft: 5, fontWeight: "600" }}
        >
          Consultation{" "}
        </Typography>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          padding: "2rem 1rem",
          marginTop: "1rem",
          backgroundColor: "#fff",
          borderRadius: "0px 40px 0px 40px",
        }}
      >
        <Grid item xs={12}>
          <Typography>Prescription</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<Create color="primary" />}
            onClick={() => {
              document.getElementById("prescriptionContainer").scrollIntoView()
            }}
          >
            Edit Prescription
          </Button>
        </Grid>
        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
          <Button
            startIcon={<GetApp color="primary" />}
            style={{marginRight: "20px"}}
            disabled={checkDisableDownload(state)}
            onClick={() =>{
              handleEndConsultation({
                onlyPrescription: true,
                download: true,
                sendToPatient: false,
              })
              setState({...state, otherData: (state?.otherData ? {...state?.otherData, downloadPrescription: true} : {downloadPrescription: true})})
            }
            }
          >
            Download Prescription
          </Button>
            {loader?.type === "download" ? <CircularProgress size={22}/> : null}
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            startIcon={<Print color="primary" />}
            disabled={checkDisableDownload(state)}
            onClick={() =>
              handleEndConsultation({
                onlyPrescription: true,
                print: true,
                sendToPatient: false,
              })
            }
          >
            Print Prescription
          </Button>
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            type="tel"
            value={phoneNumber}
            InputProps={{
              endAdornment: (
                <img
                  src={whatsappLogo}
                  style={{
                    width: "22px",
                    objectFit: "contain",
                  }}
                />
              ),
            }}
            variant="outlined"
            placeholder="Additional Phone Number"
            helperText={
              "Add any other phone number you want to send prescription to on WhatsApp"
            }
            inputProps={{ maxLength: "10" }}
            onChange={(e) => {
              if (reg.test(e.target.value)) setPhoneNumber(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <Button
              color="primary"
              onClick={() => {
                setPaymentModalData({
                  id: appointmentData._id,
                  treatmentId: appointmentData.treatmentId
                    ? appointmentData.treatmentId
                    : null,
                  appointmentId: appointmentData._id,
                  name: appointmentData?.patient?.name,
                  code: appointmentData?.patient?.code,
                  patientId: appointmentData?.patient?._id,
                  invoiceData: appointmentData?.invoiceData
                    ? appointmentData.invoiceData
                    : [],
                  appointmentAmount: appointmentData?.amount,
                  patient: appointmentData?.patient,
                  docId: appointmentData?.docId
                });
                setManagePaymentDialogOpen(true)
              }}
              startIcon={<Receipt />}
            >
              Send Invoice
            </Button>
          </Grid>

          <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
            <Button
              color="primary"
              onClick={() => {
                handleSendGoogleLink()
              }}
              startIcon={<i class="fa-brands fa-google"></i>}
            >
              Send Google Review Link
            </Button>
            {googleReviewLoader ? <CircularProgress size={22} style={{marginLeft: "3px"}}/> : null}
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ margin: "1rem 0rem", marginTop: "-5px" }}>
          <Divider />
        </Grid>
        <Grid item container xs={12} spacing={2} style={{padding: 0, marginBottom: "-2%"}}>
          <Grid item>
            <Button
              startIcon={<Translate color="primary" />}
              style={{ cursor: "default" }}
            >
              Choose Language of Instructions
            </Button>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={state?.language ? state?.language : "en"}
                onChange={(e) => {
                  setState({ ...state, language: e.target?.value })
                  dispatch({
                    type: SET_USER_LANG,
                    payload: e.target?.value
                  })
                }}
              >
                {radioHeaders.map((v) => (
                  <FormControlLabel
                    value={v?.value}
                    label={v?.label}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} direction="column" spacing={2}>


          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={sendToPatient}
                  onChange={(e) => setSendToPatient(e.target.checked)}
                />
              }
              label="Send Prescription to Patient"
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#00A87B" }}
              startIcon={loader?.type === 'print' && loader?.loading ? <CircularProgress style={{color: "white"}} size={20} /> : <i class="fab fa-whatsapp"></i>}
              onClick={() =>{
                handleEndConsultation({
                  onlyPrescription: true,
                  print: true,
                  sendToPatient: false,
                })
                // handleEndConsultation({
                //   sendToPatient: sendToPatient,
                //   onlyPrescription: false,
                //   additionalPhoneNumber: phoneNumber,
                // })
              }
              }
            >
              Print & End Consultation
            </Button>
            <Typography
              variant="body1"
              color="secondary"
              style={{ marginTop: "0.5rem" }}
            >
              Click here to end consultation and send prescription on patient's
              WhatsApp
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Invoice
        open={invoiceOpen}
        setOpen={setInvoiceOpen}
        state={appointmentData}
        billFor={modalData.billFor}
        invoiceNo={invoiceNo}
        amount={modalData?.amount}
        id={appointmentData?._id}
      ></Invoice> */}
      {dialogOpen ? (
        <GenericDialogForm
          style={{ overflowY: "visible" }}
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          dialogTitle="Add Info"
          formData={{ invoiceFor: "Consultation", amount: appointmentData?.amount }}
          dialogContent={[
            {
              label: "Bill for",
              key: "invoiceFor",
              type: "select",
              md: 6,
              options: ["Vaccination", "X-Ray", "Service", "Consultation"],
              required: true,
              freeSolo: true
            },
            {
              label: "Invoice amount (Rs.)",
              key: "amount",
              type: "text",
              md: 6,
              required: true,
            },
            {
              label: "Details",
              key: "details",
              type: "text",
              md: 12,
              placeholder: "Eg: 15 sessions of Miniscus tear physiotherapy",
              required: false,
            }
          ]}
          handleSubmit={(formData) => {
            setModalData({ ...formData })
            prescriptionSocket.emit("invoice_no", {
              appointmentId: appointmentData?._id,
            })
            setLoading(true)
            console.log("hello", formData)
            customAxios
              .put(`/consultation/generate-invoice/${appointmentData?._id}`, {
                ...formData,
              })
              .then((res) => {
                if (res.data.success) {
                  handleDownloadInvoice(res.data.data, true, false)
                } else {
                  toast.error("Some error occured")
                  setLoading(false)
                }
              })
              .catch((err) => {
                setLoading(false)

                toast.error("Some error occured")
              })
          }}
        />
      ) : null}

      {printModalData && printModalData?.open ? 
        <PrintPrescriptionDialog 
          printModalData={printModalData} 
          setPrintModalData={setPrintModalData} 
          handleEndConsultation={() => handleEndConsultation({
              sendToPatient: sendToPatient,
              onlyPrescription: false,
              additionalPhoneNumber: phoneNumber,
              endConsultation: true
            })}
          language={state?.language}
          stateData={state}
          setStateData={setState}
          loader={loader}
          /> : null}

      {managePaymentDialogOpen ? (
        <ManagePaymentDialog
          modalData={paymentModalData}
          dialogText={""}
          setUpdater={null}
          setModalData={setPaymentModalData}
          managePaymentDialogOpen={managePaymentDialogOpen}
          setManagePaymentDialogOpen={setManagePaymentDialogOpen}
        />
      ) : null}

    </Grid>
  )
}

export default EndConsultation


function PrintPrescriptionDialog({setPrintModalData, printModalData, handleEndConsultation, language, stateData, setStateData, loader}) {
  
  const { id } = useParams()

  const contentRef = useRef(null);
  const iframeRef = useRef(null);
  const pdfRef = useRef(null);
  // const iframeRef1 = useRef(null);

  // const handlePrint = () => {
  //   AWS.config.update({
  //     accessKeyId: "AKIAUSXZZ7HZK47LXEVL",
  //     secretAccessKey: "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",
  //     region: "ap-south-1"
  //   })
  //   var s3 = new AWS.S3({
  //     signatureVersion: 'v4',
  //   });

  //   let params = {
  //     Bucket: "paperplane-s3",
  //     Key: `${state?.data?._id}`,
  //   }

  //   s3.getObject(params, (err, res) => {
  //     let blob = new Blob([res.Body], { type: res.ContentType })
      
  //     let pdfw = window.open(window.URL.createObjectURL(blob))
  //     // you need to call print after window loads like this
  //     if (pdfw)
  //       pdfw.onload = function () {
  //         pdfw.print()
  //       }
  //     else {
  //       window.alert("Please allow popups")
  //     }
  //   })

  // };

  
  const [presUrl, setPresUrl] = useState(null)
  
  const [numPages, setNumPages] = useState(null);
  
  const [revertLoader, setRevertLoader] = useState(0)
  
  const revertTranslation = () => {
    setRevertLoader(1)
    const tests = stateData?.tests?.map((v) => v.name)
    
    customAxios
    .put(`consultation/generate-prescription/${id}`, {
      ...stateData,
      tests: tests,
      onlyPrescription: true,
      sendToPatient: false,
      print: true,
      language: "en"
    }).then((res) => {
      setRevertLoader(2)
      // setStateData({...stateData, language: "en"})
      if (res.data?.success) {
        setPrintModalData({"open": true, "data": res.data.data, url: res.data?.data?.url})
      }
    })
  }

  const setPrescriptionUrl = (
    data
    ) => {
    // AWS.config.update({
    //   accessKeyId: "AKIAUSXZZ7HZK47LXEVL",
    //   secretAccessKey: "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",
    //   region: "ap-south-1"
    // })
    // var s3 = new AWS.S3({
    //   signatureVersion: 'v4',
    // });

    // let params = {
    //   Bucket: "paperplane-s3",
    //   Key: `${data._id}`,
    // }
    // s3.getSignedUrl('getObject', params, (error, url) => {
    //   if (error) {
    //     console.error('Error generating S3 signed URL:', error);
    //   } else {
    //     setPresUrl(url);
    //     setNumPages(null);

    //     pdfjs.getDocument(url).promise.then((pdf) => {
    //       setNumPages(pdf.numPages);
    //     });
    //   }
    // });

    let url = data?.url

    setPresUrl(url);
    setNumPages(null);

    pdfjs.getDocument(url).promise.then((pdf) => {
      setNumPages(pdf.numPages);
    });

  }
  
  
  const [state, setState] = useState({...printModalData});
  useEffect(() => {
    if(printModalData) setState({...printModalData})
  }, [printModalData])

  useEffect(() => {
    if(state?.data){
      setPrescriptionUrl(state.data)
    }
  }, [state?.data])

  const [printLoad, setPrintLoad] = useState(false)
  
  
  const handlePrint = () => {

    setPrintLoad(true)
    customAxios.put('ai/print-pdf', {"url": presUrl}, {
      responseType: 'blob', // To receive binary data
    }).then(response => {
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      
      const iframe = document.getElementById('pdf-url-print');
      iframe.src = pdfUrl;

      setPrintLoad(false);
      setStateData({...stateData, otherData: (stateData?.otherData ? {...stateData?.otherData, printPrescription: true} : {printPrescription: true})})
      iframe.onload = () => {
        iframe.contentWindow.print();
        // Clean up: remove the iframe after printing is done
      };
    })

  }



  return (
    <Dialog open={printModalData?.open} disableEscapeKeyDown maxWidth={"md"}>
      <DialogTitle>
        <Grid container alignItems="center" spacing={1} style={{marginLeft: "-1rem"}}>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={() => setPrintModalData({...printModalData, open: false})}
              >
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item>
            <span
                style={{
                  // background: colors.gradient,
                color: "white",
                padding: "0.3rem",
                borderRadius: "4px",
                fontSize: 26,
                background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                }}
            >
                {/* Prescription Pad */}
              Prescription Preview
            </span>{" "}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent id="dialog-content" ref={contentRef} style={{padding: 10, paddingLeft: 40, paddingRight: 40}}>
          <iframe
            id="pdf-url-print"
            ref={pdfRef}
            src={presUrl}
            title="PDF Viewer"
            style={{ display: 'none' }} // Hide the iframe
          />
        {presUrl ?
        <div id="print" ref={iframeRef}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '60%', maxWidth: "100%", overflowX: "hidden" }}>
        <Document ref={iframeRef} file={presUrl}>
          {numPages && range(0, numPages)?.map((v, i) => (
            <div style={{marginBottom: 20}}><Page pageNumber={i+1} width={window.innerWidth*0.5} renderTextLayer={false} renderAnnotationLayer={false}/></div>
            
            ))}
        </Document></div> : null}
      </DialogContent>
      <DialogActions>
        {language !== "en" && revertLoader !== 2? <Button onClick={() => revertTranslation()} style={{marginRight: 'auto', borderColor: '#EC7063', color: "#EC7063"}} startIcon={!revertLoader ? <Replay />: <CircularProgress size={20} style={{color: "#EC7063"}}/>} variant="outlined">
          Revert Translation
        </Button> : null}
        <Button onClick={() => setPrintModalData({...state, open: false})} variant="outlined" color="primary">
          Edit Prescription
        </Button>
        <Button onClick={() => handlePrint()} variant="outlined" startIcon={
          !printLoad ? <Print /> : <CircularProgress color="primary" size={22}/>
        } color="primary">
          Print
        </Button>
      <Button onClick={() => {
        handleEndConsultation()
        }} endIcon={loader?.type === "end" && loader?.loading ? <CircularProgress style={{color:"white"}} size={24}/> : null} variant="contained" color="primary">
          End Consultation
        </Button>
      </DialogActions>      
    </Dialog>
  )
}