/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import ReactGA from "react-ga4"
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
  Button,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import moment from "moment"
import mixpanel from "mixpanel-browser"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import customAxios from "../../../axios"
import { connect } from "react-redux"
import axios from "axios"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core"
// core components
function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

function DoctorSlotCreator(props) {
  const [blockDialogOpen, setBlockDialogOpen] = useState(false)
  const [counter, setCounter] = useState(
    props.currentLocation?.counter ? props.currentLocation?.counter : 0
  )
  const [emrCounter, setEmrCounter] = useState(
    props.currentLocation?.emrCounter ? props.currentLocation?.emrCounter : 0
  )
  console.log("908", props.currentLocation)

  //Monday
  const [mondayArray, setMondayArray] = useState([{}])
  const [mondayArrayISO, setMondayArrayISO] = useState([{}])
  const [mondayStatus, setMondayStatus] = useState(false)

  const [intervalForLocation, setIntervalForLocation] = useState("")
  const [currentLocationData, setCurrentLocationData] = useState({})

  //Tuesday
  const [tuesdayArray, setTuesdayArray] = useState([{}])
  const [tuesdayArrayISO, setTuesdayArrayISO] = useState([{}])
  const [tuesdayStatus, setTuesdayStatus] = useState(false)

  //Wednesday
  const [wednesdayArray, setWednesdayArray] = useState([{}])
  const [wednesdayArrayISO, setWednesdayArrayISO] = useState([{}])
  const [wednesdayStatus, setWednesdayStatus] = useState(false)

  //Thursday
  const [thursdayArray, setThursdayArray] = useState([{}])
  const [thursdayArrayISO, setThursdayArrayISO] = useState([{}])
  const [thursdayStatus, setThursdayStatus] = useState(false)

  //Friday
  const [fridayArray, setFridayArray] = useState([{}])
  const [fridayArrayISO, setFridayArrayISO] = useState([{}])
  const [fridayStatus, setFridayStatus] = useState(false)

  //Saturday
  const [saturdayArray, setSaturdayArray] = useState([{}])
  const [saturdayArrayISO, setSaturdayArrayISO] = useState([{}])
  const [saturdayStatus, setSaturdayStatus] = useState(false)

  const [sundayArray, setSundayArray] = useState([{}])
  const [sundayArrayISO, setSundayArrayISO] = useState([{}])
  const [sundayStatus, setSundayStatus] = useState(false)

  const [isLoactionOnline, setIsLoactionOnline] = useState("")
  const [updateDate, setUpdateDate] = useState("")

  useEffect(() => {
    setIsLoactionOnline(props.currentLocation?.isOnline)
    setCounter(
      props?.currentLocation?.counter ? props?.currentLocation?.counter : 0
    )
    setEmrCounter(
      props?.currentLocation?.emrCounter
        ? props?.currentLocation?.emrCounter
        : 0
    )
    setMondayStatus(!!props.currentLocation?.workingHours?.monday)
    const temp_monday_array = props.currentLocation?.workingHours?.monday?.map(
      (item) => ({
        start: moment.utc(item.start).format("HH:mm"),
        end: moment.utc(item.end).format("HH:mm"),
      })
    )

    if (temp_monday_array && Array.isArray(temp_monday_array)) {
      setMondayArray(temp_monday_array)
    } else {
      setMondayArray([{}])
    }
    setTuesdayStatus(!!props.currentLocation?.workingHours?.tuesday)
    const temp_tuesday_array =
      props.currentLocation?.workingHours?.tuesday?.map((item) => ({
        start: moment.utc(item.start).format("HH:mm"),
        end: moment.utc(item.end).format("HH:mm"),
      }))
    if (temp_tuesday_array && Array.isArray(temp_tuesday_array)) {
      setTuesdayArray(temp_tuesday_array)
    } else {
      setTuesdayArray([{}])
    }

    setWednesdayStatus(!!props.currentLocation?.workingHours?.wednesday)
    const temp_wednesday_array =
      props.currentLocation?.workingHours?.wednesday?.map((item) => ({
        start: moment.utc(item.start).format("HH:mm"),
        end: moment.utc(item.end).format("HH:mm"),
      }))
    if (temp_wednesday_array && Array.isArray(temp_wednesday_array)) {
      setWednesdayArray(temp_wednesday_array)
    } else {
      setWednesdayArray([{}])
    }

    setThursdayStatus(!!props.currentLocation?.workingHours?.thursday)
    const temp_thursday_array =
      props.currentLocation?.workingHours?.thursday?.map((item) => ({
        start: moment.utc(item.start).format("HH:mm"),
        end: moment.utc(item.end).format("HH:mm"),
      }))
    if (temp_thursday_array && Array.isArray(temp_thursday_array)) {
      setThursdayArray(temp_thursday_array)
    } else {
      setThursdayArray([{}])
    }

    setFridayStatus(!!props.currentLocation?.workingHours?.friday)
    const temp_friday_array = props.currentLocation?.workingHours?.friday?.map(
      (item) => ({
        start: moment.utc(item.start).format("HH:mm"),
        end: moment.utc(item.end).format("HH:mm"),
      })
    )
    if (temp_friday_array && Array.isArray(temp_friday_array)) {
      setFridayArray(temp_friday_array)
    } else {
      setFridayArray([{}])
    }

    setSaturdayStatus(!!props.currentLocation?.workingHours?.saturday)
    const temp_saturday_array =
      props.currentLocation?.workingHours?.saturday?.map((item) => ({
        start: moment.utc(item.start).format("HH:mm"),
        end: moment.utc(item.end).format("HH:mm"),
      }))
    if (temp_saturday_array && Array.isArray(temp_saturday_array)) {
      setSaturdayArray(temp_saturday_array)
    } else {
      setSaturdayArray([{}])
    }

    setSundayStatus(!!props.currentLocation?.workingHours?.sunday)
    const temp_sunday_array = props.currentLocation?.workingHours?.sunday?.map(
      (item) => ({
        start: moment.utc(item.start).format("HH:mm"),
        end: moment.utc(item.end).format("HH:mm"),
      })
    )
    setSundayArray(temp_sunday_array)
    if (temp_sunday_array && Array.isArray(temp_sunday_array)) {
      setSundayArray(temp_sunday_array)
    } else {
      setSundayArray([{}])
    }
    setCurrentLocationData(props.currentLocation)
    setUpdateDate(props.currentLocation?.workingHours?.updateDate)
    setIntervalForLocation(props.currentLocation?.workingHours?.interval)
  }, [props.currentLocation])

  useEffect(() => {}, [currentLocationData])

  const handleSubmit = async (e) => {
    ReactGA.event({
      category: "Doctor Slot",
      action: "Slot Changed",
    })
    e.preventDefault()

    let dataToSend = {}

    let working_hours = []
    let monday = {}
    let tuesday = {}
    let wednesday = {}
    let thursday = {}
    let friday = {}
    let saturday = {}
    let sunday = {}

    let mondayErrorCounter = 0
    let tuesdayErrorCounter = 0
    let wednesdayErrorCounter = 0
    let thursdayErrorCounter = 0
    let fridayErrorCounter = 0
    let saturdayErrorCounter = 0
    let sundayErrorCounter = 0

    let mondayStartEndTimeCounter = 0
    let tuesdayStartEndTimeCounter = 0
    let wednesdayStartEndTimeCounter = 0
    let thursdayStartEndTimeCounter = 0
    let fridayStartEndTimeCounter = 0
    let saturdayStartEndTimeCounter = 0
    let sundayStartEndTimeCounter = 0

    let totalErrorCounter = 0

    if (mondayArray[0] && isEmpty(mondayArray[0])) {
      monday = null
    } else {
      setMondayArrayISO([])
      for (let x = 0; x < mondayArray.length; x++) {
        const isoDateStart = moment.utc(mondayArray[x].start, "HH:mm")

        const isoDateEnd = moment.utc(mondayArray[x].end, "HH:mm")

        let temp = {}
        temp.start = isoDateStart.toISOString()
        temp.end = isoDateEnd.toISOString()
        let mondayDifference = new Date(temp.end) - new Date(temp.start)
        if (mondayDifference > 0) {
          mondayArrayISO.push(temp)
        } else {
          // toast.error("Start time greater than end time in Monday time slots")
          mondayStartEndTimeCounter++
          totalErrorCounter++
        }
      }
      mondayArrayISO.sort(function (a, b) {
        let myDateA = new Date(a.start)
        let myDateB = new Date(b.start)
        return myDateA.getTime() - myDateB.getTime()
      })

      for (let x = 0; x < mondayArrayISO.length - 1; x++) {
        let element_1 = new Date(mondayArrayISO[x].end)
        let element_2 = new Date(mondayArrayISO[x + 1].start)

        if (
          element_1.getTime() === element_2.getTime() ||
          element_1.getTime() > element_2.getTime()
        ) {
          // toast.error("Overlap in the time slots created on Monday")
          mondayErrorCounter++
          totalErrorCounter++
          //return;
        }
      }
      if (mondayArrayISO[0] && isEmpty(mondayArrayISO[0])) {
        mondayArrayISO.shift()
      }
      setMondayArrayISO(mondayArrayISO)
      working_hours = mondayArrayISO
      setMondayArrayISO([])

      monday = working_hours
    }

    if (tuesdayArray[0] && isEmpty(tuesdayArray[0])) {
      tuesday = null
    } else {
      setTuesdayArrayISO([])
      for (let x = 0; x < tuesdayArray.length; x++) {
        const isoDateStart = moment.utc(tuesdayArray[x].start, "HH:mm")

        const isoDateEnd = moment.utc(tuesdayArray[x].end, "HH:mm")

        let temp = {}

        temp.start = isoDateStart.toISOString()
        temp.end = isoDateEnd.toISOString()

        let tuesdayDifference = new Date(temp.end) - new Date(temp.start)
        if (tuesdayDifference > 0) {
          tuesdayArrayISO.push(temp)
        } else {
          // toast.error("Start time greater than end time in Tuesday time slots")
          tuesdayStartEndTimeCounter++
          totalErrorCounter++
        }
      }

      tuesdayArrayISO.sort(function (a, b) {
        let myDateA = new Date(a.start)
        let myDateB = new Date(b.start)
        return myDateA.getTime() - myDateB.getTime()
      })

      for (let x = 0; x < tuesdayArrayISO.length - 1; x++) {
        let element_1 = new Date(tuesdayArrayISO[x].end)
        let element_2 = new Date(tuesdayArrayISO[x + 1].start)

        if (
          element_1.getTime() === element_2.getTime() ||
          element_1.getTime() > element_2.getTime()
        ) {
          // toast.error("Overlap in the time slots created on Tuesday")
          tuesdayErrorCounter++
          totalErrorCounter++
          //return;
        }
      }
      if (tuesdayArray[0] && isEmpty(tuesdayArrayISO[0])) {
        tuesdayArrayISO.shift()
      }
      setTuesdayArrayISO(tuesdayArrayISO)
      working_hours = tuesdayArrayISO
      setTuesdayArrayISO([])

      tuesday = working_hours
    }

    if (wednesdayArray[0] && isEmpty(wednesdayArray[0])) {
      wednesday = null
    } else {
      setWednesdayArrayISO([])
      for (let x = 0; x < wednesdayArray.length; x++) {
        const isoDateStart = moment.utc(wednesdayArray[x].start, "HH:mm")

        const isoDateEnd = moment.utc(wednesdayArray[x].end, "HH:mm")

        let temp = {}

        temp.start = isoDateStart.toISOString()
        temp.end = isoDateEnd.toISOString()
        let wednesdayDifference = new Date(temp.end) - new Date(temp.start)
        if (wednesdayDifference > 0) {
          wednesdayArrayISO.push(temp)
        } else {
          // toast.error(
          // 	"Start time greater than end time in Wednesday time slots"
          // )
          wednesdayStartEndTimeCounter++
          totalErrorCounter++
        }
      }

      wednesdayArrayISO.sort(function (a, b) {
        let myDateA = new Date(a.start)
        let myDateB = new Date(b.start)
        return myDateA.getTime() - myDateB.getTime()
      })

      for (let x = 0; x < wednesdayArrayISO.length - 1; x++) {
        let element_1 = new Date(wednesdayArrayISO[x].end)
        let element_2 = new Date(wednesdayArrayISO[x + 1].start)

        if (
          element_1.getTime() === element_2.getTime() ||
          element_1.getTime() > element_2.getTime()
        ) {
          // toast.error("Overlap in the time slots created on Wednesday")
          wednesdayErrorCounter++
          totalErrorCounter++
          //return;
        }
      }
      if (wednesdayArray[0] && isEmpty(wednesdayArrayISO[0])) {
        wednesdayArrayISO.shift()
      }
      setWednesdayArrayISO(wednesdayArrayISO)
      working_hours = wednesdayArrayISO
      setWednesdayArrayISO([])

      wednesday = working_hours
    }

    if (thursdayArray[0] && isEmpty(thursdayArray[0])) {
      thursday = null
    } else {
      setThursdayArrayISO([])
      for (let x = 0; x < thursdayArray.length; x++) {
        const isoDateStart = moment.utc(thursdayArray[x].start, "HH:mm")

        const isoDateEnd = moment.utc(thursdayArray[x].end, "HH:mm")

        let temp = {}

        temp.start = isoDateStart.toISOString()
        temp.end = isoDateEnd.toISOString()

        let thursdayDifference = new Date(temp.end) - new Date(temp.start)
        if (thursdayDifference > 0) {
          thursdayArrayISO.push(temp)
        } else {
          // toast.error("Start time greater than end time in Thursday time slots")
          thursdayStartEndTimeCounter++
          totalErrorCounter++
        }
      }

      thursdayArrayISO.sort(function (a, b) {
        let myDateA = new Date(a.start)
        let myDateB = new Date(b.start)
        return myDateA.getTime() - myDateB.getTime()
      })

      for (let x = 0; x < thursdayArrayISO.length - 1; x++) {
        let element_1 = new Date(thursdayArrayISO[x].end)
        let element_2 = new Date(thursdayArrayISO[x + 1].start)

        if (
          element_1.getTime() === element_2.getTime() ||
          element_1.getTime() > element_2.getTime()
        ) {
          // toast.error("Overlap in the time slots created on Thursday")
          thursdayErrorCounter++
          totalErrorCounter++
          //return;
        }
      }
      if (thursdayArray[0] && isEmpty(thursdayArrayISO[0])) {
        thursdayArrayISO.shift()
      }
      setThursdayArrayISO(thursdayArrayISO)
      working_hours = thursdayArrayISO
      setThursdayArrayISO([])

      thursday = working_hours
    }
    if (fridayArray[0] && isEmpty(fridayArray[0])) {
      friday = null
    } else {
      setFridayArrayISO([])
      for (let x = 0; x < fridayArray.length; x++) {
        const isoDateStart = moment.utc(fridayArray[x].start, "HH:mm")

        const isoDateEnd = moment.utc(fridayArray[x].end, "HH:mm")

        let tempObj = {}
        tempObj.start = isoDateStart.toISOString()
        tempObj.end = isoDateEnd.toISOString()

        let fridayDifference = new Date(tempObj.end) - new Date(tempObj.start)

        if (fridayDifference > 0) {
          fridayArrayISO.push(tempObj)
        } else {
          // toast.error("Start time greater than end time in Friday time slots")
          fridayStartEndTimeCounter++
          totalErrorCounter++
        }
      }

      fridayArrayISO.sort(function (a, b) {
        let myDateA = new Date(a.start)
        let myDateB = new Date(b.start)
        return myDateA.getTime() - myDateB.getTime()
      })

      for (let x = 0; x < fridayArrayISO.length - 1; x++) {
        let element_1 = new Date(fridayArrayISO[x].end)
        let element_2 = new Date(fridayArrayISO[x + 1].start)

        if (
          element_1.getTime() === element_2.getTime() ||
          element_1.getTime() > element_2.getTime()
        ) {
          // toast.error("Overlap in the time slots created on Friday")
          fridayErrorCounter++
          totalErrorCounter++
          //return;
        }
      }
      if (fridayArray[0] && isEmpty(fridayArrayISO[0])) {
        fridayArrayISO.shift()
      }
      setFridayArrayISO(fridayArrayISO)
      working_hours = fridayArrayISO
      setFridayArrayISO([])

      friday = working_hours
    }

    if (saturdayArray[0] && isEmpty(saturdayArray[0])) {
      saturday = null
    } else {
      setSaturdayArrayISO([])
      for (let x = 0; x < saturdayArray.length; x++) {
        const isoDateStart = moment.utc(saturdayArray[x].start, "HH:mm")

        const isoDateEnd = moment.utc(saturdayArray[x].end, "HH:mm")

        let temp = {}

        temp.start = isoDateStart.toISOString()
        temp.end = isoDateEnd.toISOString()

        let saturdayDifference = new Date(temp.end) - new Date(temp.start)
        if (saturdayDifference > 0) {
          saturdayArrayISO.push(temp)
        } else {
          // toast.error("Start time greater than end time in Saturday time slots")
          saturdayStartEndTimeCounter++
          totalErrorCounter++
        }
      }

      saturdayArrayISO.sort(function (a, b) {
        let myDateA = new Date(a.start)
        let myDateB = new Date(b.start)
        return myDateA.getTime() - myDateB.getTime()
      })

      for (let x = 0; x < saturdayArrayISO.length - 1; x++) {
        let element_1 = new Date(saturdayArrayISO[x].end)
        let element_2 = new Date(saturdayArrayISO[x + 1].start)

        if (
          element_1.getTime() === element_2.getTime() ||
          element_1.getTime() > element_2.getTime()
        ) {
          // toast.error("Overlap in the time slots created on Saturday")
          saturdayErrorCounter++
          totalErrorCounter++
          //return;
        }
      }
      if (saturdayArray[0] && isEmpty(saturdayArrayISO[0])) {
        saturdayArrayISO.shift()
      }
      setSaturdayArrayISO(saturdayArrayISO)
      working_hours = saturdayArrayISO
      setSaturdayArrayISO([])

      saturday = working_hours
    }
    if (sundayArray[0] && isEmpty(sundayArray[0])) {
      sunday = null
    } else {
      setSundayArrayISO([])
      for (let x = 0; x < sundayArray.length; x++) {
        const isoDateStart = moment.utc(sundayArray[x].start, "HH:mm")

        const isoDateEnd = moment.utc(sundayArray[x].end, "HH:mm")

        let temp = {}

        temp.start = isoDateStart.toISOString()
        temp.end = isoDateEnd.toISOString()

        let sundayDifference = new Date(temp.end) - new Date(temp.start)
        if (sundayDifference > 0) {
          sundayArrayISO.push(temp)
        } else {
          // toast.error("Start time greater than end time in Sunday time slots")
          sundayStartEndTimeCounter++
          totalErrorCounter++
        }
      }

      sundayArrayISO.sort(function (a, b) {
        let myDateA = new Date(a.start)
        let myDateB = new Date(b.start)
        return myDateA.getTime() - myDateB.getTime()
      })

      for (let x = 0; x < sundayArrayISO.length - 1; x++) {
        let element_1 = new Date(sundayArrayISO[x].end)
        let element_2 = new Date(sundayArrayISO[x + 1].start)

        if (
          element_1.getTime() === element_2.getTime() ||
          element_1.getTime() > element_2.getTime()
        ) {
          // toast.error("Overlap in the time slots created on Sunday")
          sundayErrorCounter++
          totalErrorCounter++
          //return;
        }
      }
      if (isEmpty(sundayArrayISO[0])) {
        sundayArrayISO.shift()
      }
      setSundayArrayISO(sundayArrayISO)
      working_hours = sundayArrayISO
      setSundayArrayISO([])

      sunday = working_hours
    }

    let dayNamesArray = []
    let dayNamesArrayforOverlap = []
    if (mondayStartEndTimeCounter > 0) {
      dayNamesArray.push("Monday")
    }
    if (tuesdayStartEndTimeCounter > 0) {
      dayNamesArray.push("Tuesday")
    }
    if (wednesdayStartEndTimeCounter > 0) {
      dayNamesArray.push("Wednesday")
    }
    if (thursdayStartEndTimeCounter > 0) {
      dayNamesArray.push("Thursday")
    }
    if (fridayStartEndTimeCounter > 0) {
      dayNamesArray.push("Friday")
    }
    if (saturdayStartEndTimeCounter > 0) {
      dayNamesArray.push("Saturday")
    }
    if (sundayStartEndTimeCounter > 0) {
      dayNamesArray.push("Sunday")
    }

    if (mondayErrorCounter > 0) {
      dayNamesArrayforOverlap.push("Monday")
    }
    if (tuesdayErrorCounter > 0) {
      dayNamesArrayforOverlap.push("Tuesday")
    }
    if (wednesdayErrorCounter > 0) {
      dayNamesArrayforOverlap.push("Wednesday")
    }
    if (thursdayErrorCounter > 0) {
      dayNamesArrayforOverlap.push("Thursday")
    }
    if (fridayErrorCounter > 0) {
      dayNamesArrayforOverlap.push("Friday")
    }
    if (saturdayErrorCounter > 0) {
      dayNamesArrayforOverlap.push("Saturday")
    }
    if (sundayErrorCounter > 0) {
      dayNamesArrayforOverlap.push("Sunday")
    }

    let errorToastForStartEndTime = ""
    let errorToastForOverlap = ""
    dayNamesArrayforOverlap.forEach((ele) => {
      errorToastForOverlap += " " + ele
    })
    dayNamesArray.forEach((ele) => {
      errorToastForStartEndTime += " " + ele
    })

    if (dayNamesArray.length > 0) {
      toast.error(
        "Sßomething is wrong with " +
          errorToastForStartEndTime +
          " slots"
      )
    }
    if (dayNamesArrayforOverlap.length > 0) {
      toast.error(
        "Overlap in the time slots created on " + errorToastForOverlap
      )
    }

    // let mondayStartEndTimeCounter = 0
    // let tuesdayStartEndTimeCounter = 0
    // let wednesdayStartEndTimeCounter = 0
    // let thursdayStartEndTimeCounter = 0
    // let fridayStartEndTimeCounter = 0
    // let saturdayStartEndTimeCounter = 0
    // let sundayStartEndTimeCounter = dateToSe

    dataToSend.monday = monday
    dataToSend.tuesday = tuesday
    dataToSend.wednesday = wednesday
    dataToSend.thursday = thursday
    dataToSend.friday = friday
    dataToSend.saturday = saturday
    dataToSend.sunday = sunday
    dataToSend.interval = intervalForLocation
    dataToSend.counter = counter
    dataToSend.emrCounter = emrCounter

    if (totalErrorCounter === 0) {
      const resFinal = await customAxios.post(
        `/slots/update?org_id=${currentLocationData._id}`,
        dataToSend
      )
      if (resFinal.data.success) {
        mixpanel.track("Slot Changed")
        props.setDummyCounter(Math.random())
        toast.success("Timings changed successfully")
      } else {
        if (resFinal.data.data === "Slot duration should be atleast 1 hour")
          toast.error(
            "The difference between end time and start time must be of at least 1 hour"
          )
        else {
          toast.error("Error in slot submission")
        }
      }
    }
  }

  useEffect(() => {}, [mondayArray])

  useEffect(() => {}, [tuesdayArray])

  useEffect(() => {}, [wednesdayArray])

  useEffect(() => {}, [thursdayArray])

  useEffect(() => {}, [fridayArray])

  useEffect(() => {}, [saturdayArray])

  useEffect(() => {}, [sundayArray])

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [day, month, year].join("-")
  }

  const tenDaysFromToday = () => {
    let someDate = new Date()
    let numberOfDaysToAdd = 10
    someDate.setDate(someDate.getDate() + numberOfDaysToAdd)
    let ans = formatDate(someDate)
    return ans
  }

  return (
    <Form id="prescription-form" onSubmit={handleSubmit}>
      <Row style={{ marginBottom: "1rem" }}>
        {isLoactionOnline ? (
          <>
            <Col md="4">
              <TextField
                label="Interval"
                fullWidth
                value={intervalForLocation}
                onChange={(e) => {
                  setIntervalForLocation(e.target.value)
                }}
                InputProps={{ endAdornment: "minutes" }}
                variant="outlined"
              />
            </Col>
          </>
        ) : (
          <>
            <Col md="4">
              <TextField
                fullWidth
                label="Max. patients"
                value={counter}
                onChange={(e) => {
                  setCounter(e.target.value)
                }}
                InputProps={{ endAdornment: "patients/hr" }}
                variant="outlined"
              />
            </Col>
          </>
        )}
        <>
          <Col md="4">
            <TextField
              fullWidth
              label="Emergency"
              value={emrCounter}
              onChange={(e) => {
                setEmrCounter(e.target.value)
              }}
              InputProps={{ endAdornment: "slots/day" }}
              variant="outlined"
            />
          </Col>
        </>
      </Row>
      <Row>
        <Col md="2">
          <FormGroup>
            <h2>Day</h2>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols2Input"
            ></label>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <h2>Start Time</h2>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <div>
              <h2>End Time</h2>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              Monday
            </label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              <label className="custom-toggle custom-toggle-primary">
                <input
                  type="checkbox"
                  checked={mondayStatus ? "true" : ""}
                  onClick={() => {
                    setMondayStatus(!mondayStatus)
                    if (!mondayStatus === false) {
                      setMondayArray([{}])
                    }
                  }}
                ></input>
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </label>
            </label>
          </FormGroup>
        </Col>
        <Col md="8">
          {mondayStatus ? (
            mondayArray.map((p, index) => (
              <Row key={index}>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id="example3cols2Input"
                      placeholder="Start Time"
                      type="time"
                      value={p.start}
                      onChange={(e) => {
                        const value = e.target.value
                        setMondayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].start = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id={`Instruction${index}`}
                      placeholder="End Time"
                      type="time"
                      value={p.end}
                      onChange={(e) => {
                        const value = e.target.value
                        setMondayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].end = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                {index !== 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="danger"
                        outline
                        onClick={() => {
                          if (mondayArray.length > 1) {
                            setMondayArray((p) => {
                              let copy = Array.from(p)
                              copy.splice(index, 1)
                              return copy
                            })
                          }
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}

                {index === 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        outline
                        color="primary"
                        onClick={() => {
                          setMondayArray((p) => [...p, {}])
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            ))
          ) : (
            <Row>
              <Col md="12">
                <h4>Unavailable</h4>{" "}
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              Tuesday
            </label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              <label className="custom-toggle custom-toggle-primary">
                <input
                  type="checkbox"
                  checked={tuesdayStatus ? "true" : ""}
                  onClick={() => {
                    setTuesdayStatus(!tuesdayStatus)
                    if (!tuesdayStatus === false) {
                      setTuesdayArray([{}])
                    }
                  }}
                ></input>
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </label>
            </label>
          </FormGroup>
        </Col>
        <Col md="8">
          {tuesdayStatus ? (
            tuesdayArray.map((p, index) => (
              <Row key={index}>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id="example3cols2Input"
                      placeholder="Start Time"
                      type="time"
                      value={p.start}
                      onChange={(e) => {
                        const value = e.target.value
                        setTuesdayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].start = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id={`Instruction${index}`}
                      placeholder="End Time"
                      type="time"
                      value={p.end}
                      onChange={(e) => {
                        const value = e.target.value
                        setTuesdayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].end = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                {index !== 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="danger"
                        outline
                        onClick={() => {
                          if (tuesdayArray.length > 1) {
                            setTuesdayArray((p) => {
                              return p
                                .slice(0, index)
                                .concat(p.slice(index + 1, p.length))
                            })
                          }
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
                {index === 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="primary"
                        outline
                        onClick={() => {
                          setTuesdayArray((p) => [...p, {}])
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            ))
          ) : (
            <Row>
              <Col md="12">
                <h4>Unavailable</h4>{" "}
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              Wednesday
            </label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              <label className="custom-toggle custom-toggle-primary">
                <input
                  type="checkbox"
                  checked={wednesdayStatus ? "true" : ""}
                  onClick={() => {
                    setWednesdayStatus(!wednesdayStatus)
                    if (!wednesdayStatus === false) {
                      setWednesdayArray([{}])
                    }
                  }}
                ></input>
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </label>
            </label>
          </FormGroup>
        </Col>
        <Col md="8">
          {wednesdayStatus ? (
            wednesdayArray?.map((p, index) => (
              <Row key={index}>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id="example3cols2Input"
                      placeholder="Start Time"
                      type="time"
                      value={p.start}
                      onChange={(e) => {
                        const value = e.target.value
                        setWednesdayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].start = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id={`Instruction${index}`}
                      placeholder="End Time"
                      type="time"
                      value={p.end}
                      onChange={(e) => {
                        const value = e.target.value
                        setWednesdayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].end = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                {index !== 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="danger"
                        outline
                        onClick={() => {
                          if (wednesdayArray.length > 1) {
                            setWednesdayArray((p) => {
                              return p
                                .slice(0, index)
                                .concat(p.slice(index + 1, p.length))
                            })
                          }
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
                {index === 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="primary"
                        outline
                        onClick={() => {
                          setWednesdayArray((p) => [...p, {}])
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            ))
          ) : (
            <Row>
              <Col md="12">
                <h4>Unavailable</h4>{" "}
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              Thursday
            </label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              <label className="custom-toggle custom-toggle-primary">
                <input
                  type="checkbox"
                  checked={thursdayStatus ? "true" : ""}
                  onClick={() => {
                    setThursdayStatus(!thursdayStatus)
                    if (!thursdayStatus === false) {
                      setThursdayArray([{}])
                    }
                  }}
                ></input>
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </label>
            </label>
          </FormGroup>
        </Col>
        <Col md="8">
          {thursdayStatus ? (
            thursdayArray?.map((p, index) => (
              <Row key={index}>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id="example3cols2Input"
                      placeholder="Start Time"
                      type="time"
                      value={p.start}
                      onChange={(e) => {
                        const value = e.target.value
                        setThursdayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].start = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id={`Instruction${index}`}
                      placeholder="End Time"
                      type="time"
                      value={p.end}
                      onChange={(e) => {
                        const value = e.target.value
                        setThursdayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].end = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                {index !== 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="danger"
                        outline
                        onClick={() => {
                          if (thursdayArray.length > 1) {
                            setThursdayArray((p) => {
                              return p
                                .slice(0, index)
                                .concat(p.slice(index + 1, p.length))
                            })
                          }
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
                {index === 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="primary"
                        outline
                        onClick={() => {
                          setThursdayArray((p) => [...p, {}])
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            ))
          ) : (
            <Row>
              <Col md="12">
                <h4>Unavailable</h4>{" "}
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              Friday
            </label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              <label className="custom-toggle custom-toggle-primary">
                <input
                  type="checkbox"
                  checked={fridayStatus ? "true" : ""}
                  onClick={() => {
                    setFridayStatus(!fridayStatus)
                    if (!fridayStatus === false) {
                      setFridayArray([{}])
                    }
                  }}
                ></input>
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </label>
            </label>
          </FormGroup>
        </Col>
        <Col md="8">
          {fridayStatus ? (
            fridayArray?.map((p, index) => (
              <Row key={index}>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id="example3cols2Input"
                      placeholder="Start Time"
                      type="time"
                      value={p.start}
                      onChange={(e) => {
                        const value = e.target.value
                        setFridayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].start = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id={`Instruction${index}`}
                      placeholder="End Time"
                      type="time"
                      value={p.end}
                      onChange={(e) => {
                        const value = e.target.value
                        setFridayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].end = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                {index !== 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="danger"
                        outline
                        onClick={() => {
                          if (fridayArray.length > 1) {
                            setFridayArray((p) => {
                              return p
                                .slice(0, index)
                                .concat(p.slice(index + 1, p.length))
                            })
                          }
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
                {index === 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="primary"
                        outline
                        onClick={() => {
                          setFridayArray((p) => [...p, {}])
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            ))
          ) : (
            <Row>
              <Col md="12">
                <h4>Unavailable</h4>{" "}
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              Saturday
            </label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              <label className="custom-toggle custom-toggle-primary">
                <input
                  type="checkbox"
                  checked={saturdayStatus ? "true" : ""}
                  onClick={() => {
                    setSaturdayStatus(!saturdayStatus)
                    if (!saturdayStatus === false) {
                      setSaturdayArray([{}])
                    }
                  }}
                ></input>
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </label>
            </label>
          </FormGroup>
        </Col>
        <Col md="8">
          {saturdayStatus ? (
            saturdayArray?.map((p, index) => (
              <Row key={index}>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id="example3cols2Input"
                      placeholder="Start Time"
                      type="time"
                      value={p.start}
                      onChange={(e) => {
                        const value = e.target.value
                        setSaturdayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].start = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id={`Instruction${index}`}
                      placeholder="End Time"
                      type="time"
                      value={p.end}
                      onChange={(e) => {
                        const value = e.target.value
                        setSaturdayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].end = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                {index !== 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="danger"
                        outline
                        onClick={() => {
                          if (saturdayArray.length > 1) {
                            setSaturdayArray((p) => {
                              return p
                                .slice(0, index)
                                .concat(p.slice(index + 1, p.length))
                            })
                          }
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
                {index === 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="primary"
                        outline
                        onClick={() => {
                          setSaturdayArray((p) => [...p, {}])
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            ))
          ) : (
            <Row>
              <Col md="12">
                <h4>Unavailable</h4>{" "}
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              Sunday
            </label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label className="form-control-label" htmlFor="example3cols3Input">
              <label className="custom-toggle custom-toggle-primary">
                <input
                  type="checkbox"
                  checked={sundayStatus ? "true" : ""}
                  onClick={() => {
                    setSundayStatus(!sundayStatus)
                    if (!sundayStatus === false) {
                      setSundayArray([{}])
                    }
                  }}
                ></input>
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </label>
            </label>
          </FormGroup>
        </Col>
        <Col md="8">
          {sundayStatus ? (
            sundayArray?.map((p, index) => (
              <Row key={index}>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id="example3cols2Input"
                      placeholder="Start Time"
                      type="time"
                      value={p.start}
                      onChange={(e) => {
                        const value = e.target.value
                        setSundayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].start = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Input
                      id={`Instruction${index}`}
                      placeholder="End Time"
                      type="time"
                      value={p.end}
                      onChange={(e) => {
                        const value = e.target.value
                        setSundayArray((p) => {
                          let copy = Array.from(p)
                          copy[index].end = value
                          return copy
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                {index !== 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="danger"
                        outline
                        onClick={() => {
                          if (sundayArray.length > 1) {
                            setSundayArray((p) => {
                              return p
                                .slice(0, index)
                                .concat(p.slice(index + 1, p.length))
                            })
                          }
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
                {index === 0 && (
                  <Col md="2">
                    <FormGroup>
                      <Button
                        color="primary"
                        outline
                        onClick={() => {
                          setSundayArray((p) => [...p, {}])
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            ))
          ) : (
            <Row>
              <Col md="12">
                <h4>Unavailable</h4>{" "}
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <div style={{ textAlign: "right" }}>
        <Button color="primary" type="submit">
          Submit
        </Button>
      </div>
    </Form>
  )
}

const mapStateToProps = (state) => ({
  doctor: state.user,
})

export default connect(mapStateToProps)(DoctorSlotCreator)
