import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Grid, IconButton, LinearProgress, Slide, Typography, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import whatsappLogo from "./../../../assets/img/icons/common/whatsapp.png"
import { useEffect } from 'react';

import animationData from "./../../../assets/animationData/59839-commnet-animation.json"
import GenericAnimation from '../../animation';


const CustomSnackbar = ({ isSnackbarOpen, setSnackbarOpen, textComponent, onButtonClick }) => {  
    const [progress, setProgress] = useState(0);

    const theme = useTheme();

  
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
      setProgress(0)
      if(textComponent?.current)
      textComponent.current = null;
    };
  
    const handleSnackbarClick = () => {
      setSnackbarOpen(true);
    };

    useEffect(() => {
        if(progress < 100 && isSnackbarOpen){
            setTimeout(() => {
                setProgress(progress+1);
            }, 20);   
        }
    }, [progress, isSnackbarOpen])

  
    return (
            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={5000} // number of milliseconds
                onClose={handleSnackbarClose}
                style={{backgroundColor: "white", minWidth: "350px", border: "1px solid", borderColor: theme.palette.primary.main, marginTop: "3%", 
                    transition: 'transform 0.3s ease-in-out',
                    transform: progress > 1 ? "translateX(0%)" : "translateX(110%)"
                    // transform: isSnackbarOpen ? 'translateY(0)' : 'translateY(100%)'
                }}
                anchorOrigin={{"horizontal": "right", "vertical": "top"}}
            >
                <>
                    <IconButton onClick={handleSnackbarClose} style={{top: 0, right: 0, position: 'absolute'}}>
                        <Close fontSize='small'/>
                    </IconButton>
                    <Grid container onClick={() => {
                                        handleSnackbarClose();
                                        onButtonClick();
                                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={12} spacing={0} container style={{minHeight: "5rem", maxHeight: "5rem", padding: 5, maxWidth: "30rem"}}>
                            {/* <Grid item xs={12} style={{marginTop: 5, marginLeft: 10}}>
                                <Typography variant="h5" color="primary" style={{fontSize: "18px", fontWeight: "bold"}}>
                                    New Message Received!
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12} container alignItems="center">
                                <Grid item xs={3} md={2}>
                                    <img
                                    src={whatsappLogo}
                                    style={{
                                        width: "32px",
                                        objectFit: "contain",
                                        marginLeft: 10,
                                        marginRight: -10
                                    }}
                                    />
                                    {/* <GenericAnimation animationData={animationData} height={"4.5rem"} width={"4.5rem"} style={{"padding": 0}}/> */}
                                </Grid>
                                <Grid item xs={9} md={8} container style={{marginTop: "-10px"}}>

                                    <Grid item xs={12}>
                                        New Message Received!
                                    </Grid>
                                    <Grid item xs={12} container>
                                        <Grid item xs={12} style={{display: "flex", marginTop: "10px"}}>
                                            {textComponent?.current ? textComponent?.current : <><Typography variant="subtitle1" style={{fontWeight: "bold"}}>
                                                Patient Name:   
                                            </Typography>
                                            <Typography variant="subtitle1" style={{marginLeft: "10px"}}>
                                                {" "}Message here
                                            </Typography></>}
                                            {/* <Typography variant="subtitle1" style={{marginLeft: "auto"}}>
                                                Click here to reply
                                            </Typography> */}
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={2}>
                                        <Typography variant="subtitle1">
                                            Click here to reply
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                                {/* <Grid item xs={12} md={2}>
                                    <Button variant='contained' style={{color: "white", backgroundColor: "green"}} onClick={() => {
                                        handleSnackbarClose();
                                        onButtonClick();
                                    }}>Reply</Button>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{width: "100%"}}>
                            <LinearProgress color="primary" variant="determinate" style={{height: "5px"}} value={isSnackbarOpen ? progress : 100}  valueBuffer={10}/>
                        </Grid>
                    </Grid>
                </>
            </Snackbar>
    );
  };
  
  export default CustomSnackbar;