import React, { useState } from "react";
import { Avatar, Box, Button, FormControlLabel, Grid, IconButton, Popover, Switch, Tab, Tabs, TextField, Typography, makeStyles, styled } from "@material-ui/core"
import moment from "moment"
import GenericAnimation from "../animation"
import highlighterAnimation from "./../../assets/animationData/highlighter.json"
import PropTypes from 'prop-types';

import ArrowKeyIcon from "./../../assets/img/icons/buttons.png"
import { ArrowDropUp, Assignment, Close, Delete, SaveOutlined, Search } from "@material-ui/icons";
import HistoricalData from "./LabResults/historicalData";
import { Autocomplete } from "@material-ui/lab";

export const TabSwitchingHelperText = ({historicalLabTests, historicalData}) => {

  const [historicalModal, setHistoricalModal] = useState(false)

  return (
    <div style={{display: "flex", marginBottom: "15px"}}>
      {historicalLabTests && historicalData && Object?.keys(historicalData)?.length ? 
      <Button variant="outlined" color="primary" endIcon={<Assignment />} size="small" style={{marginRight: "10px", fontSize: "12px"}} onClick={() => setHistoricalModal(true)}>
        See Historical Data
      </Button> : null}
      <Typography
        style={{
          fontSize: "12px",
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        Use
        <span
          style={{
            margin: "0 0.5rem",
            fontWeight: "800",
            fontStyle: "oblique",
            // fontSize: "16px",
          }}
        >
          <img src={ArrowKeyIcon} alt="" style={{maxWidth: "40px"}}/>
        </span>
        to Navigate
      </Typography>
      <HistoricalData 
        open={historicalModal}
        setOpen={setHistoricalModal}
        data={historicalData}
      />
    </div>
  )
}
export const SlotBlock = (data) => {
  switch (data.type) {
    case "empty_slot":
      return (
        <Grid
          item
          xs={12}
          style={{
            padding: "1rem",
            background: "#f3f3f3",
            borderRadius: "12px",
            fontSize: "12px",
          }}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography>
                {moment.utc(data.start).format("hh:mm A") +
                  " - " +
                  moment.utc(data.end).format("hh:mm A")}{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={false} />}
                label="Block"
              ></FormControlLabel>
            </Grid>
          </Grid>
        </Grid>
      )
    case "booked_slot":
      return (
        <Grid
          item
          xs={12}
          container
          style={{
            padding: "1rem",
            background: "#f3f3f3",
            borderRadius: "12px",
            fontSize: "12px",
          }}
        >
          <div
            style={{ minHeight: "50px", height: "50px", background: "blue" }}
          >
            Upcoming
          </div>
          <Typography>
            {moment.utc(data.start).format("hh:mm A") +
              " - " +
              moment.utc(data.end).format("hh:mm A")}{" "}
          </Typography>
          <Grid container item xs={12}>
            <Grid item xs={6}></Grid>

            <Typography>{data.patient.name}</Typography>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={true} />}
                label="Block"
              ></FormControlLabel>
            </Grid>
          </Grid>
        </Grid>
      )

    default:
      return null
  }
}


const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: 300,
  // Enable scrolling
  overflowY: 'scroll',
  // Hide the scrollbar
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 6, // Adjust to desired thickness
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent', // Adjust to desired color
  },
});


const useStyles = makeStyles({
  tab: {
    minWidth: '15%', // Adjust the width as per your requirement
    padding: 10
  },
});


export const GenericSaveTemplate = ({ data, savedTemplates, cardTitle = "", onUpdateTemplate, onSelectTemplate, onDeleteTemplate, selectIconText = 'sx' }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const [tabVal, setTabVal] = useState(0)
  const [popoverType, setPopoverType] = useState("update")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverType("update")
  };

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverType("select")
  };

  const handleClose = () => {
    setTabVal(0);
    setAnchorEl(null);
    setFilterInput('')
    setTemplateName('')
    setUpdateTemplate('')
  };

  const handleChange = (event, newValue) => {
    setTabVal(newValue);
  };

  const open = Boolean(anchorEl);

  const [hoverSelect, setHoverSelect] = useState(null)

  const [filterInput, setFilterInput] = useState('')
  
  const [templateName, setTemplateName] = useState('')

  const [updateTemplate, setUpdateTemplate] = useState("")

  const defaultSaveData = {
    name: "", prevName: null, rxMeds: []
  }

  const getFilterTemplateList = () => {
    return savedTemplates?.length ? ([...savedTemplates]?.filter(f => {
      if(!filterInput) {
        return true
      } else {
        if(f?.name?.toLowerCase()?.includes(filterInput?.toLowerCase())) {
          return true
        } else {
          let flag = 0;
          f?.rxMeds?.map(rxM => {
            if(rxM?.name?.toLowerCase()?.includes(filterInput?.toLowerCase())) {
              flag = 1
            }
          });

          if (flag) {
            return true
          } else {
            return false
          }
        }
      }
    })) : []
  }


  const getPopoverContent = () => {
    switch(popoverType) {
      case "update": {
        return (
          <React.Fragment>
            <CustomGrid container spacing={1}>
              <Grid item xs={12}>
                <Tabs value={tabVal} onChange={handleChange} indicatorColor="primary" 
                TabIndicatorProps={{style: {
                  width: tabVal ? "28%" : "24%"
                }}}
                >
                  <Tab
                    disableRipple
                    className={classes.tab}
                    value={0}
                    label="New Template"
                  />
                  <Tab
                    disableRipple
                    className={classes.tab}
                    value={1}
                    label="Update Template" />
                </Tabs>
              </Grid>
              <Grid item xs={12} style={{marginTop: "2%"}}>
                {tabVal === 0 ? (
                  <React.Fragment>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={10}>
                        <TextField variant="outlined" placeholder="Template Name" fullWidth size="small" onChange={(e) => {
                          setTemplateName(e?.target?.value)
                        }} value={templateName}/>
                      </Grid>
                      <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled={!data?.length || !templateName}
                          onClick={() => {
                            if(templateName && onUpdateTemplate) {
                              const finalData = {
                                ...defaultSaveData,
                                name: templateName,
                                rxMeds: [...data]
                              };

                              onUpdateTemplate(finalData)
                              handleClose()
                            }
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null}
                {tabVal ===  1 ? (
                  <React.Fragment>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={10}>
                        <Autocomplete
                          options={savedTemplates?.map(v => v?.name)}
                          getOptionLabel={option => option}
                          value={updateTemplate}
                          onChange={(e, val) => setUpdateTemplate(val)}
                          renderInput={(props) => (
                            <TextField {...props} variant="outlined" placeholder="Template Name" fullWidth size="small"/>
                          )} />
                      </Grid>
                      <Grid item xs={1}>
                        <Button variant="contained" color="primary" disabled={!data?.length || !updateTemplate} onClick={() => {
                          if(updateTemplate && onUpdateTemplate) {
                            const finalData = {
                              ...defaultSaveData,
                              name: updateTemplate,
                              prevName: updateTemplate,
                              rxMeds: [...data]
                            }

                            onUpdateTemplate(finalData)
                            handleClose()
                          }
                        }}>
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </CustomGrid>
          </React.Fragment>
        )
        
      }

      case "select": {
        return (
          <React.Fragment>
            <CustomGrid container spacing={1} style={{overflow: 'hidden'}}>
              <Grid item xs={12}>
                <Typography style={{fontSize: "15px", fontWeight: "600"}}>
                  Search {cardTitle}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{marginTop: "5px", marginBottom: "5px"}}>                
                <TextField variant="outlined" placeholder="Start typing template name or elements" fullWidth size="small"
                  InputProps={{ startAdornment: <Search /> }}
                  onChange={(e) => setFilterInput(e?.target?.value)}
                />
              </Grid>
              <CustomGrid item xs={12} container spacing={1} style={{height: "auto", maxHeight: "140px", minHeight: "auto", overflowY: "scroll"}}>
                {getFilterTemplateList()?.length ? getFilterTemplateList()?.map((val, i) => (
                  <Grid item container xs={12} spacing={1} alignItems="center"
                    onMouseEnter={() => setHoverSelect(i)}
                    onMouseLeave={() => setHoverSelect(null)}
                    style={{cursor: "pointer", display: "flex"}}
                    onClick={() => {
                      if(onSelectTemplate) {
                        onSelectTemplate(val)
                        handleClose()
                      }
                    }}
                  >
                    <Grid item xs={1}>
                      <Avatar style={{background: "white", color: "#5e72e4", marginBottom: "10px", fontSize: "22px"}}>
                        {val?.name?.[0]?.toUpperCase()}
                      </Avatar>
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography style={{fontSize: "16px", fontWeight: "550"}}>
                            {val?.name} 
                          </Typography>
                        </Grid>
                        {val?.rxMeds?.length ? <Grid item xs={12} style={{marginTop: "1px", overflow: "hidden"}}>
                          <Typography style={{opacity: "0.6", textOverflow: "ellipsis", maxWidth: "100%", overflow: 'hidden', whiteSpace: "nowrap"}}>
                            {val?.rxMeds?.map((rx, rxIdx) => rxIdx ? `, ${rx?.name}` : rx?.name)}
                          </Typography>
                        </Grid> : null}
                      </Grid>
                    </Grid>

                    {hoverSelect === i ? (
                      <Grid item style={{marginLeft: "auto"}} xs={1}>
                        <IconButton onClick={(e) => {
                          if(onDeleteTemplate) {
                            e?.stopPropagation();
                            onDeleteTemplate(val)
                          }
                        }}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    ) : null}

                  </Grid>
                )) : <Grid item xs={12} style={{marinTop: "1px"}}>
                <Typography style={{fontWeight: "550", fontSize: "15px", color: "grey"}}>
                  No Templates found
                </Typography>
              </Grid>}
              </CustomGrid>
            </CustomGrid>
          </React.Fragment>
        )
        
      }

      default: {
        return null
      }
    }
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <SaveOutlined style={{fontSize: "22px"}} />
      </IconButton>
        <IconButton onClick={handleClick1} style={{fontSize: "20px", position: "relative", padding: 10}}>
          T {" "}<span style={{ fontSize: "16px"}}>{selectIconText}</span>
        </IconButton>

        {/* {open && popoverType === "update" ? <ArrowDropUp style={{
          position: "absolute",
          zIndex: 10,
          right: "64%",
          top: "60%",
          fontSize: "26px",
          color: "#5e72e4"
        }} /> : null}

        {open && popoverType === "select" ? <ArrowDropUp style={{
          position: "absolute",
          zIndex: 10,
          right: "44%",
          top: "60%",
          fontSize: "26px",
          color: "#5e72e4"
        }} /> : null} */}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{style: {
          width: "34%",
          minWidth: "34%",
          height: "auto",
          padding: "10px",
          borderRadius: "10px",
          position: 'relative',
          marginLeft: "3%"
        }}}
      >
        <IconButton style={{position: 'absolute', right: 0, top: 0, zIndex: 2}} onClick={() => handleClose()}>
          <Close style={{fontSize: "20px"}} />
        </IconButton>
        
        <Grid container style={{overflow: 'hidden'}}>
          {getPopoverContent()} 
        </Grid>
      </Popover>
    </React.Fragment>
  )
}