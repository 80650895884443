/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react"
// react plugin used to create charts
// reactstrap components
import { Card, Col, Container, Input, Row } from "reactstrap"
import { connect, useDispatch, useSelector } from "react-redux"
import "./index.css"
// core components
import Header from "../../../components/Dashboard/CreateSlot/Header"
import customAxios from "../../../axios"
import CardBody from "reactstrap/lib/CardBody"
import DoctorSlotCreator from "../../../components/Dashboard/CreateSlot/DoctorSlotCreator"
// import GenericModal from "../../../components/AllPatients/GenericModal"
import CancelSlotModal from "./CancelSlotModal"
import {
  clearAppointmentDetails,
  clearMedicalHistory,
  clearPrescriptionDetails,
  clearVitals,
} from "../../../redux/actions/consultation.actions"
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core"
import moment from "moment"
import { toast } from "react-toastify"
import { loadOrganisations } from "../../../redux/actions/user.actions"
import googleLogo from "./../../../assets/img/brand/googleLogo.png"
import {
  handleBlockSlots,
  handleUnblockSlot,
} from "../../../components/Common/helper"
function CreateSlot({ doctor }) {
  const dispatch = useDispatch()
  const initialiseBlockSlot = () => {
    const start = moment().format("HH:mm")
    const end = moment().add("1", "hour").format("HH:mm")
    return {
      startDate: start,
      endDate: end,
      date: moment.utc(new Date()).format("YYYY-MM-DD"),
    }
  }
  const [doctorLocations, setDoctorLocations] = useState([])
  const [currentLocation, setcurrentLocation] = useState({})
  const [currentLocationId, setCurrentLocationId] = useState("")
  const [dummyCounter, setDummyCounter] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState(initialiseBlockSlot())

  useEffect(() => {
    const current = doctorLocations?.filter(
      (ele) => ele._id === currentLocationId
    )
    setcurrentLocation(current[0])
    //eslint-disable-next-line
  }, [currentLocationId])

  useEffect(() => {
    dispatch(loadOrganisations())
    // eslint-disable-next-line
  }, [dummyCounter])
  const [blockDialogOpen, setBlockDialogOpen] = useState(false)
  const [unblockDialogOpen, setUnblockDialogOpen] = useState(false)
  const [blockedSlotList, setBlockedSlotList] = useState([])
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  useEffect(() => {
    console.log(doctor)
    customAxios
      .get(`organisation/fetch/${doctor?.user?._id}?is_doctor=${isDoctor}`)
      .then((res) => {
        setDoctorLocations(res.data.data)
        if (currentLocationId === "") {
          setcurrentLocation(res.data.data[0])
        } else {
          const dataToSet = res.data.data.filter(
            (ele) => ele._id === currentLocationId
          )
          setcurrentLocation(dataToSet[0])
        }
      })
      .catch((err) => console.log(err))
    //eslint-disable-next-line
  }, [dummyCounter])
  useEffect(() => {
    console.log("curr97", currentLocation)
    if (currentLocation?._id) {
      customAxios
        .get(`slots/block-slots/${currentLocation?._id}`)
        .then((res) => {
          if (res.data.success) {
            console.log("res39", res.data.data)
            setBlockedSlotList(res.data.data)
          } else {
          }
        })
        .catch((err) => {})
    }
  }, [currentLocation, dummyCounter])

  const publicNumbers = useSelector((state) =>
    state?.user?.user?.publicNumbers ? state?.user?.user?.publicNumbers : [0]
  )

  useEffect(() => {
    console.log(currentLocation)
  }, [currentLocation])
  const blockOptions = [
    { label: "1 hour", value: "1", by: "hour" },
    { label: "2 hours", value: "2", by: "hours" },
    { label: "5 hours", value: "5", by: "hours" },
  ]
  return (
    <>
      <Container className="mt-2" fluid>
        <Card>
          <CardBody>
            <Grid container xs={12} alignItems="center">
              <Col md="2">
                <h3 className="form-control-label">
                  Showing your timings for:
                </h3>
              </Col>
              <Col md="3">
                <Input
                  type="select"
                  value={currentLocationId}
                  onChange={(e) => {
                    console.log(e)
                    setCurrentLocationId(e.target.value)
                    // setcurrentLocation({})
                  }}
                >
                  {doctorLocations?.map((location, index) => (
                    <option
                      key={index}
                      label={location.locationName}
                      value={location._id}
                    ></option>
                  ))}
                </Input>
              </Col>
            </Grid>
            <Grid
              container
              xs={12}
              alignItems="center"
              spacing={2}
              style={{ margin: "1rem 0" }}
            >
              <Grid container item xs={12} alignItems="center" spacing={2}>
                <Grid item xs={6} md={2}>
                  <Button
                    fullWidth
                    variant="outlined"
                    title="Sync your paperplane account to your Google Calendar to get appointment updates"
                    color="primary"
                    onClick={() => {
                      customAxios
                        .post("/auth/authorize-user")
                        .then((res) => {
                          if (res.data.success) {
                            window.open(res.data.data)
                          } else {
                            toast.error("Some error occured")
                          }
                        })
                        .catch((err) => {
                          toast.error("Some error occured")
                        })
                    }}
                  >
                    <img
                      src={googleLogo}
                      style={{
                        objectFit: "contain",
                        width: "25px",
                        marginRight: "0.2rem",
                      }}
                      alt="Google Logo"
                    />{" "}
                    Calendar Sync
                  </Button>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: "12px" }}>
                    <i
                      class="fa-solid fa-circle-info"
                      style={{ marginRight: "0.5rem" }}
                    ></i>{" "}
                    Sync your paperplane account to your Google Calendar to get
                    appointment notifcations and calendar view.
                  </Typography>
                </Grid>
              </Grid>

              {!currentLocation?.isOnline &&
              (publicNumbers?.includes(123123) ||
                publicNumbers?.includes(0)) ? (
                <Grid container item xs={12} spacing={2} alignItems="center">
                  <Grid item xs={6} md={2}>
                    <Button
                      fullWidth
                      onClick={() => setBlockDialogOpen(true)}
                      color="secondary"
                      style={{ border: "1px solid red", color: "red" }}
                      variant="outlined"
                    >
                      Block/Unblock Slots
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: "12px" }}>
                      <i
                        class="fa-solid fa-circle-info"
                        style={{ marginRight: "0.5rem" }}
                      ></i>{" "}
                      Patients won't be able to book appointments for this
                      period.
                      <br />
                      <span style={{ marginLeft: "1.4rem" }}>
                        Booked appointments will be put on hold (distinctly
                        visible on dashboard).
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {currentLocation?.isOnline && (
                <Col md="3">
                  <Button
                    color="primary"
                    variant="outlined"
                    type="button"
                    onClick={() => setIsOpen(true)}
                  >
                    Cancel Slot
                  </Button>
                </Col>
              )}
            </Grid>
            <br />
            <DoctorSlotCreator
              currentLocation={currentLocation}
              setDummyCounter={setDummyCounter}
            ></DoctorSlotCreator>
          </CardBody>
        </Card>
      </Container>
      <Dialog
        open={blockDialogOpen}
        onClose={() => setBlockDialogOpen(false)}
        // fullWidth
        // maxWidth="sm"
      >
        <DialogTitle>Block/Unblock Slots</DialogTitle>
        <DialogContent>
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ marginBottom: "1rem" }}>
              <Typography style={{ fontSize: "12px" }} color="secondary">
                Patients won't be able to book appointments for this period.
                <br />
                Booked appointments will be put on hold (distinctly visible on
                dashboard).
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={2}
              direction="row"
              alignItems="center"
              // justifyContent="center"
            >
              {/* <Grid
                item
                xs={12}
                container
                spacing={2}
                style={{ marginBottom: "1rem" }}
              >
                <Grid item>
                  {" "}
                  <Chip
                    label={"Today"}
                    color="error"
                    onClick={() => {
                      const end = moment.utc().format("YYYY-MM-DD")
                      setState({ ...state, date: end })
                    }}
                  ></Chip>
                </Grid>
                <Grid item>
                  {" "}
                  <Chip
                    label={"Tomorrow"}
                    color="error"
                    onClick={() => {
                      const end = moment
                        .utc()
                        .add("1", "day")
                        .format("YYYY-MM-DD")
                      setState({ ...state, date: end })
                    }}
                  ></Chip>
                </Grid>

                {blockOptions.map((ele) => (
                  <Grid item>
                    {" "}
                    <Chip
                      label={"For " + ele.label}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        const start = moment().format("HH:mm")
                        const end = moment()
                          .add(ele.value, "hour")
                          .format("HH:mm")
                        setState({ ...state, startDate: start, endDate: end })
                        console.log("432", start, end)
                      }}
                    ></Chip>
                  </Grid>
                ))}
              </Grid> */}
              <Grid item xs={4}>
                <TextField
                  label="Date"
                  type="date"
                  fullWidth
                  value={state.date}
                  onChange={(e) => {
                    setState({ ...state, date: e.target.value })
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Start Time"
                  type="time"
                  fullWidth
                  value={state.startDate}
                  onChange={(e) => {
                    setState({ ...state, startDate: e.target.value })
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.endDate}
                  onChange={(e) => {
                    setState({ ...state, endDate: e.target.value })
                  }}
                  label="End Time"
                  variant="outlined"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </Grid>

              <Grid
                item
                style={{ marginTop: "1rem" }}
                justifyContent="flex-end"
                container
                xs={12}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    handleBlockSlots(
                      { ...state, orgId: currentLocation._id },
                      () => {
                        setDummyCounter((prev) => prev + 1)
                        setState(initialiseBlockSlot())
                      }
                    )
                  }
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            {blockedSlotList.length !== 0 ? (
              <Grid
                item
                xs={12}
                container
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <Typography align="center">List of blocked slots</Typography>
                </Grid>
                {blockedSlotList.map((ele) => {
                  return (
                    <Grid
                      item
                      alignItems="center"
                      container
                      xs={12}
                      spacing={2}
                      justifyContent="center"
                    >
                      <Grid item xs={4}>
                        <TextField
                          label="Date"
                          variant="outlined"
                          InputProps={{ readOnly: true }}
                          value={moment.utc(ele.startTime).format("DD-MM-YYYY")}
                        ></TextField>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          type="time"
                          value={moment.utc(ele.startTime).format("HH:mm")}
                          label="Start Time"
                          fullWidth
                          variant="outlined"
                          InputProps={{ readOnly: true }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="time"
                          value={moment.utc(ele.endTime).format("HH:mm")}
                          label="End Time"
                          InputProps={{ readOnly: true }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "center" }}>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            handleUnblockSlot(ele._id, () => {
                              const temp = [...blockedSlotList]
                              const filteredList = temp.filter(
                                (slot) => slot._id !== ele._id
                              )
                              if (!filteredList.length)
                                setUnblockDialogOpen(false)

                              setBlockedSlotList([...filteredList])
                            })
                          }}
                        >
                          <i class="fa-solid fa-xmark"></i>
                        </IconButton>{" "}
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
      </Dialog>
      <CancelSlotModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentLocationId={currentLocationId}
      ></CancelSlotModal>
    </>
  )
}

const mapStateToProps = (state) => ({
  doctor: state.user,
})

export default connect(mapStateToProps)(CreateSlot)
