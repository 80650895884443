import React, { useEffect, useState } from "react"
import Header from "../../../components/Dashboard/Home/Header"
// import UtilizedCapacity from "../../../components/Dashboard/Analytics/UtlizedCapacity";
import Revenue from "../../../components/Dashboard/Analytics/Revenue"
import Gender from "../../../components/Dashboard/Analytics/Gender"
import Age from "../../../components/Dashboard/Analytics/Age"
import moment from "moment"
import customAxios from "../../../axios"
import { useSelector } from "react-redux"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
} from "reactstrap"
import { toast } from "react-toastify"
import DateRangePicker from "../../../components/Dashboard/Home/DateRangePicker"
import LoadingAnimation from "../examples/LoadingAnimation"
import DateTypeToggle from "../../../components/Common/DateTypeToggle"
import { Autocomplete } from "@material-ui/lab"
import { Button, CircularProgress, Collapse, Grid, styled, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, useTheme } from "@material-ui/core"
import TextField from '@mui/material/TextField';

import analyticsLoadingAnimation from "../../../assets/animationData/108296-analytics.json"

import {
  curveCatmullRom,
  line,
} from 'd3-shape';
// import { RangeDatePicker } from "react-google-flight-datepicker"

import ReactDatetime from "react-datetime";
import { scalePoint } from 'd3-scale';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Legend,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, ArgumentScale, AreaSeries, EventTracker, PieSeries, LineSeries, Palette } from '@devexpress/dx-react-chart';
import GenericAnimation from "../../../components/animation"
import { Details, ExpandLess, ExpandMore, Info } from "@material-ui/icons"

const Analytics = () => {
  const [state, setState] = useState({ startDate: null, endDate: null })
  const [isLoading, setIsLoading] = useState(false)
  const [completedAppTableData, setCompletedAppTableData] = useState({})
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [ageData, setAgeData] = useState({})
  const [genderData, setGenderData] = useState({})
  const [docList, setDocList] = useState([])
  const [appointmentFormStats, setAppointmentFormStats] = useState({})
  const [whatsappStats, setWhatsappStats] = useState({})
  const [pharmacyStats, setPharmacyStats] = useState({})
  const [whatsappStatsDateType, setWhatsappStatsDateType] = useState("week")
  const [pharmacyStatsDateType, setPharmacyStatsDateType] = useState("week")
  const [appointmentFormStatsDateType, setAppointmentFormStatsDateType] =
    useState("week")
  const [docId, setDocId] = useState("")
  const [currDateType, setCurrDateType] = useState("week")
  const [locations, setLocations] = useState([])

  const [appointmentData, setAppointmentData] = useState({
    completed: 0,
    cancelled: 0,
    currentStatus: "month",
  })
  const user = useSelector((state) => state.user.user)

  const [currentLocation, setCurrentLocation] = useState(null)
  const [dateType, setDateType] = useState("month")
  const [revenueData, setRevenueData] = useState({})

  const [analyticData, setAnalyticData] = useState([])
  const [invoiceOpen, setInvoiceOpen] = useState(false)

  const dummyRangeDate = {
    "start": moment.utc(new Date()).subtract(7, "days"),
    "end": moment.utc(new Date())
  }
  const [rangeDate, setRangeDate] = useState({...dummyRangeDate})

  useEffect(() => {
    customAxios.get("/doctor/").then((res) => {
      if (res.data.success) {
        setDocId(res.data.data[0]?._id)
        setDocList(res.data.data)
      }
    })
  }, [])

  const [invoiceData, setInvoiceData] = useState([])
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  useEffect(() => {
    setInvoiceLoading(true)
    if(rangeDate?.end && rangeDate?.start)
    customAxios.get(`/analytics/invoice-items?org_id=${currentLocation?._id}&start=${rangeDate?.start?.toISOString()}&end=${rangeDate?.end?.toISOString()}&query_date=range`).then((res) => {
      if(res.data?.success) {
        // console.log(res.data.data)
        setInvoiceData(res.data?.data)
        setInvoiceLoading(false)
        let invoiceAmount = 0;
        res?.data?.data?.map(v => {
          invoiceAmount += (Number(v?.amount) * Number(v?.quantity));

          if(v?.discount && Number(v?.discount)){
            invoiceAmount -= Number(v?.discount)
          }
        })
        setTotalInvoiceAmount(invoiceAmount)
      }
    })
  }, [rangeDate, currentLocation])

  const [revenueLoading, setRevenueLoading] = useState(false)
  useEffect(() => {
    if(currentLocation) {
      setRevenueLoading(true)
      customAxios
      .get(`/analytics/doctor-revenue?org_id=${currentLocation?._id}&query_date=${dateType}`).then((res) => {
        setRevenueData({...res?.data?.data?.appoloData});
        setRevenueLoading(false)
      })
    }
  }, [currentLocation, dateType])

  const [analyticLoading, setAnalyticLoading] = useState(false)
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0)
  useEffect(() => {
    setAnalyticLoading(true)
    if(currentLocation) {
      customAxios
      .get(`/analytics/paperplane-analytics?org_id=${currentLocation?._id}`).then((res) => {
        setAnalyticData([...res?.data?.data?.locations?.[0]?.analytics])
        setAnalyticLoading(false)
      })
    }
  }, [currentLocation])

  const disableEndDt = current => {
		if (state.startDate) {
			return current.isAfter(state.startDate.format("YYYY-MM-DD"));
		}
	}

  useEffect(() => {
    let isCombine = user?.multiOrgs ? true : false
    customAxios
      .get(`/organisation/all?is_combine=${isCombine}`)
      .then((res) => {
        if (res.data?.data?.length !== 0) {
          setLocations(res.data?.data)
          setCurrentLocation(res.data.data?.[0])
        }
      })
      .catch((err) => console.log(err))

    //eslint-disable-next-line
  }, [])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // useEffect(() => {
  //   customAxios
  //     .get(`/analytics/past-appointment/${appointmentData.currentStatus}`)
  //     .then((res) => {
  //       if (res.data.success) {
  //         setAppointmentData({
  //           ...appointmentData,
  //           completed: res.data.data.completed,
  //           cancelled: res.data.data.cancelled,
  //         })
  //       } else {
  //         toast.error("Error in fetching footfall data")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  //   // eslint-disable-next-line
  // }, [appointmentData.currentStatus])
  const theme = useTheme()

  return (
    <>
      <Header parentName="Analytics" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Grid container spacing={2} alignItems="center"
                >
                  <Grid item md={5} xs={12}>
                    <h5 className="h3 mb-0">Revenue</h5>
                    <h6 className="surtitle">
                      {revenueData?.dateString ? revenueData.dateString : ""}
                    </h6>
                    <h6 className="surtitle" style={{fontSize: 9, color: theme.palette.primary.main}}>
                      Increase your revenue through <b style={{color: "orangered"}}>Manage Payment</b>
                    </h6>
                  </Grid>
                  <Grid item container md={7} alignItems="center">
                    <Grid item md={6}>
                    <Autocomplete
                      options={locations}
                      style={{marginRight: "2%"}}
                      getOptionLabel={(option) => user?.multiOrgs ? option?.organisationName : option?.locationName }
                      openOnFocus
                      fullWidth
                      value={currentLocation}
                      onChange={(e, val) => {
                        if(val)
                          setCurrentLocation({...val})
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          label="Locations"
                          />
                      )}
                    />
                    </Grid>
                    <Grid item md={6}>
                    <ToggleButtonGroup
                      exclusive
                      value={dateType}
                      onChange={(e, value) => {
                        if (value !== null)
                          setDateType(value)
                      }}
                      aria-label="text alignment"
                      color="primary"
                      size="medium"
                      style={{float: "right"}}
                    >
                      <ToggleButton value="month" aria-label="left aligned">
                        This Month{" "}
                      </ToggleButton>
                      <ToggleButton value="week" aria-label="centered">
                        Week{" "}
                      </ToggleButton>
                      <ToggleButton value="today" aria-label="right aligned">
                        Today{" "}
                      </ToggleButton>
                      <ToggleButton value="last_month" aria-label="right aligned">
                        Last Month
                      </ToggleButton>
                    </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <center>
                      <h1
                        className="display-4 pb-0 mb-0 font-weight-bold"
                        style={{ color: "#98dd29" }}
                      >
                        {(revenueData?.totalRevenue?.amount && !revenueLoading ? "Rs. " + revenueData.totalRevenue?.amount : "-")}
                      </h1>
                      <p className="">Total Revenue</p>
                    </center>
                  </Col>

                  <Col md={4}>
                    <center>
                      <h1
                        className="display-4 pb-0 mb-0 font-weight-bold"
                        style={{ color: "#5DADE2" }}
                      >
                        {(revenueData?.normalRevenue?.amount && !revenueLoading ? "Rs. " + revenueData.normalRevenue?.amount : "-")}
                      </h1>
                      <p className="">Consultation Revenue</p>
                    </center>
                  </Col>

                  <Col md={4}>
                    <center>
                      <h1
                        className="display-4 pb-0 mb-0 font-weight-bold"
                        style={{ color: "#F0B27A" }}
                      >
                        {(revenueData?.treatmentRevenue?.amount && !revenueLoading ? "Rs. " + revenueData.treatmentRevenue?.amount : "-")}
                      </h1>
                      <p className="">Procedure Revenue</p>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button variant="text" color="primary" endIcon={!invoiceOpen ? <ExpandMore /> : <ExpandLess/>} onClick={() => setInvoiceOpen(!invoiceOpen)}>
                      <h5 className="h3 mb-0">View Invoice</h5>
                    </Button>
                    <Collapse in={invoiceOpen} unmountOnExit timeout={"auto"}>
                      <Grid container spacing={2}>
                        <Grid item container xs={12} justifyContent="flex-end" alignItems="flex-end">
                          <Grid item style={{marginRight: "auto"}}>
                            <Typography>Total Amount: Rs. <b>{totalInvoiceAmount}</b></Typography>
                          </Grid>
                          <Grid item style={{marginRight: 10}}>
                            <ReactDatetime
                              dateFormat="DD/MM/YYYY"
                              inputProps={{
                                placeholder: "Start Date",
                                label: "Start Date"
                              }}
                              value={rangeDate?.start}
                              timeFormat={false}
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                // if (state?.startDate && state?.startDate?._d + "" === currentDate._d + "") {
                                // 	classes += " start-date";
                                // } else if (
                                // 	state?.startDate &&
                                // 	state?.endDate &&
                                // 	new Date(state?.startDate?._d + "") < new Date(currentDate._d + "") &&
                                // 	new Date(state?.endDate._d + "") > new Date(currentDate._d + "")
                                // ) {
                                // 	classes += " middle-date";
                                // } else if (state?.endDate && state?.endDate?._d + "" === currentDate._d + "") {
                                // 	classes += " end-date";
                                // }
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                              onChange={(e) => {
                                if (e && typeof(e) === "object")
                                  setRangeDate({...rangeDate, "start": e?.subtract(23, "hours")?.subtract(55, "minutes")})                                
                              }}
                              // isValidDate={disableEndDt}
                            />
                          </Grid>
                          <Grid item style={{marginRight: 10}}>
                            <ReactDatetime
                              dateFormat="DD/MM/YYYY"
                              inputProps={{
                                placeholder: "End Date",
                                label: "End Date"
                              }}
                              timeFormat={false}
                              value={rangeDate?.end}
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                // if (state?.startDate && state?.startDate?._d + "" === currentDate._d + "") {
                                // 	classes += " start-date";
                                // } else if (
                                // 	state?.startDate &&
                                // 	state?.endDate &&
                                // 	new Date(state?.startDate?._d + "") < new Date(currentDate._d + "") &&
                                // 	new Date(state?.endDate._d + "") > new Date(currentDate._d + "")
                                // ) {
                                // 	classes += " middle-date";
                                // } else if (state?.endDate && state?.endDate?._d + "" === currentDate._d + "") {
                                // 	classes += " end-date";
                                // }
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                              onChange={(e) => {
                                if (e && typeof(e) === "object")
                                  setRangeDate({...rangeDate, "end": e})
                              }}
                              // isValidDate={disableEndDt}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <TableContainer>
                          <Table>
                            <TableHead>
                              <TableCell>Date</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Patient</TableCell>
                              <TableCell>Payment Mode</TableCell>
                              <TableCell>Bill For</TableCell>
                            </TableHead>
                            <TableBody>
                              {!invoiceLoading ? (invoiceData?.length ? invoiceData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(v => (<TableRow>
                                <TableCell>{moment.utc(v?.date).format("DD-MMM-YY")}</TableCell>
                                <TableCell>{(v?.amount)}</TableCell>
                                <TableCell>{(v?.quantity)}</TableCell>
                                <TableCell>{(v?.patient?.name)}</TableCell>
                                <TableCell>{(v?.modeOfPayment)}</TableCell>
                                <TableCell>{(v?.invoiceFor)}</TableCell>
                              </TableRow>)) : <>
                              <TableCell /><TableCell />
                              <TableCell>No entries for selected dates!</TableCell>
                              <TableCell /><TableCell />
                              </> ) : <>
                              <TableCell /><TableCell />
                              <TableCell><CircularProgress /></TableCell>
                              <TableCell /><TableCell />
                              </>
                              }
                            </TableBody>
                          </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: "All", value: invoiceData?.length }]}
                            component="div"
                            count={invoiceData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        <ShowAnalytics analyticData={analyticData} analyticLoading={analyticLoading} />

      </Container>
    </>
  )
}

export default Analytics




const PREFIX = 'Demo';

const classes = {
  title: `${PREFIX}-title`,
  chart: `${PREFIX}-chart`,
};

const Line = props => (
  <LineSeries.Path
    {...props}
    path={line()
      .x(({ arg }) => arg)
      .y(({ val }) => val)
      .curve(curveCatmullRom)}
  />
);

const StyledDiv = styled('div')(() => ({
  [`&.${classes.title}`]: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '10px',
  },
}));

const Root = props => (
  <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
);
const Label = props => (
  <Legend.Label {...props} sx={{ mb: 1, whiteSpace: 'nowrap' }} />
);
const Item = props => (
  <Legend.Item {...props} sx={{ flexDirection: 'column-reverse' }} />
);

const ArguementLabel = props => {
  return  <ArgumentAxis.Label {...props} />
};

const ValueLabel = props => {
  return  <ValueAxis.Label {...props} />
};

const StyledChart = styled(Chart)(() => ({
  [`&.${classes.chart}`]: {
    paddingRight: '30px',
  },
}));



function ShowAnalytics({analyticData, analyticLoading}) {


  const [colStructure, setColStructure] = useState([]);

  useEffect(() => {
    let tempStruct = [];

    analyticData?.map((val) => {
      if(val && val?.chartType !== "TextBoxChart"){
        if(tempStruct?.length > 0 && tempStruct?.[tempStruct.length - 1]?.colNo < 3){
          tempStruct[tempStruct.length - 1]['colNo'] += 1
          tempStruct[tempStruct.length - 1]['colData'].push({...val})
        } else {
          tempStruct.push({'colNo': 1, 'colData': [{...val}]});
        }
      }
    })
    setColStructure([...tempStruct]);
  }, [analyticData])


  const getChart = (value) => {
    switch(value?.chartType) {
      case "PieChart": {
        return (
            <Chart
              data={value?.chartData}
              
            >
              <Palette scheme={value?.chartData?.map(v => {
                return v?.pieColor?.includes("0xff") ? v?.pieColor?.replace("0xff", "#") : v?.pieColor?.replace("0x78", "#") 
              })} />
              <PieSeries
                valueField="pieValue"
                argumentField="indexTitle"
                innerRadius={0.6}
                  
              />
              <EventTracker />
              <Tooltip contentComponent={(text) => text?.text + " %"}/>
              <Animation />
              <Legend />
            </Chart>
 
        )
      }

      case "LineChart": {

        let tempChartData = [];
        value?.lineChartList?.map((v, i) => {
          v?.chartData?.map((v1, i1) => {
            if(tempChartData?.[i1]) {
              tempChartData[i1][`value${i}`] = v1[1]
            } else {
              tempChartData.push({[`value${i}`]: v1[1], "keyName": `${i1}.0`})
            }
          })
        })

        return (
          <StyledChart
          data={tempChartData}
          className={classes.chart}
        >
          <ArgumentScale factory={scalePoint} />
          <ArgumentAxis labelComponent={(arguementData) => {

            let visibleText = ""
            if(value?.xAxisTitles?.[arguementData?.text]){
              visibleText = value.xAxisTitles[arguementData?.text]
            }

            return <ArguementLabel {...arguementData} text={visibleText}/>
            }} />

          <ValueAxis labelComponent={(valueData) => {
              let visibleText = ""
              
              if (!value?.percentFormat){
                visibleText = valueData?.text
              }

              else if(value?.yAxisTitles?.[`${valueData?.text}.0`]){
                visibleText = value.yAxisTitles[`${valueData?.text}.0`]
              }
              
              return <ValueLabel {...valueData} text={visibleText}/>
              }} />
          
          {value?.lineChartList?.map((val, idx) => (
            <LineSeries
            name={val?.lineChartTitle ?  val?.lineChartTitle : "field"}
            valueField={`value${idx}`}
            argumentField={"keyName"}
            seriesComponent={Line}
          />
          ))}

          <EventTracker />
          <Tooltip contentComponent={(tooltipVal) => {
            let visibleText = ""
            if (!value?.percentFormat){
              visibleText = tooltipVal?.text
            }
            else if(value?.tooltipYAxis?.[tooltipVal?.text] && value?.tooltipXAxis?.[tooltipVal?.targetItem?.point?.toString() + ".0"]){
              visibleText = value?.tooltipXAxis?.[tooltipVal?.targetItem?.point?.toString() + ".0"] + " - " +  value?.tooltipYAxis[tooltipVal?.text]
            }

            return visibleText
          }}/>         
          
          <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
          <Animation />
        </StyledChart>
 
        )
      }
    }
  }

  
  return (
    <React.Fragment>  
      {!analyticLoading ? colStructure?.map((col) => (
        <Row>
          {col?.colData?.map((colData) => (
            <Col md={(12/col?.colNo).toString()}>
            <Card>
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h5 className="h3 mb-0">{colData?.title}</h5>
                    {/* <h6 className="surtitle">
                      Number of completed and cancelled appointments
                    </h6> */}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    {getChart(colData)}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
 
          ))}
       </Row>
      )) : <Row>
        <Col md={12}>
        <GenericAnimation animationData={analyticsLoadingAnimation} width={400} height={400}/>
        </Col>
        <Col md={12}>
          <Grid container justifyContent="center">

            <Typography variant="h6" style={{fontWeight: "bold"}}>Loading Analytics...</Typography>
          </Grid>
        </Col>
        </Row>}
        </React.Fragment>
  )
}