import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import Row from "reactstrap/lib/Row";
import customAxios from "../../../../axios";
import LoadingAnimation from "../../examples/LoadingAnimation";

import PhoneAnimation from "./PhoneAnimation";
function EnterPhone({ state, setState, setActiveStep }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (state) => {
    setIsLoading(true);
    const temp = { ...state };
    temp["phoneNo"] = "+91" + state.phoneNo;

    customAxios
      .get(`/auth/enter-phone/${"+91" + state.phoneNo}`, { ...temp })
      .then((res) => {
        setIsLoading(false);
        console.log("res", res.data.data);
        if (res.data.success) {
          setState({ ...state });
          setActiveStep((prev) => prev + 1);
        } else {
          toast.error("Phone number already exists.");
        }
      })
      .catch((err) => {
        toast.error("Enter a valid Phone number");

        setIsLoading(false);
        console.log("err", err);
      });
  };
  return (
    <Row>
      <Col style={{ justifyContent: "center" }} className='row'>
        <PhoneAnimation />
      </Col>

      <Col md={12} className='text-center'>
        <h1>Enter your primary phone number</h1>
      </Col>
      <Col md={12}>
        <InputGroup className='p-4'>
          <InputGroupAddon addonType='prepend'>+91</InputGroupAddon>

          <Input
            value={state.phoneNo}
            onChange={(e) => {
              const temp = { ...state };
              temp["phoneNo"] = e.target.value;
              setState({ ...temp });
            }}></Input>
        </InputGroup>
      </Col>
      <Col md={12} className='text-center'>
        <Col className='text-center'>
          {isLoading && <LoadingAnimation></LoadingAnimation>}
          {!isLoading && (
            <Button
              onClick={() => handleSubmit(state)}
              className='p-3'
              color='primary'
              block
              type='button'>
              Send OTP
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
}

export default EnterPhone;
