import React from "react";
import GenericAnimation from "../animation";
import animationData from "../../assets/animationData/PendingVerificaiton.json";
import Logo from "./../../assets/logos/Logo Light.png";

import { Button, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { logout } from "../../redux/actions/auth.actions";
function PendingVerification() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isSubscribe = useSelector((state) => state.user?.user?.isSubscribe);
  const isNewUser = useSelector((state) => state.auth.newUser);
  const isDoctor = useSelector((state) => state.auth.isDoctor);
  const getActiveComponent = () => {
    if (isAuthenticated) {
      if (isSubscribe === "pending" || isSubscribe === "inactive") {
        return (
          <Grid
            container
            alignItems="center"
            direction="column"
            style={{ padding: "2rem 3rem" }}
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img src={Logo} alt="logo" style={{ width: "200px" }} />
            </Grid>
            <Grid item>
              <GenericAnimation
                animationData={animationData}
                width={300}
                height={350}
              ></GenericAnimation>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="h5" color="textSecondary">
                Your verification is still pending. Please come back later.
                <br />
                You'll be notified once the verification process is complete.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(logout());
                  history.push("/auth/login");
                }}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        );
      } else {
        return <Redirect to="/admin/dashboard"></Redirect>;
      }
    } else {
      return <Redirect to="/auth/login" />;
    }
  };
  return getActiveComponent();
}

export default PendingVerification;
