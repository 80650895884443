import {
	POST_PRESCRIPTION_DETAILS,
	CLEAR_PRESCRIPTION_DETAILS,
	POST_MEDICAL_HISTORY,
	CLEAR_MEDICAL_HISTORY,
	POST_VITALS,
	CLEAR_VITALS,
	SAVE_APPOINTMENT_DATA,
	DELETE_APPOINTMENT_DATA,
	POST_PRESCRIPTION_TEMPLATE,
	CLEAR_PRESCRIPTION_TEMPLATE,
} from "../constants/index"

export const postPrescriptionDetails = prescriptionData => {
	console.log(prescriptionData)
	return async dispatch => {
		dispatch({ type: POST_PRESCRIPTION_DETAILS, payload: prescriptionData })
	}
}

export const clearPrescriptionDetails = () => {
	return async dispatch => {
		dispatch({ type: CLEAR_PRESCRIPTION_DETAILS })
	}
}

export const postMedicalHistory = historyData => {
	console.log(historyData)
	return async dispatch => {
		dispatch({ type: POST_MEDICAL_HISTORY, payload: historyData })
	}
}

export const clearMedicalHistory = () => {
	return async dispatch => {
		dispatch({ type: CLEAR_MEDICAL_HISTORY })
	}
}

export const postVitals = vitalData => {
	console.log(vitalData)
	return async dispatch => {
		dispatch({ type: POST_VITALS, payload: vitalData })
	}
}

export const clearVitals = () => {
	return async dispatch => {
		dispatch({ type: CLEAR_VITALS })
	}
}

export const postAppointmentDetails = appointmentDetails => {
	console.log(appointmentDetails)
	return async dispatch => {
		dispatch({ type: SAVE_APPOINTMENT_DATA, payload: appointmentDetails })
	}
}

export const clearAppointmentDetails = () => {
	console.log("Clearing appointment details")
	return async dispatch => {
		dispatch({ type: DELETE_APPOINTMENT_DATA })
	}
}

export const postPrescriptionTemplate = templateData => {
	console.log(templateData)
	return dispatch => {
		dispatch({ type: POST_PRESCRIPTION_TEMPLATE, payload: templateData })
	}
}

export const clearPrescriptionTemplate = () => {
	return dispatch => {
		dispatch({ type: CLEAR_PRESCRIPTION_TEMPLATE })
	}
}
