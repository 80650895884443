import React from "react";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";
import LoadingAnimation from "../examples/LoadingAnimation";

function GenericModalBody({
  modalData,
  setModalData,
  headers,
  handleSubmit,
  saveLabel,
  isLoading,
  buttons = [],
  message,
}) {
  console.log("modalDataNew", modalData);
  return (
    <>
      <div className="modal-body">
        <Row>
          <Col md="12" className="text-center">
            <h2>{message ? message : null}</h2>
          </Col>
        </Row>
        <Row>
          {headers.map((ele, index) => (
            <Col md={ele?.fullWidth ? "12" : "6"} key={index}>
              <FormGroup>
                <Label className="md-2">{ele.label}</Label>
                <InputGroup>
                  {ele.addOnType && ele.addOnType === "prepend" && (
                    <InputGroupAddon addonType={ele.addOnType}>
                      {ele.addOn}
                    </InputGroupAddon>
                  )}
                  <Input
                    id={index}
                    key={index}
                    disabled={ele.disabled}
                    value={modalData?.[ele.key]}
                    onChange={(e) => {
                      const temp = modalData;
                      temp[ele.key] = e.target.value;
                      setModalData({ ...temp });
                    }}
                    type={ele.type ? ele.type : "text"}
                    placeholder={ele.placeholder ? ele.placeholder : ""}
                  >
                    {ele?.type ? (
                      ele?.type === "select" ? (
                        <>
                          {ele?.options?.map((option, idx) => (
                            <option
                              key={idx}
                              value={
                                ele.optionValue
                                  ? option[ele.optionValue]
                                  : option
                              }
                            >
                              {ele?.optionLabel
                                ? option[ele?.optionLabel]
                                : option}
                            </option>
                          ))}
                          <option value="" hidden />
                        </>
                      ) : null
                    ) : null}
                  </Input>
                  {ele.addOnType && ele.addOnType === "append" && (
                    <InputGroupAddon addonType={ele.addOnType}>
                      {ele.addOn}
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
          ))}
        </Row>
      </div>
      <div className="modal-footer">
        {buttons.length ? (
          buttons.map((ele) => (
            <>
              {" "}
              <Col md="12" className="row text-center">
                {!isLoading && (
                  <Button
                    color="primary"
                    type="button"
                    block
                    onClick={() => {
                      ele.onClick(modalData);
                    }}
                  >
                    {ele.label}
                  </Button>
                )}
              </Col>
            </>
          ))
        ) : (
          <>
            {" "}
            <Col md="12" className="row text-center">
              {isLoading && (
                <LoadingAnimation height={35} width={35}></LoadingAnimation>
              )}
              {!isLoading && (
                <Button
                  color="primary"
                  type="button"
                  block
                  onClick={() => {
                    handleSubmit(modalData);
                  }}
                >
                  {saveLabel}
                </Button>
              )}
            </Col>
          </>
        )}
        <Col md="12" className="row text-center">
          {isLoading && (
            <LoadingAnimation height={35} width={35}></LoadingAnimation>
          )}
        </Col>
      </div>
    </>
  );
}

export default GenericModalBody;
