import React, { useCallback } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  Typography,
  TextField,
  Button,
  withStyles,
  IconButton,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  AppBar,
  Box,
  Switch,
  CircularProgress,
  Chip,
} from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
  Close,
  DeleteOutline,
  EditOutlined,
  ArrowBackIos,
  ExpandMore,
} from "@material-ui/icons"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import CustomAutocomplete from "../../Common/AutoComplete"
import GenericAnimation from "../../animation"
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "moment"
import DateFnsUtils from "@date-io/date-fns" // choose your lib
import { RangeDatePicker } from "react-google-flight-datepicker"
import "react-google-flight-datepicker/dist/main.css"
import useKeyboardShortcut from "use-keyboard-shortcut"
import { isNumber } from "lodash"

function GenericDialogFormNew1({
  onDialogMount = () => {},
  open,
  autoClose = true,
  updateData = null,
  handlePrevious = null,
  dialogTitle = "",
  maxWidth = "xs",
  dialogProps = {},
  dialogContent,
  setOpen = () => {},
  message = "",
  footerMessage = "",
  submitButtonDisabled = false,
  fullWidth = false,
  submitButtonColor,
  submitButtonTextColor,
  handleClose = () => setOpen(false),
  submitButtonLoading = false,
  tabs = [
    {
      label: dialogTitle,
      value: 0,
      dialogContent: dialogContent,
      message: message,
      footerMessage: footerMessage,
    },
  ],
  style = {},
  animationProps = null,
  showDialogAction = true,
  handleCancel = () => handleClose(),
  handleSubmit,
  cancelButtonText = "Cancel",
  submitButtonText = "Submit",
  formData = null,
  isList = false,
  editListItem = false,
  removeListItem = false,
  onEditItem = null,
  onRemoveItem = null,
  showRequired = false,
  showOptional = false,
  defaultTabValue = 0,
  submitWithEnter = false,
}) {
  // useEffect(() => {
  //   setState((prev) => ({ ...prev, ...formData }))
  // }, [formData])
  const [tab, setTab] = useState(defaultTabValue)
  const [state, setState] = useState(() => (formData ? formData : {}))

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (updateData) setState((prev) => ({ ...prev, ...updateData }))
  }, [updateData])

  const getDialogContent = (field, index) => {
    switch (field?.type) {
      case "text": {
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") onSubmit();
                // }}
                fullWidth
                required={field.required}
                {...field?.inputProps}
                inputprops={{
                  maxLength: field.maxLength ? field.maxLength : "30",
                }}
                autoFocus={index === 0}
                placeholder={field?.placeholder}
                variant="outlined"
                disabled={
                  field.disabled
                    ? field.disabled
                    : field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false
                }
                value={state?.[field?.key]}
                onChange={(e) => {
                  if (field.onChange)
                    field?.onChange(e.target.value, state, setState)
                  else {
                    const temp = { ...state }
                    temp[field?.key] = e.target.value
                    setState({ ...temp })
                  }
                }}
                {...field.otherProps}
              />
            </Grid>
          </Grid>
        )
      }

      case "autocomplete":
        const onChange = (event, { newValue }) => {
          if (field.onChange) {
            field?.onChange(newValue, state, setState)
          } else {
            const temp = { ...state }
            temp[field?.key] = newValue
            setState({ ...temp })
          }
        }

        // Autosuggest will call this function every time you need to update suggestions.
        // You already implemented this logic above, so just use it.

        // Autosuggest will call this function every time you need to clear suggestions.
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutocomplete
                field={field}
                inputProps={{
                  placeholder: field.placeholder,
                  value: state?.[field?.key] ? state?.[field?.key] : "",
                  onChange: onChange,
                  disabled: field.disabled
                    ? field.disabled
                    : field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false,
                }}
              />
            </Grid>
          </Grid>
        )

      case "checkbox": {
        return (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              {field?.label ? (
                <FormLabel>
                  <Typography>
                    {field?.label}
                    <span style={{ fontSize: "14px", color: "grey" }}>
                      {" "}
                      {showRequired
                        ? field.required
                          ? "*"
                          : showOptional
                          ? "(Optional)"
                          : null
                        : null}
                    </span>
                  </Typography>
                </FormLabel>
              ) : null}
              <FormGroup>
                {field?.options?.map((v) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          state?.[field?.key]?.find(
                            (f) =>
                              f?.[field?.optionKey] === v?.[field?.optionKey]
                          )
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e?.target?.checked) {
                            const temp = { ...state }
                            temp[field?.key].push({ ...v })
                            setState({ ...temp })
                          } else {
                            const temp = { ...state }
                            temp[field?.key] = temp?.[field?.key]?.filter(
                              (f) =>
                                f?.[field?.optionKey] !== v?.[field?.optionKey]
                            )

                            setState({ ...temp })
                          }
                        }}
                        name={v?.[field?.optionKey]}
                        color={"primary"}
                      />
                    }
                    label={v?.[field?.optionKey]}
                    {...field.otherProps}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        )
      }


      case "switch": {
        return (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              {/* {field?.label ? (
                <FormLabel>
                  <Typography>
                    {field?.label}
                    <span style={{ fontSize: "14px", color: "grey" }}>
                      {" "}
                      {showRequired
                        ? field.required
                          ? "*"
                          : showOptional
                          ? "(Optional)"
                          : null
                        : null}
                    </span>
                  </Typography>
                </FormLabel>
              ) : null} */}
              <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          state?.[field?.key]?.value
                        }
                        onChange={(e) => field?.onChange(e)}
                        name={field?.label}
                        color={"primary"}
                      />
                    }
                    label={field?.label}
                    
                  />
              </FormGroup>
            </FormControl>
          </Grid>
        )
      }


      case "date-range":
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RangeDatePicker
                startDate={state[field.key]?.startDate}
                endDate={state[field.key]?.endDate}
                onChange={(startDate, endDate) => {
                  const value = { startDate: startDate, endDate: endDate }
                  const temp = { ...state }
                  state[field.key] = value
                  setState({ ...temp })
                }}
                {...field.otherProps}
              />{" "}
            </Grid>
          </Grid>
        )

      case "date":
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  fullWidth
                  emptyLabel="Date of Appointment"
                  // maxDate={new Date("2021-07-31")}
                  inputVariant="outlined"
                  value={state[field?.key]}
                  onChange={(value) => {
                    if (field.onChange) {
                      field.onChange(value, state, setState)
                    } else {
                      const temp = { ...state }
                      temp[field?.key] = value
                      setState({ ...temp })
                    }
                  }}
                  variant="inline"
                  {...field.otherProps}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )
      
        case "datetime":
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  autoOk
                  fullWidth
                  emptyLabel="Date of Appointment"
                  maxDate={new Date()}
                  inputVariant="outlined"
                  value={state?.[field?.key] ? new Date(state[field?.key]) : null}
                  onChange={(value) => {
                    if (field.onChange) {
                      field.onChange(value, state, setState)
                    } else {
                      const temp = { ...state }
                      temp[field?.key] = value
                      setState({ ...temp })
                    }
                  }}
                  variant="inline"
                  {...field.otherProps}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )

        case "unit-autocomplete":
        
        let units = [...field?.unitOptions]

        // if (field?.key === 'ageString' && !state?.[field?.key] && state?.['age']){
        //   const temp = { ...state }
        //   temp[field?.key] = state['age']
        //   setState({ ...temp })
        // }

        if (state?.[field?.key] && parseFloat(state?.[field?.key]) && parseFloat(state?.[field?.key]) > 1 && field?.unitOptions1) {
          units = [...field?.unitOptions1]
        }
  
        // if(val?.name && index === 1) {
        //   let text = val?.name;
        //   let syrupUnits = ["ml", "teaspoon", "tablespoon", "bottle", "glassful", "cup"];
        //   let tabUnits = ["tablet", "capsule"];
  
        //   if(text?.toLowerCase()?.includes("gel") || text?.toLowerCase()?.includes("lotion") || text?.toLowerCase()?.includes("ointment") || text?.toLowerCase()?.includes("cream")){
        //     units = ["ml", "unit", "fingertip", "application"];
        //   } else if(text?.toLowerCase()?.includes("syrup") || (val?.prev?.doseQuantity ? (val?.prev?.name?.toLowerCase() === text?.toLowerCase() && syrupUnits.some(s => s.includes(val?.prev?.doseQuantity?.split(" ")?.[1]))) : false))
        //   units = syrupUnits;
        //   else if(text?.toLowerCase()?.includes("syring") || text?.toLowerCase()?.includes("inject"))
        //   units = ["ml", "vial", "iu", "unit", "ampule"]
        //   else if(text?.toLowerCase()?.includes("capsul") || text?.toLowerCase()?.includes("tab") || (val?.prev?.doseQuantity ? (val?.prev?.name?.toLowerCase() === text?.toLowerCase() && tabUnits.some(s => s.includes(val?.prev?.doseQuantity?.split(" ")?.[1]))) : false))
        //   units = tabUnits
        // }
        
        let unitOptions = state[field?.key] && ![...field?.unitOptions]?.find(f => state?.[field?.key] && state?.[field?.key]?.toString()?.toLowerCase()?.includes(f?.toLowerCase())) ? [...units].map(v => `${state[field?.key]} ${v}`) : [];
  
        return (
          <Grid container item md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>{field?.label}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={unitOptions}
                getOptionLabel={(option) => field?.optionKey ? option?.[field?.optionKey] : option}
                freeSolo={field?.freeSolo ? true : false}
                disabled={
                  field?.dependencyKey ? !state?.[field?.dependencyKey] : false
                }
                // openOnFocus={false}
                fullWidth
                
                value={state[field?.key] ? state[field?.key].toString() : ""}
                onChange={(e, val) => {
                    if (field.onChange)
                      field.onChange(val, state, setState)
                    else {
                      const temp = { ...state }
                      temp[field?.key] = val
                      setState({ ...temp })
                    }
                }}
                // disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    // helperText={
                    //   <Typography style={{ fontSize: "14px" }}>
                    //     Use arrow keys <i class="fas fa-angle-down"></i> &{" "}
                    //     <i class="fas fa-angle-up"></i>
                    //   </Typography>
                    // }
                    {...params}

                    onChange={(e) => {
                      const re = new RegExp("^[0-9/.]*$")
                      if(field?.freeSolo && re.test(e?.target?.value))
                      {
                        if (field.onChange)
                          field.onChange(e.target.value, state, setState)
                        else {
                          const temp = { ...state }
                          temp[field?.key] = e.target.value
                          setState({ ...temp })
                        }}
                    }}
                    fullWidth
                    placeholder={field?.placeholder}
                    variant="outlined"
                    // onChange={(e) => {
                    //   if (field.onChange) field.onChange(e.target.value, state, setState)
                    // }}
  
                  />
                )}
              />
            </Grid>
          </Grid>
        )
  


      case "select":
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") onSubmit();
                // }}
                fullWidth
                autoFocus={index === 0}
                disabled={
                  field.disabled
                    ? field.disabled
                    : field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false
                }
                select
                placeholder={field?.placeholder}
                variant="outlined"
                value={state?.[field?.key]}
                onChange={(e) => {
                  if (field.onChange)
                    field.onChange(e.target.value, state, setState)
                  else {
                    const temp = { ...state }
                    temp[field?.key] = e.target.value
                    setState({ ...temp })
                  }
                }}
                {...field.otherProps}
              >
                {field.options?.map((ele, idx) => (
                  <MenuItem key={idx} value={ele.value ? ele.value : ele}>
                    {ele.label ? ele?.label : ele}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )

        
        case "select-chip":
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {field.options?.map((ele, idx) => (
                <Chip key={idx} value={ele.value ? ele.value : ele} label={ele?.label} style={{margin: 5}} onClick={() => {
                  const temp = { ...state }
                  temp[field?.key] = ele?.value
                  setState({ ...temp })
                }} variant={state?.[field?.key]?.toLowerCase() === (ele?.value) ? "default" : "outlined"} 
                  color={state?.[field?.key]?.toLowerCase() === (ele?.value) ? "primary" : "default"} >
                </Chip>
              ))}
            </Grid>
          </Grid>
        )


        case "select-free":
          return (
            <Grid item container xs={12} md={field?.md ? field?.md : 6}>
              <Grid item xs={12}>
                <Typography>
                  {field?.label}
                  <span style={{ fontSize: "14px", color: "grey" }}>
                    {" "}
                    {showRequired
                      ? field.required
                        ? "*"
                        : showOptional
                        ? "(Optional)"
                        : null
                      : null}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter") onSubmit();
                  // }}
                  fullWidth
                  autoFocus={index === 0}
                  disabled={
                    field.disabled
                      ? field.disabled
                      : field?.dependencyKey
                      ? !state?.[field?.dependencyKey]
                      : false
                  }
                  
                  placeholder={field?.placeholder}
                  variant="outlined"
                  value={state?.[field?.key]}
                  // onClick={(event) => {
                  //   setAnchorEl(event.currentTarget);
                  // }}
                  onChange={(e) => {
                    if (field.onChange)
                      field.onChange(e.target.value, state, setState)
                    else {
                      const temp = { ...state }
                      temp[field?.key] = e.target.value
                      setState({ ...temp })
                    }
                  }}
                  {...field.otherProps}
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <IconButton onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                          }}><ExpandMore /></IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => {
                            setAnchorEl(null);
                          }}
                        >
                          {/* <MenuItem onClick={handleOptionClick('option1')}>
                            Option 1
                          </MenuItem>
                          <MenuItem onClick={handleOptionClick('option2')}>
                            Option 2
                          </MenuItem>
                          <MenuItem onClick={handleOptionClick('option3')}>
                            Option 3
                          </MenuItem> */}
                          {field.options?.map((ele, idx) => (
                            <MenuItem key={idx} value={ele.value ? ele.value : ele} onClick={() => {
                              setAnchorEl(null);
                              console.log(anchorEl)
                              if (field.onChange)
                                field.onChange(ele?.label, state, setState)
                              else {
                                const temp = { ...state }
                                temp[field?.key] = ele?.label
                                setState({ ...temp })
                              }
                            }}>
                              {ele?.label ? ele?.label : ele}
                            </MenuItem>
                          ))}
                        </Menu>
                      </React.Fragment>
                    ),
                  }}
                >
                  
                </TextField>
              </Grid>
            </Grid>
          )
        
      case "patient-search": {

        let patientOptions = state?.[field?.key] ? field?.options : []

        return (

          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
                  <Autocomplete
                        value={state?.[field?.key]}
                        onChange={(event, newValue) => {
                            if (field.onChange)
                              field?.onChange(newValue, state, setState, true)
                        }}
                        clearOnBlur
                        handleHomeEndKeys
                        openOnFocus={false}
                        // disabled={!state?.[field?.key]}
                        filterOptions={(options, {inputValue}) => {
                          return options?.filter((option) =>
                            option?.phoneNo?.toString()?.startsWith(inputValue)
                          )
                        }}
                        options={patientOptions}
                        getOptionLabel={(option) => {
                            return option?.phoneNo?.toString();
                        }}
                        renderOption={(option) => {
                            return (<Grid container justifyContent='space-between'>
                              <Grid item xs={11}>
                                {option?.phoneNo}, {option?.name}
                              </Grid>
                              {option?.gender ? <Grid item xs={1}>
                                {option?.gender?.[0]?.toUpperCase()}
                              </Grid> : null}
                            </Grid>);
                        }}
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" autoFocus={true} placeholder={field?.placeholder}
                            onChange={(event, newValue) => {
                              if (field.onChange)
                                field?.onChange(event?.target?.value, state, setState)
                            }}
                            />
                        )}
                        />
                        </Grid>
                        </Grid>
        )
      }


      default:
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") onSubmit();
                // }}
                fullWidth
                inputprops={{
                  maxLength: field.maxLength ? field.maxLength : "30",
                }}
                {...field?.inputProps}
                autoFocus={index === 0}
                disabled={
                  field.disabled
                    ? field.disabled
                    : field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false
                }
                type={field.type ? field.type : "text"}
                placeholder={field?.placeholder}
                variant="outlined"
                value={state?.[field?.key]}
                onChange={(e) => {
                  if (field.onChange)
                    field?.onChange(e.target.value, state, setState)
                  else {
                    const temp = { ...state }
                    temp[field?.key] = e.target.value
                    setState({ ...temp })
                  }
                }}
                {...field.otherProps}
              ></TextField>
            </Grid>
          </Grid>
        )
    }
  }
  const onSubmit = () => {
    let error = {}
    if (tabs[tab]?.dialogContent?.length) {
      const checkDialogContent = [...tabs[tab]?.dialogContent]
      checkDialogContent?.reverse()
      checkDialogContent?.map((v) => {
        if (v?.required && !state?.[v?.key] && !state?.[v?.key]?.length) {
          error = {
            check: true,
            message: `${v?.label ? v?.label : v?.errorKey} is required`,
          }
        }
      })
    }
    if (!error?.check) {
      handleSubmit(state, tab)
      if (autoClose) onClose()
    } else toast.error(error?.message)
  }

  const onClose = (type = null) => {
    setState({})
    if (type === "cancel") handleCancel()
    else handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={{
        style: {
          borderRadius: "5px",
          minWidth: "300px",
          ...style,
        },
      }}
      {...dialogProps}
    >
      <DialogTitle onClose={onClose} style={{backgroundColor: "whitesmoke", borderRadius: "5px"}}>
        {/* {handlePrevious ? (
          <IconButton onClick={() => handlePrevious()}>
            <ArrowBackIos />
          </IconButton>
        ) : null} */}
        <Typography variant="h6" style={{fontWeight: "bold"}}>
            {dialogTitle}
        </Typography>
      </DialogTitle>
      {!isList ? (
        <DialogContent
          style={{ overflowY: "visible", position: "relative", padding: 5 }}
          id="dialogRoot"
        >
          {/* <IconButton
            onClick={() => handleClose()}
            style={{ position: "absolute", top: 0, right: 0, zIndex: "111" }}
          >
            <Close /> 
          </IconButton>

          {/* <AppBar elevation={0} position="static">
            <Tabs
              value={tab}
              onChange={(event, value) => {
                setTab(value)
              }}
              aria-label="simple tabs example"
              centered
              textColor="primary"
              style={{ backgroundColor: "white" }}
            >
              {tabs?.map((ele, idx) => {
                return <Tab label={ele.label} {...a11yProps(idx)} />
              })}
            </Tabs>
          </AppBar> */}
          {tabs?.map((ele, idx) => {
            return (
              <TabPanel value={tab} index={idx}>
                <div style={{ overflowY: "visible" }}>
                  <Grid item container xs={12}>
                    {animationProps ? (
                      <GenericAnimation {...animationProps} />
                    ) : null}
                  </Grid>
                  {ele.message ? (
                    <Grid
                      container
                      item
                      xs={12}
                      style={{ marginBottom: "1rem" }}
                    >
                      {ele.message}
                    </Grid>
                  ) : null}
                  <Grid container spacing={1} alignItems="baseline">
                    {ele?.dialogContent?.map((v, index) =>
                      getDialogContent(v, index)
                    )}
                  </Grid>
                  {ele.footerMessage ? (
                    <Grid
                      container
                      item
                      xs={12}
                      // style={{ marginBottom: "1rem" }}
                    >
                      {ele.footerMessage()}
                    </Grid>
                  ) : null}
                </div>
              </TabPanel>
            )
          })}
        </DialogContent>
      ) : (
        <>
          <DialogContent
            style={{ overflowY: "visible", position: "relative" }}
            id="dialogRoot"
          >
            <IconButton
              onClick={() => handleClose()}
              style={{ position: "absolute", top: 0, right: 0, zIndex: "111" }}
            >
              <Close />
            </IconButton>

            <AppBar elevation={0} position="static">
              <Tabs
                value={tab}
                onChange={(event, value) => {
                  setTab(value)
                }}
                aria-label="simple tabs example"
                centered
                textColor="primary"
                style={{ backgroundColor: "white" }}
              >
                {tabs?.map((ele, idx) => {
                  return <Tab label={ele.label} {...a11yProps(idx)} />
                })}
              </Tabs>
            </AppBar>

            {dialogContent?.length ? (
              <List component="nav">
                {dialogContent?.map((v) => (
                  <ListItem button onClick={() => handleSubmit(v)}>
                    <ListItemText primary={v?.name} />
                    {editListItem || removeListItem ? (
                      <ListItemSecondaryAction>
                        {editListItem ? (
                          <IconButton
                            onClick={() => (onEditItem ? onEditItem(v) : null)}
                          >
                            <EditOutlined />
                          </IconButton>
                        ) : null}
                        {removeListItem ? (
                          <IconButton
                            edge="end"
                            onClick={() =>
                              onRemoveItem ? onRemoveItem(v) : null
                            }
                          >
                            <DeleteOutline style={{ color: "#EC7063" }} />
                          </IconButton>
                        ) : null}
                      </ListItemSecondaryAction>
                    ) : null}
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography style={{ textAlign: "center", margin: "2rem 0" }}>
                No Data Found
              </Typography>
            )}
          </DialogContent>
        </>
      )}
      {showDialogAction ? (
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onClose("cancel")}
          >
            {cancelButtonText}
          </Button>
          <Button
            variant="contained"
            color={submitButtonColor ? "" : "primary"}
            style={{backgroundColor: submitButtonColor ? submitButtonColor : "", color: submitButtonTextColor ? submitButtonTextColor : "" }}
            disabled={submitButtonDisabled ? submitButtonDisabled : false}
            onClick={() => onSubmit()}
          >
            {!submitButtonLoading ? submitButtonText : 
              <CircularProgress
                size={24}
                style={{
                  // position: 'absolute',
                  // top: '50%',
                  // left: '50%',
                  // marginTop: -12,
                  // marginLeft: -12,
                  color: "white"
                }}
              />
            }
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

export default GenericDialogFormNew1

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
