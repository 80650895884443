import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import logo from "./../../../assets/logos/Logo Light.png";
import moment from "moment";
import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Modal from "reactstrap/lib/Modal";
import { toast } from "react-toastify";
import Button from "reactstrap/lib/Button";
import customAxios from "../../../axios";
import LoadingAnimation from "../../../views/pages/examples/LoadingAnimation";
const style = {
  headerCell: {
    fontWeight: "600",
    fontSize: "18px ",
    backgroundColor: "#fff",
  },
  dataCell: {
    fontSize: "16px",
  },
};

function Invoice({
  open,
  setOpen,
  id,
  amount,
  state,
  invoiceNo,
  billFor = "Service",
}) {
  const [isLoading, setIsLoading] = useState(false);

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const sendInvoiceToNumber = (invoiceView) => {
    customAxios
      .put(`consultation/send-invoice/${id}`, {
        ...state,
        file: invoiceView,
      })
      .then((res) => {
        if (res.data.success) {
          setOpen(false);
          toast.success(`Invoice successfully sent.`);
        } else {
          toast.error("Some error occured while sending the invoice.");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured");
        setIsLoading(false);
      });

    //eslint-disable-next-line
  };

  const handlePdf = () => {
    html2canvas(document.getElementById("invoice"), {
      scale: 2,
      onrendered: function (canvas) {
        var context = canvas.getContext("2d");
        context.scale(2, 2);
        context["imageSmoothingEnabled"] = false;
        context["mozImageSmoothingEnabled"] = false;
        context["oImageSmoothingEnabled"] = false;
        context["webkitImageSmoothingEnabled"] = false;
        context["msImageSmoothingEnabled"] = false;
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4", true);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");
      pdf?.save(
        `${state.patient.name}-Invoice_${moment(new Date()).format(
          "DD/MM/YYYY"
        )}`
      );
      let blobFile = pdf.output("blob");
      blobToBase64(blobFile).then((data) => sendInvoiceToNumber(data));
    });
  };

  return (
    <div>
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={open}
        toggle={() => setOpen(false)}
      >
        <div className="modal-body">
          <Grid
            container
            spacing={3}
            justify="space-around"
            className="p-4"
            id="invoice"
          >
            <Grid item xs={12} container>
              <div style={{ minHeight: "200px" }}></div>
            </Grid>
            <Grid item container xs={6} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Invoice Issued To:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{state.patient?.name}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">
                  {" "}
                  (+91) {state.patient?.phoneNo}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={6} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" align="right">
                  Invoice Issued By:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="right">
                  Dr. {state.doctor?.name}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" align="right">
                  {state?.organisation?.organisationName},
                </Typography>
                <Typography variant="body1" align="right">
                  {state?.organisation?.address}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={style.headerCell}>
                        Description
                      </TableCell>
                      <TableCell style={style.headerCell}>Unit Price</TableCell>
                      <TableCell style={style.headerCell}>Quantity</TableCell>
                      <TableCell style={style.headerCell}>Amount</TableCell>
                      <TableCell style={style.headerCell}>Paid By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={style.dataCell}>
                        {state.isOnline ? "Virtual " : "Physical "}
                        Medical Consultation <br />
                        Bill For: <strong>{billFor.toUpperCase()}</strong>
                        <br />
                        Dated:{" "}
                        <strong>
                          {moment.utc(state?.start).format("D/MM/YYYY")}
                        </strong>
                      </TableCell>
                      <TableCell style={style.dataCell}> ₹ {amount}</TableCell>
                      <TableCell style={style.dataCell}>1</TableCell>
                      <TableCell style={style.dataCell}>₹ {amount}</TableCell>
                      <TableCell style={style.dataCell}>
                        {state?.modeOfpayment
                          ? state?.modeOfPayment
                          : state?.isOnline
                          ? "Online"
                          : "Cash"}
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                  <TableCell colSpan={4} align='right'>
                  Total: {state.organisation.fees}
                  </TableCell>
                </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="default">
                Invoice Issued on: {moment(new Date()).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Invoice no: #{invoiceNo}</Typography>
            </Grid>
            <Grid
              spacing={2}
              container
              item
              xs={12}
              className="mt-4"
              justify="center"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontSize: "14px" }}
                >
                  Powered By:
                </Typography>
                <img src={logo} alt="Logo" style={{}}></img>
              </Grid>
              <Grid item>
                <Typography align="center">
                  <i className="fas fa-envelope"></i> support@paperplanetech.co
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="modal-footer">
          {isLoading && <LoadingAnimation />}
          {!isLoading && (
            <Button
              block
              color="primary"
              onClick={() => {
                setIsLoading(true);
                handlePdf();
              }}
            >
              Download & Send Invoice
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Invoice;
