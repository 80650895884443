// import customAxios from "../../axios";
import customAxios from "../../axios"
import {
  PATIENT_LOADER,
  REMOVE_REMINDER_APPOINTMENT,
  SET_ALL_CLINICS,
  SET_APPOINTMENT_STATUS,
  SET_CURRENT_DATE_TYPE,
  SET_CURRENT_DATE_TYPE_VALUE,
  SET_CURRENT_ORGANISATION,
  SET_CUSTOM_VITALS,
  SET_FILTER_OPEN,
  SET_PATIENTS,
  SET_REMINDER_APPOINTMENT,
  SET_SORT_ORDER,
  SET_USER_ORGANISATIONS,
} from "../constants"
import mixpanel from "mixpanel-browser"
export const setFilterOpen = (payload) => {
  return {
    type: SET_FILTER_OPEN,
    payload: payload,
  }
}
export const setAllClinics = (payload) => {
  return {
    type: SET_ALL_CLINICS,
    payload: payload,
  }
}
export const setAppointmentStatus = (payload) => {
  return {
    type: SET_APPOINTMENT_STATUS,
    payload: payload,
  }
}
export const loadDoctor = (payload) => {
  return async (dispatch) => {
    dispatch({ type: "GET_USER_LOADING" })
    customAxios
      .get("/auth/")
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data, "hello2")

          mixpanel.identify(res.data.data._id)
          mixpanel.register({
            name: res?.data?.data?.name,

            phone: res?.data?.data?.phoneNo,
            id: res?.data?.data?._id,
          })
          mixpanel.people.set({
            name: res?.data?.data?.name,

            phone: res?.data?.data?.phoneNo,
            id: res?.data?.data?._id,
          })

          dispatch({ type: "SET_USER", payload: res.data.data })
          mixpanel.track("Login")

          /*
        For Psychiatry
        prefrenceArray.push("Symptoms")
        prefrenceArray.push("Diagnosis")
        prefrenceArray.push("PatientNotes")
        prefrenceArray.push("FollowUp")  
        prefrenceArray.push("Problems")
        
        Not Included - Drugs, Tests, FollowupNotes, FollowUpAdvice
        */

          const doctorType = res.data.data?.additionalDetail?.qualification
          const prescriptionPreferences =
            res?.data?.data?.prescriptionPreferences

          const psychiatryList = [
            "Clinical Psychologist",
            "Counselling Psychologist",
            "Therapist",
            "Counsellor",
            "Psychologist",
            "Psychiatry",
            "Psychiatrist",
          ]
          if (psychiatryList.includes(doctorType)) {
            let prefrenceArray = []
            prefrenceArray.push("Symptoms")
            prefrenceArray.push("Vitals")
            prefrenceArray.push("Diagnosis")
            prefrenceArray.push("PatientNotes")
            prefrenceArray.push("FollowUp")
            prefrenceArray.push("Problems")

            dispatch({
              type: "SET_DOCTOR_PRESCRIPTION_PREFERENCE",
              payload: prefrenceArray,
            })
            console.log("<---Logic for Adding Preference")
          } else {
            let prefrenceArray = []
            prefrenceArray.push("Symptoms")
            prefrenceArray.push("Diagnosis")
            prefrenceArray.push("FollowUp")
            prefrenceArray.push("FollowupNotes")
            prefrenceArray.push("FollowUp")
            prefrenceArray.push("Vitals")
            prefrenceArray.push("FollowUpAdvice")
            if (doctorType?.toLowerCase().includes("gyn")) {
              console.log("999")
              prefrenceArray.push("ObsHistory")
              prefrenceArray.push("GynacVitals")
            }

            if (prescriptionPreferences) {
              if (prescriptionPreferences.isMedicine)
                prefrenceArray.push("Drugs")
              if (prescriptionPreferences.isExercise)
                prefrenceArray.push("Exercise")
            } else {
              prefrenceArray.push("Drugs")
            }
            prefrenceArray.push("Tests")
            dispatch({
              type: "SET_DOCTOR_PRESCRIPTION_PREFERENCE",
              payload: prefrenceArray,
            })
          }

          console.log(res.data.data, "SETUSER")
        }
      })
      .catch((err) => console.log("err", err))
  }
}

export const setCurrDateType = (payload) => {
  return { type: SET_CURRENT_DATE_TYPE, payload: payload }
}
export const setCurrDateTypeValue = (payload) => {
  return { type: SET_CURRENT_DATE_TYPE_VALUE, payload: payload }
}
export const setSortOrder = (payload) => {
  return { type: SET_SORT_ORDER, payload: payload }
}
export const loadOrganisations = (payload) => {
  console.log("payload", payload)
  return async (dispatch) => {
    customAxios
      .get(`/organisation/all`)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length === 1)
            dispatch({
              type: SET_CURRENT_ORGANISATION,
              payload: res.data.data[0],
            })
          dispatch({ type: SET_USER_ORGANISATIONS, payload: res.data.data })
        }
      })
      .catch((err) => console.log(err))
  }
}


export const loadPatients = (payload) => {

  return async (dispatch) => {
    customAxios
    .put(`/patients/filter`, {})
      .then((res) => {
        if (res.data.success) {
            dispatch({
              type: SET_PATIENTS,
              payload: res.data.data,
            })
            return Promise.resolve();
          }
      })
      .catch((err) => console.log(err))
  }
}


export const loadCurrentOrganisations = (payload) => {
  console.log("payload", payload)
  return async (dispatch) => {
    customAxios
      .get(`/organisation/all`)
      .then((res) => {})
      .catch((err) => console.log(err))
  }
}

export const setAppointmentReminder = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_REMINDER_APPOINTMENT, payload: payload })
  }
}

export const removeAppointmentReminder = () => {
  return async (dispatch) => {
    dispatch({ type: REMOVE_REMINDER_APPOINTMENT })
  }
}

export const setCustomVitals = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CUSTOM_VITALS, payload: payload })
  }
}