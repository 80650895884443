import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import customAxios from '../../../../axios';
import LoadingAnimation from '../../examples/LoadingAnimation';
import PhoneOtpAnimation from './PhoneOtpAnimation.js';

function VerifyPhone({ state, setState, setActiveStep }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (state) => {
    setIsLoading(true);
    const temp = { ...state };
    temp['phoneNo'] = '+91' + state.phoneNo;

    customAxios
      .put(`/auth/validate-otp`, { ...temp })
      .then((res) => {
        setIsLoading(false);
        if (res.data.success) {
          const temp = { ...state };
          setState({
            ...state,
            gender: 'Male',
            phoneOtp: temp.otp,
            phoneNo: '+91' + temp.phoneNo,
          });

          setActiveStep((prev) => prev + 1);
        } else {
          toast.error('Incorrect OTP');
        }
        console.log('res', res.data.data);
      })
      .catch((err) => {
        toast.error('Incorrect OTP');

        setIsLoading(false);
        console.log('err', err);
      });
  };
  return (
    <>
      <Col md={12} className='text-center'>
        <PhoneOtpAnimation></PhoneOtpAnimation>
      </Col>

      <Col md={12} className='text-center'>
        <h1>Enter OTP</h1>
        <p style={{}}>A six digit code was sent to {' ' + state?.phoneNo}</p>
      </Col>
      <Col md={12}>
        <InputGroup className='p-4'>
          <Input
            value={state.otp}
            onChange={(e) => {
              const temp = { ...state };
              temp['otp'] = e.target.value;
              setState({ ...temp });
            }}></Input>
        </InputGroup>
      </Col>
      <Col md={12} className='text-center'>
        <Col className='text-center'>
          {isLoading && <LoadingAnimation></LoadingAnimation>}
          {!isLoading && (
            <Button
              onClick={() => handleSubmit(state)}
              className='p-3'
              color='primary'
              block
              type='button'>
              Verify OTP
            </Button>
          )}
        </Col>
      </Col>
    </>
  );
}

export default VerifyPhone;
