import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";
import customAxios from "../../../axios";
import LoadingAnimation from "../examples/LoadingAnimation";

function GenericModalBodyForAddPatients({
  modalData,
  setModalData,
  headers,
  setPatientList,
  setActiveStep,
  message,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleByPatientCode = (modalData) => {
    if (!modalData.patientCode || modalData.patientCode.length !== 6)
      toast.error("Patient code must be of 6 digits!");
    else {
      setIsLoading(true);
      customAxios
        .get(`/patients?code=${modalData.patientCode}`)
        .then((res) => {
          if (res.data.success) {
            if (res.data?.data) {
              setModalData({
                ...modalData,
                ...res.data.data[0],
                patientId: res.data.data[0]?._id,
              });
              setActiveStep(3);
            } else {
              toast.warn(
                "No active patient linked with this number. Add new patient!"
              );
              setModalData({ ...modalData });

              setActiveStep(2);
            }
          } else {
            toast.error("Some error occured");
          }
          setIsLoading(false);
        })
        .catch((err) => toast.error("Some error occured"));
    }
  };
  const handleSearch = (modalData) => {
    if (!modalData.searchBy) toast.error("Please enter the required fields!");
    else if (!modalData.orgId) toast.error("Please select the location");
    else if (modalData.searchBy.toString().length > 6) {
      handleByPhoneNo({ ...modalData, phoneNo: modalData.searchBy.toString() });
    } else {
      handleByPatientCode({
        ...modalData,
        patientCode: modalData.searchBy.toString(),
      });
    }
  };
  const handleByPhoneNo = (modalData) => {
    if (!modalData.phoneNo || modalData.phoneNo.length !== 10)
      toast.error("Phone number must be of 10 digits!");
    else {
      setIsLoading(true);
      customAxios
        .get(`/patients?phone=${modalData.phoneNo}`)
        .then((res) => {
          setModalData({ ...modalData });
          if (res.data.success) {
            if (res.data.data.length) {
              setModalData({
                ...modalData,
                ...res.data.data[0],
                patientId: res.data.data[0]?._id,
              });
              setActiveStep(3);
            } else {
              toast.warn(
                "No active patient linked with this number. Add new patient!"
              );

              setActiveStep(2);
            }
          } else {
            toast.error("Some error occured");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Some error occured");
        });
    }
  };
  console.log("modalDataNew", modalData);
  return (
    <>
      <div className="modal-body pt-0">
        <Row>
          <Col md="12" className="text-center">
            <h2>{message ? message : null}</h2>
          </Col>
        </Row>
        <Row>
          {headers.map((ele, index) => (
            <Col md={ele?.fullWidth ? "12" : "6"} key={index}>
              <FormGroup>
                <Label className="md-2">{ele.label}</Label>
                <InputGroup>
                  {ele.addOnType && ele.addOnType === "prepend" && (
                    <InputGroupAddon addonType={ele.addOnType}>
                      {ele.addOn}
                    </InputGroupAddon>
                  )}
                  {console.log(modalData[ele.key], "values", ele.key)}
                  <Input
                    id={index}
                    key={index}
                    disabled={ele.disabled}
                    value={modalData[ele.key]}
                    onChange={(e) => {
                      const temp = modalData;
                      if (ele?.type === "text" || !ele?.type)
                        temp[ele.key] =
                          e.target.value.length <
                          (ele?.limit ? ele?.limit : 100)
                            ? e.target.value
                            : temp[ele.key];
                      else temp[ele.key] = e.target.value;

                      setModalData({ ...temp });
                    }}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    type={ele.type ? ele.type : "text"}
                    placeholder={ele.placeholder ? ele.placeholder : ""}
                  >
                    {ele?.type ? (
                      ele?.type === "select" ? (
                        <>
                          {ele?.options?.map((option, idx) => (
                            <option
                              key={idx}
                              value={
                                ele.optionValue
                                  ? option[ele.optionValue]
                                  : option
                              }
                            >
                              {ele?.optionLabel
                                ? option[ele?.optionLabel]
                                : option}
                            </option>
                          ))}
                          <option value="" hidden />
                        </>
                      ) : null
                    ) : null}
                  </Input>
                  {ele.addOnType && ele.addOnType === "append" && (
                    <InputGroupAddon addonType={ele.addOnType}>
                      {ele.addOn}
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
          ))}
        </Row>
      </div>
      <div className="modal-footer">
        {isLoading && (
          <Col md="12" className="row text-center p-4">
            <LoadingAnimation height={35} width={35}></LoadingAnimation>
          </Col>
        )}
        {!isLoading && (
          <>
            <Col xs="12">
              <Button
                block
                color="primary"
                onClick={() => handleSearch(modalData)}
              >
                Search Patient
              </Button>
            </Col>

            <Col xs="12">
              <Button
                block
                color="primary"
                onClick={() => {
                  if (!modalData?.orgId)
                    toast.error("Please select a location");
                  else setActiveStep(2);
                }}
              >
                Add New Patient
              </Button>
            </Col>
          </>
        )}
      </div>
    </>
  );
}

export default GenericModalBodyForAddPatients;
