import React, { useEffect, useState } from "react";

import { Card, CardBody, Row, Col, Button } from "reactstrap";

import JoinMeetModal from "./JoinMeetModal";

import AlertImg from "../../../assets/img/Dashboard/Alert.svg";
import customAxios from "../../../axios";
import { connect, useDispatch } from "react-redux";
import {
  removeAppointmentReminder,
  setAppointmentReminder,
} from "../../../redux/actions/user.actions";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";
//import customAxios from '../../../axios';

const CurrentAction = (appointmentReminder) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const [appointment, setAppointment] = useState(
    appointmentReminder?.appointmentReminder
  );
  const [display, setDisplay] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    if (!appointmentReminder?.appointmentReminder?._id) {
      loadData();
    } else {
      const data = appointmentReminder?.appointmentReminder;
      const start = data.slot ? data.slot.start : data.start;
      const end = data.slot ? data.slot.end : data.end;

      let timeToStart = new Date(start) - new Date();
      let timeDiff = (new Date(end) - new Date(start)) / 2 + timeToStart;
      if (timeToStart / 1000 < 700 && timeDiff >= 0) {
        setAppointment(appointmentReminder.appointmentReminder);
        setTimeout(() => {
          dispatch(removeAppointmentReminder());
        }, timeDiff);
      }

      if (timeDiff < 0) dispatch(removeAppointmentReminder());
    }
    //eslint-disable-next-line
  }, [appointmentReminder]);

  const loadData = () => {
    customAxios
      .get(`/appointments/day/today?status=upcoming&type=online`)
      .then((res) => {
        if (res?.data?.success) {
          let data = res.data.data;
          data?.sort(function (a, b) {
            const date1 = a.batch
              ? a.batch.start
              : a.slot
              ? a.slot.start
              : a.start;
            const date2 = b.batch
              ? b.batch.start
              : b.slot
              ? b.slot.start
              : b.start;
            return new Date(date2).getTime() - new Date(date1).getTime();
          });
          if (data?.length) {
            setAppointment(data?.[0]);
            dispatch(setAppointmentReminder(data?.[0]));
            data = data?.[0];
            const date = data.slot ? data.slot.start : data.start;
            let appointmentDate = new Date(date);
            let timeDiff = (appointmentDate - new Date()) / 1000;
            if (timeDiff < 700 && !display) {
              setDisplay(true);
            }
          }
        }
      });
  };
  const ShareWebsite = () => (
    <CardBody>
      <Row>
        <Col md="9">
          <div className="ml-4">
            <h1 className="text-primary">
              <strong>Share More To Get More Patients</strong>
            </h1>
            <h2 className="" style={{ opacity: 0.4 }}>
              Your patients can visit your online front and book appointments
              from this link.
            </h2>
            <br />
            <Row style={{ alignItems: "center" }}>
              {user?.link ? (
                <Col md="8">
                  <Button
                    style={{ marginLeft: "auto" }}
                    color="primary"
                    block
                    onClick={() => window.open(user?.link)}
                  >
                    View Website
                  </Button>
                </Col>
              ) : null}
              <Col md="4">
                <Button
                  style={{ marginLeft: "auto" }}
                  color="success"
                  block
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `https://api.whatsapp.com/send/?phone=918707699762&text=${
                          user?.phoneNo
                        }%20press%20send%20to%20book%20appointment%20with%20Dr.%20${
                          user?.name.split(" ")[0]
                        }%20%F0%9F%91%89`
                      )
                      .then((res) => {
                        mixpanel.people.increment("Copied WhatsApp Link");
                        toast.success("Link copied successfully.");
                      })
                  }
                >
                  Share
                  <i
                    class="fab fa-whatsapp fa-lg"
                    style={{ color: "#fff", marginLeft: "5px" }}
                  ></i>
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md="3">
          <div>
            <img src={AlertImg} alt="Alert" />
          </div>
        </Col>
      </Row>
    </CardBody>
  );

  const NoReminders = () => (
    <CardBody style={{ height: "14rem" }}>
      <Row>
        <Col md="9">
          <div className="ml-4">
            <h1 className="text-primary">
              <strong>You do not have any new alerts</strong>
            </h1>
            <br></br>
            <h2 className="">We'll remind you about your next appointment.</h2>
          </div>
        </Col>
        <Col md="3">
          <div>
            <img src={AlertImg} alt="Alert" />
          </div>
        </Col>
      </Row>
    </CardBody>
  );
  useEffect(() => {
    setInterval(() => {
      const data = appointment;
      const start = data.slot ? data.slot.start : data.start;
      const end = data.slot ? data.slot.end : data.end;

      let appointmentDate = new Date(start);
      let timeDiff = (appointmentDate - new Date()) / 1000;
      let totalTimeDiff =
        (new Date(end) - new Date(start)) / 2 + timeDiff * 1000;

      setCurrentTime(new Date());

      if (timeDiff < 700 && !display && totalTimeDiff >= 0) {
        setDisplay(true);
      }
    }, 3000);
    //eslint-disable-next-line
  }, [appointment]);

  return (
    <div style={{ height: "100%" }}>
      <Card style={{ height: "93%" }}>
        {display ? (
          <CardBody>
            <Row>
              <Col md="9">
                <div className="ml-4">
                  <h1 className="text-primary">
                    <strong>Reminder!</strong>
                  </h1>
                  <br></br>
                  <h2 className="text-danger pb=0 mt-0">
                    Online consultaion with {appointment?.patient?.name} starts{" "}
                    {parseInt(
                      (new Date(
                        appointment?.slot
                          ? appointment.slot.start
                          : appointment.start
                      ) -
                        new Date()) /
                        1000 /
                        60
                    ) > 0
                      ? "in " +
                        parseInt(
                          (new Date(
                            appointment?.slot
                              ? appointment.slot.start
                              : appointment.start
                          ) -
                            currentTime) /
                            1000 /
                            60
                        ) +
                        " Minutes"
                      : "now!"}
                  </h2>
                  <JoinMeetModal
                    appointment={appointment}
                    patientName={appointment?.patient?.name}
                  />
                </div>
              </Col>
              <Col md="3">
                <div>
                  <img src={AlertImg} style={{ width: "78%" }} alt="Alert" />
                </div>
              </Col>
            </Row>
          </CardBody>
        ) : (
          NoReminders()
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointmentReminder: state.user.appointmentReminder,
  user: state,
});

export default connect(mapStateToProps)(CurrentAction);
