import React, { useEffect, useState } from "react"
import {
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  Paper,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core"
import Button from "reactstrap/lib/Button"
import ShowRevenueGenerated from "../../../components/Dashboard/Revenue/RevenueGenerated"
import customAxios from "../../../axios"
import { Card, CardBody, CardHeader } from "reactstrap"
import { toast } from "react-toastify"
import { colors } from "../../../assets/css/colors"
require("./Revenue.css")

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

function Revenue() {
  const classes = useStyles();
  const [revenueData, setRevenueData] = useState({})
  const [totalRevenue, setTotalRevenue] = useState({})
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    setLoading(true)
    customAxios
      .get(`/analytics/revenue-detail`)
      .then((res) => {
        if (res.data.success) {
          setTotalRevenue(res.data.data)
          setLoading(false)
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        console.log("err")
      })
  }, [])
  useEffect(() => {
    customAxios
      .get(`/analytics/doctor-revenue`)
      .then((res) => {
        setRevenueData(res?.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })

    customAxios
      .get(`/analytics/get-transactions`)
      .then((res) => {
        setRows(res?.data?.data.reverse())
      })
      .catch((error) => {
        console.log(error)
      })
    //eslint-disable-next-line
  }, [])

  // const labels = Utils.months({count: 7});
  return (
    <>
      <div style={{ padding: "4vh" }}>
        <Card>
        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
          <Grid container xs={12} style={{ padding: "1rem" }} spacing={2}>
            <Grid item md={4}>
              <Typography variant="h4">
                Online Revenue:
                <span style={{ color: colors.primary }}>
                  {" "}
                  ₹{totalRevenue?.onlineRevenue}
                </span>
              </Typography>
              <Typography>
                Revenue collected from online appointments and online invoices{" "}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h4">
                Expected Revenue:{" "}
                <span style={{ color: colors.primary }}>
                  ₹{totalRevenue.expectedRevenue}
                </span>
              </Typography>
              <Typography>
                Revenue you can retreive from app
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h4">
                Total Appointments:{" "}
                <span style={{ color: colors.primary }}>
                  {totalRevenue.totalAppointments}
                </span>
              </Typography>
              <Typography>
                Number of total appointment till now
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card>
          <CardHeader>
            <h5 className="h3 mb-0">Past Transactions</h5>
            <h6 className="surtitle">Past Online transactions</h6>
          </CardHeader>
          <CardBody>
            {rows?.length ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Transaction</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, idx) => (
                      <TableRow
                        key={row?.id + idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {new Date(row?.date).toDateString()}
                        </TableCell>
                        <TableCell>{"Rs." + row?.amount}</TableCell>
                        <TableCell>{row?.id}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            outline
                            type="button"
                            pill
                            onClick={() => window.open(row?.shortUrl)}
                          >
                            View Transaction
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <h3 style={{ textAlign: "center" }}>No Data Found</h3>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default Revenue
