import React, { useState } from "react";
import { Button, Modal } from "reactstrap/lib";
import ReactDatetime from "react-datetime";
import { toast } from "react-toastify";
// reactstrap components
import { FormGroup, InputGroupAddon, InputGroupText, InputGroup, Col, Row } from "reactstrap";
import moment from "moment";

export default function DateRangePicker({
	isOpen,
	setIsOpen,
	defaultState,
	state,
	setState,
	onCancel,
	onSubmit
}) {
	const yesterday = moment().subtract(1, "year");
	const disablePastDt = (current) => {
		return current.isAfter(yesterday);
	};

	const disableEndDt = (current) => {
		if (state?.startDate) {
			return current.isAfter(state.startDate.format("YYYY-MM-DD"));
		}
	};
	console.log("hello1", state);
	return (
		<Modal
			className="modal-dialog-centered modal-lg p-20 mt-10 mb-10"
			isOpen={isOpen}
			toggle={() => setIsOpen(false)}
			style={{ minWidth: "300px", minHeight: "300px", padding: "20px" }}
		>
			<div style={{ textAlign: "center", margin: "20px", marginTop: "30px" }}>
				<Row>
					<Col xs={6}>
						<div className="pb-4">Start Date</div>
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" />
									</InputGroupText>
								</InputGroupAddon>
								<ReactDatetime
									dateFormat="DD/MM/YYYY"
									inputProps={{
										placeholder: "Date Picker Here"
									}}
									timeFormat={false}
									renderDay={(props, currentDate, selectedDate) => {
										let classes = props.className;
										if (state?.startDate && state?.startDate?._d + "" === currentDate._d + "") {
											classes += " start-date";
										} else if (
											state?.startDate &&
											state?.endDate &&
											new Date(state?.startDate?._d + "") < new Date(currentDate._d + "") &&
											new Date(state?.endDate._d + "") > new Date(currentDate._d + "")
										) {
											classes += " middle-date";
										} else if (state?.endDate && state?.endDate?._d + "" === currentDate._d + "") {
											classes += " end-date";
										}
										return (
											<td {...props} className={classes}>
												{currentDate.date()}
											</td>
										);
									}}
									onChange={(e) => {
										console.log(e);
										setState({ startDate: e, type: "range" });
									}}
									isValidDate={disablePastDt}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					<Col xs={6}>
						<div className="pb-4">End Date</div>
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" />
									</InputGroupText>
								</InputGroupAddon>
								<ReactDatetime
									dateFormat="DD/MM/YYYY"
									inputProps={{
										placeholder: "Date Picker Here"
									}}
									timeFormat={false}
									renderDay={(props, currentDate, selectedDate) => {
										let classes = props.className;
										if (state?.startDate && state?.startDate?._d + "" === currentDate._d + "") {
											classes += " start-date";
										} else if (
											state?.startDate &&
											state?.endDate &&
											new Date(state?.startDate?._d + "") < new Date(currentDate._d + "") &&
											new Date(state?.endDate?._d + "") > new Date(currentDate._d + "")
										) {
											classes += " middle-date";
										} else if (state?.endDate && state?.endDate?._d + "" === currentDate._d + "") {
											classes += " end-date";
										}
										return (
											<td {...props} className={classes}>
												{currentDate.date()}
											</td>
										);
									}}
									onChange={(e) => setState({ ...state, endDate: e })}
									isValidDate={disableEndDt}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
				</Row>
				<div style={{ textAlign: "right" }}>
					<Button color="primary" type="button" onClick={onCancel}>
						Cancel
					</Button>
					<Button
						color="primary"
						type="button"
						onClick={() => {
							if (state?.startDate && state?.endDate && state?.type) {
								onSubmit({
									...state,
									startDate: new Date(
										new Date(state.startDate).getTime() + 330 * 60000
									).toISOString(),
									endDate: new Date(new Date(state.endDate).getTime() + 330 * 60000).toISOString()
								});
								setState(defaultState);
							} else toast.error("Fill all details");
						}}
					>
						Ok
					</Button>
				</div>
			</div>
		</Modal>
	);
}
