import moment from "moment"
function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray
  var str = ""

  for (var i = 0; i < array.length; i++) {
    var line = ""
    for (var index in array[i]) {
      if (line != "") line += ","

      line += array[i][index]
    }

    str += line + "\r\n"
  }

  return str
}

export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers)
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items)

  var csv = convertToCSV(jsonObject)

  var exportedFilenmae = fileTitle + ".csv" || "export.csv"

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae)
  } else {
    var link = document.createElement("a")
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", exportedFilenmae)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

// or 'my-unique-title'

export const csvExporter = (itemsNotFormatted) => {
  const flag = itemsNotFormatted.find((ele) => ele.rating)
  let headers = {}
  if (flag)
    headers = {
      serialNo: "S. No",
      invoiceNo: "Receipt No",
      date: "Date of Appointment",
      time: "Time of Appointment",
      patientCode: "Patient Code", // remove commas to avoid errors,
      consultationNo: "Registration No.",
      patientName: "Patient Name",
      docName: "Doctor incharge",
      amount: "Amount",
      paymentMode: "Mode",
      type: "Type",
      rating: "Rating",
      feedback: "Feedback",
      diagnosis: "Diagnosis",
    }
  else
    headers = {
      serialNo: "S. No",
      invoiceNo: "Receipt No",
      date: "Date of Appointment",
      time: "Time of Appointment",
      patientCode: "Patient Code", // remove commas to avoid errors,
      consultationNo: "Registration No.",
      patientName: "Patient Name",
      docName: "Doctor incharge",
      amount: "Amount",
      paymentMode: "Mode",
      type: "Type",
      diagnosis: "Diagnosis"
    }
  console.log("items", itemsNotFormatted)
  const itemsFormatted = itemsNotFormatted.map((ele, idx) => ({
    serialNo: idx + 1,
    invoiceNo: ele?.invoiceNo ? ele.invoiceNo : "-",
    date: ele.start ? moment.utc(ele.start).format("DD/MM/YYYY") : "-",
    time: ele.start ? moment.utc(ele.start).format("hh:mm A") : "-",
    patientCode: ele?.patient?.code ? ele?.patient?.code : "-",
    consultationNo: ele?.consultationNo ? ele?.consultationNo : "-",
    patientName: ele?.patient?.name ? ele?.patient?.name : "-",
    docName: ele?.doctor?.name ? ele?.doctor?.name : "-",
    amount: ele?.amount ? ele?.amount : "-",

    paymentMode: ele?.modeOfPayment
      ? ele?.modeOfPayment
      : ele?.isOnline
      ? "Online"
      : "Cash",

    type: ele?.emergency ? "Emergency" : ele?.isOnline ? "Online" : "Offline",
    rating: ele.rating,
    feedback: ele.feedback,
    diagnosis: ele?.diagnosis ? ele?.diagnosis?.join(", ") : "-"
  }))
  const fileTitle = `AppointmentData-${moment
    .utc(new Date())
    .format("DD/MM/YYYY")}}`
  exportCSVFile(headers, itemsFormatted, fileTitle)
}
