
import animationData from '../../../../assets/lock.json';
import React from 'react';

import Lottie from 'react-lottie';
function HidingAnimation() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    renderer: 'svg',
  };
  return (
    <Lottie
      options={defaultOptions}
      height={200}
      width={200}
      isStopped={false}
      isPaused={false}
    />
  );
}

export default HidingAnimation;