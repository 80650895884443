import React, { useState } from "react"
import { toast } from "react-toastify"

import Modal from "reactstrap/lib/Modal"
import customAxios from "../../../axios"
import GenericModalBodyForAddPatients from "./GenericModalBodyForAddPatients"
import GenericModalForSchedule from "./GenericModalForSchedule"
import {
  stepOneHeaders,
  stepThreeHeaders,
  stepTwoHeaders,
} from "./ModalHeadersSchedule"
import { useSelector } from "react-redux"
function AddPatientWithScheduleModal({
  isOpen,
  setIsOpen,
  setDummyCounter,
  modalData,
  setModalData,
}) {
  // const currOrganisation = useSelector((state) => state.user.currOrganisation);
  const allOrganisations = useSelector((state) =>
    state?.user?.organisations?.filter((ele) => !ele.isOnline)
  )
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const handleValidatePatient = (modalData) => {
    if (!modalData.orgId || !modalData.phoneNo || !modalData.birthDate)
      toast.error("Please enter all the details properly.")
    else {
      setIsLoading(true)
      customAxios
        .put(`/patients/check`, { ...modalData })

        .then((res) => {
          setIsLoading(false)
          if (res.data.success) {
            if (!res.data.data.patientId) setActiveStep(1)
            else {
              let sortedData = []
              if (res.data.data.appointments.length) {
                sortedData = res.data.data.appointments.sort(function (a, b) {
                  return a.batch.start < b.batch.start
                    ? -1
                    : a.batch.start > b.batch.start
                    ? 1
                    : 0
                })
                console.log("verify", sortedData)

                setModalData({
                  ...modalData,
                  ...res.data.data.patient,
                  patientId: res.data.data.patientId,
                  appointmentId: sortedData[0]._id,
                })
              } else {
                setModalData({
                  ...modalData,
                  ...res.data.data.patient,
                  patientId: res.data.data.patientId,
                })
              }
              setActiveStep(2)
            }
          } else {
            toast.error("Incorrect OTP")
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error("some error occured.")
          console.log("err", err)
        })
    }
  }

  const handleUpdatePatient = (modalData) => {
    setIsLoading(true)
    customAxios
      .put("/appointments/book-patient ", modalData)
      .then((res) => {
        if (res.data.success) {
          setDummyCounter(Math.random())
          handleModalClose()
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)

        console.log("err", err)
      })
  }

  const getActiveModalBody = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <GenericModalBodyForAddPatients
            setDummyCounter={setDummyCounter}
            headers={[
              ...stepOneHeaders,
              {
                label: "Location",
                key: "orgId",
                type: "select",
                options: [...allOrganisations],
                optionLabel: "locationName",
                optionValue: "_id",
                fullWidth: true,
              },
            ]}
            modalData={modalData}
            isLoading={isLoading}
            saveLabel="Search Patient"
            setActiveStep={setActiveStep}
            handleSubmit={handleValidatePatient}
            setModalData={setModalData}
          ></GenericModalBodyForAddPatients>
        )

      case 1:
        return (
          <GenericModalForSchedule
            setDummyCounter={setDummyCounter}
            headers={stepTwoHeaders}
            modalData={modalData}
            isLoading={isLoading}
            saveLabel="Done"
            setActiveStep={setActiveStep}
            setModalData={setModalData}
            setIsOpen={setIsOpen}
            handleModalClose={handleModalClose}
          ></GenericModalForSchedule>
        )
      case 2:
        return (
          <GenericModalForSchedule
            setDummyCounter={setDummyCounter}
            headers={stepThreeHeaders}
            modalData={modalData}
            isLoading={isLoading}
            handleModalClose={handleModalClose}
            message="We found a profile linked to the number !"
            saveLabel="Done"
            setActiveStep={setActiveStep}
            handleSubmit={handleUpdatePatient}
            setModalData={setModalData}
            setIsOpen={setIsOpen}
          ></GenericModalForSchedule>
        )
      default:
        return <h1>Bla</h1>
    }
  }
  const handleModalClose = () => {
    setIsOpen(false)
    setIsLoading(false)
    setActiveStep(0)
  }
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => handleModalClose()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Schedule New Appointment
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => handleModalClose()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      {getActiveModalBody(activeStep)}
    </Modal>
  )
}

export default AddPatientWithScheduleModal
