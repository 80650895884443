import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  DialogTitle,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  TextField,
  Typography,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import moment from "moment";
import ReactDatetime from "react-datetime";

import React, { useEffect, useState } from "react";
import LoadingAnimation from "../../../views/pages/examples/LoadingAnimation";
import FollowUp from "../../CreatePrescription/followUp";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  inputDate: {
    width: "90%",
    "& input": {
      height: "57px",
      border: "1px solid #ccc",
      "&:hover": {
        borderColor: "black",
      },
    },
  },
  dateButton: {
    width: "10%",
    cursor: "default !important",
  },
}));
function QuickEndModal({
  modalData,
  setModalData,
  open,
  message = "",
  setOpen,
  loading = false,
  onSubmit,
  onClose = () => setOpen(false),
  maxWidth = "xl",
  dialogTitle = "Quick End Consultation",
  cancelButtonText = "Discard",
  submitButtonText = "Save",
}) {
  const [radioInput, setRadioInput] = useState({ type: "day", unit: "" });
  const classes = useStyles();
  useEffect(() => {
    if (radioInput?.type && radioInput?.unit) {
      const afterDate = moment().add(
        Number(radioInput?.unit),
        radioInput?.type
      );

      setModalData({
        ...modalData,
        date: moment(afterDate).format("YYYY-MM-DD"),
      });
    }
  }, [radioInput]);
  console.log("890", modalData);
  console.log("890radio", radioInput);
  const re = /^[0-9\b]+$/;

  const currentDate = moment.now();
  const disablePastDt = (current) => {
    return current.isAfter(currentDate);
  };

  const radioHeaders = [
    { label: "Days", value: "day" },
    { label: "Weeks", value: "week" },
    { label: "Months", value: "month" },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth}>
      <DialogTitle onClose={onClose}>{dialogTitle}</DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <DialogContentText>
          <Typography align="left" variant="h6">
            {message}
          </Typography>
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Follow Up Details:</Typography>
          </Grid>
          <Grid item container xs={12} spacing={1} alignItems="center">
            <Grid item xs={1}>
              <Typography>After</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                value={radioInput?.unit}
                onChange={(e) =>
                  e.target.value === "" || re.test(e.target.value)
                    ? setRadioInput({ ...radioInput, unit: e.target.value })
                    : null
                }
                placeholder="00"
              />
            </Grid>
            <Grid item xs={7}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={radioInput?.type}
                  onChange={(e) =>
                    setRadioInput({ ...radioInput, type: e.target?.value })
                  }
                >
                  {radioHeaders.map((v) => (
                    <FormControlLabel
                      value={v?.value}
                      label={v?.label}
                      control={<Radio color="primary" />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", flex: "flex-wrap" }}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                className={classes.dateButton}
                disableFocusRipple
                disableRipple
              >
                <CalendarTodayOutlined />
              </Button>
              <TextField
                type="date"
                min={new Date()}
                value={modalData?.date}
                variant="outlined"
                placeholder="Your followup date"
                onChange={(e) => {
                  setModalData({
                    ...modalData,
                    date: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {loading && <LoadingAnimation />}
        {!loading && (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onClose("cancel")}
            >
              {cancelButtonText}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmit(modalData)}
            >
              {submitButtonText}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default QuickEndModal;
