import React, { Fragment, useEffect, useRef, useState } from "react"
import ReactGA from "react-ga4"
import { withRouter } from "react-router-dom"
import { useHistory } from "react-router-dom"
import useKeyboardShortcut from "use-keyboard-shortcut"
import otpAnimation from "../../../assets/otp.json"
import { Card, CardHeader, Table } from "reactstrap"
import AWS from "aws-sdk"
import { toast } from "react-toastify"
import ActionableDropdown from "./ActionableDropDown"
// import OnlineImage from "./book-online.svg"
// import OfflineImage from "./book-offline.svg";
import LoadingAnimation from "../../../views/pages/examples/LoadingAnimation"
import "../../../assets/vendor/nucleo/css/nucleo.css"
// import JitsiMeetComponent from "./JitsiMeet";
import moment from "moment"
import success from "./../../../assets/audios/confirm.mp3"
import customAxios from "../../../axios"
import { connect, useDispatch, useSelector } from "react-redux"
import whatsappLogo from "./../../../assets/img/icons/common/whatsapp.png"
import JoinMeetModal from "./JoinMeetModal"
import ManagePaymentDialog from "../ManagePaymentDialog"

import StopWatchIcon from "../../../assets/img/icons/common/stopwatch1.png"
import PrintIcon from "../../../assets/img/icons/common/invoice1.png"
import PersonAddIcon from "../../../assets/img/icons/common/personadd1.png"
import GoogleIcon from "../../../assets/img/icons/common/google.svg"

import {
  loadPatients,
  setAllClinics,
  setAppointmentStatus,
  setCurrDateType,
  setCurrDateTypeValue,
  setCustomVitals,
  setFilterOpen,
  setSortOrder,
} from "../../../redux/actions/user.actions"
import PsedutoAppointmentModal from "./PseudotoAppintmentModal"
import mixpanel from "mixpanel-browser"
import DateRangePicker from "./DateRangePicker"
import { SET_CURRENT_ORGANISATION } from "../../../redux/constants"

import actionLoaderAnimation from "../../../assets/animationData/92898-loading-with-tick.json"

import "react-big-calendar/lib/css/react-big-calendar.css"
import {
  Chip,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Tooltip,
  TableContainer,
  TablePagination,
  useTheme,
  CircularProgress,
  withStyles,
  styled,
  makeStyles,
} from "@material-ui/core"
import { Done, Edit } from "@material-ui/icons"
import { csvExporter } from "./ExportToCsv"
import QuickEndModal from "../../Consultation(new)/QuickEndModal"
import Invoice from "../../Consultation(new)/Invoice"
import GenericDialogForm from "../../CreatePrescription/GenericDialog"
import FileUploadModal from "../../Common/FileUploadModal"
import GenericAnimation from "../../animation"
import warningAnimation from "./../../../assets/animationData/warning.json"
import notificationBell from "./../../../assets/animationData/notificationBell.json"
import AddAppointmentDialog, {
  handleSendWhatsAppLink,
} from "./AddAppointmentDialog"
// import { SlotBlock } from "../../Common/helper"
// import { uniqueId } from "lodash"
import LoaderFullpage from "../../Common/LoaderFullpage"
import { getDate } from "date-fns"
import { colors } from "../../../assets/css/colors"
import displayAge from "../../../assets/functions/displayAge"
import CustomSnackbar from "../CustomizeSnackbar"
import GenericDialogFormNew1 from "../../CreatePrescription/GenericDialog/newIndex"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

const CustomTooltip = withStyles({
  tooltip: {
    color: 'black', // Change the text color
    backgroundColor: 'white', // Change the background color
  },
})(Tooltip);


const useStyles = makeStyles({
  draggableRow: {
    backgroundColor: '#F8F9F9',
    transition: 'background-color 0.3s ease-in-out'
  }
})


function StartConsultationButton(props) {
  const history = useHistory()
  if (props.isDoctor) {
    if (props.appointment?.isOnline) {
      return (
        <Button
          color="primary"
          type="button"
          variant="outlined"
          startIcon={<i class="fas fa-play"></i>}
          onClick={() => {
            mixpanel.track("Start Consultation Button Clicked")
            history.push(`/admin/chat`, {
              appointmentId: props.appointment._id,
            })
            ReactGA.event({
              category: "Consultation",
              action: "Start Consultation",
            })
          }}
        >
          Start Consultation
        </Button>
      )
    } else {
      return (
        <Button
          color="primary"
          type="button"
          variant="outlined"
          startIcon={<i class="fas fa-play"></i>}
          onClick={() => {
            mixpanel.track("Start Consultation Button Clicked")
            history.push(`/admin/consultation/${props.appointment._id}`)
            ReactGA.event({
              category: "Consultation",
              action: "Start Consultation",
            })
          }}
        >
          Start Consultation
        </Button>
      )
    }
  } else {
    return null
  }
}

function DashboardTable({
  setModalData,
  modalData,
  setIsOpen,
  data,
  startLoading,
  endLoading,
  dummyCounter,
  isOpenWithSchedule,
  setIsOpenWithSchedule,
  updater,
  setUpdater,
  ...props
}) {
  const { flushHeldKeys } = useKeyboardShortcut(
    ["/"],
    (shortcutKeys) => {
      document.getElementById("searchQuery").focus()
      console.log("patkha", shortcutKeys)
    },
    {
      overrideSystem: true,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  )

  const classes = useStyles()

  const paymentCollectedDialogHeaders = [
    {
      label: "Mode of Payment",
      key: "modeOfPayment",
      type: "autocomplete",
      options: ["Cash", "Card", "UPI", "Other"],
    },
    {
      label: "Amount",
      key: "amount",
      type: "number",
    },
    {
      label: "Bill For",
      key: "invoiceFor",
      required: true,
      md: 12,
      type: "autocomplete",
      options: ["Service", "Vaccination", "X-Ray", "Consultation"],
    },
    {
      label: "Details",
      key: "details",
      type: "text",
      md: 12,
      placeholder: "Eg: 15 sessions of Miniscus tear physiotherapy",
      required: false,
    }
  ]
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [addPatientOpen, setAddPatientOpen] = useState(false)

  const [updatePatientOpen, setUpdatePatientOpen] = useState(false)
  const [updatePatientLoader, setUpdatePatientLoader] = useState(false)
  const [patientData, setPatientData] = useState(null)
  const handleUpdatePatient = (data) => {
    // setLoading(true)
    setUpdatePatientLoader(true)
    customAxios
      .put(`patients/update/${data?._id}`, { ...data })
      .then((res) => {
        if (res.data.success) {
          setUpdater((prev) => !prev)
          setUpdatePatientLoader(false)
          setUpdatePatientOpen(false)
          toast.success("Patient details updated successfully.")
          const audio = new Audio(success)
          const promise = audio.play()
          if (promise !== undefined) {
            // On older browsers play() does not return anything, so the value would be undefined.
            promise
              .then(() => {
                // Audio is playing.
              })
              .catch((error) => {
                console.log(error)
              })
          }
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((Err) => {
        toast.error("Some error occured")
        // setLoading(false)
      })
  }
  const updatePatientHeaders = [
    {
      label: "Name",
      key: "name",
      type: "text",
      md: 10
    },
    {
      label: "Phone",
      key: "phoneNo",
      // disabled: true,
      type: "number",
      md: 10

    },
    {
      key: "ageString",
      label: "Age",
      unitOptions1: ['Years', "Months", "Days"],
      unitOptions: ['Year', 'Month', 'Day'],
      placeholder: "e.g. 10 Years",
      freeSolo: true,
      type: "unit-autocomplete",
      md: 10

    },
    {
      label: "Gender",
      key: "gender",
      type: "select-chip",
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Other", value: "other" },
      ],
      md: 12
    },
  ]

  const [whatsappLinkPatientList, setWhatsappLinkPatientList] = useState([])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const styles = {
    tableCell: {
      verticalAlign: "middle",
      width: "10%"
    },
  }

  const [tableRowHover, setTableRowHover] = useState(null)
  
  const [filteredList, setFilteredList] = useState([])
  const [query, setQuery] = useState("")
  const history = useHistory()
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [quickEndConsultationDialogOpen, setQuickEndConsultationDialogOpen] =
    useState(false)
  const [paymentCollectedDialogOpen, setPaymentCollectedDialogOpen] =
    useState(false)
  const [dialogOrgId, setDialogOrgId] = useState(null)
  const [dialogText, setDialogText] = useState("")
  const [csvFile, setCsvFile] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const currOrganisation = useSelector((state) => state.user.currOrganisation)
  const [editAppointmentDialogOpen, setEditAppointmentDialogOpen] =
    useState(false)
  const [expectedAppointmentsList, setExpectedAppointmentList] = useState([])
  const handleNotifyAll = (data) => {
    customAxios
      .put(`/patients/notify-all`, {
        data: [
          ...expectedFollowUpList.map((ele) => ({
            patientId: ele.patient._id,
            consultationId: ele._id,
          })),
        ],
      })
      .then((res) => {
        if (res.data.success) {
          mixpanel.track("Followup reminder to all")
          toast.success("All patients successfully notified on WhatsApp")
          const audio = new Audio(success)
          const promise = audio.play()
          if (promise !== undefined) {
            // On older browsers play() does not return anything, so the value would be undefined.
            promise
              .then(() => {
                // Audio is playing.
              })
              .catch((error) => {
                console.log(error)
              })
          }
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        console.log("err")
      })
  }

  const defaultPaymentData = [
    { invoiceFor: "Consultation", amount: 0, mode: "Cash" },
  ]

  function NumberWithOrdinal(n) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  const filterMenuOpen = useSelector((state) => state.user.filterOpen)
  const [expectedFollowUpList, setExpectedFollowUpList] = useState([])
  const [allDoctors, setAllDoctors] = useState([])
  const [otpDialogOpen, setOtpDialogOpen] = useState(false)
  const [appointmentList, setAppointmentList] = useState([])
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const currDateTypeValue = useSelector((state) => state.user.currDateTypeValue)
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const sortOrder = useSelector((state) => state.user.sortOrder)
  const organisations = useSelector((state) => state.user.organisations)
  const currDateType = useSelector((state) => state.user.currDateType)
  const userPatients = useSelector(state => (state?.user?.patients))

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const allClinics = useSelector((state) => state.user.allClinics)
  const currStatus = useSelector((state) => state.user.currStatus)
  const [invoiceOpen, setInvoiceOpen] = useState(false)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [dialogLoading, setDialogLoading] = useState(false)
  const publicNumbers = useSelector((state) => state?.user?.user?.publicNumbers)
  const [defaultTabForAddDialog, setDefaultTabForAddDialog] = useState(1)
  const [managePaymentDialogOpen, setManagePaymentDialogOpen] = useState(false)
  const [paymentsData, setPaymentsData] = useState(defaultPaymentData)

  const user = useSelector((state) => state?.user?.user)
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false)
  const [dateSlotsList, setDateSlotsList] = useState([])
  const [allSlotsList, setAllSlotsList] = useState([])

  const [allPatients, setAllPatients] = useState([])

  useEffect(() => {
    if(!allPatients || !allPatients?.length)
    dispatch(loadPatients())
  }, [])

  useEffect(() => {
    if(userPatients && userPatients?.length)
    setAllPatients([...userPatients])
  }, [userPatients])

  useEffect(() => {
    customAxios
      .put("/appointments/upcoming-patients", {})
      .then((res) => {
        if (res.data.success) setWhatsappLinkPatientList(res.data.data)
        else {
        }
      })
      .catch((err) => {
        console.log("err")
      })
  }, [updater])
  const getAllSlots = (data) => {
    if (calendarOpen) setDialogLoading(true)
    customAxios
      .put(`/slots/all-slot/${data.orgId}`, data)
      .then((res) => {
        if (res.data.success) {
          if (calendarOpen) setDialogLoading(false)
          setAllSlotsList([...res.data.data.slice()])
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        toast.error("Some error occured")
        if (calendarOpen) setDialogLoading(false)
      })
  }

  const getColor = (color) => {
    switch (color) {
      case "green":
        return "lightgreen"
      case "red":
        return "lightsalmon"
      case "yellow":
        return "gold"
      default:
        return "lightblue"
    }
  }

  const getText = (data) => {
    switch (data) {
      case "green":
        return "More than 50% of slots are free"
      case "red":
        return "25-50% of slots are free"
      case "yellow":
        return "Less than 25% of slots are free"
      default:
        return ""
    }
  }


  useEffect(() => {
    if(isDoctor) {
      customAxios.get(`/doctor/get-custom-vitals/${user?._id}`).then((res) => {
        if(res?.data?.data?.length && res?.data?.success){
          let customVitals = [...res?.data?.data]
          dispatch(setCustomVitals(customVitals))
        }
      })
    }
  }, [])

  const getDates = (orgId, organisations) => {
    // setLoading(true)
    const doctor = organisations
      ?.find((ele) => ele?._id === orgId)
      ?.doctors?.find((v, idx) => {
        return isDoctor ? user?._id === v?.doctorId : idx === 0
      })
    console.log("doctor86", doctor)
    customAxios
      .get(
        `https://pplane-api-prod.paperplane.health/api/v1/slots/slot-dates/${orgId}?doc_id=${doctor.doctorId}`
      )
      .then((res) => {
        if (res.data.success) {
          // setLoading(false)

          setDateSlotsList([...res?.data?.data])
        } else {
          // setLoading(false)
          if (res.data.message === "division by zero") {
            toast.error(
              <div>
                No open slots found. Please change your timings from{" "}
                <span
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/admin/CreateSlot")}
                >
                  here
                </span>
              </div>
            )
          } else toast.error("Some error occured")
        }
      })
      .catch((err) => {
        // setLoading(false)

        console.log("err", err)
        toast.error("Some error occured")
      })
  }

  const handleBulkUpload = (files, dialogOrgId) => {
    if (!dialogOrgId && !publicNumbers?.includes(232323)) toast.error("Please select an organisation")
    else {
      setDialogLoading(true)
      customAxios
        .put(
          publicNumbers?.includes(232323) && !dialogOrgId ? '/patients/upload-csv?book_appointment=true' : `/patients/upload-csv?org_id=${dialogOrgId}&book_appointment=true`,
          {
            ...files[0],
          }
        )
        .then((res) => {
          if (res.data.success) {
            setUploadOpen(false);
            setDialogLoading(false);
            setDialogOrgId(null);
            mixpanel.track("Bulk Appointments Uploaded")
            toast.success("File uploaded successfully. ")
            toast.info(
              "It might take upto 5 minutes to update the patient list."
            )
          } else {
            setDialogLoading(false)
            setDialogOrgId(null);

            toast.error(
              "File upload failed. Please checkthe format of the sample file for reference."
            )
          }
          setCsvFile([])
        })
        .catch((err) => {
          setDialogOrgId(null);
          setDialogLoading(false)
          toast.error("Some error occured")
        })
    }
  }

  useEffect(() => {
    if (!isDoctor) {
      customAxios.get(`/organisation/all`, {}).then((res) => {
        if (res?.data?.success) {
          const final = res?.data?.data?.map((ele) => ({
            label: ele?.locationName,
            value: ele._id,
            ...ele,
          }))
          setAllDoctors(final)
        }
      })
    }
  }, [])
  const getStatusChip = (appointment) => {
    if (appointment.completed)
      return (
        <Chip
          size="large"
          style={{ background: "darkgreen", color: "white" }}
          label="Completed"
        ></Chip>
      )

    if (appointment.cancelled)
      return (
        <Chip
          size="large"
          style={{ background: "red", color: "white" }}
          label="Cancelled"
        ></Chip>
      )
    else
      return (
        <Chip
          style={{ background: "blue", color: "white" }}
          label="Upcoming"
        ></Chip>
      )
  }
  const paymentCollected = (modalData) => {
    startLoading()
    customAxios
      .put(`appointments/update-payment-status/${modalData.id}`, {
        mode: modalData.modeOfPayment,
        amount: modalData?.amount,
        invoiceFor: modalData?.invoiceFor,
      })
      .then((res) => {
        if (res.data.success) {
          setPaymentCollectedDialogOpen(false)
          if (setUpdater) setUpdater((prev) => !prev)
          customAxios
            .put(`/consultation/generate-invoice/${modalData.id}`, {})
            .then((res) => {
              setInvoiceDialogOpen(false)
              if (res.data.success) {
                handleDownloadInvoice(
                  res.data.data,
                  true,
                  false,
                  `${modalData.name}_(#${modalData.code})_Invoice`
                )
              } else {
                endLoading()
                toast.error("Some error occured")
              }
            })
            .catch((err) => {
              endLoading()
              toast.error("Some error occured")
            })
        } else {
          endLoading()
        }
      })
      .catch((err) => {
        endLoading()

        toast.error("Some error occured")
      })
  }
  const handleDownloadInvoice = (
    data,
    download = false,
    print = false,
    fileName
  ) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    // AWS.config.update({
    //   accessKeyId: "AKIAUSXZZ7HZK47LXEVL",
    //   secretAccessKey: "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",
    // })
    // var s3 = new AWS.S3()
    // let params = {
    //   Bucket: "paperplane-s3",
    //   Key: `${data.location + data._id}`,
    // }
    // s3.getObject(params, (err, res) => {
    //   let blob = new Blob([res.Body], { type: res.ContentType })
    //   if (download) {
    //     a.href = window.URL.createObjectURL(blob)
    //     a.download = fileName
    //     a.click()
    //     window.URL.revokeObjectURL(data?.url)
    //   }
    //   if (print) {
    //     let pdfw = window.open(window.URL.createObjectURL(blob), "_blank")
    //     // you need to call print after window loads like TableCellis
    //     if (pdfw) {
    //       pdfw.onload = function () {
    //         pdfw.print()
    //       }
    //     } else {
    //       window.alert("Please allow popups")
    //     }
    //   }
    //   endLoading()
    // })

    fetch(data?.url)
      .then((response) => {
        if (response.ok) {
          return response.blob()
        }}).then(blob => {
          if(blob){
            if (download) {
              a.href = window.URL.createObjectURL(blob)
              a.download = `${data?.patientId}_Invoice`
              a.click()
              window.URL.revokeObjectURL(data?.url)
            }
            if (print) {
              let pdfw = window.open(window.URL.createObjectURL(blob), "_blank")
              // you need to call print after window loads like TableCellis
              if (pdfw) {
                pdfw.onload = function () {
                  pdfw.print()
                }
              } else {
                window.alert("Please allow popups")
              }
            }
          }
          endLoading()

        })

  }
  // useEffect(() => {
  //   if (dateSlotsList.length)
  //     getAllSlots({
  //       slotDate: moment(dateSlotsList[0].date).format("DD/MM/YYYY"),
  //       orgId: "all",
  //       docId: isDoctor ? user._id : user.admin,
  //     })
  // }, [dateSlotsList])
  const generatePdf = (appointmentId) => {
    customAxios
      .get(`/consultation/get-prescription-pdf/${appointmentId}`)
      .then((res) => {
        if (res?.data?.data?.url) window.open(res.data.data.url)
        else toast.error("PDF does not exist")
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const quickEndConsultation = (modalData) => {
    setIsLoading(true)
    customAxios
      .post("consultation/quick-end", {
        appointmentId: modalData.id,
        followUp: modalData.date
          ? { expDate: moment.utc(modalData?.date).format() }
          : null,
      })
      .then((res) => {
        if (res.data.success) {
          setIsLoading(false)
          toast.success("Appointment marked as completed")
          const audio = new Audio(success)
            const promise = audio.play()
            if (promise !== undefined) {
              // On older browsers play() does not return anything, so the value would be undefined.
              promise
                .then(() => {
                  // Audio is playing.
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          setQuickEndConsultationDialogOpen(false)
          setUpdater((prev) => !prev)
        } else {
          setIsLoading(false)
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        toast.error("Some error occured")
        setIsLoading(false)
      })
  }
  useEffect(() => {
    setIsLoading(true)

    if (currOrganisation && currDateType && currStatus && sortOrder) {
      customAxios
        .get(
          currDateType !== "day"
            ? allClinics
              ? `/appointments/day/range?status=${currStatus}&start=${currDateTypeValue?.value?.startDate}
								&end=${currDateTypeValue?.value?.endDate}`
              : `/appointments/day/range?org_id=${currOrganisation._id}&status=${currStatus}
						&start=${currDateTypeValue?.value?.startDate}&end=${currDateTypeValue?.value?.endDate}`
            : allClinics
            ? `/appointments/day/${moment(currDateTypeValue.value).add(330, 'minutes').toISOString()}?status=${currStatus}`
            : `/appointments/day/${moment(currDateTypeValue.value).add(330, 'minutes').toISOString()}?org_id=${currOrganisation._id}&status=${currStatus}`
        )
        .then((res) => {
          console.log("beforelist", res.data.data)
          if (res.data.success) {
            const sortedData = res?.data?.data?.sort(function (a, b) {
              const date1 = a.start
              const date2 = b.start
              return new Date(date2).getTime() - new Date(date1).getTime()
              // return moment.utc(new Date(date2)).getTime() - moment.utc(new Date(date2)).getTime()
            })

            if (sortOrder === "ascending" && currDateType === "today")
              sortedData.reverse()
            const finalData = sortedData?.reverse()

            if((currDateTypeValue?.label?.includes("Today"))){
              customAxios.get('/doctor/appointment-priority').then(res => {
                if(res?.data?.success){
                  let orderedList = res?.data?.data?.data ? res?.data?.data?.data : []

                  let tempOrder = [
                    ...orderedList?.map(id => finalData?.find(f => f?._id === id)), 
                    ...finalData?.filter(f => !orderedList?.includes(f?._id))
                  ];

                  tempOrder = tempOrder.filter(f => f);

                  setAppointmentList([...tempOrder]);
                } else {
                  setAppointmentList([...finalData]);
                }
              }).catch((err) => setAppointmentList(finalData))
            } else {
              setAppointmentList(finalData)
            }

            
            setIsLoading(false)
          } else {
            console.log("err")
          }
        })
        .catch((err) => {
          console.log("err")
        })

      customAxios
        .get(
          allClinics
            ? `/appointments/proposed-followup/${"week"}`
            : `/appointments/proposed-followup/${"week"}?org_id=${
                currOrganisation._id
              }`
        )
        .then((res) => {
          if (res.data.success) setExpectedFollowUpList(res.data.data)
          else {
            console.log("err")
          }
        })
        .catch((err) => {
          console.log("err")
        })

      if (currOrganisation?._id) {
        let body = {}
        if (currDateType === "day")
          body = { startDate: currDateTypeValue.value }
        else {
          body = { ...currDateTypeValue.value }
        }

        customAxios
          .put(`/slots/count-slot/${currOrganisation._id}`, { ...body })
          .then((res) => {
            if (res.data.success) {
              if (
                res.data.data.totalSlot === 0 ||
                res.data.data.bookedSlot > res.data.data.totalSlot
              ) {
                setCapacity(null)
              } else if (res.data.data.totalSlot !== "too many") {
                const free = res.data.data.totalSlot - res.data.data.bookedSlot
                setCapacity({
                  totalSlot: res.data.data.totalSlot,
                  freeSlot: free,
                })
              } else {
                setCapacity(null)
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        setCapacity(null)
      }
    }
  }, [
    currStatus,
    currOrganisation,
    currDateType,
    currDateTypeValue,
    allClinics,
    sortOrder,
    updater,
  ]);

  const changeDateType = (dateType, data) => {
    switch (dateType) {
      case "day": {
        const final = {
          label: "Today",
          value: moment(new Date())?.add(330, "minutes").toISOString(),
        }
        dispatch(setCurrDateType(dateType))
        dispatch(setCurrDateTypeValue(final))
        break
      }
      case "week": {
        console.log("pola1", moment().startOf("week"))
        const startDate = moment(new Date()).startOf("week")
        const endDate = moment(new Date()).endOf("week")
        const final = {
          label: `${
            startDate.format("DD MMM") +
            " - " +
            endDate.format("DD MMM")
          }`,
          value: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        }

        dispatch(setCurrDateType(dateType))

        dispatch(setCurrDateTypeValue(final))
        break
      }
      case "month": {
        const startDate = moment(new Date()).startOf("month")
        const endDate = moment(new Date()).endOf("month")
        const final = {
          label: `${startDate.format("MMMM")}`,
          value: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        }
        dispatch(setCurrDateType(dateType))

        dispatch(setCurrDateTypeValue(final))
        break
      }
      case "custom": {
        const final = {
          label: `${
            data?.startDate?.utc().format("DD MMM") +
            " - " +
            data?.endDate?.utc().format("DD MMM")
          }`,
          value: {
            startDate: moment(data?.startDate)?.toISOString(),
            endDate: moment(data?.endDate)?.toISOString(),
          },
        }

        dispatch(setCurrDateType(dateType))

        dispatch(setCurrDateTypeValue(final))
        break
      }
      default:
        return null
    }
  }

  const getNextDateType = (dateType, add = true) => {
    if (dateType !== "day") {
      if (dateType === "month" || dateType === "week") {
        const currDate = currDateTypeValue.value.startDate
        const futureMonth = add
          ? moment(currDate).add("1", dateType)
          : moment(currDate).subtract("1", dateType)
        const startDate = moment(futureMonth).startOf(dateType)
        const endDate = moment(futureMonth).endOf(dateType)
        console.log("pola2", dateType, startDate.format("DD MMMM"))
        const final = {
          label:
            dateType === "month"
              ? `${startDate.format("MMMM")}`
              : dateType === "week"
              ? `${
                  startDate?.format("DD MMM") +
                  " - " +
                  endDate?.format("DD MMM")
                }`
              : null,
          value: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        }

        dispatch(setCurrDateTypeValue(final))
      } else {
        changeDateType("day")
      }
    } else {
      const currDate = currDateTypeValue.value
      const futureDate = add
        ? moment(currDate).add("1", dateType)
        : moment(currDate).subtract("1", dateType)

      const final = {
        label: `${
          moment(futureDate).format("DD/MM/YYYY") ===
          moment().format("DD/MM/YYYY")
            ? "Today"
            : moment(futureDate).format("DD MMM")
        }
                        `,
        value: moment(futureDate).toISOString(),
      }
      dispatch(setCurrDateTypeValue(final))
    }
  }
  const getFilterChats = (searchQuery) => {
    console.log("filterDataCalled")
    if (searchQuery) {
      const filterData = appointmentList?.filter(
        (ele) =>
          ele?.patient?.name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          ele?.patient?.phoneNo?.toString().includes(searchQuery.toLowerCase())
      )
      setFilteredList(filterData?.slice())
    } else {
      setFilteredList([...appointmentList?.slice()])
    }
  }
  useEffect(() => {
    getFilterChats(query)
  }, [query, appointmentList])

  const defaultState = { startDate: null, endDate: null }
  const [capacity, setCapacity] = useState(null)
  const [state, setState] = useState(defaultState)
  const [upcoming, setUpcoming] = useState("-")
  const [completed, setCompleted] = useState("-")
  const [cancelled, setCancelled] = useState("-")
  useEffect(() => {
    if (currOrganisation) {
      customAxios
        .get(
          !allClinics
            ? `/appointments/day/today?org_id=${currOrganisation?._id}&status=all`
            : `/appointments/day/today?&status=all`
        )

        .then((res) => {
          if (res) {
            setUpcoming(res?.data?.data?.length?.toString())
          }
        })
      customAxios
        .get(
          !allClinics
            ? `/appointments/day/today?org_id=${currOrganisation?._id}&status=completed`
            : `/appointments/day/today?&status=completed`
        )

        .then((res) => {
          if (res) setCompleted(res?.data?.data?.length?.toString())
        })
      customAxios
        .get(
          !allClinics
            ? `/appointments/day/today?org_id=${currOrganisation?._id}&status=cancelled`
            : `/appointments/day/today?&status=cancelled`
        )
        .then((res) => {
          if (res) setCancelled(res?.data?.data?.length?.toString())
        })
    }
  }, [currOrganisation, allClinics])

  const theme = useTheme()

  const dateTypeOptions = ["day", "week", "month"]

  const sendGoogleReviewLink = (reviewData) => {
    customAxios
      .get(`/patients/send-message/${reviewData?.patient?.phoneNo}?message_type=review_link`, {})
      .then((res) => {
        if (res?.data?.success) {
          toast.success("Sent Successfuly!")
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => toast.error("Some error occured"))
  }

  const [actionDropdownLoading, setActionDropdownLoading] = useState(null)

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
        if(event?.key?.toLowerCase() === "n" && (event?.target?.localName === "body" || event?.target?.localName === "button" || event?.target?.localName === "a")){
          setDefaultTabForAddDialog(1)
          setAddPatientOpen(true) 
      }
    })
  }, [])

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = [...filteredList];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFilteredList([...items]);
    updateAppointmentPriority([...items]?.map(v => v?._id))
  };

  const updateAppointmentPriority = (ids) => {
    customAxios.put('/doctor/appointment-priority', {data: ids}).then(res => {
      console.log(res?.data, "ON UPDATING PRIORITY")
    })
  }


  return (
    <Card
    >
      <CardHeader className="border-0 pl-4 pr-4 pt-1 pb-2">
        <LoaderFullpage loading={dialogLoading} />
        {/* <CustomSnackbar /> */}
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          style={{
            marginBottom: "1.5rem",
            margintop: "-1rem",
            position: "relative",
          }}
         
        >
          <Button
            style={{ position: "absolute", right: 0, bottom: "-1.5rem" }}
            color="primary"
            startIcon={
              filterMenuOpen ? (
                <i class="fas fa-minus-circle"></i>
              ) : (
                <i class="fas fa-plus-circle"></i>
              )
            }
            onClick={() => dispatch(setFilterOpen(!filterMenuOpen))}
          >
            {filterMenuOpen ? "Hide Filters" : "View Filters"}
          </Button>
          <Grid item md={4} xs={12}>
            <Typography variant="h5">
              <span></span>
              <br />
              👋 Hi, {user.name}
            </Typography>
            <Typography
              style={{ fontSize: "12px", marginTop: "0.5rem" }}
              color="secondary"
            >
              {" "}
              {moment(new Date()).format("ddd, MMM DD")}
            </Typography>

            <Typography>
              <strong style={{ color: "blue " }}>{upcoming}</strong>{" "}
              <span> Upcoming</span> |{" "}
              <strong style={{ color: "green" }}>{completed}</strong>
              <span> Completed</span> |{" "}
              <strong style={{ color: "red" }}>{cancelled}</strong>
              <span> Cancelled</span>
            </Typography>
            <Typography>
              <img
                src={whatsappLogo}
                style={{
                  width: "22px",
                  objectFit: "contain",
                  marginRight: "0.1rem",
                }}
              />
              WhatsApp link sent to{" "}
              <strong style={{ color: colors.primary }}>
                {whatsappLinkPatientList?.length}
              </strong>{" "}
              patients
            </Typography>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {publicNumbers?.includes(232323) ||
            publicNumbers?.includes(123123) ||
            publicNumbers?.includes(123123) ||
            publicNumbers?.includes(0) ? (
              <Grid item>
                <Button
                  color="primary"
                  fullWidth
                  startIcon={<i className="fas fa-users"></i>}
                  block
                  variant="outlined"
                  onClick={() => {
                    setUploadOpen(true)
                  }}
                >
                  Add Bulk Appointments{" "}
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Grid item container spacing={0}>
                <Grid item>
                  <Button
                    color="primary"
                    aria-haspopup="true"
                    variant="contained"
                    startIcon={<i class="fas fa-plus"></i>}

                    onClick={() => {
                      setDefaultTabForAddDialog(1)
                      setAddPatientOpen(true)
                    }}
                  >
                    Add Appointment
                  </Button>
                  <Typography variant="subtitle1" style={{opacity: "70%", marginTop: 5, fontSize: "14px"}}>
                    Press <em><b>"N"</b></em>{"  "} <span style={{marginLeft: "2px"}}>to add appointment</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      border: "1px solid #00A87B",
                      color: "#00A87B",
                      marginLeft: "-10px"
                    }}
                    variant="outlined"
                    startIcon={
                      <img
                        src={whatsappLogo}
                        style={{
                          width: "22px",
                          objectFit: "contain",
                        }}
                      />
                    }
                    onClick={() => {
                      mixpanel.track("Send booking link click on dashboard")
                      setDefaultTabForAddDialog(0)
                      setAddPatientOpen(true)
                    }}
                  >
                    Send booking link
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ padding: "0 1rem" }} />
        {filterMenuOpen ? (
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            style={{ marginTop: "1rem" }}
            spacing={2}
          >
            <Grid
              item
              md={12}
              xs={12}
              container
              spacing={2}
              alignItems="flex-start"
            >
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <i
                        className="fas fa-search"
                        style={{ color: "grey", marginRight: "0.4rem" }}
                      ></i>
                    ),
                    endAdornment: query && (
                      <IconButton onClick={() => setQuery("")} color="primary">
                        <i class="fas fa-times"></i>
                      </IconButton>
                    ),
                  }}
                  value={query}
                  id="searchQuery"
                  onChange={(e) => setQuery(e.target.value)}
                  label="Search Patient"
                  helperText={`Showing ${filteredList?.length} of ${appointmentList?.length} results`}
                  placeholder='Press "/" to search patients '
                ></TextField>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  helperText={
                    currDateType !== "day" ||
                    moment(currDateTypeValue.value).format("DD/MM/YYYY") !==
                      moment(new Date()).format("DD/MM/YYYY") ? (
                      <div style={{ textTransform: "none" }}>
                        Go to{" "}
                        <span
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            fontWeight: "600",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            changeDateType("day")
                          }}
                        >
                          Today
                        </span>
                      </div>
                    ) : null
                  }
                  style={{ textTransform: "capitalize" }}
                  value={currDateType}
                  onChange={(e) => {
                    changeDateType(e.target.value)
                  }}
                >
                  {dateTypeOptions?.map((ele) => (
                    <MenuItem
                      value={ele}
                      style={{ textTransform: "capitalize" }}
                    >
                      {ele}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={3} xs={6}>
                {!openDatePicker && !currDateType?.type ? (
                  <>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        border: "1px solid rgba(0, 0, 0,0.2)",
                        borderRadius: "4px",
                        padding: "4px",
                      }}
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <Grid item style={{ textAlign: "center" }}>
                        {" "}
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            getNextDateType(currDateType, false)
                          }}
                          style={{ fontSize: "20px" }}
                        >
                          {" "}
                          <i class="fas fa-caret-left"></i>
                        </IconButton>
                        <Typography
                          color="secondary"
                          style={{ fontSize: "10px", marginTop: "-0.7rem" }}
                        >
                          {" "}
                          Previous
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          // onClick={() => setOpenDatePicker(true)}

                          align="center"
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {currDateTypeValue.label}
                          </span>
                        </Typography>
                        {/* <Tooltip
                          placement="top"
                          title={
                          }
                        >
                          <>
                            <i
                              className="fas fa-info-circle"
                              style={{ marginLeft: "0.2rem" }}
                            ></i>{" "}
                          </>
                        </Tooltip> */}
                        {capacity ? (
                          <Typography
                            style={{
                              color: "grey",
                              fontSize: "10px",
                            }}
                          >
                            {capacity?.freeSlot}/{capacity.totalSlot} slots are
                            free
                          </Typography>
                        ) : null}
                      </Grid>

                      <Grid item style={{ textAlign: "center" }}>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            getNextDateType(currDateType, true)
                          }}
                          style={{ fontSize: "20px" }}
                        >
                          {" "}
                          <i class="fas fa-caret-right"></i>
                        </IconButton>
                        <Typography
                          color="secondary"
                          style={{ fontSize: "10px", marginTop: "-0.7rem" }}
                        >
                          Next
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* {organisations.length === 1 ? (
                    <Grid item xs={12}>
                      <Typography
                        color="secondary"
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          fontSize: "12px",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          mixpanel.track("Calendar view clicked on dashboard")
                          if (currOrganisation?._id === 0)
                            getDates(organisations[0]?._id, organisations)
                          else getDates(currOrganisation?._id, organisations)
                          setCalendarOpen(true)
                        }}
                      
                        align="center"
                      >
                        Calendar View{" "}
                        <i
                          class="fa-solid fa-arrow-up-right-from-square"
                          style={{ color: "blue" }}
                        ></i>
                      </Typography>
                    </Grid>
                  ) : null} */}

                    {/* <ToggleButtonGroup
                    exclusive
                    value={currDateType}
                    onChange={(e) => {
                      if (e?.target?.value === "Choose Date")
                        setOpenDatePicker(true)
                      else dispatch(setCurrDateType(e.target.value))
                    }}
                    aria-label="text alignment"
                    color="primary"
                  >
                    <ToggleButton value="today" aria-label="left aligned">
                      Today{" "}
                    </ToggleButton>
                    <ToggleButton value="week" aria-label="centered">
                      Week{" "}
                    </ToggleButton>
                    <ToggleButton value="month" aria-label="right aligned">
                      Month
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <TextField
                    select
                    fullWidth
                    label="Time"
                    variant="outlined"
                    style={{ textTransform: "capitalize" }}
                    value={currDateType}
                    onChange={(e) => {
                      if (e?.target?.value === "Choose Date")
                        setOpenDatePicker(true)
                      else dispatch(setCurrDateType(e.target.value))
                    }}
                  >
                    {[...dateTypeOptions, "Choose Date"].map((option) => (
                      <MenuItem
                        value={option}
                        style={{ textTransform: "capitalize" }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  </>
                ) : (
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={() => setOpenDatePicker(true)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => dispatch(setCurrDateType("today"))}
                        >
                          <i class="fa-solid fa-xmark"></i>
                        </IconButton>
                      ),
                    }}
                    value={
                      currDateType?.startDate && currDateType?.endDate
                        ? `${moment
                            .utc(currDateType?.startDate)
                            .format("DD/MM/yyyy")} - ${moment
                            .utc(currDateType?.endDate)
                            .format("DD/MM/yyyy")}`
                        : ""
                    }
                  />
                )}
              </Grid>
              <Grid item md={2} xs={12}>
                {" "}
                <TextField
                  fullWidth
                  variant="outlined"
                  type="select"
                  select
                  label="Location"
                  value={currOrganisation?._id}
                  onChange={(e) => {
                    if (e.target.value === 0) {
                      dispatch(setAllClinics(true))
                      dispatch({
                        type: SET_CURRENT_ORGANISATION,
                        payload: { _id: 0, locationName: "All Clinics", organisationName: "All Clinics" },
                      })
                    } else {
                      if (allClinics) dispatch(setAllClinics(!allClinics))

                      dispatch({
                        type: SET_CURRENT_ORGANISATION,
                        payload: organisations?.find(
                          (ele) => ele._id === e.target.value
                        ),
                      })
                    }
                  }}
                >
                  {[
                    { _id: 0, locationName: "All Clinics", organisationName: "All Clinics" },
                    ...organisations,
                  ]?.map((location, index) => (
                    <MenuItem key={index} value={location._id}>
                      {isDoctor ? location?.organisationName : location.locationName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  select
                  color="primary"
                  label="Status"
                  variant="outlined"
                  value={currStatus}
                  onChange={(e) => {
                    dispatch(setAppointmentStatus(e.target.value))
                  }}
                >
                  <MenuItem value="any">All</MenuItem>
                  <MenuItem value="all">Upcoming</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {openDatePicker ? (
          <DateRangePicker
            state={state}
            defaultState={defaultState}
            setState={setState}
            onSubmit={(data) => {
              console.log("pola", data)
              setOpenDatePicker(false)
              dispatch(setCurrDateType(data))
            }}
            isOpen={openDatePicker}
            setIsOpen={() => setOpenDatePicker(!openDatePicker)}
            onCancel={() => {
              setOpenDatePicker(false)
              setState(defaultState)
              dispatch(setCurrDateType("today"))
            }}
          />
        ) : null}
      </CardHeader>

      <Grid container item xs={12}>
        <Grid
          container
          item
          xs={12}
          style={{ margin: "1rem", marginTop: "0" }}
          spacing={1}
        >
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Typography variant="h5">
                <span style={{ textTransform: "capitalize" }}>
                  {currStatus === "any"
                    ? "All"
                    : currStatus === "all"
                    ? "Upcoming"
                    : currStatus}
                </span>{" "}
                Appointments{" "}
              </Typography>

              <Typography
                style={{ fontSize: "14px", marginTop: "0.5rem" }}
                color="secondary"
              >
                Reach out to to them on{" "}
                <span
                  style={{
                    color: "green",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/admin/chat")}
                >
                  <img
                    src={whatsappLogo}
                    style={{
                      width: "22px",
                      objectFit: "contain",
                      margin: "0 0.2rem",
                    }}
                  />{" "}
                  WhatsApp{" "}
                </span>
                for more updates.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>
                <Button
                  style={{ color: "green" }}
                  startIcon={<i class="fas fa-redo"></i>}
                  onClick={() => setUpdater((prev) => !prev)}
                >
                  Refresh
                </Button>
              </Grid>{" "}
              <Grid item>
                <Button
                  color="primary"
                  fullWidth
                  startIcon={<i class="fas fa-file-export"></i>}
                  title="Export the current appointment table in a .csv format"
                  block
                  onClick={() => {
                    if (filteredList?.length) csvExporter(filteredList)
                    else {
                      toast.error("No appointments found.")
                    }
                    mixpanel.track("CSV_EXPORT")
                  }}
                >
                  Export as csv
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DragDropContext onDragEnd={handleDragEnd}>

        <TableContainer>
          <Table stickyHeader>
            <TableHead style={{ position: "sticky", top: 0, zIndex: "210", backgroundColor: "aliceblue" }}>
              {/* <TableRow style={{ backgroundColor: "aliceblue" }}> */}
                {currStatus === "any" ? (
                  <TableCell style={{ maxWidth: "100px" }}>Status</TableCell>
                ) : null}
                <TableCell style={{width: "20%"}}>Patient Details</TableCell>
                <TableCell style={{width: "10%"}}>Contact</TableCell>
                {/* <TableCell >Symptoms</TableCell> */}
                {organisations[0]?.address ===
                "Sunshine Hospital Opp Patel Lawns Beed Byepass Road Aurangabad Phone 9130561222" ? null : organisations?.length <=
                  1 ? null : (
                  <TableCell style={{ maxWidth: "100px" }}>Location</TableCell>
                )}
                {/* <TableCell>
                  Date
                  {currDateType === "today" &&
                    (sortOrder === "descending" ? (
                      <i
                        class="fas fa-sort-alpha-down "
                        style={{ cursor: "pointer", paddingLeft: "10px" }}
                        onClick={() => {
                          dispatch(setSortOrder("ascending"))
                        }}
                      ></i>
                    ) : (
                      <i
                        class="fas fa-sort-alpha-up"
                        style={{ cursor: "pointer", paddingLeft: "10px" }}
                        onClick={() => {
                          dispatch(setSortOrder("descending"))
                        }}
                      ></i>
                    ))}
                </TableCell> */}
                <TableCell>Slot</TableCell>
                {/* {currDateType === "today" && (
                  <TableCell >Token No.</TableCell>
                )} */}
                {!isDoctor && organisations.length > 1 ? (
                  <TableCell>Doctor</TableCell>
                ) : null}
                <TableCell align={"center"}>Payment Status</TableCell>
                <TableCell align={"center"}>Type</TableCell>
                <TableCell align={"center"}>Action</TableCell>
                <TableCell></TableCell>
              {/* </TableRow> */}
            </TableHead>

            {!isLoading && filteredList?.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: "center" }}>
                    <Typography variant="h6">No patients found </Typography>
                    {query ? (
                      <Typography variant="body1">
                        <span
                          style={{
                            color: "red",
                            textDecorate: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setQuery("")}
                        >
                          Clear
                        </span>{" "}
                        search to view all appointments{" "}
                      </Typography>
                    ) : null}
                    <Grid
                      container
                      xs={12}
                      spacing={2}
                      justifyContent="center"
                      style={{ marginTop: "0.5rem" }}
                    >
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<i class="fas fa-plus"></i>}
                          onClick={() => {
                            setDefaultTabForAddDialog(1)
                            setAddPatientOpen(true)
                          }}
                        >
                          Add Appointment
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          style={{
                            border: "1px solid #00A87B",
                            color: "#00A87B",
                          }}
                          variant="outlined"
                          startIcon={
                            <img
                              src={whatsappLogo}
                              style={{
                                width: "28px",
                                objectFit: "contain",
                              }}
                            />
                          }
                          onClick={() => {
                            setDefaultTabForAddDialog(0)
                            setAddPatientOpen(true)
                          }}
                        >
                          Send booking link
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}

          <Droppable droppableId="table" isDropDisabled={!currDateTypeValue?.label?.includes("Today")}>
            {(provided) => (
            <TableBody ref={provided?.innerRef} {...provided.droppableProps}>
              {!isLoading &&
                filteredList &&
                filteredList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((appointment, index) => (
                    <Draggable key={appointment?._id} draggableId={appointment?._id} index={index} isDragDisabled={!currDateTypeValue?.label?.includes("Today")}>
                    {(provided, snapshot) => (
                    <TableRow
                      style={{
                        background: appointment.freeze ? "bisque" : "inherit",
                      }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={snapshot.isDragging || (tableRowHover === appointment?._id) ? classes.draggableRow : ""}
                      onMouseEnter={() => setTableRowHover(appointment?._id)}
                      onMouseLeave={() => setTableRowHover(null)}
                    >
                      {currStatus === "any" ? (
                        <TableCell
                          style={{ ...styles.tableCell, maxWidth: "120px" }}
                        >
                          {getStatusChip(appointment)}
                        </TableCell>
                      ) : null}

                      <TableCell
                        style={{ position: "relative", ...styles.tableCell, width: "20%" }}
                      >
                        <div style={{display: "flex", alignItems:"center"}}>
                          <span style={{padding: 0, color: theme.palette.primary.main, cursor: "pointer", fontSize: "14px", fontWeight: "bold"}} onClick={() => {
                            mixpanel.track("View Profile from dashboard")

                            history.push(
                              `/admin/patientProfile/${appointment.patient._id}`
                            )
                          }}>{appointment?.patient?.name}{" "}</span>

                          {!appointment?.completed && !appointment?.cancelled  && !(publicNumbers?.toString() === "99999" || user?.orgStaff === 'jagrani') ? <IconButton
                            style={{
                              // position: "absolute",
                              // top: "0rem",
                              // zIndex: "10",
                              // backgroundColor: "#f3f3f3 ",
                              padding: "0.25rem",
                              marginLeft: 5
                              // right: "0",
                            }}
                            onClick={() => {
                              setPatientData({...appointment?.patient})
                              setUpdatePatientOpen(true)
                            }}
                          >
                            <Edit style={{ fontSize: "16px" }} color="primary" />
                          </IconButton> : null}

                          {/* {appointment.checkIn ? (
                            <i
                              class="fas fa-check-circle"
                              title="Patient checked in"
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginLeft: "0.2rem",
                              }}
                            ></i>
                          ) : null} */}

                          {!appointment?.patient?.visitCount ? <Chip size="small" label="NEW" style={{marginLeft: "auto", fontSize: "12px", borderRadius: "10px", opacity: "60%", fontWeight: "bold", backgroundColor: "green", color: "white"}}></Chip> :
                          <CustomTooltip title={appointment?.completed ? `${NumberWithOrdinal(appointment?.patient?.visitCount)} visit` : `${NumberWithOrdinal(appointment?.patient?.visitCount + 1)} visit`} placement="right-end" arrow style={{fontSize: "12px", backgroundColor: "white", color: "black"}}>
                            <Chip size="small" label={appointment?.completed ? appointment?.patient?.visitCount : appointment?.patient?.visitCount+1} style={{marginLeft: "auto", fontSize: "12px", borderRadius: "10px", backgroundColor: "whitesmoke", fontWeight: "bold"}} />
                          </CustomTooltip>
                          }
                        </div>

                        {appointment?.organisation?.isEnterprise && !isDoctor ? (
                          <IconButton
                            style={{
                              position: "absolute",
                              top: "0rem",
                              zIndex: "10",
                              // backgroundColor: "#f3f3f3 ",
                              padding: "0.5rem",
                              right: "0",
                            }}
                            title="Edit Appointment"
                            onClick={() => {
                              startLoading()

                              customAxios
                                .post(
                                  `/appointments/send-wa-otp/${appointment._id}`
                                )
                                .then((res) => {
                                  if (res.data.success) {
                                    setModalData({
                                      ...res.data.data,
                                      appointmentId: appointment?._id,
                                      patientName: appointment?.patient?.name,
                                      patientAge: appointment?.patient?.age,
                                      patientGender: appointment?.patient?.gender,
                                      modeOfPayment: appointment.modeOfPayment
                                        ? appointment.modeOfPayment
                                        : "Cash",
                                      invoiceFor: appointment?.invoiceFor
                                        ? appointment?.invoiceFor
                                        : "Consultation",
                                      amount: appointment?.amount,
                                      orgId: appointment?.organisation._id,
                                    })

                                    setOtpDialogOpen(true)
                                  } else toast.error("Some error occured")
                                  endLoading(false)
                                })
                                .catch((err) => {
                                  toast.error("Some error occured")
                                  endLoading(false)
                                  console.log("error321", err)
                                })
                            }}
                          >
                            <Edit style={{ fontSize: "16px" }} color="primary" />
                          </IconButton>
                        ) : null}
                        {displayAge(appointment.patient)},{" "}
                        {appointment?.patient?.gender}
                        <br />
                        {appointment?.patient?.lastVisited ? <span style={{color: "grey"}}>{`Last Visit: ${moment
                                    ?.utc(appointment?.patient?.lastVisited)
                                    ?.format("ddd, DD MMM")}`}</span> : null}
                      </TableCell>
                      <TableCell 
                        style={{ position: "relative", ...styles.tableCell, width: "10%" }}
                        >
                      {appointment?.patient?.phoneNo}
                      </TableCell>
                      {/* <TableCell style={{}}>
                      {appointment?.problems?.slice(0, 2)?.map((ele, index) => (
                        <span
                          key={index}
                          className="badge-md  m-1 "
                          style={{
                            color: "#ffffff",
                            borderRadius: "5px",
                            backgroundColor: "#93D2E0 ",
                          }}
                        >
                          {ele}
                        </span>
                      ))}
                    </TableCell> */}
                      {organisations[0]?.address ===
                      "Sunshine Hospital Opp Patel Lawns Beed Byepass Road Aurangabad Phone 9130561222" ? null : organisations?.length <=
                        1 ? null : (
                        <TableCell
                          style={{ maxWidth: "125px", ...styles.tableCell }}
                        >
                          <Tooltip
                            title={appointment?.organisation?.address}
                            placement="top"
                          >
                            <Typography
                              // variant="subtitle1"
                              style={{ color: "black" }}
                            >
                              {appointment?.organisation?.address.length > 13
                                ? appointment?.organisation?.address?.substring(
                                    0,
                                    13
                                  ) + "..."
                                : appointment?.organisation?.address}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      )}

                      <>
                        {/* <TableCell style={{ ...styles.tableCell }}>
                          <Tooltip
                            title={
                              appointment.freeze
                                ? "The doctor has currently blocked this slot. Please reach out to the patient for rescheduling/cancelling the appointment  (on the right)."
                                : ""
                            }
                            placement="top"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {moment(appointment?.start).format("D/MM/YYYY") ===
                              moment().format("D/MM/YYYY")
                                ? "Today"
                                : moment
                                    .utc(appointment?.start)
                                    .format("DD/MM/YYYY") ===
                                  moment.utc().add(1, "days").format("DD/MM/YYYY")
                                ? "Tomorrow"
                                : moment
                                    .utc(appointment?.start)
                                    .format("ddd, DD MMM")}
                              {appointment.freeze ? (
                                <GenericAnimation
                                  style={{ marginLeft: "0.2rem" }}
                                  animationData={warningAnimation}
                                  height={30}
                                  width={30}
                                ></GenericAnimation>
                              ) : null}
                            </div>
                          </Tooltip>
                        </TableCell> */}

                        <TableCell style={{ ...styles.tableCell, fontWeight: "bold" }}>
                          {moment.utc(appointment?.start).format("hh:mm A")}
                          <br />
                          <span style={{color: "grey"}}>{moment(appointment?.start).format("D/MM/YYYY") ===
                              moment().format("D/MM/YYYY")
                                ? "Today"
                                : moment
                                    .utc(appointment?.start)
                                    .format("DD/MM/YYYY") ===
                                  moment.utc().add(1, "days").format("DD/MM/YYYY")
                                ? "Tomorrow"
                                : moment
                                    .utc(appointment?.start)
                                    .format("ddd, DD MMM")}</span>
                        </TableCell>
                      </>
                      {/* {currDateType === "today" && (
                      <TableCell>{appointment?.tokenNo}</TableCell>
                    )} */}
                      {!isDoctor && organisations.length > 1 ? (
                        <TableCell style={{ ...styles.tableCell }}>
                          Dr. {appointment?.doctor.name}
                        </TableCell>
                      ) : null}


                        <TableCell style={{ ...styles.tableCell }} align="center">
                          <div className="text-error" style={{ color: "green" }}>
                            <Button
                              style={{
                                marginTop: 15,
                                border: "1px solid goldenrod",
                                color: "green",
                                borderColor: "green"
                              }}
                              variant="outlined"
                              // disabled={appointment?.paymentStatus}
                              onClick={() => {
                                setDialogText(
                                  <div>
                                    <Typography>
                                      Are you sure you want to mark the
                                      appointment as{" "}
                                      <span className="text-danger">paid</span>?
                                    </Typography>
                                    <Typography
                                      color="secondary"
                                      variant="subtitle1"
                                    >
                                      {" "}
                                      Note: Select{" "}
                                      <span style={{ color: "green" }}>
                                        Online&nbsp;
                                      </span>{" "}
                                      as mode of payment to send payment link to
                                      patient's WhatsApp
                                    </Typography>
                                  </div>
                                )
                                setModalData({
                                  id: appointment._id,
                                  treatmentId: appointment.treatmentId
                                    ? appointment.treatmentId
                                    : null,
                                  appointmentId: appointment._id,
                                  name: appointment?.patient?.name,
                                  code: appointment?.patient?.code,
                                  patientId: appointment?.patient?._id,
                                  invoiceData: appointment?.invoiceData
                                    ? appointment.invoiceData
                                    : [],
                                  appointmentAmount: appointment?.amount,
                                  patient: appointment?.patient,
                                  docId: appointment?.docId
                                })

                                // if (publicNumbers?.toString() === "99999" || user?.orgStaff === 'jagrani'){
                                //   setPaymentCollectedDialogOpen(true)
                                // } else
                                setManagePaymentDialogOpen(true)
                              }}
                            >
                              Manage Payment
                            </Button>
                            <div><span style={{color: 'grey'}}>Patient is </span> 
                              {appointment?.invoiceCount ? <span style={{color: "#0B5345"}}><em>"Billed"</em></span> : <span style={{color: "red"}}><em>"Un-billed"</em></span>}
                            </div>
                          </div>
                      </TableCell>
                      <TableCell style={{ ...styles.tableCell }} align="center">
                        {appointment.treatmentId ? (
                          <><i
                            class="fas fa-procedures"
                            title={appointment?.treatment?.name}
                            style={{ fontSize: "24px", color: "goldenrod" }}
                          ></i>
                          <div>Procedure</div>
                          </>
                        ) : appointment?.isOnline ? (
                          <><i
                            className="fas fa-wallet"
                            title="Online Appointment"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                          <div>Procedure</div>
                          </>
                        ) : appointment.vaccination ? (
                          <i
                            className="fas fa-syringe"
                            title="Vaccination Appointment"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                        ) : (
                          <>
                            {/* <div style={{display: 'flex',
                              justifyContent: 'center'}}> */}
                            <i
                              class="fas fa-walking"
                              title="Offline Appointment"
                              style={{
                                fontSize: "24px",
                                color: "grey",
                                // marginLeft: 5
                              }}
                            ></i>{" "}
                            {/* </div> */}
                            <div 
                            // style={{
                            //   display: 'flex',
                            //   justifyContent: 'center',
                            // }}
                            >Walk-in</div>
                            {appointment.emergency ? (
                              <Chip
                                label="Emergency Appointment"
                                style={{
                                  backgroundColor: "#FF3E43",
                                  color: "white",
                                  borderRadius: "0.25em",
                                  marginLeft: "1rem",
                                }}
                              ></Chip>
                            ) : null}
                          </>
                        )}
                      </TableCell>

                      {/* <TableCell style={{ ...styles.tableCell }}>
                        {appointment?.paymentStatus ? (
                          <div className="text-success ">
                            {appointment?.modeOfPayment
                              ? `Paid by ${appointment?.modeOfPayment}`
                              : appointment?.isOnline
                              ? "Paid Online"
                              : "Paid"}
                          </div>
                        ) : (
                          <div className="text-error" style={{ color: "red" }}>
                            {isDoctor && "Pending"}
                            {!isDoctor && (
                              <Button
                                style={{ border: "1px solid red", color: "red" }}
                                variant="outlined"
                                disabled={appointment?.paymentStatus}
                                onClick={() => {
                                  setDialogText(
                                    <div>
                                      Are you sure you want to mark the
                                      appointment as{" "}
                                      <span className="text-danger">paid</span>?
                                    </div>
                                  )
                                  setModalData({
                                    id: appointment._id,
                                    name: appointment?.patient?.name,
                                    code: appointment?.patient?.code,
                                  })
                                  setPaymentCollectedDialogOpen(true)
                                }}
                              >
                                Mark as Paid
                              </Button>
                            )}
                          </div>
                        )}
                      </TableCell>
                      <TableCell style={{ ...styles.tableCell }}>
                        {appointment?.isOnline ? (
                          <i
                            className="fas fa-wallet"
                            title="Online Appointment"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                        ) : appointment.vaccination ? (
                          <i
                            className="fas fa-syringe"
                            title="Vaccination Appointment"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                        ) : (
                          <>
                            <i
                              class="fas fa-walking"
                              title="Offline Appointment"
                              style={{
                                fontSize: "24px",
                                color: "grey",
                              }}
                            ></i>{" "}
                            {appointment.emergency ? (
                              <Chip
                                label="Emergency Appointment"
                                style={{
                                  backgroundColor: "#FF3E43",
                                  color: "white",
                                  borderRadius: "0.25em",
                                  marginLeft: "1rem",
                                }}
                              ></Chip>
                            ) : null}
                          </>
                        )}
                      </TableCell> */}
                      <TableCell style={{ ...styles.tableCell }} colSpan={2}>
                        {!appointment?.completed && !appointment?.cancelled ? (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-start",
                              columnGap: "1rem",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <IconButton
                              style={{ color: "green", padding: 5 }}
                              title="Chat"
                              onClick={() => {
                                mixpanel.track("WhatsApp from dashboard")

                                history.push(`/admin/chat`, {
                                  patient: appointment?.patient,
                                })
                              }}
                            >
                              <img
                                src={whatsappLogo}
                                style={{
                                  width: "28px",
                                  objectFit: "contain",
                                }}
                              />
                            </IconButton>

                            <StartConsultationButton
                              appointment={appointment}
                              isDoctor={isDoctor}
                            />
                            {!isDoctor && user.phoneNo === 8960099939 ? (
                              <StartConsultationButton
                                appointment={appointment}
                                isDoctor={true}
                              />
                            ) : null}
                            {/* {!appointment.isOnline && !appointment.checkIn ? (
                              <Button
                                style={{ border: "1px solid lightgreen" }}
                                title="The patient is available and has checked in for the offline appointment."
                                onClick={() => {
                                  customAxios
                                    .put(
                                      `/appointments/check-in/${appointment._id}?check_in=true`
                                    )
                                    .then((res) => {
                                      if (res.data.success) {
                                        toast.success("Patient checked in")
                                        mixpanel.track("Check in from Dashboard")
                                        setUpdater((prev) => !prev)
                                      } else toast.error("Some error occured")
                                    })
                                    .catch((err) =>
                                      toast.error("Some error occured")
                                    )
                                }}
                              >
                                Check In
                              </Button>
                            ) : null} */}

                              {/* <Button
                                color="primary"
                                variant="outlined"
                              title="Mark As Completed"
                              style={{padding: 5}}
                                onClick={() => {
                                  setModalData({ id: appointment._id })

                                  setDialogText(
                                    <div>
                                      Are you sure you want to mark the
                                      appointment as{" "}
                                      <span className="text-danger">
                                        completed
                                      </span>
                                      ?
                                    </div>
                                  )

                                  setQuickEndConsultationDialogOpen(true)
                                }}
                              >
                                Mark As Completed
                              </Button> */}
                            
                            {/* <IconButton
                              color="primary"
                              outline
                              type="button"
                              size="small"
                              variant="outlined"
                              title="View profile"
                              onClick={() => {
                                mixpanel.track("View Profile from dashboard")

                                history.push(
                                  `/admin/patientProfile/${appointment.patient._id}`
                                )
                              }}
                            >
                                <img src={PersonAddIcon} style={{height: 30, width: 30}}/>
                            </IconButton> */}

                            
                            {actionDropdownLoading && actionDropdownLoading === appointment?._id ?
                            //  <CircularProgress
                            //   size={24}
                            //   color="primary"
                            // />
                            <GenericAnimation animationData={actionLoaderAnimation} height={30} width={30} style={{padding: 0, marginLeft: -5}} loop={false} speed={0}/>
                            : <ActionableDropdown
                            disable={false}
                            setUpdater={setUpdater}
                            isLoading={actionDropdownLoading}
                            setIsLoading={setActionDropdownLoading}
                            appointment={appointment}
                            onReschedule={() => {
                              setModalData({
                                appointmentId: appointment._id,
                                orgId: appointment.organisation._id,
                                ...appointment.patient,
                                onReschedule: true
                              })
                              setAddPatientOpen(true)
                            }}
                            onMarkAsComplete={() => {
                              setModalData({ id: appointment._id })

                              setDialogText(
                                <div>
                                  Are you sure you want to mark the
                                  appointment as{" "}
                                  <span className="text-danger">
                                    completed
                                  </span>
                                  ?
                                </div>
                              )

                              setQuickEndConsultationDialogOpen(true)
                            }}
                          />}
                          </div>
                        ) : appointment?.completed ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "evenly-spaced",
                              columnGap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              style={{ color: "green", padding: 5 }}
                              title="Chat"
                              onClick={() => {
                                mixpanel.track("WhatsApp from dashboard")
                                history.push(`/admin/chat`, {
                                  patient: appointment?.patient,
                                })
                              }}
                            >
                              <img
                                src={whatsappLogo}
                                style={{
                                  width: "28px",
                                  objectFit: "contain",
                                }}
                              />{" "}
                            </IconButton>

                            {appointment?.prescriptionExists ? <Button
                              color="primary"
                              variant="outlined"
                              title="View Prescription"
                              startIcon={<i class="fas fa-file-prescription"></i>}
                              onClick={() => {
                                mixpanel.track("View Prescription from Dashboard")
                                generatePdf(appointment._id)
                              }}
                            >
                              View Prescription
                            </Button> : null}
                            {appointment?.invoiceCount ? <IconButton
                              style={{ color: "green", padding: 5 }}
                              title="Print Invoice"
                              onClick={() => {
                                setModalData(appointment)
                                startLoading()

                                customAxios
                                  .put(
                                    `/consultation/generate-invoice/${appointment._id}`,
                                    {}
                                  )
                                  .then((res) => {
                                    setInvoiceDialogOpen(false)
                                    if (res.data.success) {
                                      mixpanel.track(
                                        "Print Invoice from Dashboard"
                                      )
                                      handleDownloadInvoice(
                                        res.data.data,
                                        true,
                                        false,
                                        `${appointment?.patient?.name}_(#${appointment?.patient?.code})_Invoice`
                                      )
                                    } else {
                                      endLoading()
                                      toast.error("Some error occured")
                                    }
                                  })
                                  .catch((err) => {
                                    endLoading()
                                    toast.error("Some error occured")
                                  })
                              }}
                            >
                                <img src={PrintIcon} style={{height: 30, width: 30}}/>
                            </IconButton> : null}


                            {/* <IconButton
                              color="primary"
                              outline
                              type="button"
                              size="small"
                              variant="outlined"
                              title="View profile"
                              onClick={() => {
                                history.push(
                                  `/admin/patientProfile/${appointment.patient._id}`
                                )
                              }}
                            >
                                <img src={PersonAddIcon} style={{height: 30, width: 30}}/>
                            </IconButton> */}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "evenly-spaced",

                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              style={{ color: "green", padding: 5 }}
                              title="Chat"
                              onClick={() => {
                                history.push(`/admin/chat`, {
                                  patient: appointment?.patient,
                                })
                              }}
                            >
                              <img
                                src={whatsappLogo}
                                style={{
                                  width: "28px",
                                  objectFit: "contain",
                                }}
                              />{" "}
                            </IconButton>

                            {/* <IconButton
                              color="primary"
                              outline
                              type="button"
                              size="small"
                              variant="outlined"
                              title="View profile"
                              onClick={() => {
                                history.push(
                                  `/admin/patientProfile/${appointment.patient._id}`
                                )
                              }}
                            >
                                <img src={PersonAddIcon} style={{height: 30, width: 30}}/>
                            </IconButton> */}
                          </div>
                        )}
                      </TableCell>

                    </TableRow>
                    )}
                    </Draggable>
                  ))}

                  {isLoading?<TableRow>
                    <TableCell colSpan={12} align="center">
                      <CircularProgress
                          size={30}
                          color="primary"
                        />
                        
                    </TableCell>
                  </TableRow>:null}
            </TableBody>
            )}
            </Droppable>
          </Table>
        </TableContainer>

      </DragDropContext>

      <TablePagination
        align="center"
        rowsPerPageOptions={[
          5,
          10,
          25,
          50,
          75,
          { label: "All", value: filteredList?.length },
        ]}
        component="div"
        count={filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, page) => {
          setPage(page)
        }}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(e.target.value)
          setPage(0)
        }}
        
      />
      {expectedFollowUpList.length ? (
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          direction="column"
        >
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              style={{ margin: "1rem" }}
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item container direction="column" xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h5">Expected Follow-ups</Typography>
                </Grid>
                <Grid item md={8} xs={12} style={{ marginTop: "0.5rem" }}>
                  <Typography style={{ fontSize: "14px" }} color="secondary">
                    Follow up reminders along with booking link sent on these
                    patient's WhatsApp.
                    <br /> Reach out to to them on{" "}
                    <span
                      style={{
                        color: "green",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push("/admin/chat")}
                    >
                      <img
                        src={whatsappLogo}
                        style={{
                          width: "22px",
                          objectFit: "contain",
                          margin: "0 0.2rem",
                        }}
                      />{" "}
                      WhatsApp{" "}
                    </span>
                    for more updates.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography color="secondary" style={{ marginTop: "0.5rem" }}>
                  Send followup reminders to all the patients below with one
                  click 👇
                </Typography>

                <Button
                  title="Notfiy Patient"
                  color="primary"
                  onClick={() => {
                    handleNotifyAll()
                  }}
                  endIcon={<i class="fas fa-users"></i>}
                >
                  <GenericAnimation
                    animationData={notificationBell}
                    width={35}
                    height={35}
                  />{" "}
                  Click here to remind all patients on WhatsApp
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer style={{ marginTop: "1rem" }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "lightyellow" }}>
                  <TableCell>Name</TableCell>
                  {organisations[0]?.address ===
                  "Sunshine Hospital Opp Patel Lawns Beed Byepass Road Aurangabad Phone 9130561222" ? null : organisations?.length <=
                    1 ? null : (
                    <TableCell
                      style={{ maxWidth: "125px", ...styles.tableCell }}
                    >
                      Location
                    </TableCell>
                  )}
                  <TableCell>Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expectedFollowUpList?.map((ele) => (
                  <TableRow>
                    <TableCell
                      style={{ position: "relative", ...styles.tableCell }}
                    >
                      {ele?.patient?.name} <br />
                      {displayAge(ele?.patient)},{" "}
                      {ele?.patient?.gender}
                      <br />
                      {ele?.patient?.phoneNo}
                    </TableCell>{" "}
                    {organisations[0]?.address ===
                    "Sunshine Hospital Opp Patel Lawns Beed Byepass Road Aurangabad Phone 9130561222" ? null : organisations?.length <=
                      1 ? null : (
                      <TableCell
                        style={{ maxWidth: "125px", ...styles.tableCell }}
                      >
                        <Tooltip
                          title={ele?.organisation?.address}
                          placement="top"
                        >
                          <Typography
                            // variant="subtitle1"
                            style={{ color: "black" }}
                          >
                            {ele?.organisation?.address.length > 13
                              ? ele?.organisation?.address?.substring(0, 13) +
                                "..."
                              : ele?.organisation?.address}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    )}
                    <TableCell style={{ ...styles.tableCell }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {moment(ele?.followUp?.date).format("D/MM/YYYY") ===
                        moment().format("D/MM/YYYY")
                          ? "Today"
                          : moment
                              .utc(ele?.followUp?.date)
                              .format("ddd, DD MMM")}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-start",
                          columnGap: "1rem",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <IconButton
                          style={{ color: "green" }}
                          title="Chat"
                          onClick={() => {
                            mixpanel.track("WhatsApp from dashboard")

                            history.push(`/admin/chat`, {
                              patient: ele?.patient,
                            })
                          }}
                        >
                          <img
                            src={whatsappLogo}
                            style={{
                              width: "28px",
                              objectFit: "contain",
                            }}
                          />
                        </IconButton>
                        <Button
                          title="Notfiy Patient"
                          onClick={() => {
                            customAxios
                              .put("patients/notify-patient", {
                                docId: ele?.doctor?._id,
                                patientId: ele.patient?._id,
                                consultationId: ele._id,
                              })
                              .then((res) => {
                                if (res.data.success) {
                                  mixpanel.track("FollowUp reminder sent")
                                  toast.success("Patient notified successfully")
                                  const audio = new Audio(success)
                                  const promise = audio.play()
                                  if (promise !== undefined) {
                                    // On older browsers play() does not return anything, so the value would be undefined.
                                    promise
                                      .then(() => {
                                        // Audio is playing.
                                      })
                                      .catch((error) => {
                                        console.log(error)
                                      })
                                  }
                                }
                              })
                              .catch((err) => {
                                toast.error("Some error occured")
                              })
                          }}
                        >
                          <GenericAnimation
                            animationData={notificationBell}
                            width={35}
                            height={35}
                          />{" "}
                          Click here to remind patient on WhatsApp
                        </Button>
                        {/* <IconButton
                          color="primary"
                          title="Notfiy Patient"
                          onClick={() => {
                            customAxios
                              .put("patients/notify-patient", {
                                docId: ele?.doctor?._id,
                                patientId: ele.patient?._id,
                                consultationId: ele._id,
                              })
                              .then((res) => {
                                if (res.data.success)
                                  toast.success("Patient notified successfully")
                              })
                              .catch((err) => {
                                toast.error("Some error occured")
                              })
                          }}
                        >
                          <i class="fas fa-bell"></i>
                        </IconButton> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}

      {managePaymentDialogOpen ? (
        <ManagePaymentDialog
          modalData={modalData}
          dialogText={dialogText}
          setUpdater={setUpdater}
          setModalData={setModalData}
          managePaymentDialogOpen={managePaymentDialogOpen}
          setManagePaymentDialogOpen={setManagePaymentDialogOpen}
        />
      ) : null}

      {whatsappLinkPatientList.length ? (
        <Grid container item xs={12}>
          <Grid container item xs={12} style={{ margin: "1rem" }} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">WhatsApp link sent</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={{ fontSize: "14px" }} color="secondary">
                WhatsApp booking link has been sent to these patients.
                <br /> Reach out to to them on{" "}
                <span
                  style={{
                    color: "green",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/admin/chat")}
                >
                  <img
                    src={whatsappLogo}
                    style={{
                      width: "22px",
                      objectFit: "contain",
                      margin: "0 0.2rem",
                    }}
                  />{" "}
                  WhatsApp{" "}
                </span>
                for more updates.
              </Typography>
            </Grid>
          </Grid>

          <TableContainer style={{ marginTop: "1rem" }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "beige" }}>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {whatsappLinkPatientList?.map((ele) => (
                  <TableRow>
                    <TableCell
                      style={{ position: "relative", ...styles.tableCell }}
                    >
                      {ele?.patient?.name} <br />
                      {displayAge(ele?.patient)},{" "}
                      {ele?.patient?.gender}
                      <br />
                      {ele?.patient?.phoneNo}
                    </TableCell>{" "}
                    <TableCell style={{ ...styles.tableCell }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {moment(ele.waLinkAt).format("D/MM/YYYY") ===
                        moment().format("D/MM/YYYY")
                          ? "Today"
                          : moment.utc(ele.waLinkAt).format("ddd, DD MMM")}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-start",
                          columnGap: "1rem",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <IconButton
                          style={{ color: "green" }}
                          title="Chat"
                          onClick={() => {
                            mixpanel.track("WhatsApp from dashboard")

                            history.push(`/admin/chat`, {
                              patient: ele?.patient,
                            })
                          }}
                        >
                          <img
                            src={whatsappLogo}
                            style={{
                              width: "28px",
                              objectFit: "contain",
                            }}
                          />
                        </IconButton>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            handleSendWhatsAppLink({
                              phoneNo: ele.patient.phoneNo,
                              docId: ele.docId,
                            })
                          }}
                        >
                          Resend Link
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}
      {quickEndConsultationDialogOpen ? (
        <QuickEndModal
          open={quickEndConsultationDialogOpen}
          onSubmit={quickEndConsultation}
          setOpen={setQuickEndConsultationDialogOpen}
          modalData={modalData}
          loading={isLoading}
          message={dialogText}
          setModalData={setModalData}
          dialogTitle="Quick End Consultation"
          submitButtonText="End Consultation"
        />
      ) : null}
      {paymentCollectedDialogOpen ? (
        <GenericDialogForm
          setIsOpen={setPaymentCollectedDialogOpen}
          message={dialogText}
          dialogContent={paymentCollectedDialogHeaders}
          style={{ overflowY: "visible" }}
          open={paymentCollectedDialogOpen}
          handleClose={() => setPaymentCollectedDialogOpen(false)}
          dialogTitle="Add Invoice Info"
          formData={{ invoiceFor: "Consultation", modeOfPayment: "Cash" }}
          handleSubmit={(formData) =>
            paymentCollected({ ...modalData, ...formData })
          }
        />
      ) : null}
      {otpDialogOpen ? (
        <GenericDialogForm
          setIsOpen={setOtpDialogOpen}
          autoClose={false}
          message={
            <Typography variant="body1" align="center">
              An otp was sent to the organisation's admin. Call{" "}
              <a href={`tel:${modalData.to}`}>{modalData.to}</a> to ask for the
              OTP.
            </Typography>
          }
          animationProps={{ animationData: otpAnimation }}
          dialogContent={[
            {
              key: "otp",
              maxlength: "6",
              label: "Enter Otp",
              md: 12,
              inputProps: {
                helperText: "Otp is valid for 10 minutes only.",
              },
            },
          ]}
          open={otpDialogOpen}
          handleClose={() => setOtpDialogOpen(false)}
          dialogTitle="Edit Appointment"
          formData={{ ...modalData }}
          submitButtonText="Verify OTP"
          handleSubmit={(formData) => {
            customAxios
              .put(`/appointments/verify-wa-otp/${modalData.appointmentId}`, {
                ...formData,
                ...modalData,
              })
              .then((res) => {
                if (res.data.success) {
                  setModalData({ ...formData, ...modalData })
                  setOtpDialogOpen(false)
                  setEditAppointmentDialogOpen(true)
                } else {
                  toast.error("Incorrect Otp")
                }
              })
              .catch("Some error occured")
          }}
        />
      ) : null}

      {editAppointmentDialogOpen ? (
        <GenericDialogForm
          setIsOpen={setEditAppointmentDialogOpen}
          dialogContent={[
            {
              key: "patientName",
              label: "Patient Name",
              md: 6,
              required: true,
            },
            {
              key: "patientAge",
              label: "Patient Age",
              md: 6,
              required: true,
            },
            {
              key: "patientGender",

              type: "select",
              options: [
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ],
              label: "Patient Gender",
              md: 6,
              required: true,
            },
            {
              label: "Mode of Payment",
              key: "modeOfPayment",
              type: "autocomplete",
              options: ["Cash", "Card", "UPI", "Other"],
              required: true,
            },
            {
              label: "Amount",
              key: "amount",
              type: "number",
              required: true,
            },
            {
              label: "Bill For",
              key: "invoiceFor",
              required: true,
              md: 6,
              type: "autocomplete",
              options: ["Service", "Vaccination", "X-Ray", "Consultation"],
            },
            {
              label: "Doctor",
              key: "orgId",
              required: true,
              md: 6,
              type: "select",
              options: [...allDoctors],
            },
            {
              label: "Details",
              key: "details",
              type: "text",
              md: 12,
              placeholder: "Eg: 15 sessions of Miniscus tear physiotherapy",
              required: false,
            }
          ]}
          open={editAppointmentDialogOpen}
          autoClose={false}
          handleClose={() => setEditAppointmentDialogOpen(false)}
          dialogTitle="Edit Appointment"
          formData={{
            ...modalData,
          }}
          submitButtonText="Submit"
          handleSubmit={(formData) => {
            const res = allDoctors?.find((ele) => ele._id === formData?.orgId)

            console.log("website", formData, allDoctors)
            const finalObj = {
              patient: {
                name: formData?.patientName,
                age: formData?.patientAge,
                gender: formData?.patientGender,
              },
              ...formData,
              docId: res?.admin,
            }
            customAxios
              .post(`/appointments/update/${modalData.appointmentId}`, {
                ...finalObj,
              })
              .then((res) => {
                if (res.data.success) {
                  setOtpDialogOpen(false)
                  setEditAppointmentDialogOpen(false)
                  handleDownloadInvoice(
                    res.data.data,
                    true,
                    false,
                    `${modalData.patientName}_Invoice`
                  )
                } else {
                  toast.error("Some error occured")
                }
              })
              .catch((err) => {
                toast.error("Some error occured")
              })
          }}
        />
      ) : null}
      {uploadOpen ? (
        <FileUploadModal
          multiple={false}
          allowedFileTypes={".csv"}
          message={
            <Grid item xs={12} style={{ marginBottom: "1rem" }} container>
              <Grid item xs={12} style={{ margin: "1.5rem 0" }}>
                <Typography style={{ margin: "0.5rem 0" }}>
                  Select an organisation to add bulk appointments:
                </Typography>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label="Organisation"
                  onChange={(e) => setDialogOrgId(e.target.value)}
                >
                  {organisations.map((ele) => (
                    <MenuItem value={ele._id}>{ele.organisationName}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Add a file: <br />
                  <ul>
                    <li>
                      Upload a <strong>.csv</strong> file to book bulk
                      appointments
                    </li>
                    <li>
                      Time should be in <strong>24 hr</strong> format
                    </li>
                    <li>
                      The appointments will be booked for the{" "}
                      <strong>next day</strong> on the given time slot
                    </li>
                    <li>
                      <strong>Time</strong> is a <strong>required</strong> field
                    </li>
                    <li>
                      First row should be the same as mentioned in the file
                      below
                    </li>
                    <li>
                      It might take <strong>upto 5 minutes</strong> to reflect
                      the changes in the dashboard.
                    </li>
                    {publicNumbers?.includes(232323) ? (<>
                      <li>
                        Format for <strong>Date</strong> and <strong>DateRange</strong> should
                        be in <strong>DD/MM/YYYY</strong>.
                      </li>
                      <li>
                        The duration of <strong>DateRange</strong> cannot be 
                        greater than <strong>10 days</strong>.
                      </li>
                      <li>
                        Upto 10 locations can be filled in <strong>LocationRange</strong> separated
                        by commas(,).
                      </li>
                    </>) : null}
                  </ul>
                  {publicNumbers?.includes(232323) ? (<>Download the sample csv(s):
                    <span
                      onClick={() => [
                        window.open(
                          "https://storage.googleapis.com/website-paperplane/Bulk_Upload_Time_Sample.csv"
                        ),
                      ]}
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      HL_Appointment
                    </span>{"  "}
                    <span
                      onClick={() => [
                        window.open(
                          "https://storage.googleapis.com/website-paperplane/HL_HQ+flow.csv"
                        ),
                      ]}
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      HL_HQ flow.csv
                    </span>{"  "}<span
                      onClick={() => [
                        window.open(
                          "https://storage.googleapis.com/website-paperplane/Advik_like_flow+sample.csv"
                        ),
                      ]}
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      Advik_like_flow sample.csv
                    </span></>) : 
                    (<>Download a sample csv from
                    <span
                      onClick={() => [
                        window.open(
                          "https://storage.googleapis.com/website-paperplane/Bulk_Upload_Time_Sample.csv"
                        ),
                      ]}
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      here
                    </span></>)}
                </Typography>
              </Grid>
            </Grid>
          }
          open={uploadOpen}
          setOpen={setUploadOpen}
          state={csvFile}
          loading={dialogLoading}
          dialogTitle="Add bulk appoitments"
          setState={setCsvFile}
          onSubmit={() => handleBulkUpload(csvFile, dialogOrgId)}
        ></FileUploadModal>
      ) : null}
      {addPatientOpen && (
        <AddAppointmentDialog
          open={addPatientOpen}
          currOrg={currOrganisation}
          modalData={modalData}
          setModalData={setModalData}
          setOpen={setAddPatientOpen}
          setUpdater={setUpdater}
          setAllPatients={setAllPatients}
          allPatients={allPatients}
          defaultTabValue={defaultTabForAddDialog}
        />
      )}
      {invoiceOpen ? (
        <Invoice
          open={invoiceOpen}
          setOpen={setInvoiceOpen}
          state={modalData}
          amount={modalData?.amount}
          billFor={modalData.billFor}
          invoiceNo={modalData?.invoiceNo}
          id={modalData?._id}
        ></Invoice>
      ) : null}

      {updatePatientOpen ? (
        <GenericDialogFormNew1
          style={{ overflowY: "visible" }}
          formData={patientData}
          open={updatePatientOpen}
          handleClose={() => setUpdatePatientOpen(false)}
          dialogContent={updatePatientHeaders}
          dialogTitle="Edit Patient Details"
          handleSubmit={handleUpdatePatient}
          submitButtonLoading={updatePatientLoader}
        ></GenericDialogFormNew1>
      ) : null}

      {/* {calendarOpen ? (
        <GenericDialogForm
          dialogTitle="Calendar"
          onDialogMount={() => {}}
          handleClose={() => {
            setAllSlotsList([])
            setCalendarOpen(false)
          }}
          handleSubmit={() => handleClose()}
          open={calendarOpen}
          dialogContent={[
            {
              key: "date",
              type: "date",
              md: 12,
              onChange: (value, state, setState) => {
                setState((prev) => ({ ...state, date: value }))
                getAllSlots({
                  slotDate: moment(value).format("DD/MM/YYYY"),
                  orgId: "all",
                  docId: isDoctor ? user._id : user.admin,
                })
              },
              otherProps: {
                variant: "static",
                helperText: (
                  <Typography style={{ fontSize: "12px" }}>
                   
                    Pro Tip: Change your timings{" "}
                    <span
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push("/admin/CreateSlot")}
                    >
                      here
                    </span>
                  </Typography>
                ),
              
                ToolbarComponent: () => {
                  return (
                    <Grid
                      item
                      xs={12}
                      container
                      style={{
                        background: "#f3f3f3",
                        padding: "0.5rem",
                        borderRadius: "12px 012px 0px 0px",
                      }}
                    >
                      <Grid item md={4} xs={12}>
                        <div
                          style={{
                            minHeight: "24px",
                            width: "24px",
                            borderRadius: "50%",
                            background: getColor("green"),
                          }}
                        ></div>
                        <Typography
                          style={{ fontSize: "10px", color: "darkcyan" }}
                        >
                          50-100% slots free
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <div
                          style={{
                            minHeight: "24px",
                            width: "24px",
                            borderRadius: "50%",
                            background: getColor("yellow"),
                          }}
                        ></div>
                        <Typography
                          style={{ fontSize: "10px", color: "darkcyan" }}
                          color="secondary"
                        >
                          25-50% slots free
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <div
                          style={{
                            minHeight: "24px",
                            width: "24px",
                            borderRadius: "50%",
                            background: getColor("red"),
                          }}
                        ></div>
                        <Typography
                          style={{ fontSize: "10px", color: "darkcyan" }}
                          color="secondary"
                        >
                          25% slots free
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                },
                fullWidth: true,
                shouldDisableDate: (date) => {
                  const enableDate = dateSlotsList.find(
                    (ele) => moment(date).format("YYYY-MM-DD") === ele.date
                  )

                  if (enableDate) return false
                  return true
                },
                disablePast: true,
                renderDay: (
                  day,
                  selectedDate,
                  isInCurrentMonth,
                  dayComponent
                ) => {
                  const date = dateSlotsList.find((ele) =>
                    moment(ele.date).isSame(day)
                  )

                
                  return (
                    <Tooltip
                      placement="top"
                      title={date ? getText(date.color) : ""}
                    >
                      <div
                        style={{
                          width: "36px",
                          height: "36px",
                          backgroundColor:
                            selectedDate === day
                              ? "white"
                              : date
                              ? getColor(date.color)
                              : "inherit",
                          color: date ? "white" : "inherit",
                          borderRadius: "50%",
                          margin: "1px 2px",
                        }}
                      >
                        {dayComponent}
                      </div>
                    </Tooltip>
                  )
                },
              },
            },
          
          ]}
          footerMessage={() => {
            return allSlotsList.length ? (
              <Grid container item xs={12}>
                <Grid item xs={12} style={{ margin: "1rem 0" }}>
                  <Typography>
                    {moment(allSlotsList[0].start).format("ddd, DD MMM")}
                  </Typography>
                  <Typography color="secondary" style={{ fontSize: "12px" }}>
                    You can block slots and even schedule appointments from this
                    screen
                  </Typography>
                  <Typography style={{ fontSize: "11px" }} color="secondary">
                    You can also change your timings from{" "}
                    <span
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push("/admin/CreateSlot")}
                    >
                      here
                    </span>
                  </Typography>
                </Grid>
                <Grid item container xs={12} alignItems="center">
                  {allSlotsList?.map((ele, idx) => (
                    <Grid item style={{ padding: "0.5rem" }} xs={12}>
                      {" "}
                      {
                        <SlotBlock
                          data={ele}
                          onAddAppointment={() => {
                            setModalData({
                              date: new Date(ele.start),
                              time: ele.display,
                              start: ele.start,
                              end: ele.end,
                            })
                            setCalendarOpen(false)

                            setAddPatientOpen(true)
                          }}
                          setUpdater={() =>
                            getAllSlots({
                              slotDate: moment(allSlotsList[0].start).format(
                                "DD/MM/YYYY"
                              ),
                              orgId: "all",
                              docId: isDoctor ? user._id : user.admin,
                            })
                          }
                          key={uniqueId()}
                        ></SlotBlock>
                      }
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : (
              ""
            )
          }}
        ></GenericDialogForm>
      ) : null} */}
    </Card>
  )
}

const mapStateToProps = (state) => ({
  organisations: state.user.organisations,
})

export default connect(mapStateToProps)(withRouter(DashboardTable))
