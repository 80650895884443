import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import customAxios from "../../axios";
import AddStaff from "./AddStaff";
import StaffTable from "./StaffTable";

function StaffManagement() {
  const handleDelete = (idx) => {
    const { _id: staffId } = tableData[idx];
    console.log("Delete triggered", staffId);
    customAxios
      .put(`/staff/remove/${staffId}`, {})
      .then((res) => {
        const temp = [...tableData];
        temp.splice(idx, 1);
        setTableData([...temp]);
      })
      .catch((err) => console.log("err", err));
  };
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({ new: true, organisations: [] });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    customAxios
      .put("/staff/filter")
      .then((res) => {
        setIsLoading(false);
        setTableData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleSubmit = (modalData, allOrganisations) => {
    const temp = allOrganisations.filter((ele) => ele.isSelected);
    const selectedIds = temp?.map((ele) => ele._id);
    if (!modalData.new) {
      if (
        !modalData.name ||
        !modalData.phoneNo ||
        !modalData.birthDate ||
        !modalData.email
      ) {
        toast.error("Please fill all the details properly");
      } else {
        customAxios
          .put(`/staff/update/${modalData._id}`, {
            ...modalData,
            organisations: [...selectedIds],
          })
          .then((res) => {
            if (res.data.success) {
              setIsOpen(false);
              window.location.reload();
            } else toast.error("The email or phone number already exists.");
          })
          .catch((err) => {
            toast.error("Some error occured.");
          });
      }
    } else {
      if (
        !modalData.name ||
        !modalData.phoneNo ||
        !modalData.birthDate ||
        !modalData.email
      ) {
        toast.error("Please fill all the details properly");
      } else {
        customAxios
          .post(`/staff/add`, { ...modalData, organisations: [...selectedIds] })
          .then((res) => {
            if (res.data.success) {
              setIsOpen(false);
              window.location.reload();
            } else {
              toast.error("The email or phone number already exists");
            }
            console.log("res", res);
          })
          .catch((err) => {
            toast.error("Some error occured");
          });
      }
    }
  };

  const columns = [
    { id: "name", name: "Name" },
    { id: "phoneNo", name: "Phone" },
    { id: "email", name: "Email" },
  ];

  const [tableData, setTableData] = useState([]);
  return (
    <>
      <Card className="m-3">
        <Row className="p-3" style={{ alignItems: "center" }}>
          <Col md="10">
            <h3 style={{ fontSize: "30px" }}>Clinic Staff:</h3>
          </Col>
          <Col md="2">
            <Button
              color="primary"
              block
              onClick={() => {
                setModalData({ new: true, organisations: [] });
                setIsOpen(true);
              }}
            >
              <i className="fas fa-plus "></i> Add Staff
            </Button>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <StaffTable
              columns={columns}
              data={tableData}
              isLoading={isLoading}
              setModalData={setModalData}
              setIsOpen={setIsOpen}
              handleDelete={handleDelete}
            ></StaffTable>
          </Col>
        </Row>

        <AddStaff
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalData={modalData}
          setModalData={setModalData}
          title="Add Patient"
          handleSubmit={handleSubmit}
        />
      </Card>
    </>
  );
}

export default StaffManagement;
