import { Grid, Typography, TextField, Button, Avatar, useTheme } from "@material-ui/core"
import React, { useEffect } from "react"
import { useRef } from "react"
import GenericAnimation from "../../animation"
import { TabSwitchingHelperText } from "../helper"
function SystemExamination({ state, setState, padIndex, setActiveIndex, activeIndex }) {
  const seFields = [
    { label: "CVS" },
    { label: "CNS" },
    { label: "P/A" },
    { label: "R/S" },
  ]

  const update = useRef(0)
  const theme = useTheme()

  useEffect(() => {
    if(update?.current < 2) {
      update.current += 1
      const res = state?.otherData?.systemExamination?.length
        ? state?.otherData?.systemExamination
        : seFields;
  
      setState({
        ...state,
        otherData: { 
          ...(state?.otherData ? state?.otherData : {}), 
          systemExamination: [...res] 
        },
      });
    }
  }, [state])

  const textFieldsRefs = useRef([]);

  function handleKeyDown(event, index) {
    if (event.key === "ArrowRight" && index < textFieldsRefs.current.length - 1) {
      textFieldsRefs.current[index + 1].focus();
    } else if (event.key === "ArrowLeft" && index > 0) {
      textFieldsRefs.current[index - 1].focus();
    }
    else if (event.key === "ArrowUp" && padIndex > 0) {
      setActiveIndex({"index": padIndex - 1, "from": padIndex})
    } else if (event.key === "ArrowDown")
      setActiveIndex({"index": padIndex + 1, "from": padIndex})
  }

  useEffect(() => {
    if(activeIndex?.index === padIndex) {
      textFieldsRefs.current[0].focus();
    }
  }, [activeIndex])


  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container justifyContent="space-between" style={{marginBottom: "-8px", marginTop: "-10px"}}>
          <Grid item style={{display: "flex", alignItems:"center", marginBottom: "5px", marginTop: "5px"}}>  
            <Avatar style={{
                fontSize: 18,
                width: 30,
                height: 30, 
                marginRight: 10, 
                color: "#5e72e4", 
                backgroundColor: "white",
              }}>
                <i className="fas fa-stethoscope"></i>
              </Avatar>
          <Typography variant="h6" style={{fontWeight: "600"}}>System Examination</Typography>
          </Grid>        
        {/* <TabSwitchingHelperText /> */}
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          style={{ flexWrap: "nowrap", marginTop: "-0.8rem" }}
          container
          alignItems="center"
        >
          <Typography color="secondary">
            *In order to apply ‘Normal’ to all fields{" "}
          </Typography>
          <Button
            color="primary"
            onClick={() => {
              const temp = state?.otherData?.systemExamination.map((ele) => ({
                label: ele.label,
                value: "Normal",
              }))
              console.log("999", temp)
              setState({
                ...state,
                otherData: { ...state.otherData, systemExamination: [...temp] },
              })
            }}
          >
            Click Here
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          item
          spacing={2}
          style={{ marginTop: "-1.5rem" }}
        >
          {(state?.otherData?.systemExamination)?.map((v, i) => (
            <Grid item container xs={6} md={3} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">{v?.label}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  inputRef={(ref) => (textFieldsRefs.current[i] = ref)}
                  onKeyDown={(e) => handleKeyDown(e, i)}
                  // InputProps={{
                  //   endAdornment: (
                  //     <>
                  //       <Typography color="secondary">{v?.metricUnit}</Typography>
                  //       <DeleteForever
                  //         color="error"
                  //         style={{ cursor: "pointer" }}
                  //         onClick={() => {
                  //           const temp = [...state.vitals];
                  //           temp.splice(i, 1);
                  //           setState({ ...state, vitals: [...temp] });
                  //         }}
                  //       />
                  //     </>
                  //   ),
                  // }}
                  //   onKeyDown={(e) => {
                  //   if (e.key === "Enter" &&(e.target.value === "" || re.test(e.target.value)) ){

                  //   }
                  // }}
                  value={state?.otherData?.systemExamination?.[i]["value"]}
                  placeholder="e.g Normal"
                  onChange={(e) => {
                    const temp = [...state?.otherData?.systemExamination]
                    temp[i]["value"] = e.target.value
                    setState({
                      ...state,
                      otherData: {
                        ...state?.otherData,
                        systemExamination: [...temp],
                      },
                    })
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SystemExamination
