import {
  Avatar,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  styled,
  useTheme,
} from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import customAxios from "../../axios"
import ReactDatetime from "react-datetime"

import printIcon from "./../../assets/img/consultation/id-card icon.png"
import {
  AddBoxOutlined,
  ArrowBack,
  ArrowBackIos,
  ArrowForwardIos,
  BorderColor,
  CheckCircle,
  CheckCircleOutline,
  ChevronRight,
  Close,
  Delete,
  Description,
  Edit,
  FastForward,
  FileCopy,
  History,
  Home,
  HomeOutlined,
  Info,
  LocationCity,
  PersonPinCircle,
  PinDrop,
  Print,
  Publish,
} from "@material-ui/icons"
import userImg from "./../../assets/user.png"
import mixpanel from "mixpanel-browser"
import { toast } from "react-toastify"
import { useHistory } from "react-router"
import QuickEndModal from "./QuickEndModal"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import noDataImg from "./../../assets/img/consultation/no_data_vector1.jpg"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import displayAge from "../../assets/functions/displayAge"
import GenericDialogFormNew1 from "../CreatePrescription/GenericDialog/newIndex"
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns" // choose your lib
import moment from "moment"
import { Skeleton } from "@material-ui/lab"

import { Viewer } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useSelector } from "react-redux"

// Create new plugin instance

const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: 300,
  // Enable scrolling
  overflowY: 'scroll',
  // Hide the scrollbar
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 1, // Adjust to desired thickness
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent', // Adjust to desired color
  },
});


const useStyles = makeStyles({
  tab: {
    minWidth: '15%', // Adjust the width as per your requirement
    // padding: 0
  },
  tooltip: {
    fontSize: "14px"
  }
});


function Sidebar({
  appointmentData,
  patientData,
  activeStep,
  setActiveStep,
  setLoading,
  state, 
  setState,
  templateList = []
}) {
  const classes = useStyles()
  const history = useHistory()
  const quickEndModalHeaders = [
    {
      label: "Follow-up Date",
      key: "expDate",
      type: "date",
      fullWidth: true,
    },
  ]
  const quickEndAppointment = (modalData) => {
    setModalLoading(true)
    customAxios
      .post("consultation/quick-end", {
        appointmentId: appointmentData._id,
        followUp: modalData.date
          ? { expDate: moment.utc(modalData?.date).format() }
          : null,
      })
      .then((res) => {
        if (res.data.success) {
          setModalLoading(false)

          mixpanel.track("Quick End Consultation with Follow Up")
          mixpanel.people.increment("Quick End Consultation with Follow Up")

          toast.success("Consultaion completed successfully.")
          setQuickEndModalOpen(false)
          history.push("/admin/dashboard")
        } else {
          setModalLoading(false)

          toast.error("Some error occured.")
        }
      })
      .catch((err) => {
        setModalLoading(false)

        mixpanel.track("Quick End Appointment Error", { ...err })

        toast.error("Some error occured.")
        console.log(err)
      })
  }
  const [quickEndModalOpen, setQuickEndModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [modalLoading, setModalLoading] = useState(false)

  const [updatePatientOpen, setUpdatePatientOpen] = useState(false)
  const handleUpdatePatient = (data) => {
    setLoading(true)
    setUpdatePatientOpen(false)
    customAxios
      .put(`patients/update/${appointmentData?.patientId}`, { ...data })
      .then((res) => {
        setLoading(false)
        window.location.reload()
        if (res.data.success) {
          toast.success("Patient details updated successfully.")
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((Err) => {
        toast.error("Some error occured")
        setLoading(false)
      })
  }
  const updatePatientHeaders = [
    {
      label: "Name",
      key: "name",
      type: "text",
      md: 10
    },
    {
      label: "Phone",
      key: "phoneNo",
      // disabled: true,
      type: "number",
      md: 10
    },
    {
      key: "ageString",
      label: "Age",
      unitOptions1: ['Years', "Months", "Days"],
      unitOptions: ['Year', 'Month', 'Day'],
      placeholder: "e.g. 10 Years",
      freeSolo: true,
      type: "unit-autocomplete",
      md: 10
    },
    {
      label: "Gender",
      key: "gender",
      type: "select-chip",
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Other", value: "other" },
      ],
      md: 12
    },
  ]

  const patientInforoutes = [
    {
      label: "Medical Record",
      icon: AddBoxOutlined,
    },
    {
      label: "Patient Assessment",
      icon: Description,
    },
    {
      label: "Appointment History",
      icon: History,
    },
  ]
  const handleGenerateSticker = () => {
    setLoading(true)
    customAxios

      .get(
        `doctor/patient-sticker/${patientData?._id}?org_id=${appointmentData?.organisation?._id}`
      )
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          window.open(res.data.data.url, "_blank")

        } else {
          setLoading(false)
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Some error occured")
      })
  }

  const [selectedTab, setSelectedTab] = useState(0);
  const [avatarSelected, setAvatarSelected] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const theme = useTheme();

  const [pastVisits, setPastVisits] = useState([])
  const [loadingPastVists, setLoadingPastVists] = useState(true)

  const updateState = (data, keyName = "all", medAppend = false) => {

    toast.success("Copied to Pad")

    let tempData = null;
    try {
      tempData = JSON.parse(JSON.stringify(data));
    } catch (err) {
      tempData = [...data];
      console.log(err, 'in deep copy')
    }

    switch(keyName) {
      case "all":{
        let tests = []
        if(tempData?.tests?.length){
          tests = tempData.tests.map(v => ({name: v}))
        }
        setState({...state, ...tempData, tests: [...tests], otherData: (state?.otherData ? {...state?.otherData, prevHistoryUsed: true} : {prevHistoryUsed: true})})
        break
      }
      default:{
        if(medAppend) {
          setState({...state, medicines: [...state?.medicines, ...tempData], otherData: (state?.otherData ? {...state?.otherData, prevHistoryUsed: true} : {prevHistoryUsed: true})})
        } else {
          setState({...state, [keyName]: [...state?.[keyName], ...tempData], otherData: (state?.otherData ? {...state?.otherData, prevHistoryUsed: true} : {prevHistoryUsed: true})})
        }
        break
      }
    }

  }

  const updateVital = (data) => {
    if(data?.length){
      setState({...state, vitals: [...data], vitalUpdate: true});

      toast.success("Copied to Pad")
    }
  }
  
  useEffect(() => {
    setLoadingPastVists(true)
    if(appointmentData?.docId && patientData?._id)
    customAxios
      .get(`consultation/filter?doc_id=${appointmentData?.docId}&patient_id=${patientData?._id}`)
      .then((res) => {
        if (res.data.success) {
          setPastVisits(res?.data?.data?.reverse())
          setLoadingPastVists(false)
        }
      })
  }, [appointmentData]);


  const [vitalList, setVitalList] = useState([])
  const isOH = useSelector((state) => state.user?.user?.ohID)

  useEffect(() => {
    customAxios
      .get(`/vital/${patientData?._id}`)
      .then((res) => {
        if (res.data.success) {
          const temp = res.data.data.vitals;
          temp.reverse();
          if(isOH && temp?.length && temp?.[0]?.data?.length){
            let todayDate = moment.utc(new Date()).format("DD MMMM yy")

            let tempVitalData = [];
            temp?.map(vt => {
              if((moment.utc(vt?.date)?.format("DD MMMM yy") === todayDate) && vt?.['data']) {
                tempVitalData = tempVitalData.concat(vt?.['data'])
              }
            })
            if(tempVitalData?.length){
              setState({...state, vitals: [...tempVitalData], vitalUpdate: true});
            }
          }
          setVitalList([...temp]);
        }
      })
      .catch((err) => console.log(err));
  }, [patientData]);


  return (
    <Grid
      container
      style={{
        // width:    "300px",
        height: "95vh",
        padding: "1rem",
        backgroundColor: "#fff",
        borderRadius: "40px 0px 40px 0px",
        position: "relative"
      }}
    >
      <Grid item xs={12} container justifyContent="center" style={{height: "15%", maxHeight: "100%"}}>
        <Grid item xs={12} container justifyContent="flex-end" style={{marginBottom: "-3rem"}}>
        <IconButton
          style={{
            marginLeft: "auto"
          }}
          onClick={() => setUpdatePatientOpen(true)}
        >
          <Edit color="primary" />
        </IconButton>
        </Grid>
        <Grid
          item
          xs={4}
          container
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <Avatar
            style={{
              objectFit: "contain",
              width: "60px",
              height: "60px",
              backgroundColor: "#5e72e4",
            }}
          >
            {patientData?.name?.split("")[0]}
          </Avatar>
          <Button
            onClick={() => handleGenerateSticker()}
            title="Print patient details on a sticker"
            color="primary"
            size="small"
            style={{ borderRadius: "11px", marginTop: "0.5rem" }}
          >
            Print Sticker
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontWeight: "700" }}>
            {patientData?.name}
          </Typography>
          <Typography>
            {displayAge(patientData)} |{" "}
            {patientData?.gender} |{" "}
            {patientData?.birthDate}
          </Typography>
          <Typography>{patientData?.phoneNo}</Typography>
          <Typography>
            <span style={{}}># {patientData?.code}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2} alignItems="center">
          {/* <Grid item>
            <IconButton
              color="primary"
              title="Call Patient"
              onClick={() => {
                var a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.href = `tel:${patientData.phoneNo}`
                a.click()
              }}
            >
              <i class="fas fa-phone-alt fa-flip-horizontal fa-0.75x"></i>{" "}
            </IconButton>
          </Grid> */}
          {appointmentData?.isOnline ? (
            <Grid item>
              <IconButton
                style={{ color: "green" }}
                title="Chat"
                onClick={() => {
                  history.push(`/admin/chat`, {
                    appointmentId: appointmentData._id,
                  })
                }}
              >
                <img
                  src={whatsappLogo}
                  style={{
                    width: "28px",
                    objectFit: "contain",
                  }}
                />{" "}
              </IconButton>
            </Grid>
          ) : null}
          {appointmentData?.link ? (
            <Grid item>
              <IconButton
                color="primary"
                title="Video call"
                onClick={() => {
                  window.open(appointmentData.link)
                }}
              >
                <i class="fas fa-video"></i>
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
        {patientData?.address ? (
          <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
            <Typography>
              <PinDrop color="primary" fontSize="small" />{" "}
              <span style={{}}> {patientData?.address}</span>
            </Typography>
          </Grid>
        ) : null}

          <Grid item xs={12} container justifyContent="center" style={{marginTop: "3%"}}>
            <Grid item>
              <div style={{ bottom: -10, left: 10, fontSize: "13px", }}>
                Powered By 
                <span style={{fontWeight: "600", marginLeft: "4px", fontSize: "13px"}}>  
                  Paper<span style={{
                    color: "#4D63DD",
                    // background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                    // WebkitBackgroundClip: 'text',
                    // WebkitTextFillColor: 'transparent', 
                    // fontWeight: "bold",
                    // marginLeft: 3,
                    // fontSize: "16px"
                  }}>plane</span>
                </span>
              </div>
            </Grid>
          </Grid>

                  <Grid item xs={12} style={{marginTop: "2%"}}>
                    <Divider />
                  </Grid>
        <Grid item container xs={12} style={{marginTop: "2%"}}>
          <Grid item xs={12}>
            <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" style={{maxWidth: "100%", padding: 0}}>
              <Tab
              // style={{maxWidth: "50px", padding: 0}}
              className={classes.tab}
              disableRipple
                value={0}
                label={
                  <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item>
                      <Avatar title="Past Visits" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 0 ? theme.palette.primary.main : "#CACFD2"}}>
                        P
                      </Avatar>
                    </Grid>

                    {selectedTab === 0 ? <Grid item>
                      <Typography variant="subtitle2">
                        Past Visits
                      </Typography>
                    </Grid> : null}
                  </Grid>
                }
              />

              <Tab  
              disableRipple
              className={classes.tab}
              value={1}
              label={
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                      <Avatar title="Saved Templates" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 1 ? theme.palette.primary.main : "#CACFD2"}}>
                        Rx
                      </Avatar>
                    </Grid>
                  {selectedTab === 1 ? <Grid item>
                    <Typography variant="subtitle2">
                      Saved Templates
                    </Typography>
                  </Grid> : null}
                </Grid>
              }              
              />

              <Tab  
              disableRipple
              className={classes.tab}
              value={2}
              label={
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                      <Avatar title="Vitals" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 2 ? theme.palette.primary.main : "#CACFD2"}}>
                        V
                      </Avatar>
                    </Grid>
                  {selectedTab === 2 ? <Grid item>
                    <Typography variant="subtitle2">
                      Vitals
                    </Typography>
                  </Grid> : null}
                </Grid>
              }              
              />

              {/*
               <Tab
              disableRipple
              className={classes.tab}
              value={2}
              label={
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                    <Avatar title="Vitals" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 2 ? theme.palette.primary.main : "#CACFD2"}}>
                      V
                    </Avatar>
                  </Grid>
                  {selectedTab === 2 ? <Grid item>
                    <Typography variant="subtitle2">
                      Vitals
                    </Typography>
                  </Grid> : null}
                </Grid>
              }              />*/}
               <Tab
              disableRipple
              className={classes.tab}
              value={3}
              label={
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                    <Avatar title="Health Records" style={{padding: 0, width: 25, height: 25, backgroundColor: selectedTab === 3 ? theme.palette.primary.main : "#CACFD2"}}>
                      R
                    </Avatar>
                  </Grid>
                  {selectedTab === 3 ? <Grid item>
                    <Typography variant="subtitle2">
                      Health Records
                    </Typography>
                  </Grid> : null}
                </Grid>
              } />
            </Tabs>
          </Grid>

          <CustomGrid item xs={12} style={{maxHeight: "72vh", overflowY: "scroll", paddingTop: "10px"}}>
            {selectedTab === 0 && (
              <Grid container>
                <PastVisit data={pastVisits} loading={loadingPastVists} updateState={updateState}/>
              </Grid>
            )}
            {selectedTab === 1 && (
              <Grid container>
                <PastVisit data={templateList} loading={loadingPastVists} updateState={updateState} forTemplate={true}/>
              </Grid>
            )}
            {selectedTab === 2 && (
              <Grid container>
                <PastVital data={vitalList} updateVital={updateVital} />
              </Grid>
            )}
            {selectedTab === 3 && (
              <Grid container>
                <MedicalRecords appointmentData={appointmentData} />
              </Grid>
            )}
          </CustomGrid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
      </Grid> */}
      
      {/* {patientInforoutes?.map((ele) => {
        const { icon: Icon } = ele
        return (
          <Grid
            item
            xs={12}
            container
            onClick={() => setActiveStep(ele.label)}
            // onMouseEnter={() => setActiveStep(ele.label)}
            alignItems="center"
            style={{
              cursor: "pointer",
              flexWrap: "nowrap",
              backgroundColor: activeStep === ele.label ? "#5e72e4" : "#fff",
              borderRadius: "11px",
              padding: "0.5rem",
              margin: "0.25rem 0 0 0 ",
            }}
            justifyContent="space-between"
          >
            <Grid item container>
              <Icon
                style={{ color: activeStep === ele.label ? "#fff" : "#5e72e4" }}
              />
              <Typography
                style={{
                  paddingLeft: "1rem",
                  color: activeStep === ele.label ? "#fff" : "#696969",
                }}
              >
                {ele.label}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight
                style={{ color: activeStep === ele.label ? "#fff" : "#5e72e4" }}
              />
            </Grid>
          </Grid>
        )
      })}
      <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
        <Typography style={{ fontWeight: "600" }}>Prescription Pad</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        onClick={() => setActiveStep("Write Prescription")}
        alignItems="center"
        // onMouseEnter={() => setActiveStep("Write Prescription")}
        style={{
          cursor: "pointer",
          flexWrap: "nowrap",
          borderRadius: "11px",
          padding: "0.5rem",
          margin: "0.25rem 0 0 0 ",

          backgroundColor:
            activeStep === "Write Prescription" ? "#5e72e4" : "#fff",
        }}
        justifyContent="space-between"
      >
        <Grid item container>
          <BorderColor
            style={{
              color: activeStep === "Write Prescription" ? "#fff" : "#5e72e4",
            }}
          />
          <Typography
            style={{
              paddingLeft: "1rem",
              color: activeStep === "Write Prescription" ? "#fff" : "#696969",
            }}
          >
            Write Prescription
          </Typography>
        </Grid>
        <Grid item>
          <ChevronRight
            style={{
              color: activeStep === "Write Prescription" ? "#fff" : "#5e72e4",
            }}
          />
        </Grid>
      </Grid>{" "}
      {/* <Grid
        item
        xs={12}
        container
        onClick={() => setActiveStep("End Consultation")}
        onMouseEnter={() => setActiveStep("End Consultation")}
        alignItems="center"
        style={{
          cursor: "pointer",
          flexWrap: "nowrap",
          borderRadius: "11px",
          padding: "0.5rem",
          margin: "0.25rem 0 0 0 ",

          backgroundColor:
            activeStep === "End Consultation" ? "#5e72e4" : "#fff",
        }}
        justifyContent="space-between"
      >
        <Grid item container>
          <CheckCircleOutline
            style={{
              color: activeStep === "End Consultation" ? "#fff" : "#5e72e4",
            }}
          />
          <Typography
            style={{
              paddingLeft: "1rem",
              color: activeStep === "End Consultation" ? "#fff" : "#696969",
            }}
          >
            End Consultation
          </Typography>
        </Grid>
        <Grid item>
          <ChevronRight
            style={{
              color: activeStep === "End Consultation" ? "#fff" : "#5e72e4",
            }}
          />
        </Grid>
      </Grid>{" "}
      <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
        <Divider />
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button
          title="Send a follow-up date and end consultation without prescription details"
          startIcon={<FastForward />}
          fullWidth
          onClick={() => setQuickEndModalOpen(true)}
          style={{ color: "#DD4D4D", border: "1px solid #DD4D4D" }}
        >
          Quick End Consultation
        </Button>
      </Grid> */}
      {quickEndModalOpen ? (
        <QuickEndModal
          open={quickEndModalOpen}
          onSubmit={quickEndAppointment}
          setOpen={setQuickEndModalOpen}
          modalData={modalData}
          loading={modalLoading}
          message={
            <div>
              Are you sure you want to{" "}
              <span className="text-danger">end the consultation</span>?
            </div>
          }
          setModalData={setModalData}
          dialogTitle="Quick End Consultation"
          submitButtonText="End Consultation"
        />
      ) : null}
      {updatePatientOpen ? (
        <GenericDialogFormNew1
          style={{ overflowY: "visible" }}
          formData={patientData}
          open={updatePatientOpen}
          handleClose={() => setUpdatePatientOpen(false)}
          dialogContent={updatePatientHeaders}
          dialogTitle="Edit Patient Details"
          handleSubmit={handleUpdatePatient}
        ></GenericDialogFormNew1>
      ) : null}
      
    </Grid>
  )
}

export default Sidebar




const PastVisit = ({ data, loading, updateState, forTemplate }) => {

  function sortByDateDescending(a, b) {
    const dateA = new Date(a?.date);
    const dateB = new Date(b?.date);
    return dateB - dateA;
  }

  return (
    <React.Fragment>
      {!loading ? <Grid item xs={12} container>
        {data?.length ? [...data]?.sort(sortByDateDescending)?.map((val, idx) => (
          <PastVisitCard card={val} index={idx} updateState={updateState} forTemplate={forTemplate}/>
        )) : <Grid item xs={12} container justifyContent="center" alignItems="center" style={{opacity: "70%"}}>
            <Grid item style={{marginTop: "15%", textAlign: "center"}}>
              <img src={noDataImg} style={{height: "50%", width: "50%"}} />
            </Grid>
            <Grid item style={{marginTop: "2rem", textAlign: "center"}}>
              <Typography variant="h6" style={{color: "grey", fontWeight: "bolder"}}>
                {forTemplate ? "No Saved Templates" : "No Past Visits"}
              </Typography>
              <Typography variant="subtilte1" style={{color: "grey"}}>
                {forTemplate ? "Your Saved Templates will be shown here" : "Your patient's past prescriptions will be shown here"}
              </Typography>
            </Grid>
          </Grid>}
      </Grid> : (
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "80px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />

          <Skeleton animation="wave" variant="rect" style={{marginTop: 20, marginBottom: 10, height: "80px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />

          <Skeleton animation="wave" variant="rect" style={{marginTop: 20, marginBottom: 10, height: "80px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />
        </Grid>
      )}
    </React.Fragment>
  )
}


const PastVisitCard = ({card, index, updateState, forTemplate}) => {

  const prescription = card?.prescription;
  const classes = useStyles()

  const getSymptomText = () => {
    let symptomText = "";
    prescription?.symptoms?.map((s, i) => {
      if(s?.name){
        symptomText += `${s.name}`;
        if(i < prescription?.symptoms?.length - 1){
          symptomText += " | ";
        }
      }
    })

    return symptomText;
  }

  const getTestText = () => {
    let testText = "";
    prescription?.tests?.map((s, i) => {
      if(s){
        testText += `${s}`;
        if(i < prescription?.tests?.length - 1){
          testText += " | ";
        }
      }
    })

    return testText;
  }

  const getDiagnosisText = (withComma = false) => {
    let diagnosisText = "";
    prescription?.diagnosis?.map((s, i) => {
      if(s?.name){
        diagnosisText += `${s.name}`;
        if(i < prescription?.diagnosis?.length - 1 && s?.name){
          diagnosisText += withComma ? ", " : " | ";
        }
      }
    })

    return diagnosisText
  }

  const [medicinesHover, setMedicinesHover] = useState(null);

  const getPastMeds = (med, medIdx) => {

    let medDetails = '';
    const medKeys = ["doseQuantity", "frequence", "timing", "duration", "instructions"];
    medKeys.map((key, ki) => {
      if(med?.[key]){
        if (medDetails !== ''){
          medDetails += " | "
        }
        medDetails += med[key];
        
      }
    })

    return (
      <Grid item xs={12} container>
        <Grid item xs={12} onMouseEnter={() => {
          setMedicinesHover(medIdx)
        }}
        onMouseLeave={() => {setMedicinesHover(null)}}
        onClick={() => updateState([{...med}], "medicines", true)}
        style={{display: "flex", alignItems: "center"}}
        >
          <Typography style={{fontWeight: 'bold', color: "black"}} color={medicinesHover === medIdx ? "primary" : "default"}>
            {med?.name}
          </Typography>
          {medicinesHover === medIdx ? <FileCopy fontSize="small" style={{fontSize: "10px", marginLeft: 5}}/> : null}
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {medDetails}
          </Typography>
        </Grid>
      </Grid>
    )
  }


  const getLabTests = () => {

    let labDetails = '';
    const labKeys = ["name", "reading", "unit", "interpretation"];

    prescription?.labResults?.map((lab, li) => {
      
      labKeys.map((key) => {
        if(lab?.[key]){
          if(lab?.[key] && key === "name"){
            labDetails += `${lab?.[key]}`
          } else if(lab?.[key] && key === 'interpretation'){
            labDetails += ` (${lab?.[key]})`
          } else if(lab?.[key] && key === 'reading'){
            labDetails += `: ${lab?.[key]}`
          } else if(lab?.[key] && key === 'unit'){
            labDetails += ` ${lab?.[key]}`
          }
        }
      })

      if(li < prescription?.labResults?.length - 1){
        labDetails += " | "
      }
    })


    return labDetails
  }


  const [symptomHover, setSymptomHover] = useState(false);
  const [diagnosisHover, setDiagnosisHover] = useState(false);
  const [medHover, setMedHover] = useState(false);
  const [testHover, setTestHover] = useState(false);
  const [labResultHover, setLabResultHover] = useState(false);


  return (
    <Grid item xs={12} container style={{width: "100%", height: "auto", margin: 5}} spacing={2}>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item xs={11}> 
          {!forTemplate ? <Typography style={{fontSize: "14px", fontWeight: "bold", color: "black"}}>
            {moment.utc(card?.date)?.format("DD MMMM yy")}: {
            prescription?.diagnosis?.length ? <span style={{marginLeft: 1}}>{getDiagnosisText(true)}</span> : null
            }
          </Typography> : (
            <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
              {card?.templateName}
            </Typography>
          )}
        </Grid>
        {prescription?.symptoms?.length || prescription?.medicines?.length || prescription?.diagnosis?.length || prescription?.tests?.length || prescription?.labResults?.length ? <Grid item xs={1}>
          <IconButton size="small" disableRipple onClick={() => updateState({...prescription})}>
            <Tooltip title="Copy to Pad" classes={{tooltip: classes.tooltip }}>
              <FileCopy fontSize="small" style={{fontSize: "14px"}}/>
            </Tooltip>
          </IconButton>
        </Grid> : null}
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {prescription?.symptoms?.length ? <Grid item xs={12} container spacing={1} style={{backgroundColor: symptomHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setSymptomHover(true)
        }}
        onMouseLeave={() => {setSymptomHover(false)}}
        onClick={() => updateState([...prescription?.symptoms], "symptoms")}
        >
          <Grid item xs={1} style={{marginRight: "5px"}}>
            <Tooltip title="Symptoms" classes={{tooltip: classes.tooltip }}>
              {/* <i className="fas fa-virus-covid"></i> */}
              <Avatar style={{height: "24px", width: "24px", fontSize: "13px", color: "#D4AC0D", backgroundColor: "#FCF3CF", fontWeight: "bold", marginRight: "10px"}} color="primary">Sx</Avatar>
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            {getSymptomText()}
            {symptomHover ? <IconButton size="small" style={{position: "absolute", right: 5, top: 15}}>
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton> : null}
          </Grid>
        </Grid> : null}

        {prescription?.diagnosis?.length ? <Grid item xs={12} container spacing={1} style={{backgroundColor: diagnosisHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setDiagnosisHover(true)
        }}
        onMouseLeave={() => {setDiagnosisHover(false)}}
        onClick={() => updateState([...prescription?.diagnosis], "diagnosis")}
        >
          <Grid item xs={1} style={{marginRight: "5px"}}>
            <Tooltip title="Diagnosis" classes={{tooltip: classes.tooltip }}>
              <Avatar style={{height: "24px", width: "24px", fontSize: "13px", color: "#E74C3C", backgroundColor: "#FADBD8", fontWeight: "bold", marginRight: "10px"}} color="primary">Dx</Avatar>
              {/* <i className="fas fa-head-side-virus"></i> */}
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            {getDiagnosisText()}
            {diagnosisHover ? <IconButton size="small" style={{position: "absolute", right: 5, top: 15}}>
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton> : null}
          </Grid>
        </Grid> : null}

        {prescription?.medicines?.length ? <Grid item xs={12} container spacing={1} style={{backgroundColor: medHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setMedHover(true)
        }}
        onMouseLeave={() => {setMedHover(false)}}
        >
          <Grid item xs={1} style={{marginRight: "5px"}}>
            <Tooltip title="Medicines" classes={{tooltip: classes.tooltip }}>
              <Avatar style={{height: "24px", width: "24px", fontSize: "13px", color: "#17A589", backgroundColor: "#D1F2EB", fontWeight: "bold", marginRight: "10px"}} color="primary">Mx</Avatar>
              {/* <i className="fas fa-pills"></i> */}
            </Tooltip>
          </Grid>
          <Grid item xs={11} container spacing={1}>
            {prescription?.medicines?.map((v, i) => getPastMeds(v, i))}
            {medHover ? <Tooltip title="Copy All Medicines"><IconButton title="Copy All Medicines" size="small" style={{position: "absolute", right: 5, top: 15}}
                onClick={() => updateState([...prescription?.medicines], "medicines")}
                >
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton></Tooltip> : null}
          </Grid>
        </Grid> : null}

        {prescription?.tests?.length ? <Grid item xs={12} container spacing={2} style={{backgroundColor: testHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setTestHover(true)
        }}
        onMouseLeave={() => {setTestHover(false)}}
        onClick={() => updateState(prescription?.tests?.filter(v => v)?.map(v => {return {name: v}}), "tests")}
        >
          <Grid item xs={1} style={{marginRight: "5px"}}>
            <Tooltip title="Lab Tests" classes={{tooltip: classes.tooltip }}>
              <Avatar style={{height: "24px", width: "24px", fontSize: "11px", color: "#5e72e4", backgroundColor: "#D1E8F8", fontWeight: "bold", marginRight: "10px"}} color="primary">Test</Avatar>
              {/* <i className="fas fa-flask-vial"></i> */}
            </Tooltip>
          </Grid>
          <Grid item xs={10} container>
             {"  "}{getTestText()}
            {testHover ? <IconButton size="small" style={{position: "absolute", right: 5, top: 15}}>
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton> : null}
          </Grid>
        </Grid> : null}

        {prescription?.labResults?.length ? <Grid item xs={12} container spacing={1} style={{backgroundColor: labResultHover ? "#F8F9F9": "white", cursor: "pointer", position: "relative"}} 
        onMouseEnter={() => {
          setLabResultHover(true)
        }}
        onMouseLeave={() => {setLabResultHover(false)}}
        onClick={() => updateState([...prescription?.labResults], "labResults")}
        >
          <Grid item xs={1} style={{marginRight: "5px"}}>
            <Tooltip title="Lab Results" classes={{tooltip: classes.tooltip }}>
              <Avatar style={{height: "24px", width: "24px", fontSize: "13px", color: "#8E44AD", backgroundColor: "#E8DAEF", fontWeight: "bold", marginRight: "10px"}} color="primary">Lab</Avatar>
              {/* <i className="fas fa-microscope"></i> */}
            </Tooltip>
          </Grid>
          <Grid item xs={10} container>
            {getLabTests()}
            {labResultHover ? <IconButton size="small" style={{position: "absolute", right: 5, top: 15}}>
              <FileCopy style={{fontSize: "12px"}} />
              </IconButton> : null}
          </Grid>
        </Grid> : null}

      </Grid>
      <Grid item xs={12} style={{marginTop: 5}}>
        <Divider />
      </Grid>
    </Grid>
  )
}




const PastVital = ({ data, updateVital }) => {


  const showVitalCard = (vitalData) => {

    return vitalData?.data?.length ? (
      <React.Fragment>
        <Grid item xs={12} container spacing={1} alignItems="center"
        >
          <Grid item xs={11}>
            <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
                {moment.utc(vitalData?.date)?.format("DD MMMM yy")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton size="small" disableRipple onClick={() => updateVital([...vitalData?.data])}>
              <Tooltip title="Copy to Pad">
                <FileCopy fontSize="small" style={{fontSize: "14px"}}/>
              </Tooltip>
            </IconButton>
          </Grid>

          <Grid item xs={12} container spacing={1} style={{paddingLeft: 5 }}>
            {vitalData?.data ? vitalData?.data?.map(vital => (
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography style={{fontWeight: 550, opacity: "80%", fontSize: "15px"}}>
                    {vital?.metricName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{fontWeight: 650}}>
                    {vital?.metricQuantity} {` ${vital?.metricUnit}`}
                  </Typography>
                </Grid>
              </Grid>
            )) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </React.Fragment>
    ) : null
  }

  return (
    <React.Fragment>
      <Grid item xs={12} container spacing={2} style={{marginTop: '10px'}}>
       {data?.length ? data?.map((vitalData) => (
        showVitalCard(vitalData)
       )) : <Grid item xs={12} container justifyContent="center" alignItems="center" style={{opacity: "70%"}}>
            <Grid item style={{marginTop: "15%", textAlign: "center"}}>
              <img src={noDataImg} style={{height: "50%", width: "50%"}} />
            </Grid>
            <Grid item style={{marginTop: "2rem", marginLeft: "5px", textAlign: "center"}}>
              <Typography variant="h6" style={{color: "grey", fontWeight: "bolder"}}>
                {"No Vitals Found"}
              </Typography>
              <Typography variant="subtilte1" style={{color: "grey"}}>
                {"Your patient's vitals will be shown here"}
              </Typography>
            </Grid>
          </Grid>}
      </Grid>
    </React.Fragment>
  )
}


const MedicalRecords = ({ appointmentData }) => {

  const documentsRef = useRef(null)

  const [state, setState] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const [medicalRecords, setMedicalRecords] = useState([])
  const [recordLoader, setRecordLoader] = useState(false)

  const [selectedFile, setSelectedFile] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false)
 
  const getMedicalRecords = () => {
    setRecordLoader(true)
    customAxios.get(`appointments/get-medical-record/${appointmentData?.patientId}?thumbnail_req=${true}&prescription_req=${true}`).then(res => {
      if(res?.data?.success){
        setMedicalRecords([...res?.data?.data])
      }
      setRecordLoader(false)
    })
  }

  useEffect(() => {
    getMedicalRecords()
  }, [])

  const uploadFiles = async (files) => {
    const tempArr = state?.length ? [...state] : []

    if (files) {
      for (const file of files) {
        let reader = new FileReader()

        reader.onload = function (e) {
          tempArr.push({
            file: e.target.result,
            name: file.name,
            fileDate: moment.utc(new Date()).format("YYYY-MM-DD"),
            type: "Other",
            extension: file.type,
          })

          setState([...tempArr])
          setOpenDialog(true)
        }

        reader.readAsDataURL(file)
      }
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={12} container style={{width: "100%", height: "auto", margin: 5}} spacing={2}>
        {!recordLoader ? <Grid item xs={12}>
          {!medicalRecords?.length ? <Grid container justifyContent="center" spacing={1}>
            <Grid item style={{marginTop: "15%", textAlign: "center"}}>
              <img src={noDataImg} style={{height: "auto", width: "50%"}} />
            </Grid>
            <Grid item>
              <Typography style={{textAlign: "center"}}>
                You can see your patient's health records here
              </Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item>
              <Button variant="outlined" color='primary'
              startIcon={<Publish />}
                onClick={() => {
                  documentsRef.current.click()
                }}
              >Upload Record</Button>
            </Grid>
          </Grid> : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button fullWidth variant="outlined" color="primary" 
                startIcon={<Publish />}
                onClick={() => {
                  documentsRef?.current?.click()
                }}>Upload new record</Button>
              </Grid>
              {medicalRecords?.map((mRecord, mIdx) => (
                <Grid item xs={12} component={Card} style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "20vh",
                  borderRadius: "5px",
                  position: "relative",
                  background: `url(${mRecord?.thumbnailUrl})`,
                  backgroundSize: "cover",
                  // backgroundAttachment: "fixed",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer"
                }} onClick={() => {
                  // window.open(mRecord?.url, "_blank")
                  setOpenDrawer(true)
                  setSelectedFile({...mRecord, idx: mIdx})
                }}>
                  <div style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "whitesmoke",
                    width: "100%",
                    padding: 5
                  }}>
                    <Grid container>
                      <Grid item>
                        <Typography variant="h6">{(new Date(mRecord?.fileDate ?? mRecord?.date)?.getDate()) < 10 ? `0${(new Date(mRecord?.fileDate ?? mRecord?.date)?.getDate())}` : (new Date(mRecord?.fileDate ?? mRecord?.date)?.getDate())}</Typography>
                        <Typography variant="subtitle1">{(moment.utc(mRecord?.fileDate ?? mRecord?.date).format("MMM'YY"))}</Typography>
                      </Grid>  
                      <Grid item style={{marginLeft: "auto"}}>
                        <Chip style={{borderRadius: "10px"}} variant="outlined" color='primary' label={mRecord?.type} />
                      </Grid>
                    </Grid>  
                  </div>                  
                </Grid>
              ))}

            </Grid>)}
        </Grid> : (
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "80px", opacity: "40%"}} />
            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />

            <Skeleton animation="wave" variant="rect" style={{marginTop: 20, marginBottom: 10, height: "80px", opacity: "40%"}} />
            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", opacity: "40%"}} />
            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "40%"}} />
            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "40px", opacity: "40%"}} />
          </Grid>
        )}
      </Grid>
      <input
        onChange={(e) => {
          uploadFiles(e.target.files)
        }}
        hidden
        label="additionalFiles"
        ref={documentsRef}
        title="FilesUploader"
        type="file"
        multiple
        accept="image/*,application/pdf"
      ></input>
      <MedicalRecordDialog 
        fileData={state}
        setFileData={setState}
        documentsRef={documentsRef}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setMedicalRecords={setMedicalRecords}
        appointmentData={appointmentData}
        getMedicalRecords={getMedicalRecords}
      />
      {openDrawer ? 
      <RecordViewer file={selectedFile} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} fileData={medicalRecords} setFile={setSelectedFile} fileIdx={selectedFile?.idx} />
        : null}
    </React.Fragment>
  )
} 


const MedicalRecordDialog = ({ fileData, setFileData, documentsRef, openDialog, setOpenDialog, appointmentData, setMedicalRecords, getMedicalRecords }) => {

  const handleClose = () => {
    setOpenDialog(false);
    setFileData([])
  }

  const [saveLoader, setSaveLoader] = useState(false)
  
  const handleSubmit = () => {
    // console.log(fileData, '98979897check')
    if(fileData?.length) {
      setSaveLoader(true)
      customAxios.post(`appointments/upload-documents/${appointmentData?._id}`, {
        "documents": [...fileData]
      }).then(res => {
        setOpenDialog(false);
        setFileData([])
        if(res?.data?.success){
          getMedicalRecords()
        }
        setSaveLoader(false)
      }).catch(err => {
        setOpenDialog(false);
        setFileData([])
      })
    }
  }

  const displayFile = (file) => {
    if(file?.extension?.includes("image")){
      return <img src={file?.file} style={{objectFit: "none", width: "50px", objectFit: "contain"}} />
    } else if(file?.extension?.includes("pdf")){
      // return <img src={file?.thumbnailUrl} />
      return (
        <i class="fas fa-file-pdf" style={{fontSize: "40px", color: "orangered", marginLeft: "10px"}} />
      )
    } else if(file?.extension?.includes("/vnd.open")){
      return (
        <i class="fas fa-file-excel" style={{fontSize: "40px", color: "orangered", marginLeft: "10px"}} />
      )
    } else if(file?.extension?.includes("audio")){
      return (
        <i class="fas fa-file-audio" style={{fontSize: "40px", color: "orangered", marginLeft: "10px"}} />
      )
    }else if(file?.extension?.includes("video")){
      return (
        <i class="fas fa-file-video" style={{fontSize: "40px", color: "orangered", marginLeft: "10px"}} />
      )
    } else {
      return (
        <i class="fas fa-file" style={{fontSize: "40px", color: "orangered", marginLeft: "10px"}} />
      )
    }
  }

  return (
    <React.Fragment>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="md">
        <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography style={{ fontSize: "18px" }}>
                        Upload a Health Record
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton aria-label="close" onClick={handleClose} stye={{padding: 5}}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent dividers style={{minHeight: "30vh", backgroundColor: "whitesmoke"}}>
          <Grid container spacing={2} style={{width: "35vw"}}>
            {fileData?.length ? fileData?.map((file, fileIdx) => (
              <Grid item xs={12}>
                <Grid container component={Card} spacing={1} style={{padding: 10, borderRadius: 10}} elevation={0} alignItems="center">
                  <Grid item>
                    <Typography>
                      {file?.name}
                    </Typography>
                  </Grid>
                  <Grid item style={{marginLeft: "auto"}}>
                    <IconButton style={{padding: 5}} size="small" onClick={() => {
                      let temp = [...fileData];
                      temp.splice(fileIdx, 1);

                      setFileData([...temp])
                    }}>
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} container alignItems="center">
                    <Grid item xs={3} style={{cursor: "pointer"}} onClick={() => {
                        openBase64FileInNewWindow(file?.file, file?.extension);
                    }}>
                      {displayFile(file)}
                    </Grid>
                    
                    <Grid item xs={5}>
                      <TextField
                          select
                          size="small"
                          // fullWidth
                          style={{width: "80%"}}
                          value={file?.type}
                          placeholder="Record Type"
                          label="Record Type"
                          onChange={(e) => {
                            let temp = [...fileData]
                            temp[fileIdx]["type"] = e.target.value

                            setFileData([...temp])
                          }}
                          variant="outlined"
                        >
                          <MenuItem value="Lab Report">Lab Report</MenuItem>
                          <MenuItem value="Prescription">Prescription</MenuItem>
                          <MenuItem value="Other">Other files</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        // focused={index === 1 ? true : false}
                        placeholder={"Creation Date"}
                        label={"Creation Date"}
                        InputProps={{
                          endAdornment: null,
                          placeholder: "Creation Date",
                          inputProps: {
                            max: new Date().toISOString().split("T")[0], // Set max date to today
                          }
                        }}
                        value={file?.fileDate}
                        type="date"
                        size="small"
                        variant="outlined"
                        onChange={(e) => {
                          // updateTableData("edit", field, rowIndex, e.target.value, index)
                          let temp = [...fileData]
                          temp[fileIdx]["fileDate"] = e.target.value

                          setFileData([...temp])
                        }}
                        // inputProps={{ maxLength: field.limit ? field.limit : 50, fontSize: 16 }}
                        // inputProps={{ style: { fontSize: "12px" } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        
                        // onKeyDown={(e) => {
                        //   handleKeyDown(e, index, null, rowIndex)
                        // }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Publish />} color="primary" onClick={() => {
            documentsRef?.current.click()
          }} style={{marginRight: "auto"}} variant="outlined">Upload another record</Button>
         
          {!saveLoader ? <Button onClick={handleSubmit} color="primary" variant="contained">Save</Button> : (
            <CircularProgress size={24} style={{marginRight: 10}}/>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}


function openBase64FileInNewWindow(base64String, fileType) {
  // Create a Blob from the base64 string
  const byteCharacters = window.atob(base64String.split(";base64,")[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: fileType }); // Change the type accordingly if it's not a PDF

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Open the file in a new window
  window.open(url, '_blank');

  // Revoke the URL once the window is closed
  window.addEventListener('unload', () => {
    URL.revokeObjectURL(url);
  });
}


const RecordViewer = ({ file, fileIdx, setFile, openDrawer, setOpenDrawer, fileData }) => {

  const toggleDrawer = (open) => () => {
    setOpenDrawer(open);
  };
  const defaultLayoutPluginInstance = defaultLayoutPlugin();


  const getFileView = () => {
    if (file?.extension?.includes("image")){
      return (
        <img src={file?.url} width={"100%"} height={"100%"} />
      )
    } else if (file?.extension?.includes("pdf")){
      return (
        <Viewer
            fileUrl={file?.url}
            plugins={[
                // Register plugins
                defaultLayoutPluginInstance,
                
            ]}
        />
      )
    } else {
      setOpenDrawer(false)
      window.open(file?.url, "_blank")
    }
  }

  const [currentIndex, setCurrentIndex] = useState(fileIdx);

  useEffect(() => {
    setCurrentIndex(fileIdx)
  }, [String(fileIdx)])

  // Handler for the "Next" button
  const handleNext = () => {
    try{
      setCurrentIndex((prevIndex) => (prevIndex + 1) % fileData.length);
      setFile({...fileData[(currentIndex + 1) % fileData.length], idx: (currentIndex + 1) % fileData.length})
    } catch (err) {
      console.log(err)
    }
  };

  // Handler for the "Previous" button
  const handlePrev = () => {
    try{
      setCurrentIndex((prevIndex) => (prevIndex - 1 + fileData.length) % fileData.length);
      setFile({...fileData[ (currentIndex - 1 + fileData.length) % fileData.length], idx:  (currentIndex - 1 + fileData.length) % fileData.length})
    } catch (err) {
      console.log(err)
    }
  };

  const handleKeyDown = (e) => {
    switch(e){
      case "ArrowRight":
        // e.preventDefault()
        handleNext()   
        break
        
      case "ArrowLeft":
        // e.preventDefault()
        handlePrev()  
        break

      case "Escape":
        setOpenDrawer(false)
        break

    }
  }

  const useKeyDown = (callback, keys) => {
    const onKeyDown = (event) => {
      const wasAnyKeyPressed = keys.some((key) => event.key === key);
      if (wasAnyKeyPressed) {
        // event.preventDefault();
        callback(event.key);
      }
    };
    useEffect(() => {
      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }, [onKeyDown]);
  };
  
  // Example usage:
  useKeyDown((key) => {
    handleKeyDown(key);
  }, ["Escape", "ArrowRight", "ArrowLeft"]);
  

  return (
    <React.Fragment>
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)} id="drawer-ppl" style={{overflowX: "hidden"}} PaperProps={{ style: {overflowX: "hidden"}}}>
        <Grid container spacing={2} style={{width: "70vw", maxWidth: "70vw", overflowX: "hidden"}} alignItems="center">
          <Grid item style={{padding: 15}}>
            <IconButton size="large" onClick={() => setOpenDrawer(false)}>
              <ArrowBack style={{fontSize: "28px"}}/>
            </IconButton>
          </Grid>
          <Grid item xs={12}></Grid>
          <CustomGrid item xs={12} container justifyContent="center" style={{paddingTop: 0, paddingLeft: 40, paddingRight: 40, maxHeight: "81vh"}}>
            {openDrawer ? getFileView() : null}
          </CustomGrid>
          <Grid item style={{ padding: 20 }}>
            <Button startIcon={<ArrowBackIos />} color="primary" variant="outlined" onClick={handlePrev}>
              Previous
            </Button>
          </Grid>

          <Grid item style={{ padding: 20, marginLeft: "auto"}}>
            <Button endIcon={<ArrowForwardIos />} color="primary" variant="outlined" onClick={handleNext}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </React.Fragment>
  )
}