import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import { AccessTime, Close, FastForward } from "@material-ui/icons";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";


const BroadcastScreen = ({ isOpen, onClose, broadcastHistory, metricLoader }) => {
    const data = [
        // { id: 1, name: "Broadcast 1", status: "pending", "recipients": 299, "createdAt": new Date(), "completedAt": "-" },
      { id: 1, name: "Broadcast 2", status: "started", "recipients": 258, "createdAt": new Date(), "completedAt": "-" },
      { id: 2, name: "Broadcast 3", status: "completed", "recipients": 258, "createdAt": new Date(), "completedAt": new Date() },
      { id: 3, name: "Broadcast 4", status: "completed", "recipients": 258, "createdAt": new Date(), "completedAt": new Date() },
      { id: 4, name: "Broadcast 5", status: "completed", "recipients": 258, "createdAt": new Date(), "completedAt": new Date() },
    ];

    const getStatus = (status) => {
      switch(status) {
        case "PENDING": 
          return (
            <Typography style={{color: "#E59866"}}>
            <AccessTime /> SCHEDULED
            </Typography>
          )
        
        case "STARTED": 
          return (
            <Typography style={{color: "#7DCEA0"}}>
            <FastForward /> RUNNING
            </Typography>
          )
        
          case "COMPLETED": 
          return (
            <Typography color="primary">
            COMPLETED
            </Typography>
          )
        
      }
    }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    return (
      <Dialog open={isOpen} onClose={() => onClose()} fullWidth maxWidth="md">
        <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography style={{ fontSize: "24px" }}>
                        Check Your{" "}
                        <span
                            style={{
                            // background: colors.gradient,
                            color: "white",
                            padding: "0.3rem",
                            borderRadius: "4px",

                            background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                            }}
                        >
                            Latest Broadcasts
                        </span>{" "}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton aria-label="close" onClick={() => onClose()} stye={{padding: 5}}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Recipients</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Completed At</TableCell>
                  <TableCell>Sent</TableCell>
                  <TableCell>Delivered</TableCell>
                  <TableCell>Read</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {broadcastHistory?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>{getStatus(row.status.toUpperCase())}</TableCell>
                    <TableCell>{row.recipients}</TableCell>
                    <TableCell>{moment(row?.date)?.format("DD/MM/YYYY HH:mm")}</TableCell>
                    <TableCell>{row?.status !== 'completed' ? "-" : moment(row?.completedOn)?.format("DD/MM/YYYY HH:mm")}</TableCell>
                    {metricLoader ? <TableCell>
                      <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", width: "40px", opacity: "40%"}} />
                    </TableCell> : <TableCell>{row?.details?.sent ? row?.details?.sent : "-"}</TableCell>}
                    
                    {metricLoader ? <TableCell>
                      <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", width: "40px", opacity: "40%"}} />
                    </TableCell> : <TableCell>{row?.details?.delivered ? row?.details?.delivered : "-"}</TableCell>}
                    
                    {metricLoader ? <TableCell>
                      <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "10px", width: "40px", opacity: "40%"}} />
                    </TableCell> : <TableCell>{row?.details?.read ? row?.details?.read : "-"}</TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: broadcastHistory?.length }]}
            component="div"
            count={broadcastHistory?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default BroadcastScreen;