/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import _ from "lodash"
import {
  FormGroup,
  Form,
  Input,
  Col,
  Button,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import moment from "moment"

import "react-toastify/dist/ReactToastify.css"
import { connect, useDispatch } from "react-redux"
import customAxios from "../../axios"
import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"
import {
  loadCurrentOrganisations,
  loadOrganisations,
} from "../../redux/actions/user.actions"

// core components
import { Tooltip } from "@material-ui/core"
import { toast } from "react-toastify"
import { element } from "prop-types"

function DoctorSlotCreator({ setActiveStep, state, setIsOpen }) {
  const defaultWorkingHours = {
    monday: [{}],
    tuesday: [{}],
    wednesday: [{}],
    thursday: [{}],
    friday: [{}],
    saturday: [{}],
    sunday: [{}],
  }
  const [workingHours, setWorkingHours] = useState(defaultWorkingHours)
  const days = Object.keys(workingHours)
  //Monday
  const [loading, setLoading] = useState(false)
  const [intervalForLocation, setIntervalForLocation] = useState("")
  const [isLoactionOnline, setIsLoactionOnline] = useState("")
  const dispatch = useDispatch()
  const handleSubmit = async (e) => {
    e.preventDefault()

    let flag = 0

    let tempObj = {}
    if (
      !state.organisationName ||
      !state.locationName ||
      !state.followUp ||
      !state.fees
    ) {
      flag = 1
      toast.error("Please fill the Clinic details properly")
    }
    if (state.interval && state?.interval === 0) {
      flag = 1
      toast.error("Interval should be greater than zero.")
    }
    if (!flag)
      for (const property in workingHours) {
        const dayValue = _.cloneDeep(workingHours[property])
        const tempArr = []

        if (dayValue) {
          for (const val of dayValue) {
            if (!val?.start) {
              toast.error(
                `The start time is not filled in ${property.toUpperCase()} slot `
              )
              flag = 1
              break
            }
            if (!val?.end) {
              flag = 1
              toast.error(
                `The end time is not filled in ${property.toUpperCase()} slot `
              )
              break
            }

            const isoDateStart = moment.utc(val.start, "HH:mm")

            const isoDateEnd = moment.utc(val.end, "HH:mm")

            let temp = {}
            temp.start = isoDateStart.toISOString()
            temp.end = isoDateEnd.toISOString()

            const start = moment(val.start, "HH:mm")
            const end = moment(val.end, "HH:mm")
            tempArr.push(temp)

            if (end?.isBefore(start)) {
              toast.error(
                `The end time is greater than start time in ${property.toUpperCase()} slots`
              )
              flag = 1
              break
            } else if (end.diff(start, "hours") < 1) {
              flag = 1
              toast.error(
                `The difference between end time and start time must be at least 1 hour`
              )
            }
            console.log("43212", tempArr)
          }

          for (let x = 0; x < tempArr.length - 1; x++) {
            let element_1 = new Date(tempArr[x].end)
            let element_2 = new Date(tempArr[x + 1].start)
            console.log("123", element_1, element_2)
            if (
              element_1.getTime() === element_2.getTime() ||
              element_1.getTime() > element_2.getTime()
            ) {
              flag = 1
              toast.error(
                `Overlap in the time in ${property.toUpperCase()} slots`
              )
              //return;
            }
          }
          console.log("43212", tempArr)
        }

        console.log("4321", dayValue, tempArr)
        tempObj[property] = dayValue ? tempArr : null

        if (flag) {
          break
        }
      }

    if (!flag) {
      console.log("432", tempObj)
      setLoading(true)
      customAxios
        .post(`/onboarding/organisation`, {
          ...state,
          workingHours: { ...tempObj, interval: state.interval },
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Organisation successfully created")
            if (setActiveStep) setActiveStep(2)
            if (setIsOpen) {
              setIsOpen(false)
              dispatch(loadOrganisations())
              setWorkingHours(defaultWorkingHours)
            }
          } else {
            if (res.data.data === "Slot duration should be atleast 1 hour")
              toast.error(
                "The difference between end time and start time must be of at least 1 hour"
              )
            else {
              toast.error("An online organisation already exists.")
            }
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          toast.error("Error in slot submission")
        })
    }
  }

  return (
    <Form id="prescription-form" onSubmit={handleSubmit}>
      {isLoactionOnline && (
        <Row>
          <Col md="2">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols3Input"
              >
                Interval
              </label>
            </FormGroup>
          </Col>
          <Col md="3">
            <InputGroup>
              <Input
                type="text"
                value={intervalForLocation}
                onChange={(e) => {
                  setIntervalForLocation(e.target.value)
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>Minutes</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="2">
          <FormGroup>
            <h2>Day</h2>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols2Input"
            ></label>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <h2>Start Time</h2>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <div>
              <h2>End Time</h2>
            </div>
          </FormGroup>
        </Col>
      </Row>
      {days.map((day) => {
        return (
          <Row>
            <Col md="2">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example3cols3Input"
                >
                  {day.toUpperCase()}{" "}
                </label>
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example3cols3Input"
                >
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      checked={workingHours[day] ? "true" : ""}
                      onChange={() => {
                        const temp = { ...workingHours }
                        if (workingHours[day]) {
                          temp[day] = null
                        } else {
                          temp[day] = [{}]
                        }
                        setWorkingHours({ ...temp })
                      }}
                    ></input>
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="OFF"
                      data-label-on="ON"
                    ></span>
                  </label>
                </label>
              </FormGroup>
            </Col>
            <Col md="8">
              {workingHours[day] ? (
                workingHours[day].map((p, index) => (
                  <Row key={index}>
                    <Col md="4">
                      <FormGroup>
                        <Input
                          id="example3cols2Input"
                          placeholder="Start Time"
                          type="time"
                          value={p.start}
                          onChange={(e) => {
                            const temp = { ...workingHours }

                            temp[day][index]["start"] = e.target.value
                            setWorkingHours({ ...temp })
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Input
                          placeholder="End Time"
                          type="time"
                          value={p.end}
                          disabled={!p.start}
                          onChange={(e) => {
                            const temp = { ...workingHours }

                            temp[day][index]["end"] = e.target.value
                            setWorkingHours({ ...temp })
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {index !== 0 && (
                      <Col md="2">
                        <FormGroup>
                          <Button
                            color="danger"
                            outline
                            onClick={() => {
                              if (workingHours[day].length > 1) {
                                const temp = { ...workingHours }
                                temp[day].splice(index, 1)
                                setWorkingHours({ ...temp })
                              }
                            }}
                          >
                            <i className="fas fa-minus"></i>
                          </Button>
                        </FormGroup>
                      </Col>
                    )}

                    {index === 0 && (
                      <Col md="2">
                        <FormGroup>
                          <Button
                            outline
                            disabled={!p.start || !p.end}
                            color="primary"
                            onClick={() => {
                              const temp = { ...workingHours }
                              temp[day].splice(workingHours[day].length, 0, {})
                              setWorkingHours({ ...temp })
                            }}
                          >
                            <i className="fas fa-plus"></i>
                          </Button>
                        </FormGroup>
                      </Col>
                    )}
                    {index === 0 && (
                      <Col md="2">
                        <FormGroup>
                          <Tooltip title="Copy to all" placement="right">
                            <Button
                              outline
                              disabled={!p.start || !p.end}
                              color="primary"
                              onClick={() => {
                                const temp = {}
                                const valToCopy = _.cloneDeep(workingHours[day])
                                days.forEach((day) => {
                                  temp[day] = workingHours[day]
                                    ? _.cloneDeep(valToCopy)
                                    : null
                                })
                                setWorkingHours({ ...temp })
                                toast.success("Copied to all")
                              }}
                            >
                              <i className="fas fa-copy"></i>
                            </Button>
                          </Tooltip>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                ))
              ) : (
                <Row>
                  <Col md="12">
                    <h3>Unavailable</h3>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )
      })}

      <div style={{ textAlign: "center" }} className="mb-2">
        {loading && <LoadingAnimation></LoadingAnimation>}
        {!loading && (
          <Button color="primary" type="submit">
            Submit
          </Button>
        )}
      </div>
    </Form>
  )
}

export default DoctorSlotCreator
