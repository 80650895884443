import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

function Settings() {
  return (
    <Row className='text-center p-4'>
      <Col xs={12}>Settings</Col>
      <Col xs={12} style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
        Tuple
      </Col>
    </Row>
  );
}

export default Settings;
