import io from "socket.io-client"

const dashboardSocket = io(
  // "https://loadbalancer.paperplane.health:8300/chat-message")
  "https://pplane-socket-prod.paperplane.health/chat-message", {
    transports: ["websocket", "polling"]
  })
dashboardSocket.on("connect", () => {
  console.log(dashboardSocket.id)
})
dashboardSocket.on("disconnect", () => {
  console.log(dashboardSocket.id)
})

export default dashboardSocket
