import { Grid, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { Button } from 'reactstrap'
import Modal from 'reactstrap/lib/Modal'
import Row from 'reactstrap/lib/Row'

export default function ResetPasswordModal({
  setIsOpen,
  isOpen,
  handleSubmit,
}) {

  const [passwordState, setPasswordState] = useState({
    currentPassword: '',
    password: "",
    confirmPassword: "",
    allowPassword: false
  })

  // useEffect(() => {
  //   if (passwordState.password.localeCompare(passwordState))
  //     return () => {
  //       cleanup
  //     }
  // }, [input])

  return (
    <Modal
      className="modal-dialog-centered modal-md"
      isOpen={isOpen}
      toggle={() => setIsOpen(false)}
      style={{ textAlign: 'center' }}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Enter your current and new password
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setIsOpen(false)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="p-3">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                value={passwordState.currentPassword}
                variant="outlined"
                fullWidth
                label="Current Password"
                onChange={e =>
                  setPasswordState({
                    ...passwordState,
                    currentPassword: e.target.value,
                  })
                }></TextField>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Row>
        <Row className="p-3">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                type="password"
                value={passwordState.password}
                variant="outlined"
                fullWidth
                label="New Password"
                onChange={e =>
                  setPasswordState({
                    ...passwordState,
                    password: e.target.value,
                  })
                }></TextField>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Row>
        <Row className="p-3">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                type="password"
                value={passwordState.confirmPassword}
                variant="outlined"
                fullWidth
                label="Confirm New Password"
                onChange={e =>
                  setPasswordState({
                    ...passwordState,
                    confirmPassword: e.target.value,
                  })
                }></TextField>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Row>
        <Row className="p-3">
          {' '}
          <Button
            className="default mt-2"
            onClick={() => {
              handleSubmit(passwordState.currentPassword, passwordState.password, passwordState.confirmPassword, setPasswordState)
            }}>
            Reset Password
          </Button>
        </Row>
      </div>
    </Modal >
  )
}
