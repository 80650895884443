import React, { useState, useEffect } from "react"
import Button from "reactstrap/lib/Button"
import Col from "reactstrap/lib/Col"
import FormGroup from "reactstrap/lib/FormGroup"
import Input from "reactstrap/lib/Input"
import InputGroup from "reactstrap/lib/InputGroup"
import InputGroupAddon from "reactstrap/lib/InputGroupAddon"
import Label from "reactstrap/lib/Label"
import Row from "reactstrap/lib/Row"
import LoadingAnimation from "../examples/LoadingAnimation"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns" // choose your lib
import moment from "moment"
import { connect } from "react-redux"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField } from "@material-ui/core"
import customAxios from "../../../axios"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { isBetween } from "moment"
import { SlowMotionVideoOutlined } from "@material-ui/icons"
function GenericModalForschedule({
  setDummyCounter,
  modalData,
  setModalData,
  headers,
  saveLabel,
  isLoading = false,
  message,
  handleSubmitSchedule,
  setIsOpen,
  handleModalClose,
}) {
  const organisation = useSelector((state) =>
    state?.user?.organisations?.find((ele) => ele._id === modalData.orgId)
  )

  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const user = useSelector((state) => state.user.user)
  console.log("staffOrg", organisation)
  const [slotOptions, setSlotOptions] = useState([])
  const [date, setDate] = useState(new Date(new Date().getTime() + 330 * 60000))
  console.log("9088", modalData)
  const [time, setTime] = useState("")
  const [blockedSlotList, setBlockedSlotList] = useState([])
  useEffect(() => {
    console.log(slotOptions, "<--slotOptions-->")
  }, [slotOptions])
  useEffect(() => {
    if (modalData?.orgId) {
      customAxios
        .get(`slots/block-slots/${modalData?.orgId}`)
        .then((res) => {
          if (res.data.success) {
            console.log("res39", res.data.data)
            setBlockedSlotList(res.data.data)
          } else {
          }
        })
        .catch((err) => {})
    }
  }, [])
  const checkDisableDate = (date) => {
    const temp = getOfflineSlots(date)
    if (temp.length) return false
    else return true
  }
  const getOfflineSlots = (date) => {
    const day = moment(date).format("dddd").toLowerCase()
    const workingHours = organisation?.doctors?.find((v, idx) => {
      return isDoctor ? user._id === v.doctorId : idx === 0
    }).workingHours
    console.log(
      "989",
      organisation?.doctors?.find((v, idx) => {
        return isDoctor ? user._id === v.doctorId : idx === 0
      })
    )
    const slots = workingHours[day]
    const interval = workingHours.interval ? workingHours?.interval : 60
    if (!slots) return []
    else {
      const tempArr = []
      const getTime = (time) =>
        new Date(2019, 9, 2, time.substring(0, 2), time.substring(3, 5), 0, 0)
      slots.forEach((ele) => {
        let start = moment.utc(ele.start)
        let end = moment.utc(ele.end)
        const flag =
          moment(date).format("DD/MM/YYYY") ===
          moment(new Date()).format("DD/MM/YYYY")

        while (end.diff(start, "minutes") >= interval) {
          if (flag) {
            const compareTime = moment.utc(start).add(50, "minutes")
            if (
              getTime(moment.utc(compareTime).format("HH:mm")) >=
              getTime(moment(new Date()).format("HH:mm"))
            )
              tempArr.push({
                displayTime:
                  moment.utc(start).format("hh:mm A") +
                  " - " +
                  moment.utc(start).add(interval, "minutes").format("hh:mm A"),

                start: date + "T" + moment.utc(start).format().split("T")[1],
                end:
                  date +
                  "T" +
                  moment
                    .utc(start)
                    .add(interval, "minutes")
                    .format()
                    .split("T")[1],
                startDate: moment.utc(start).add(interval, "minutes").format(),
              })
          } else {
            tempArr.push({
              displayTime:
                moment.utc(start).format("hh:mm A") +
                " - " +
                moment.utc(start).add(interval, "minutes").format("hh:mm A"),

              start: date + "T" + moment.utc(start).format().split("T")[1],
              end:
                date +
                "T" +
                moment
                  .utc(start)
                  .add(interval, "minutes")
                  .format()
                  .split("T")[1],
              startDate: moment.utc(start).add(interval, "minutes").format(),
            })
          }
          start = moment.utc(start).add(interval, "minutes")
        }
        let diff = end.diff(start, "minutes")
        if (diff <= interval && diff > 10) {
          const compareTime = moment.utc(start).add(diff, "minutes")
          if (
            (flag &&
              getTime(moment.utc(compareTime).format("HH:mm")) >=
                getTime(moment(new Date()).format("HH:mm"))) ||
            !flag
          )
            tempArr.push({
              displayTime:
                moment.utc(start).format("hh:mm A") +
                " - " +
                moment.utc(start).add(diff, "minutes").format("hh:mm A"),

              start: date + "T" + moment.utc(start).format().split("T")[1],
              end:
                date +
                "T" +
                moment.utc(start).add(diff, "minutes").format().split("T")[1],
            })
        }
      })
      let finalArr = []
      tempArr.forEach((slot) => {
        /*console.log(
          "9098",
          moment(slot.startDate).isBefore(moment(blockedSlotList[0].endTime)) &&
            moment(slot.startDate).isAfter(moment(blockedSlotList[0].startTime))
        )*/
        if (
          blockedSlotList.find((ele) =>
            moment(slot?.startDate).isBetween(
              moment(ele?.startTime),
              moment(ele?.endTime)
            )
          )
        )
          console.log("in between")
      })
      return tempArr
    }
  }

  console.log("modalDataNew", modalData)
  return (
    <>
      <div className="modal-body">
        <Row>
          <Col md="12" className="text-center">
            <h2>{message ? message : null}</h2>
          </Col>
        </Row>
        <Row>
          {headers?.map((ele, index) => (
            <Col md={ele.fullWidth ? "12" : "6"} key={index}>
              <FormGroup>
                <Label className="md-2">{ele.label}</Label>
                <InputGroup>
                  {ele.addOnType && ele.addOnType === "prepend" && (
                    <InputGroupAddon addonType={ele.addOnType}>
                      {ele.addOn}
                    </InputGroupAddon>
                  )}
                  {console.log(modalData[ele.key], "values", ele.key)}
                  <Input
                    id={index}
                    key={index}
                    disabled={ele.disabled}
                    value={modalData[ele.key] ? modalData[ele.key] : ""}
                    onChange={(e) => {
                      const temp = modalData
                      temp[ele.key] = e.target.value
                      setModalData({ ...temp })
                    }}
                    type={ele.type ? ele.type : "text"}
                    placeholder={ele.placeholder ? ele.placeholder : ""}
                  >
                    {ele?.type ? (
                      ele?.type === "select" ? (
                        <>
                          {ele?.options?.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))}
                          <option value="" hidden />
                        </>
                      ) : null
                    ) : null}
                  </Input>
                  {ele.addOnType && ele.addOnType === "append" && (
                    <InputGroupAddon addonType={ele.addOnType}>
                      {ele.addOn}
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
          ))}
        </Row>
        <Row style={{ padding: "1rem" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              fullWidth
              shouldDisableDate={checkDisableDate}
              emptyLabel="Date of Appointment"
              maxDate={moment(new Date()).add(
                organisation?.doctors[0]?.slotDuration
                  ? organisation?.doctors[0]?.slotDuration
                  : 9,
                "days"
              )}
              label={"Date"}
              // maxDate={new Date("2021-07-31")}
              inputVariant="outlined"
              value={moment.utc(date).format("YYYY-MM-DD")}
              onChange={(value) => {
                setDate(moment(value).format())
                const temp = getOfflineSlots(moment(value).format("YYYY-MM-DD"))
                setSlotOptions([...temp])
              }}
              variant="inline"
              disablePast
            />
          </MuiPickersUtilsProvider>
        </Row>
        <Row style={{ padding: "1rem" }}>
          <Autocomplete
            id="Time of Appointment"
            fullWidth
            noOptionsText={"The doctor has no slots on this date!"}
            disableClearable
            value={time}
            options={slotOptions}
            disabled={!date}
            onChange={(e, value) => {
              setTime(value)
            }}
            getOptionLabel={(option) => option.displayTime}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Time of Appointment:"}
                variant="outlined"
              />
            )}
          />
        </Row>
      </div>
      <div className="modal-footer">
        <Col md="12" className="row text-center">
          {isLoading && (
            <LoadingAnimation height={35} width={35}></LoadingAnimation>
          )}
          {!isLoading && (
            <Button
              color="primary"
              type="button"
              block
              onClick={() => {
                handleSubmitSchedule(
                  modalData,
                  time,
                  organisation?.doctors?.find((v, idx) => {
                    return isDoctor ? user._id === v.doctorId : idx === 0
                  }).doctorId
                )
              }}
            >
              {saveLabel}
            </Button>
          )}
        </Col>
      </div>
    </>
  )
}

export default GenericModalForschedule
