import React, { useEffect } from "react"
import { Container, Row, Col } from "reactstrap"

import Header from "../../../components/User/Header"
import AllAppointmentsTable from "../../../components/AllAppointments/AllAppointmentsTable"
import { useDispatch } from "react-redux"
import {
	clearAppointmentDetails,
	clearMedicalHistory,
	clearPrescriptionDetails,
	clearVitals,
} from "../../../redux/actions/consultation.actions"

const AllAppointments = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(clearPrescriptionDetails())
		dispatch(clearMedicalHistory())
		dispatch(clearVitals())
		dispatch(clearAppointmentDetails())
		// eslint-disable-next-line
	}, [])
	return (
		<div>
			<Header parentName="Expected Appointments"></Header>
			<Container className="mt--6" fluid>
				<Row>
					<Col md="12">
						<AllAppointmentsTable></AllAppointmentsTable>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default AllAppointments
