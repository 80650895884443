import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, TextField } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const styles = {
  root: {
    position: "relative",
  },
  contentWrapper: {
    position: "relative",
  },
  content: {
    padding: "18.5px 14px",
  },
  inputLabel: {
    position: "absolute",
    right: "-3%",
    top: "-80%",
    zIndex: 999,
    borderRadius: "50%",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    maxHeight: "70%",
    boxShadow: "15px 10px 10px #f5f5f5",
    transform: "translate(0, 24px) scale(1)",
  },
  notchedOutline: {},
};

const EditTextField = ({ classes, value, placeholder, onClick }) => {
  return (
    <div className={classes.root}>
      <IconButton
        className={classes.inputLabel}
        onClick={onClick}
        size="medium"
      >
        <Edit />
      </IconButton>
      <div className={classes.contentWrapper}>
        <TextField
          disabled={true}
          placeholder={placeholder}
          fullWidth
          value={value}
          variant="outlined"
        />
      </div>
    </div>
  );
};
export default withStyles(styles)(EditTextField);
