import React, { useEffect, useState } from "react"
import {
  Typography,
  Grid,
  Avatar,
  Button,
  TextField,
  Divider,
  TablePagination,
  Chip,
  IconButton,
  CircularProgress,
  styled,
} from "@material-ui/core"
import profileIcon from "./../../../assets/icon.jpg"
import { colors } from "../../../assets/css/colors"
import emptySearchAnimation from "./../../../assets/animationData/emptySearch.json"
import dotLoaderAnimation from "./../../../assets/animationData/26025-chat-loader.json"
import moment from "moment"
import dashboardSocket from "../../../socket/dashboardSocket"
import AddPatientModal from "../../../views/pages/dashboards/AddPatientModal"
import { useHistory, useLocation } from "react-router"
import GenericAnimation from "../../animation"
import GenericDialogForm from "../../CreatePrescription/GenericDialog"
import prescriptionSocket from "../../../socket"
import customAxios from "../../../axios"
import { toast } from "react-toastify"
import warningAnimation from "./../../../assets/animationData/warning.json"
import mixpanel from "mixpanel-browser"
import { useSelector } from "react-redux"
import { InfoOutlined } from "@material-ui/icons"
import { Skeleton } from "@material-ui/lab"
import GenericDialogFormNew1 from "../../CreatePrescription/GenericDialog/newIndex"


const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: 300,
  // Enable scrolling
  overflowY: 'scroll',
  // Hide the scrollbar
  // scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 1, // Adjust to desired thickness
    backgroundColor: "transparent",
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'black', // Adjust to desired color
    position: "absolute"
  },
});


function ChatSidebar({
  setPatient,
  docId,
  isDoctor,
  patient,
  setLoading,
  isConsultation = false,
  setNewChatModalOpen,
  newChatModalOpen,
  appointmentData = null,
  initializeSocketUser,
}) {
  const [allChats, setAllChats] = useState([])
  const [filteredChats, setFilteredChats] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [page, setPage] = useState(0)

  const [chatListLoader, setChatListLoader] = useState(false)

  const [chatHover, setChatHover] = useState(null)

  const user = useSelector((state) => state.user.user)

  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    // setLoading(true)
    setChatListLoader(true)
    dashboardSocket.emit("get_patients", {
      userId: docId,
      userType: isDoctor ? "doctor" : "staff",
    })
    dashboardSocket.on("update_patient", (args) => {
      dashboardSocket.emit("get_patients", {
        userId: docId,
        userType: isDoctor ? "doctor" : "staff",
      })
    })
  }, [])

  dashboardSocket.on("get_patients", (args) => {
    setAllChats(args)
    setChatListLoader(false)
    // setLoading(false)
  })
  const getChatTime = (date) => {
    const yesterday = moment.utc().subtract(1, "days")
    if (moment.utc(date).diff(yesterday, "days") === 0) return "Yesterday"
    else if (moment.utc(date).diff(yesterday, "days") < 0)
      return moment.utc(date).format("DD/MM/YYYY")
    else return moment.utc(date).format("hh:mm A")
  }
  const style = {
    name: {
      fontWeight: "550",
      fontSize: "14px",
      color: "black",
      maxWidth: "70%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    },
    msg: {
      fontWeight: "400",
      fontSize: "12px",
    },
    time: {
      fontSize: "12px",
      width: "30%",
      paddingLeft: "10px"
    },
  }
  const handleNewChat = (query, setState) => {

    prescriptionSocket.emit("search_patient", { phoneNo: query })
    prescriptionSocket.once("search_patient", (args) => {
      console.log("args", args)

      if (args.isExists) {
        setState({
          ...args?.patient,
          gender: args?.patient?.gender?.toLowerCase(),
        })
      }
    })
  }
  useEffect(() => {
    dashboardSocket.emit("search_patient")
  }, [])
  const getFilterChats = (searchQuery) => {
    if (searchQuery) {
      const filterData = allChats.filter(
        (ele) =>
          ele?.patient?.name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          ele?.patient?.phoneNo?.toString().includes(searchQuery.toLowerCase())
      )

      setFilteredChats(filterData.slice())
    } else {
      setFilteredChats([...allChats.slice()])
    }
  }

  const [paginateChat, setPaginateChat] = useState([]);

  useEffect(() => {
    if(filteredChats?.length){
      const tempPaginate = [...filteredChats].slice(Number(rowsPerPage)*Number(page), Number(rowsPerPage)*(Number(page)+1));
      setPaginateChat([...tempPaginate]);
    }
  }, [filteredChats])

  useEffect(() => {
    if(!filterChatValue)
    getFilterChats(searchQuery)
  }, [searchQuery, allChats])

  const getLatestChatMessage = (chat) => {
    const contentType = chat?.["contentType"]
    switch (contentType) {
      case "patient_interact":
        return <>Patient has interacted with your WhatsApp Clinic.</>
      case "text":
        if (typeof chat?.content === "object")
          return (
            <>
              Unsupported Document{" "}
              <i
                class="fas fa-exclamation-circle"
                style={{ color: "red", marginLeft: "0.2rem", fontSize: "16px" }}
              ></i>{" "}
            </>
          )
        return (
          <>
            {chat?.content?.toString().length > 30
              ? chat?.content.substring(0, 30) + "..."
              : chat["content"]}
          </>
        )

      case "wa_link":
        return (
          <>
            <i class="fas fa-link" style={{ marginRight: "0.4rem" }}></i>{" "}
            Booking Link Sent
          </>
        )

      default:
        const mimeType = contentType.split("/")[0]
        switch (mimeType) {
          case "image":
            return (
              <>
                <i class="fas fa-image" style={{ marginRight: "0.4rem" }}></i>{" "}
                Photo
              </>
            )
          case "video":
            return (
              <>
                <i class="fas fa-video" style={{ marginRight: "0.4rem" }}></i>{" "}
                Video
              </>
            )
          case "invoice":
            return (
              <>
                <i
                  class={
                    chat.content.status !== "paid"
                      ? "fas fa-receipt"
                      : "fas fa-check-circle"
                  }
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Payment{" "}
                {chat.content.status !== "paid" ? "Requested" : "Received"}
              </>
            )

          case "audio":
            return (
              <>
                <i
                  class="fas fa-microphone"
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Audio
              </>
            )

          default:
            return (
              <>
                <i
                  class="fas fa-file-alt"
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Document
              </>
            )
        }
    }
  }

  const [filterChatValue, setFilterChatValue] = useState(null)

  useEffect(() => {
    if(filterChatValue)
      filterChatByDate(filterChatValue)
    else
      getFilterChats(searchQuery)
  }, [filterChatValue])

  const filterChatByDate = (type) => {
    switch(type) {
      case "24": {
        let compareDate = new Date();
        compareDate.setDate(compareDate.getDate() - 1);

        let tempChats = [...allChats]?.filter(f => new Date(f?.date) >= compareDate);
        setFilteredChats([...tempChats])
        break
      }

      case "week": {
        let compareDate = new Date();
        compareDate.setDate(compareDate.getDate() - 7);

        let tempChats = [...allChats]?.filter(f => new Date(f?.date) >= compareDate);
        setFilteredChats([...tempChats])
        break
      }

      case "month": {
        let compareDate = new Date();
        compareDate.setDate(compareDate.getDate() - 30);

        let tempChats = [...allChats]?.filter(f => new Date(f?.date) >= compareDate);
        setFilteredChats([...tempChats])
        break
      }

      case "2xmonth": {
        let compareDate = new Date();
        compareDate.setDate(compareDate.getDate() - 60);

        let tempChats = [...allChats]?.filter(f => new Date(f?.date) >= compareDate);
        setFilteredChats([...tempChats])
        break
      }

      default: {
        return null
      }
    }
  }

  return (
    <Grid container item xs={12} style={{postion: "relative", overflow: "hidden"}}>
      <Grid item xs={12} container style={{
        padding: "1rem",
        overflow: "hidden",
        position: "sticky",
        top: 0
      }}>
        <Grid item container xs={12}>
          <Grid item xs={12} container alignItems="center" spacing={1}>
            <Grid item xs={2}>
              <img
                alt="profile"
                src={profileIcon}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "1px solid blue",
                  padding: "0.1rem",
                  marginRight: "0.65rem",
                }}
              />
            </Grid>
            <Grid item xs={10} container>
              <Grid item xs={12}>
                <Typography variant="h5" style={{fontSize: "18px"}}>
                  <span style={{ color: colors.primary, fontWeight: "600" }}>
                    {isDoctor ? `Dr. ${user?.name}'s Chat` : "Chats"}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} style={{display: "flex", alignItems: 'center'}}>
                <InfoOutlined style={{marginRight: 5, fontSize: "12px"}} fontSize={"small"}/>
                <Typography style={{fontSize: "11px"}}>
                  All messages will be sent from your clinic
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems={"center"} spacing={1}>
              <Grid item xs={11}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={searchQuery}
                  onChange={(e) => {
                    mixpanel.track("Searched for patient in chat")
                    setSearchQuery(e.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <i
                        className="fas fa-search"
                        style={{ color: "grey", marginRight: "0.4rem" }}
                      ></i>
                    ),
                  }}
                  style={{ backgroundColor: "#fff" }}
                  color="primary"
                  placeholder="Search by name or Phone Number"
                ></TextField>
              </Grid>
              <Grid item xs={1}>
                <IconButton color="primary" size="small" onClick={() => {
                  setNewChatModalOpen(true)
                }}>
                  <i class="fas fa-user-plus"></i>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {!chatListLoader ? <Grid item container xs={12} style={{marginTop: "-5px"}} spacing={1}>
            {allChats?.length ? <>
              <Grid item xs={12}>
                <Typography style={{opacity: 0.5, fontSize: "14px"}}>
                  Filter Chats by interaction
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <Grid item>
                  <Chip label="Last 24 Hours" style={{marginRight: 5, fontSize: "12px"}} onClick={() => {
                    if(filterChatValue !== "24")
                      setFilterChatValue("24")
                    else setFilterChatValue(null)
                    }} variant="outlined" color={filterChatValue === "24" ? "primary": "default"} onDelete={filterChatValue === "24" ? () => {
                      setFilterChatValue(null)
                      } : null}/>
                </Grid>

                <Grid item>
                  <Chip label="Last Week" style={{marginRight: 5, fontSize: "12px"}} onClick={() => {
                    if(filterChatValue !== "week")
                      setFilterChatValue("week")
                    else setFilterChatValue(null)
                    }} variant="outlined" color={filterChatValue === "week" ? "primary": "default"} 
                    onDelete={filterChatValue === "week" ? () => {
                      setFilterChatValue(null)
                      } : null} />
                </Grid>

                <Grid item>
                  <Chip label="Last Month" style={{marginRight: 5, fontSize: "12px"}} onClick={() => {
                    if(filterChatValue !== "month")
                      setFilterChatValue("month")
                    else setFilterChatValue(null)
                    }} variant="outlined" color={filterChatValue === "month" ? "primary": "default"} 
                    onDelete={filterChatValue === "month" ? () => {
                      setFilterChatValue(null)
                      } : null} />
                </Grid>

                <Grid item>
                  <Chip label="Last 60 Days" style={{fontSize: "12px"}} onClick={() => {
                    if(filterChatValue !== "2xmonth")
                      setFilterChatValue("2xmonth")
                    else setFilterChatValue(null)
                    }} variant="outlined" color={filterChatValue === "2xmonth" ? "primary": "default"} 
                    onDelete={filterChatValue === "2xmonth" ? () => {
                      setFilterChatValue(null)
                      } : null} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
              </Grid>
            </> : null}
          </Grid> : (
            <Grid item container xs={12} style={{marginTop: "-10px"}} spacing={1}>
              <Grid item xs={12}>
              <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "100px", opacity: "50%"}} />              
                </Grid>
            </Grid>
          )}
        </Grid>
          {/* </Grid> */}
      </Grid>
            {/* <Grid item xs={2}>
              <Button
                startIcon={<i class="fas fa-user-plus"></i>}
                color="primary"
                size="small"
                // variant="outlined"
                onClick={() => {
                  setNewChatModalOpen(true)
                  // history.push("/admin/allPatients");
                }}
              >
                {" "}
                New Chat
              </Button>
            </Grid> */}
            

          {/* </Grid> */}
      
      <CustomGrid
        item
        container
        xs={12}
        style={{ overflowY: "auto", height: "auto", minHeight: "450px", position: "relative", marginTop: "-20px"}}
        // style={{ margin: "5px 0", top: "130px", position: "absolute" }}
      >
        <Grid item xs={12} style={{position: "absolute", top: 0, width: "100%"}}>
          {paginateChat?.length ? (
            paginateChat?.map((ele, idx) => {
              return (
                // <MenuItem>
                  <>

                    <Grid
                      item
                      key={idx}
                      xs={12}
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      style={{
                        position: "relative",
                        width: "100%",

                        background:
                          location?.state?.appointmentId &&
                          appointmentData?.patient?._id === ele?.patient?._id
                            ? "bisque"
                            : ele?.patient?._id === patient?._id
                            ? "#f3f3f3"
                            : (chatHover === ele?.patient?._id ? "#F7F7F7" : "#fff"),
                        margin: "0",
                        marginTop: idx === 0 ? "0.5rem" : "0rem",
                        padding: "1rem",
                        // borderRadius: "18px",
                      }}
                      onMouseEnter={() => setChatHover(ele?.patient?._id)}
                      onMouseLeave={() => setChatHover(null)}
                    >
                      <Grid item xs={2} md={1} xl={1}>
                        <Avatar
                          style={{
                            backgroundColor: ele?.patient?.waChatColor
                              ? ele?.patient?.waChatColor
                              : colors.primary,
                            width: "38px",
                            height: "38px",
                            fontSize: "22px",
                          }}
                        >
                          {ele?.patient?.name.split("")[0]}
                        </Avatar>
                      </Grid>
                      <Grid
                        container
                        item
                        direction="column"
                        xs={10}
                        xl={10}
                        style={{ cursor: "pointer", paddingLeft: "25px" }}
                        onClick={() => {
                          mixpanel.track("Clicked on patient in chat")
                          setPatient({ ...ele?.patient })
                          initializeSocketUser(docId, isDoctor)
                        }}
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          style={{display: "flex", alignItems: "center"}}
                        >
                          <Typography variant="body2" style={style.name}>
                            {ele?.patient?.name}
                            {location?.state?.appointmentId &&
                            appointmentData?.patient?._id === ele?.patient._id ? (
                              <Typography
                                style={{
                                  fontWeight: "700",
                                  color: "gray",
                                  display: "flex",
                                  columnGap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                Ongoing Consultation
                                <GenericAnimation
                                  animationData={warningAnimation}
                                  height={30}
                                  width={30}
                                ></GenericAnimation>
                              </Typography>
                            ) : null}
                          </Typography>
                          <Typography
                            style={style.time}
                            color={ele?.unseenCount ? "primary" : "textSecondary"}
                          >
                            {getChatTime(ele?.date)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          direction="row"
                          justifyContent="space-between"
                          container
                        >
                          <Typography
                            variant="body1"
                            style={style.msg}
                            color={ele?.unseenCount ? "textPrimary" : "textSecondary"}
                          >
                            <span>
                              {ele?.userType !== "patient" &&
                              ele?.contentType === "text" ? (
                                <i
                                  class="fa-solid fa-check-double"
                                  style={{
                                    fontSize: "12px",
                                    color: ele?.templateStatus
                                      ? ele?.templateStatus === "read"
                                        ? "#53BDEB"
                                        : "grey"
                                      : ele.status === "read"
                                      ? "#53BDEB"
                                      : "grey",
                                  }}
                                ></i>
                              ) : null}{" "}
                            </span>

                            {getLatestChatMessage(ele)}
                          </Typography>
                          {ele.unseenCount ? (
                            <Avatar
                              style={{
                                width: "25px",
                                height: "25px",
                                backgroundColor: "#463ddd",
                                verticalAlign: "center",
                                padding: "0.2rem",
                                fontSize: "14px",
                              }}
                            >
                              {ele.unseenCount}
                            </Avatar>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{opacity: "40%"}}>
                      <Divider />
                    </Grid>
                    {/* </MenuItem> */}
                  </>
              )
            })
          ) : (
            <Grid
              container
              xs={12}
              item
              justifyContent="center"
              style={{ padding: "1rem" }}
            >
              {!chatListLoader ? <><Grid item xs={12}>
                <GenericAnimation
                  animationData={emptySearchAnimation}
                  height={300}
                  width={300}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  Didn't find what you're looking for!
                  <br /> Start a{" "}
                  <span
                    style={{
                      color: colors.primary,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() => history.push("/admin/allPatients")}
                  >
                    New Chat
                  </span>
                </Typography>
              </Grid></> : <Grid item xs={12} style={{marginTop: "-20px"}}>
                {/* <CircularProgress color="primary" size={"3rem"}/> */}
                {/* <GenericAnimation
                  animationData={dotLoaderAnimation}
                  height={400}
                  width={400}
                /> */}
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "90px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "90px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "90px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "90px", opacity: "40%"}} />
          <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "90px", opacity: "40%"}} />

              </Grid>}
            </Grid>
          )}
          {filteredChats?.length ? <>
            <Grid item xs={12} style={{backgroundColor: "#D7DBDD", justifyContent: "center"}}>
              <TablePagination
                align="center"
                rowsPerPageOptions={[
                  5,
                  10,
                  20,
                  50,
                  100,
                  { label: "All", value: filteredChats?.length },
                ]}
                component="div"
                count={filteredChats.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => {
                  setPage(page)
                  const tempPaginate = [...filteredChats]?.slice(Number(rowsPerPage)*Number(page), Number(rowsPerPage)*(Number(page)+1));
                  setPaginateChat([...tempPaginate]);
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(e.target.value)
                  setPage(0);
                  const tempPaginate = [...filteredChats]?.slice(0, Number(e.target.value));
                  setPaginateChat([...tempPaginate]);
                }}
              />
            </Grid>
          </> : null}
        </Grid>
        <Grid item xs={12}></Grid>
        {newChatModalOpen ? (
          <GenericDialogFormNew1
            dialogTitle={"Add new patient"}
            autoClose={true}
            dialogContent={[
              {
                label: "Name",
                key: "name",
                type: "text",
                md: 10
              },
              {
                label: "Phone",
                key: "phoneNo",
                // disabled: true,
                type: "number",
                md: 10
          
              },
              {
                key: "ageString",
                label: "Age",
                unitOptions1: ['Years', "Months", "Days"],
                unitOptions: ['Year', 'Month', 'Day'],
                placeholder: "e.g. 10 Years",
                freeSolo: true,
                type: "unit-autocomplete",
                md: 10
          
              },
              {
                label: "Gender",
                key: "gender",
                type: "select-chip",
                options: [
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                  { label: "Other", value: "other" },
                ],
                md: 12
              },
            ]}
            showRequired={true}
            open={newChatModalOpen}
            handleClose={() => setNewChatModalOpen(false)}
            handleSubmit={(data) => {
              if (data?._id) {
                setPatient(data)
              } else {
                customAxios
                  .post("/patients/create", { ...data })
                  .then((res) => {
                    if (res.data.success) {
                      console.log("from here3")

                      setPatient({ ...res.data.data })
                    } else {
                      toast.error("Some error occured")
                    }
                  })
                  .catch((err) => {
                    toast.error("Some error occured")
                  })
              }
            }}
          />
        ) : null}
      </CustomGrid>
    </Grid>
  )
}

export default ChatSidebar
