import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"

import { Route, Switch, Redirect, useLocation, useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import customAxios from "../../../axios"
import { loadCurrentOrganisations, loadDoctor, loadOrganisations, loadPatients } from "../../../redux/actions/user.actions"
import { loginUserSuccess } from "../../../redux/actions/auth.actions"
import { LOGOUT_SUCCESS, REMOVE_USER } from "../../../redux/constants"
import { Grid, LinearProgress, Typography, withStyles } from "@material-ui/core"

import loadingAnimation from "../../../assets/animationData/animation_lnjqq8sf.json"
import GenericAnimation from "../../../components/animation"
import { ArrowForward } from "@material-ui/icons"

// import axios from "axios"


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);


function RouteChat() {
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

//   const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if(id) {
        console.log("id", id)
        setProgress(80)
      customAxios
      .get(`ai/fetch-bin?uuid=${id}`)
      .then((res) => {
          dispatch({ type: LOGOUT_SUCCESS })
          dispatch({ type: REMOVE_USER })
          setProgress(100)
        if (res.data.data) {
            // console.log(res.data.data)
            if(res.data?.data?.authToken) {
              let token_exists = localStorage.getItem("token")

              if (token_exists){
              }
              localStorage.setItem("token", res.data.data.authToken)
              dispatch(loadDoctor())
              dispatch(loadOrganisations())
              dispatch(loadPatients())
              dispatch(loadCurrentOrganisations())
              dispatch(loginUserSuccess({"isDoctor": true, "isSubscribe": "active", "access_token": res?.data?.data?.authToken}))
              
              history.push(`/admin/consultation/${res?.data?.data?._id}`)
              
              
            }else {
                history.push("/auth/login")
            }

        } else {
            history.push("/auth/login")
        }
      })
      .catch((err) => {
        console.log(err);
        history.push("/auth/login")
      });
    }

  }, [])
  
  const [state, setState] = useState("example of data passing through all tabs")
  const [activeTab, setActiveTab] = useState("History")
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={2} style={{marginTop: "15%"}}>
        <Grid item>
          <GenericAnimation animationData={loadingAnimation} />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item style={{width: "20%"}}>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item>
          <Typography variant="h6" style={{display: "flex", alignItems: "center"}}>
            Moving you to 
            Paper<span style={{color: "#5E72E4", marginRight: "5px"}}>plane</span>
            {" "}Prescription Pad
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default RouteChat


export function RouteToPatient() {
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

//   const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if(id) {
        console.log("id", id)
        setProgress(80)
      customAxios
      .get(`ai/fetch-bin?uuid=${id}`)
      .then((res) => {
          dispatch({ type: LOGOUT_SUCCESS })
          dispatch({ type: REMOVE_USER })
          setProgress(100)
        if (res.data.data) {
            // console.log(res.data.data)
            if(res.data?.data?.authToken) {
              let token_exists = localStorage.getItem("token")

              if (token_exists){
              }
              localStorage.setItem("token", res.data.data.authToken)
              dispatch(loadDoctor())
              dispatch(loadOrganisations())
              dispatch(loadPatients())
              dispatch(loadCurrentOrganisations())
              dispatch(loginUserSuccess({"isDoctor": true, "isSubscribe": "active", "access_token": res?.data?.data?.authToken}))
              
              history.push(`/admin/allPatients`)
              
              
            }else {
                history.push("/auth/login")
            }

        } else {
            history.push("/auth/login")
        }
      })
      .catch((err) => {
        console.log(err);
        history.push("/auth/login")
      });
    }

  }, [])
  
  const [state, setState] = useState("example of data passing through all tabs")
  const [activeTab, setActiveTab] = useState("History")
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={2} style={{marginTop: "15%"}}>
        <Grid item>
          <GenericAnimation animationData={loadingAnimation} />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item style={{width: "20%"}}>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item>
          <Typography variant="h6" style={{display: "flex", alignItems: "center"}}>
            Moving you to 
            Paper<span style={{color: "#5E72E4", marginRight: "5px"}}>plane</span>
            {" "}Patients Dashboard
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}