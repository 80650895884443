import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import Modal from "reactstrap/lib/Modal"
import customAxios from "../../../axios"
import GenericModalBody from "./GenericModalBody"
import GenericModalBodyForAddPatients from "./GenericModalBodyForAddPatients"
import {
  stepOneHeaders,
  stepThreeHeaders,
  stepTwoHeaders,
  stepWaHeaders,
} from "./ModalHeaders"
import Toast from "reactstrap/lib/Toast"
import AddByPhoneNo from "./AddPatient/AddByPhoneNo"
import { AppBar, Tabs, Tab, Box, Typography } from "@material-ui/core"
import Button from "reactstrap/lib/Button"
import PropTypes from "prop-types"
import GenericModalForschedule from "./GenericModalForSchedule"
import { createLogicalAnd } from "typescript"
import moment from "moment"
import mixpanel from "mixpanel-browser"

function AddPatientModal({
  isOpen,
  setIsOpen,
  setUpdater,
  modalData,
  setModalData,
}) {
  // const currOrganisation = useSelector((state) => state.user.currOrganisation);
  const allOrganisations = useSelector((state) =>
    state?.user?.organisations?.filter((ele) => !ele.isOnline)
  )
  const user = useSelector((state) => state?.user?.user)
  const isDoctor = useSelector((state) => state?.auth?.isDoctor)
  const [allDoctors, setAllDoctors] = useState([])

  useEffect(() => {
    if (!isDoctor) {
      customAxios.get(`/doctor/`, {}).then((res) => {
        if (res?.data?.success) {
          setAllDoctors(res?.data?.data)
        }
      })
    }
  }, [])

  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [patientList, setPatientList] = useState([])

  const handleAddNewPatient = (modalData) => {
    setIsLoading(true)
    let flag = 1
    for (const ele of stepTwoHeaders) {
      if (ele.required && !modalData[ele.key]) {
        setIsLoading(false)
        toast.error(`Please enter ${ele.label}`)
        flag = 0
        break
      }
    }
    if (flag)
      customAxios
        .post("/patients/create", modalData)
        .then((res) => {
          if (res.data.success) {
            const temp = res.data.data
            handleBookAppointment({
              ...modalData,
              patientId: temp["_id"],
            })
          } else {
            setIsLoading(false)
            if (res.data.message === "Patient already exists")
              toast.error("Patient with this date birth already exists!")
            else toast.error("Some error occured")
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error("Some error occured")
        })
  }
  console.log("modalData", modalData)
  const handleBookAppointment = (modalData) => {
    if (!modalData.patientId) toast.error("Please select a patient. ")
    else {
      setIsLoading(true)
      customAxios
        .put("/appointments/book-patient ", {
          ...modalData,
        })
        .then((res) => {
          if (res.data.success) {
            customAxios
              .get(
                `/appointments/send-appointment-message/${res?.data?.data?._id}?language=eng`
              )
              .then((res) => {
                toast.success("Appointment booked successfully.")
                mixpanel.people.increment("Add Patient")
                mixpanel.track("Add Patient")

                setIsLoading(false)
                setIsOpen(false)
                if (setUpdater) setUpdater((prev) => !prev)
                if (setActiveStep) setActiveStep(0)
              })
              .catch((err) => {
                setIsLoading(false)
                toast.error("Some error occured")
              })

            // window.location.reload();
          } else {
            setIsLoading(false)
            if (res.data.message === "Slot is blocked")
              toast.error("Slot is blocked by the doctor.")
            else toast.error("Some error occured")
          }
        })
        .catch((err) => toast.error("Some error occured"))
    }
  }
  const getActiveModalBody = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <GenericModalBodyForAddPatients
            headers={[
              ...stepOneHeaders,
              {
                label: "Location",
                key: "orgId",
                type: "select",
                options: [...allOrganisations],
                optionLabel: "locationName",
                optionValue: "_id",
                fullWidth: true,
              },
            ]}
            modalData={modalData}
            isLoading={isLoading}
            setPatientList={setPatientList}
            saveLabel="Search Patient"
            setActiveStep={setActiveStep}
            setModalData={setModalData}
          ></GenericModalBodyForAddPatients>
        )

      case 1:
        return (
          <AddByPhoneNo
            isLoading={isLoading}
            modalData={modalData}
            handleBookAppointment={handleBookAppointment}
            setModalData={setModalData}
            patientList={patientList}
            setActiveStep={setActiveStep}
          ></AddByPhoneNo>
        )
      case 2:
        return (
          <GenericModalBody
            headers={stepTwoHeaders}
            modalData={modalData}
            isLoading={isLoading}
            saveLabel="Book Appointment"
            setActiveStep={setActiveStep}
            buttons={[
              {
                label: "Book Appointment",
                onClick: (modalData) => handleAddNewPatient(modalData),
              },
              {
                label: "Schedule Appointment for later",
                onClick: (modalData) => {
                  setIsLoading(true)
                  let flag = 1
                  for (const ele of stepTwoHeaders) {
                    if (ele.required && !modalData[ele.key]) {
                      setIsLoading(false)
                      toast.error(`Please enter ${ele.label}`)
                      flag = 0
                      break
                    }
                  }
                  if (flag)
                    customAxios
                      .post("/patients/create", modalData)
                      .then((res) => {
                        if (res.data.success) {
                          setIsLoading(false)
                          const temp = res.data.data
                          setModalData({
                            ...modalData,
                            patientId: temp["_id"],
                          })
                          setActiveStep(4)
                        } else {
                          setIsLoading(false)
                          if (res.data.message === "Patient already exists")
                            toast.error(
                              "Patient with this info already exists!"
                            )
                          else toast.error("Some error occured")
                        }
                      })
                      .catch((err) => {
                        setIsLoading(false)
                        toast.error("Some error occured")
                      })
                },
              },
            ]}
            setModalData={setModalData}
          ></GenericModalBody>
        )
      case 3:
        return (
          <GenericModalBody
            headers={stepThreeHeaders}
            modalData={modalData}
            message={"Patient found!"}
            buttons={[
              {
                label: "Book Appointment",
                onClick: (modalData) => handleBookAppointment(modalData),
              },
              {
                label: "Schedule Appointment for later",
                onClick: (modalData) => {
                  setActiveStep(4)
                },
              },
            ]}
            isLoading={isLoading}
            saveLabel="Book Appointment"
            setActiveStep={setActiveStep}
            handleSubmit={handleBookAppointment}
            setModalData={setModalData}
          ></GenericModalBody>
        )
      case 4:
        return (
          <GenericModalForschedule
            modalData={modalData}
            setModalData={setModalData}
            headers={[]}
            handleSubmitSchedule={handleSubmitSchedule}
            isLoading={isLoading}
            saveLabel={"Book Appointment"}
            setIsOpen={setIsOpen}
            handleModalClose={handleModalClose}
          />
        )
      default:
        return <h1>Bla</h1>
    }
  }
  const handleModalClose = () => {
    setIsOpen(false)
    setIsLoading(false)
    setActiveStep(0)
    setWaData({})
  }
  const [value, setValue] = useState(
    modalData?.tabValue ? modalData?.tabValue : 0
  )
  const [waData, setWaData] = useState({})
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (allDoctors) setWaData({ ...waData, docId: allDoctors?.[0]?.["_id"] })
  }, [allDoctors])
  const handleSubmitSchedule = (modalData, time, docId) => {
    if (!time) toast.error("Please select the time of appointment")
    else {
      setIsLoading(true)
      let dateToSend = new Date()
      dateToSend = moment(dateToSend).format()

      const objToSend = {
        ...modalData,
        date: dateToSend,
        start: time.start,
        end: time.end,
        paymentMode: "offline",
        orgId: modalData.orgId,
        docId: docId,
      }
      customAxios
        .post("appointments/book/offline", objToSend)
        .then((res) => {
          if (res.data.success) {
            customAxios
              .get(
                `/appointments/send-appointment-message/${res?.data?.data?.appointment?._id}?language=eng`
              )
              .then((res) => {
                toast.success("Appointment booked successfully.")
                setIsLoading(false)
                setIsOpen(false)
                setUpdater((prev) => !prev)
                setActiveStep(0)
              })
              .catch((err) => {
                setIsLoading(false)
                toast.error("Some error occured")
              })

            // window.location.reload();
          } else {
            setIsLoading(false)
            toast.error("Some error occured")
          }
        })
        .catch((err) => {
          toast.error("Some error occured")
          console.log(err)
        })
    }
  }

  const handleWaMessage = () => {
    if (waData["searchBy"]) {
      if (waData["searchBy"].length === 10)
        customAxios
          .get(
            `/patients/send-message/${waData["searchBy"]}?doc_id=${
              waData?.["docId"] ? waData["docId"] : null
            }`,
            {}
          )
          .then((res) => {
            if (res?.data?.success) {
              setWaData({})
              toast.success("Message sent successfuly")
              setIsOpen(false)
            } else {
              toast.error("Some error occured")
            }
          })
          .catch((err) => toast.error("Some error occured"))
      else toast.error("Contact length should be 10")
    } else toast.error("Contact field required!")
  }

  return (
    <Modal
      className="modal-dialog-centered modal-md"
      isOpen={isOpen}
      toggle={() => handleModalClose()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add New Patient
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => handleModalClose()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <AppBar elevation={0} position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          centered
          textColor="primary"
          style={{ backgroundColor: "white" }}
        >
          <Tab label="Send Message" {...a11yProps(0)} />
          <Tab label="Add Patient" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GenericModalBody
          headers={
            user["phoneNo"] === 9807655303 || isDoctor
              ? stepWaHeaders
              : [
                  ...stepWaHeaders,
                  {
                    label: "Doctor",
                    key: "docId",
                    type: "select",
                    options: [...allDoctors],
                    optionLabel: "name",
                    optionValue: "_id",
                    fullWidth: true,
                  },
                ]
          }
          modalData={waData}
          isLoading={isLoading}
          saveLabel="Send Message"
          handleSubmit={handleWaMessage}
          setModalData={setWaData}
        ></GenericModalBody>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {getActiveModalBody(activeStep)}
      </TabPanel>
    </Modal>
  )
}

export default AddPatientModal

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
