import { Badge, Button, FormControlLabel, Grid, MenuItem, Switch, TextField, Tooltip, Typography } from "@material-ui/core"
import { getTime } from "date-fns"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import customAxios from "../../../../axios"
import prescriptionSocket from "../../../../socket"
import GenericDialogForm from "../../../CreatePrescription/GenericDialog"
import doctorSocket from "./doctorSocket"
import whatsappPng from "./../../../../assets/img/chatWindow/whatsappPng.png"
import whatsappLink from "./../../../../assets/img/brand/banner1.png"
import appointmentLink from "./../../../../assets/img/brand/addAppointment.png"
import LoaderFullpage from "../../../Common/LoaderFullpage"
import { useHistory } from "react-router"
import success from "./../../../../assets/audios/confirm.mp3"
import { colors } from "../../../../assets/css/colors"
import GenericAnimation from "../../../animation"
import treatmentAnimation from "./../../../../assets/animationData/treatment.json"
import { ExpandLess, ExpandMore } from "@material-ui/icons"

export const handleSendWhatsAppLink = (data, setUpdater = () => {}, setOpen = () => {}, setDialogOpen = () => {}, setModalData = () => {}) => {
  toast.success("Appointment booking link sent on WhatsApp")

  const audio = new Audio(success)
  const promise = audio.play()
  if (promise !== undefined) {
    // On older browsers play() does not return anything, so the value would be undefined.
    promise
      .then(() => {
        // Audio is playing.
      })
      .catch((error) => {
        console.log(error)
      })
  }

  customAxios
    .get(
      `/patients/send-message/${data["phoneNo"]}?doc_id=${
        data["docId"] ? data["docId"] : null
      }`,
      {}
    )
    .then((res) => {
      if (res.data.success) setUpdater((prev) => !prev)
      setOpen(false)
      setDialogOpen(false)
      setModalData({})
    })
    .catch((err) => console.log("err"))
}
function AddAppointmentDialog({
  open,
  setOpen,
  setModalData,
  setUpdater = () => {},
  modalData = {},
  defaultTabValue = 1,
  allPatients,
  setAllPatients,
  onlyTab = null
}) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const currOrg = useSelector((state) => state.user.currOrganisation)
  const [dateSlotsList, setDateSlotsList] = useState([])
  const [timeSlotsList, setTimeSlotsList] = useState([])
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const userId = useSelector((state) => state.user.user._id)
  const user = useSelector((state) => state.user.user)
  const [patientList, setPatientList] = useState([])
  const [updateData, setUpdateData] = useState({})
  const [phoneNo, setPhoneNo] = useState("")
  const [treatmentList, setTreatmentList] = useState([])
  const [linkTreatment, setLinkTreatment] = useState(false)
  const [treatmentModalOpen, setTreatmentModalOpen] = useState(false)
  const [selectedTreatment, setSelectedTreatment] = useState(null)
  const [addPatientOpen, setAddPatientOpen] = useState(false)
  const [isSchedule, setIsSchedule] = useState(false)
  const history = useHistory()
  const allOrganisations = useSelector((state) =>
    state?.user?.organisations.map((ele) => ({
      label: ele?.locationName,
      value: ele?._id,
      ...ele,
    }))
  )
  
  const [doctorList, setDoctorList] = useState([])
  const publicNumbers = useSelector((state) => state?.user?.user?.publicNumbers)
  const [loadingMessage, setLoadingMessage] = useState("")

  const isOH = useSelector((state) => state.user?.user?.ohID)

  const [currOrgId, setCurrOrgId] = useState(() =>
    currOrg?._id !== 0 && currOrg?._id ? currOrg?._id : allOrganisations?.find(f => !f?.isOnline)?._id
  );

  const organisation = useSelector((state) =>
    state?.user?.organisations?.find((ele) => ele._id === currOrgId)
  )
  useEffect(() => {
    if (open && timeSlotsList.length) {
      setDialogOpen(true)
    }
  }, [timeSlotsList, open])

  useEffect(() => {
    if (dateSlotsList.length && isSchedule) getTimeSlots(dateSlotsList[0].date)
  }, [dateSlotsList])

  useEffect(() => {
    if(modalData?.onReschedule){
      setIsSchedule(true)
    }
  }, [modalData])

  useEffect(() => {
    if (!isDoctor) {
      customAxios.get(`/doctor/`, {}).then((res) => {
        if (res?.data?.success) {
          const finalArr = res.data.data.map((ele) => ({
            label: ele.name,
            value: ele._id,
          }))
          setDoctorList([...finalArr])
        } else {
          toast.error(
            "No organisations found. Please tell admin to grant location permissions"
          )
        }
      })
    }
  }, [isDoctor]);

  const addressField = (publicNumbers?.toString() === "99999" || user?.orgStaff === 'jagrani') ? [{
    label: "Patient Address",
    key: "address",
    placeholder: "For eg. B-Block, Residency Park",
    md: 12
  }] : [];

  const scheduleDataFields = [{
    md: 12,
    key: "date",
    label: "Date of Appointment",
    type: "date",
    onChange: (value, state, setState) => {
      let temp = { ...state }
      temp["date"] = value
      temp["time"] = ""
      console.log("pola43", value, temp)
      getTimeSlots(value)
      setState({ ...temp })
    },

    otherProps: {
      ToolbarComponent: () => {
        return (
          <Grid
            item
            xs={12}
            container
            style={{
              background: "#f3f3f3",
              padding: "0.5rem",
              // borderRadius: "12px 012px 0px 0px",
            }}
          >
            <Grid item md={4} xs={12}>
              <div
                style={{
                  minHeight: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  background: getColor("green"),
                }}
              ></div>
              <Typography style={{ fontSize: "10px", color: "darkcyan" }}>
                50-100% slots free
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <div
                style={{
                  minHeight: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  background: getColor("yellow"),
                }}
              ></div>
              <Typography
                style={{ fontSize: "10px", color: "darkcyan" }}
                color="secondary"
              >
                25-50% slots free
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <div
                style={{
                  minHeight: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  background: getColor("red"),
                }}
              ></div>
              <Typography
                style={{ fontSize: "10px", color: "darkcyan" }}
                color="secondary"
              >
                25% slots free
              </Typography>
            </Grid>
          </Grid>
        )
      },

      helperText: dateSlotsList.length ? (
        <Typography style={{ fontSize: "12px" }}>
          Pro Tip: Change your timings from{" "}
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => history.push("/admin/CreateSlot")}
          >
            here
          </span>
        </Typography>
      ) : (
        <Typography style={{ fontSize: "12px", color: "red" }}>
          No open slots found on this location. Change your timings from{" "}
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => history.push("/admin/CreateSlot")}
          >
            here
          </span>
        </Typography>
      ),
      disabled: !dateSlotsList.length,
      shouldDisableDate: (date) => {
        const enableDate = dateSlotsList.find(
          (ele) => moment(date).format("YYYY-MM-DD") === ele.date
        )

        if (enableDate) return false
        return true
      },
      disablePast: true,
      renderDay: (day, selectedDate, isInCurrentMonth, dayComponent) => {
        const date = dateSlotsList.find((ele) => moment(ele.date).isSame(day))

        // You can also use our internal <Day /> component
        return (
          <Tooltip placement="top" title={date ? getText(date.color) : ""}>
            <div
              style={{
                width: "36px",
                height: "36px",
                backgroundColor:
                  selectedDate === day
                    ? "white"
                    : date
                    ? getColor(date.color)
                    : "inherit",
                color: date ? "white" : "inherit",
                borderRadius: "50%",
                margin: "1px 2px",
              }}
            >
              {dayComponent}
            </div>
          </Tooltip>
        )
      },
    },
  },
  {
    label: "Time of Appointment",
    key: "time",
    dependencyKey: "date",
    md: 12,
    type: "select",
    required: true,
    otherProps: { disabled: !timeSlotsList.length },
    onChange: (value, state, setState) => {
      if (value) {
        const temp = { ...state }
        temp["time"] = value

        const slot = timeSlotsList.find((ele) => ele.display === value)
        temp["start"] = slot.start

        temp["end"] = slot.end
        setState({ ...temp })
      } else {
        const temp = { ...state }
        temp["time"] = null

        temp["start"] = null
        temp["end"] = null
        setState({ ...temp })
      }
    },

    options: timeSlotsList,
  }];

  const defaultDialogFields = [{
    key: "phoneNo",
    required: true,
    placeholder: "Search for patients",
    maxLength: "10",
    label: "Phone number",
    onChange: (value, state, setState, isPatientData = false) => {
      const regex = /^\d+$/;

      if (isPatientData){
        if(value){
          setState({ ...state, ...value, patientId: value?._id, gender: value?.gender ? value?.gender?.toLowerCase() : null, ageString: value?.age ? `${value?.age} Years` : null})
          setPhoneNo(value?.phoneNo)
        } else {
          setState({...state,
            name: null,
            phoneNo: null,
            age: null,
            ageString: null,

            gender: null,
            // ...args?.patient,
            patientId: null })
            setPhoneNo("")
        }
      } else if(regex?.test(value) || !value) {
        
        setState({ ...state, phoneNo: value })
        setPhoneNo(value)
        handleSearchPatient(value, setState)
      }
    },
    type: "patient-search",
    options: allPatients?.length ? allPatients?.slice(0, 5000) : []
  },
  {
    key: "name",
    label: "Name",
    type: patientList.length ? "select-free" : "patient-search",
    placeholder: "Search by Name",
    options: patientList?.length ? patientList : (allPatients?.length ? allPatients?.slice(0, 5000) : []),
    required: true,
    otherProps: {
      helperText: patientList.length ? (
        <Typography>
          <span
            style={{
              color: colors.primary,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setAddPatientOpen(true)
            }}
          >
            Click here
          </span>{" "}
          to add new patient
        </Typography>
      ) : null,
    },
    onChange: (value, state, setState, isPatientData = false) => {
      if (patientList.length) {
        // console.log(value, state, patientList)
        let patient = patientList.find((ele) => value === ele?.name)

        if (!patient){
          patient = patientList.find((ele) => state?.patientId === ele?._id)
        }

        setState((prev) => ({
          ...prev,
          patientId: patient?._id,
          name: value,
          phoneNo: patient?.phoneNo,
          age: patient?.age,
          ageString: patient?.ageString,
          gender: patient?.gender,
        }))
      } else {
        if (isPatientData){
          if(value){
            // setState({ ...state, ...value, patientId: value?._id})
            setPhoneNo(value?.phoneNo)
            setState({ ...state, ...value, patientId: value?._id, gender: value?.gender ? value?.gender?.toLowerCase() : null, ageString: value?.age ? `${value?.age} Years` : null})
          } else {
            setState({...state,
              name: null,
              phoneNo: null,
              age: null,
              ageString: null,
  
              gender: null,
              // ...args?.patient,
              patientId: null })
              setPhoneNo("")
          }
        } else {
          setState((prev) => ({ ...prev, name: value }))
        }
      }
    },
  },
  {
    key: "ageString",
    label: "Age",
    unitOptions1: ['Years', "Months", "Days"],
    unitOptions: ['Year', 'Month', 'Day'],
    placeholder: "e.g. 10 Years",
    freeSolo: true,
    type: "unit-autocomplete"
  },
  {
    label: "Gender",
    key: "gender",
    type: "select",
    options: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Other", value: "other" },
    ],
  },
  ...addressField,
  {
    md: 12,
    key: "orgId",
    label: "Location",
    // value: currOrgId,
    type: "select",
    required: true,
    options: allOrganisations,
    onChange: (value, state, setState) => {
      setCurrOrgId(value)

      setState((prev) => ({ ...prev, orgId: value, date: null, time: null }))
    },
  },
  {
    md: 12,
    key: "isSchedule",
    label: "Book for Later",
    value: isSchedule,

    type: "switch",
    onChange: (event) => {
      // setState((prev) => ({ ...prev,  "isSchedule": event?.target?.checked}))
      setIsSchedule(event?.target?.checked);
    },
  }]

  const [addAppointmentDialogContent, setAddAppointmentDialogContent] = useState([defaultDialogFields])

  useEffect(() => {
    if(isSchedule){
      setAddAppointmentDialogContent([...defaultDialogFields, ...scheduleDataFields])
    } else {
      setAddAppointmentDialogContent([...defaultDialogFields])
    }
  }, [isSchedule, timeSlotsList, dateSlotsList, patientList])

  const getTimeSlots = (date) => {
    setLoadingMessage("Fetching available time slots...")
    setLoading(true)
    const doctor = organisation?.doctors?.find((v, idx) => {
      return isDoctor ? user._id === v?.doctorId : idx === 0
    })
    doctorSocket.emit("get_slots", {
      docId: doctor?.doctorId,
      orgId: currOrgId,
      slotDate: moment(date).format("DD/MM/YYYY"),
    })

    doctorSocket.off("get_slots").on("get_slots", (args) => {
      if (args.length) {
        const finalArr = args?.map((ele) => {
          return {
            label: ele.display,
            value: ele.display,
            ...ele,
          }
        })
        setTimeSlotsList([...finalArr])
      } else {
        toast.error("No slots found for this date.")
        setDialogOpen(true)
      }
      setLoading(false)
    })
  }
  // useEffect(() => {
  //   if (dateSlotsList?.length) {
  //     console.log("prakash", dateSlotsList)
  //     const ele = dateSlotsList[0]

  //     setInitialData((prev) => ({
  //       ...prev,
  //       orgId: currOrgId,
  //       date: new Date(ele.date),
  //       time: "",
  //       start: "",
  //       end: "",
  //     }))
  //   }
  // }, [dateSlotsList])
  const getColor = (color) => {
    switch (color) {
      case "green":
        return "lightgreen"
      case "red":
        return "lightsalmon"
      case "yellow":
        return "gold"
      default:
        return "lightblue"
    }
  }

  const getText = (data) => {
    switch (data) {
      case "green":
        return "More than 50% of slots are free"
      case "red":
        return "Less than 25% of slots are free"
      case "yellow":
        return "25-50% of slots are free"
      default:
        return ""
    }
  }
  const getDates = (orgId) => {
    // setLoading(true)
    // setLoadingMessage("Fetching available dates...")
    const doctor = allOrganisations
      ?.find((ele) => ele._id === orgId)
      ?.doctors?.find((v, idx) => {
        return isDoctor ? user?._id === v?.doctorId : idx === 0
      })

    customAxios
      .get(
        `https://pplane-api-prod.paperplane.health/api/v1/slots/slot-dates/${orgId}?doc_id=${doctor?.doctorId}`
      )
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          setDateSlotsList(res.data.data)
          getTimeSlots(res.data.data[0].date)
        } else {
          setLoading(false)
          if (res.data.message === "division by zero") {
            toast.error(
              <div>
                No open slots found on this location. Change your timings from{" "}
                <span
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/admin/CreateSlot")}
                >
                  here
                </span>
              </div>
            )
          } else toast.error("Some error occured")
        }
      })
      .catch((err) => {
        setLoading(false)

        console.log("err", err)
        toast.error("Some error occured")
      })
  }
  useEffect(() => {
      if(isSchedule)
        getDates(currOrgId)
      setDialogOpen(true)
  }, [currOrgId, isSchedule])

  const handleSearchPatient = (query, setState) => {
    if (query.length === 10) {
      prescriptionSocket.emit("search_patient", {
        phoneNo: query,
        userId: isDoctor ? userId : allOrganisations[0].admin,
        isMultiplePatientEnabled: true
      })
      prescriptionSocket.once("search_patient", (args) => {
        if (args.isExists) {
          console.log(Array.isArray(args.patient), "po")
          if (Array.isArray(args.patient)) {
            const temp = args.patient.map((ele) => ({
              label: ele.name,
              value: ele.name,
              ...ele,
            }))
            setPatientList([...temp])
            setState((prev) => ({
              ...prev,
              name: args.patient[0].name,
              phoneNo: args.patient[0].phoneNo,
              age: args.patient[0].age,
              ageString: args.patient[0]?.ageString,

              gender: args.patient[0].gender,
              // ...args?.patient,
              patientId: args.patient[0]._id,
            }))
          } else{
            // customAxios
            //   .get(`/patients/treatments/${args.patient._id}`)
            //   .then((res) => {
            //     if (res.data.success) {
            //       setTreatmentList((prev) => [
            //         ...prev,
            //         ...res.data.data.map((ele) => ({
            //           label: ele?.treatment?.name,
            //           value: ele._id,
            //         })),
            //       ])
            //     }
            //   })
            //   .catch((err) => {
            //     console.log(err)
            //   })

            setState((prev) => ({
              ...prev,
              name: args.patient.name,
              phoneNo: args.patient.phoneNo,
              age: args.patient.age,
              ageString: args.patient?.ageString,

              gender: args.patient.gender,
              // ...args?.patient,
              patientId: args.patient._id,
            }))
          }
        }
      })
    } else {
      setState((prev) => ({
        ...prev,
        patientId: null,
        name: null,
        gender: "male",
        age: 0,
      }))
    }
    setPatientList([])
  }
  const handleReschedule = (data) => {
    customAxios
      .post(`appointments/reschedule-new`, {
        ...data,
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          setUpdater((prev) => !prev)

          toast.success("Appointment rescheduled. Patient notified on WhatsApp")
        } else {
          setLoading(false)

          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        setLoading(false)

        toast.error("Some error occured")
      })
  }
  const handleCreatePatient = (data, book = true) => {
    customAxios
      .post("/patients/create", { ...data, isNew: true })
      .then((res) => {
        if (res.data.success) {
          const temp = res.data.data

          if (book) {
            if(isSchedule){
              handleSubmitSchedule({
                ...data,
                patientId: temp._id,
              })
            }
            else {
              handleInstantAppointment({
                ...data, 
                patientId: temp._id,
              })
            }
          } else {
            setUpdateData({
              name: data.name,
              age: data.age,
              ageString: data?.ageString,
              gender: data.gender,
              patientId: temp._id,
            })

            toast.success("Patient created successfully.")
          }
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        toast.error("Some error occured")
      })
  }
  const handleInstantAppointment = (data, redirect_to = null) => {
    if(data?.name)
    customAxios
    .post("/patients/create", { ...data })
    customAxios
      .put("/appointments/book-patient ", {
        ...data,
      })
      .then((res) => {
        setButtonLoading(false)

        if (res.data.success) {
          setUpdater((prev) => !prev)
          const audio = new Audio(success)
          const promise = audio.play()
          if (promise !== undefined) {
            // On older browsers play() does not return anything, so the value would be undefined.
            promise
              .then(() => {
                // Audio is playing.
              })
              .catch((error) => {
                console.log(error)
              })
          }
          toast.success("Appointment booked successfully.")
          mixpanel.people.increment("Add Patient")
          mixpanel.track("Add Patient")

          setOpen(false)
          setDialogOpen(false)
          setModalData({})

          if(redirect_to){
            history.push(`/admin/consultation/${res?.data?.data?._id}`, {redirectTo: "allPatients"})
          }

          if(!isOH)
          setTimeout(() => {
            customAxios
              .get(
                `/appointments/send-appointment-message/${res?.data?.data?._id}?language=eng`
              )
              .then((res) => {})
              .catch((err) => {
                toast.error("Some error occured")
              })
          }, 0)

          // window.location.reload();
        } else {
          if (res.data.message === "Slot is blocked")
            toast.error("Slot is blocked by the doctor.")
          else toast.error(res?.data?.message)
        }
      })
      .catch((err) => toast.error("Some error occured"))
  }
  const handleSubmitSchedule = (data) => {
    const doctor = organisation?.doctors?.find((v, idx) => {
      return isDoctor ? user._id === v?.doctorId : idx === 0
    })
    const currOrg = allOrganisations.find((ele) => ele._id === data.orgId)
    const objToSend = {
      ...data,
      // date: dateToSend,
      paymentMode: currOrg.isOnline ? "online" : "offline",
      orgId: data.orgId,
      docId: doctor?.doctorId,
    }
    customAxios
      .post(
        `appointments/book/${currOrg.isOnline ? "online" : "offline"} `,
        objToSend
      )
      .then((res) => {
        setButtonLoading(false)
        if (res.data.success) {
          setUpdater((prev) => !prev)
          const audio = new Audio(success)
          const promise = audio.play()
          if (promise !== undefined) {
            // On older browsers play() does not return anything, so the value would be undefined.
            promise
              .then(() => {
                // Audio is playing.
              })
              .catch((error) => {
                console.log(error)
              })
          }
          toast.success("Appointment booked successfully.")

          setOpen(false)
          setDialogOpen(false)
          setModalData({})

          if (!currOrg.isOnline) {
            setTimeout(() => {
              customAxios
                .get(
                  `/appointments/send-appointment-message/${res?.data?.data?.appointment?._id}?language=eng`
                )
                .then((res) => {})
                .catch((err) => {
                  toast.error("Some error occured")
                })
            }, 0)
          }

          // window.location.reload();
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((err) => {
        toast.error("Some error occured")
        console.log(err)
      })
  }

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (data, redirect_to = null) => {
    setButtonLoading(true)
    if (data.patientId) {
      if(isSchedule)
        handleSubmitSchedule(data)
      
      else {
        handleInstantAppointment(data, redirect_to)
      }
    } else {
      handleCreatePatient(data)
    }
  }
  return (
    <div>
      {" "}
      <LoaderFullpage loading={loading} message={loadingMessage} />
      {dialogOpen ? (
        <GenericDialogForm
          updateData={updateData}
          autoClose={true}
          maxWidth={"sm"}
          fullWidth={true}
          prescriptionCTATab={isOH ? 0 : 1}
          isSchedule={isSchedule}
          submitButtonLoading={buttonLoading}
          tabs={!onlyTab ? [
            {
              label: (
                <div
                  style={{
                    display: "flex",
                    direction: "column",
                    alignItems: "center",
                  }}
                >
                  Send WhatsApp Link{" "}
                  <img
                    alt="Whatsapp Logo"
                    src={whatsappPng}
                    style={{
                      marginLeft: "0.2rem",
                      color: "green",
                      width: "25px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ),
              message: (
                <div
                  style={{
                    display: "flex",
                    direction: "column",
                    alignItems: "center",
                    marginBottom: "1rem",

                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="Whatsapp Logo"
                    src={whatsappLink}
                    style={{
                      marginLeft: "0.2rem",
                      color: "green",
                      width: "100%",
                      borderRadius: "8px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ),
              value: 0,
              dialogContent: isDoctor
                ? [
                    {
                      key: "phoneNo",
                      error: (data) => data.toString().length !== 10,
                      required: true,
                      md: 12,
                      placeholder: "Add phone number and press submit",
                      maxLength: "10",
                      label: "Phone number",
                    },
                  ]
                : [
                    {
                      key: "phoneNo",
                      required: true,
                      md: 12,
                      placeholder: "Search for patients",
                      maxLength: "10",
                      label: "Phone number",
                    },
                    {
                      key: "docId",
                      md: 12,
                      required: true,
                      type: "select",
                      label: "Doctor",
                      options: doctorList,
                    },
                  ],
            },
            {
              label: "Add New Appointment",
              value: 1,
              dialogContent: addAppointmentDialogContent,
              footerMessage: () => {
                return (
                  <Grid item container xs={12} style={{ marginTop: "0.4rem" }}>
                    {/* <Grid item xs={12}>
                      {/* <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={linkTreatment}
                            onChange={(e) => {
                              setLinkTreatment(e.target.checked)
                              if (!e.target.checked) {
                                setUpdateData({ treatmentId: null })
                              }
                            }}
                          />
                        }
                        label="Add a procedure to this appointment"
                      ></FormControlLabel> 
                      <Button variant="text" color="primary" onClick={(e) => {
                              setLinkTreatment(!linkTreatment)
                              if (linkTreatment) {
                                setUpdateData({ treatmentId: null })
                              }
                            }} startIcon={linkTreatment ? <ExpandLess /> : <ExpandMore />}>Add a procedure to this appointment</Button>
                    </Grid> */}

                    {linkTreatment && treatmentList.length ? (
                      <Grid item xs={12}>
                        <TextField
                          select
                          variant="outlined"
                          fullWidth
                          options={treatmentList}
                          value={selectedTreatment}
                          InputLabelProps={{ shrink: true }}
                          disabled={!treatmentList.length}
                          label="Ongoing Procedures"
                          onChange={(e) => {
                            setUpdateData({
                              treatmentId: e.target.value,
                            })

                            setSelectedTreatment(e.target.value)
                          }}
                        >
                          {treatmentList.map((ele) => {
                            return (
                              <MenuItem value={ele.value}>{ele.label}</MenuItem>
                            )
                          })}
                        </TextField>
                      </Grid>
                    ) : null}
                    {linkTreatment ? (
                      <div>
                        {treatmentList.length === 0 ? (
                          <Typography
                            color="secondary"
                            style={{ marginBottom: "0.5rem" }}
                          >
                            No ongoing procedures found.
                            <br /> Please add new with the button below👇
                          </Typography>
                        ) : null}
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => {
                            setTreatmentModalOpen(true)
                          }}
                          startIcon={<i className="fa fa-plus"></i>}
                        >
                          Add Procedure
                        </Button>
                      </div>
                    ) : null}
                  </Grid>
                )
              },

              message: (
                <div
                  style={{
                    display: "flex",
                    direction: "column",
                    alignItems: "center",
                    marginBottom: "1rem",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="Whatsapp Logo"
                    src={appointmentLink}
                    style={{
                      marginLeft: "0.2rem",
                      color: "green",
                      width: "100%",
                      borderRadius: "8px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ),
            }
          ] : [{
            label: "Add New Appointment",
            value: 1,
            dialogContent: [...addAppointmentDialogContent.slice(0, 4), 
              {
                md: 12,
                key: "startKey",
                label: "Select Date & Time",
                type: "datetime",
                disablePast: true,
                onChange: (value, state, setState) => {
                  let temp = { ...state }
                  temp["startKey"] = value
                  temp["start"] = moment(value).add(330, 'minutes').toISOString()
                  // temp["time"] = ""
                  console.log("pola43", value, temp)
                  // getTimeSlots(value)
                  setState({ ...temp })
                },
              }
            ],
            footerMessage: () => {
              return (
                <Grid item container xs={12} style={{ marginTop: "0.4rem" }}>
                  {/* <Grid item xs={12}>
                    {/* <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={linkTreatment}
                          onChange={(e) => {
                            setLinkTreatment(e.target.checked)
                            if (!e.target.checked) {
                              setUpdateData({ treatmentId: null })
                            }
                          }}
                        />
                      }
                      label="Add a procedure to this appointment"
                    ></FormControlLabel> 
                    <Button variant="text" color="primary" onClick={(e) => {
                            setLinkTreatment(!linkTreatment)
                            if (linkTreatment) {
                              setUpdateData({ treatmentId: null })
                            }
                          }} startIcon={linkTreatment ? <ExpandLess /> : <ExpandMore />}>Add a procedure to this appointment</Button>
                  </Grid> */}

                  {linkTreatment && treatmentList.length ? (
                    <Grid item xs={12}>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        options={treatmentList}
                        value={selectedTreatment}
                        InputLabelProps={{ shrink: true }}
                        disabled={!treatmentList.length}
                        label="Ongoing Procedures"
                        onChange={(e) => {
                          setUpdateData({
                            treatmentId: e.target.value,
                          })

                          setSelectedTreatment(e.target.value)
                        }}
                      >
                        {treatmentList.map((ele) => {
                          return (
                            <MenuItem value={ele.value}>{ele.label}</MenuItem>
                          )
                        })}
                      </TextField>
                    </Grid>
                  ) : null}
                  {linkTreatment ? (
                    <div>
                      {treatmentList.length === 0 ? (
                        <Typography
                          color="secondary"
                          style={{ marginBottom: "0.5rem" }}
                        >
                          No ongoing procedures found.
                          <br /> Please add new with the button below👇
                        </Typography>
                      ) : null}
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                          setTreatmentModalOpen(true)
                        }}
                        startIcon={<i className="fa fa-plus"></i>}
                      >
                        Add Procedure
                      </Button>
                    </div>
                  ) : null}
                </Grid>
              )
            },

            message: (
              <div
                style={{
                  display: "flex",
                  direction: "column",
                  alignItems: "center",
                  marginBottom: "1rem",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="Whatsapp Logo"
                  src={appointmentLink}
                  style={{
                    marginLeft: "0.2rem",
                    color: "green",
                    width: "100%",
                    borderRadius: "8px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ),
          }]}
          formData={{
            gender: "male",
            orgId:
              currOrg?._id !== 0 ? currOrg._id : (allOrganisations?.find(f => !f?.isOnline) ? allOrganisations?.find(f => !f?.isOnline)?._id : allOrganisations?.[0]?._id),
            docId: (allOrganisations?.find(f => !f?.isOnline) ? allOrganisations?.find(f => !f?.isOnline)?.admin : allOrganisations?.[0]?.admin),
            date: timeSlotsList.length ? new Date(dateSlotsList[0].date) : null,
            time: timeSlotsList.length ? timeSlotsList[0].display : null,
            start: timeSlotsList.length ? timeSlotsList[0].start : null,
            end: timeSlotsList.length ? timeSlotsList[0].end : null,
            ...modalData,
          }}
          dialogTitle={"Add new appointment"}
          showRequired={true}
          open={dialogOpen}
          submitWithEnter={true}
          defaultTabValue={defaultTabValue}
          handleClose={() => {
            setOpen(false)
            setDialogOpen(false)
            setModalData({})
          }}
          handleSubmit={(data, tab, redirect_to = null) => {
            console.log("submit98", data, tab)
            switch (tab) {
              case 0: {
                if(isOH) {
                  if (data.appointmentId) {
                    handleReschedule(data)
                  } else handleSubmit(data, redirect_to)
                } else {
                  handleSendWhatsAppLink(data, setUpdater, setOpen, setDialogOpen, setModalData)
                }
                break
              }
              case 1:
                if (data.appointmentId) {
                  handleReschedule(data)
                } else handleSubmit(data, redirect_to)
                break
              default:
            }
          }}
        />
      ) : null}
      {addPatientOpen ? (
        <GenericDialogForm
          open={addPatientOpen}
          setOpen={setAddPatientOpen}
          dialogContent={[
            {
              key: "name",
              label: "Name",
              required: true,
            },
            {
              key: "ageString",
              label: "Age",
              unitOptions1: ['Years', "Months", "Days"],
              unitOptions: ['Year', 'Month', 'Day'],
              placeholder: "e.g. 10 Years",
              freeSolo: true,
              type: "unit-autocomplete"
            },
            {
              label: "Gender",
              key: "gender",
              type: "select",
              options: [
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ],
            },
          ]}
          formData={{ gender: "male", phoneNo: phoneNo }}
          handleSubmit={(data) => {
            setPatientList([])

            handleCreatePatient(data, false)
          }}
          dialogTitle="Add new patient"
        />
      ) : null}
      {treatmentModalOpen ? (
        <GenericDialogForm
          dialogTitle="Add New Treatment"
          open={treatmentModalOpen}
          message={
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <GenericAnimation
                animationData={treatmentAnimation}
                height={200}
                width={200}
              ></GenericAnimation>
            </Grid>
          }
          setOpen={setTreatmentModalOpen}
          handleSubmit={(modalData) => {
            customAxios
              .put("/treatment/", modalData)
              .then((res) => {
                if (res.data.success) {
                  toast.success("Procedure added succesfully")
                  setTreatmentList((prev) => [
                    ...prev,
                    { label: modalData.name, value: res.data.data._id },
                  ])
                  setSelectedTreatment(res.data.data._id)
                  setUpdateData({ treatmentId: res.data.data._id })
                } else toast.error("Some error occured")
              })
              .catch((err) => {
                console.log(err)
                toast.error("Some error occured")
              })
          }}
          dialogContent={[
            {
              key: "name",
              label: "Name",
              required: true,
              otherProps: { inputProps: { maxLength: 30 } },
            },
            { key: "totalAmount", required: true, label: "Total Amount" },
          ]}
        />
      ) : null}
    </div>
  )
}

export default AddAppointmentDialog
