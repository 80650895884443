/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
// core components
import { useDispatch, useSelector } from "react-redux";
import routes from "../routes.js";
import jwt_decode from "jwt-decode";
import { logout } from "../redux/actions/auth.actions.js";
import { REMOVE_AUTHENTICATION_STATUS } from "../redux/constants/index.js";

function Meet(props) {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const isNewUser = useSelector((state) => state.auth.newUser);
	const isDoctor = useSelector((state) => state.auth.isDoctor);

	const dispatch = useDispatch();
	const history = useHistory();
	const userToken = useSelector((state) => state.auth.access_token);
	if (userToken != null) {
		const { expires } = jwt_decode(userToken);

		const expirationTime = expires * 1000 - 60000;
		if (Date.now() >= expirationTime) {
			dispatch({ type: REMOVE_AUTHENTICATION_STATUS });
			dispatch(logout());
			window.location = "	/auth/login";
		}
	} else {
		dispatch({ type: REMOVE_AUTHENTICATION_STATUS });
		dispatch(logout());
		window.location = "/auth/login";
	}

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			const { component: Component } = prop;
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.layout === "/meet") {
				return (
					<Route
						path={prop.layout + prop.path}
						render={(props) => {
							if (isAuthenticated && isDoctor) return <Component {...props} />;
							else if (isAuthenticated && !isDoctor && !prop.onlyDoctor)
								return <Component {...props} />;
							else if (isAuthenticated && !isDoctor && prop.onlyDoctor)
								return <Redirect to="/admin/dashboard"></Redirect>;
							else return <Redirect to="/auth/login" />;
						}}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	const navigateOnboarding = () => {
		history.push("/onboarding/welcome-doctor");
	};
	
	// toggles collapse between mini sidenav and normal
	


	return (
		<>
			{!isNewUser ? (
				<>
					
					<div className="main-content" >
					
						<Switch>{getRoutes(routes)}</Switch>
					</div>
				</>
			) : (
				navigateOnboarding()
			)}
		</>
	);
}

export default Meet;
