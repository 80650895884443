export const stepOneHeaders = [
  {
    label: "Contact",
    key: "phoneNo",
    addOnType: "prepend",
    addOn: "+91",
    type: "tel",
  },
  {
    label: "Date of Birth",
    key: "birthDate",
    type: "date",
  },
];
export const stepTwoHeaders = [
  { label: "Name", key: "name" },
  {
    label: "Contact",
    key: "phoneNo",
    addOnType: "prepend",
    addOn: "+91",
    disabled: true,
  },
  { label: "Date of Birth", key: "birthDate", disabled: true },
  {
    label: "Gender",
    key: "gender",
    type: "select",
    options: ["male", "female", "other"],
  },
];
export const stepThreeHeaders = [
  { label: "Name", key: "name", disabled: true },
  {
    label: "Contact",
    key: "phoneNo",
    addOnType: "prepend",
    addOn: "+91",
    disabled: true,
  },
  { label: "Date of Birth", key: "birthDate", disabled: true },
  {
    label: "Gender",
    disabled: true,
    key: "gender",
    type: "select",
    options: ["male", "female", "other"],
  },
];
