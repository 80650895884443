import {
  POST_PRESCRIPTION_DETAILS,
  CLEAR_PRESCRIPTION_DETAILS,
  POST_MEDICAL_HISTORY,
  CLEAR_MEDICAL_HISTORY,
  POST_VITALS,
  CLEAR_VITALS,
  SAVE_APPOINTMENT_DATA,
  DELETE_APPOINTMENT_DATA,
  POST_PRESCRIPTION_TEMPLATE,
  CLEAR_PRESCRIPTION_TEMPLATE,
} from "../constants/index";

const initialState = {
  prescription: {
    symptoms: [],
    diagnosis: [],
    drugs: [],
    tests: [],
    additionalDocs: [],
    exercise: [],
    followUp: { notes: "", expDate: "" },
  },
  history: {},
  vitals: {},
  appointment: {},
  template: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case POST_PRESCRIPTION_DETAILS:
      return {
        ...state,
        prescription: action.payload,
      };
    case CLEAR_PRESCRIPTION_DETAILS:
      return {
        ...state,
        prescription: {},
      };
    case POST_MEDICAL_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case CLEAR_MEDICAL_HISTORY:
      return {
        ...state,
        history: {},
      };
    case POST_VITALS:
      return {
        ...state,
        vitals: action.payload,
      };
    case CLEAR_VITALS:
      return {
        ...state,
        vitals: {},
      };
    case SAVE_APPOINTMENT_DATA:
      return {
        ...state,
        appointment: action.payload,
      };
    case DELETE_APPOINTMENT_DATA:
      return {
        ...state,
        appointment: {},
      };
    case POST_PRESCRIPTION_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case CLEAR_PRESCRIPTION_TEMPLATE:
      return {
        ...state,
        template: {},
      };
    default:
      return state;
  }
}
