import React, { useEffect, useState } from "react";
import {
	Button,
	Grid,
	MenuItem,
	TextField,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio
} from "@material-ui/core";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import customAxios from "../../axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: 1000,
		color: "#fff"
	}
}));
function CustomiseForm() {
	const [loading, setLoading] = useState(false);
	const classes = useStyles();
	const docId = useSelector((state) => state.user.user._id);
	const handleSubmit = (state) => {
		customAxios
			.put("/doctor/update-custom-form", { data: [...state] })
			.then((res) => {
				if (res.data.success) {
					setState([...state]);
					toast.success("Form submitted successfully");
				}
			})
			.catch((err) => toast.error("Some error occured"));
	};

	const typeOptions = [
		{ label: "Text", value: "text" },
		{ label: "Date", value: "date" },
		{ label: "Multiple Choice", value: "radio" },
		{ label: "Checkbox", value: "checkbox" }
	];
	useEffect(() => {
		setLoading(true);
		customAxios.get(`/doctor/get-custom-form/${docId}`).then((res) => {
			setLoading(false);

			setState([...res.data.data]);
			console.log(res.data.data);
		});
	}, [docId]);
	const getTextField = (type, ele, idx) => {
		switch (type) {
			case "text":
				return (
					<TextField
						fullWidth
						placeholder="Short text"
						disabled={true}
						type="text"
						color="primary"
					></TextField>
				);
			case "date":
				return <TextField fullWidth disabled={true} type="date" color="primary"></TextField>;
			case "checkbox":
				return (
					<div>
						<Grid container item xs={12} spacing={2}>
							{ele?.options?.map((option, optionIndex) => (
								<Grid item xs={12}>
									<FormControlLabel disabled={true} control={<Checkbox color="primary" />} />

									<TextField
										value={option}
										InputProps={{
											endAdornment: (
												<Button
													disabled={ele.options.length === 1 ? true : false}
													onClick={() => {
														const temp = [...state];
														let options = [...temp[idx]["options"]];
														options.splice(optionIndex, 1);
														temp[idx]["options"] = [...options];
														setState([...temp]);
													}}
												>
													<CloseIcon />
												</Button>
											)
										}}
										error={option === "" ? true : false}
										onChange={(e) => {
											const temp = [...state];
											temp[idx]["options"][optionIndex] = e.target.value;
											setState([...temp]);
										}}
									></TextField>
								</Grid>
							))}

							<Grid item xs={12}>
								<Button
									color="primary"
									variant="outlined"
									onClick={() => {
										const temp = [...state];
										let options = [...temp[idx]["options"]];
										options = [...options, `Option ${options.length + 1}`];
										temp[idx]["options"] = [...options];
										setState([...temp]);
									}}
									startIcon={<AddIcon />}
								>
									Add Option
								</Button>
							</Grid>
						</Grid>
					</div>
				);
			case "radio":
				return (
					<div>
						<Grid container item xs={12} spacing={2}>
							<RadioGroup aria-label="gender" name="gender1" row>
								{ele?.options?.map((option, optionIndex) => (
									<Grid item xs={12}>
										<FormControlLabel disabled={true} control={<Radio color="primary" />} />

										<TextField
											value={option}
											InputProps={{
												endAdornment: (
													<Button
														disabled={ele.options.length === 1 ? true : false}
														onClick={() => {
															const temp = [...state];
															let options = [...temp[idx]["options"]];
															options.splice(optionIndex, 1);
															temp[idx]["options"] = [...options];
															setState([...temp]);
														}}
													>
														<CloseIcon />
													</Button>
												)
											}}
											error={option === "" ? true : false}
											onChange={(e) => {
												const temp = [...state];
												temp[idx]["options"][optionIndex] = e.target.value;
												setState([...temp]);
											}}
										></TextField>
									</Grid>
								))}
							</RadioGroup>
							<Grid item xs={12}>
								<Button
									color="primary"
									variant="outlined"
									onClick={() => {
										const temp = [...state];
										let options = [...temp[idx]["options"]];
										options = [...options, `Option ${options.length + 1}`];
										temp[idx]["options"] = [...options];
										setState([...temp]);
									}}
									startIcon={<AddIcon />}
								>
									Add Option
								</Button>
							</Grid>
						</Grid>
					</div>
				);
			default:
				return (
					<TextField
						fullWidth
						placeholder="Short text"
						disabled={true}
						type="text"
						color="primary"
					></TextField>
				);
		}
	};
	const [state, setState] = useState([]);
	return (
		<Grid container spacing={4} style={{ padding: "50px" }} justifyContent="space-between">
			<Backdrop open={loading} className={classes.backdrop}>
				<CircularProgress color="primary" />
			</Backdrop>
			<Grid item style={{ marginBottom: "20px" }}>
				<h1 align="center">Patient Questionnaire:</h1>
			</Grid>
			<Grid item>
				<Button color="primary" variant="contained" onClick={() => handleSubmit(state)}>
					Submit
				</Button>
			</Grid>

			{state.map((ele, idx) => (
				<Grid
					container
					item
					xs={12}
					spacing={4}
					style={{
						backgroundColor: "#fff",
						borderLeft: "5px solid #6C7BFF",
						borderRadius: "10px",
						marginBottom: "50px"
					}}
				>
					<Grid item xs={8}>
						<TextField
							value={ele.question}
							fullWidth
							label="Question"
							variant="outlined"
							onChange={(e) => {
								const temp = [...state];
								temp[idx]["question"] = e.target.value;
								setState([...temp]);
							}}
							type="text"
							color="primary"
						></TextField>
					</Grid>
					<Grid item xs={4}>
						<TextField
							fullWidth
							select
							value={ele.type}
							onChange={(e) => {
								const temp = [...state];
								temp[idx]["type"] = e.target.value;
								setState([...temp]);
							}}
							label="Type"
							color="primary"
							variant="outlined"
						>
							{typeOptions.map((type, index) => (
								<MenuItem key={index} value={type.value}>
									{type.label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						{getTextField(ele.type, ele, idx)}
					</Grid>

					<Grid container justify="flex-end" item xs={12} spacing={2}>
						<Grid item>
							<Button
								color="primary"
								variant="outlined"
								onClick={() => {
									const temp = [...state];

									temp.splice(idx + 1, 0, {
										question: "What is your name?",
										type: "text",
										options: ["Option 1"]
									});

									setState([...temp]);
								}}
								startIcon={<AddIcon />}
							>
								Add Question
							</Button>
						</Grid>
						<Grid item>
							<Button
								startIcon={<FileCopyIcon />}
								color="secondary"
								onClick={() => {
									const temp = [...state];
									temp.splice(idx, 0, { ...ele });
									setState([...temp]);
								}}
								variant="outlined"
							>
								Duplicate
							</Button>{" "}
						</Grid>
						<Grid item>
							<Button
								key={idx}
								startIcon={<DeleteIcon />}
								onClick={() => {
									const temp = [...state];
									temp.splice(idx, 1);
									setState([...temp]);
								}}
								disabled={state.length === 1 ? true : false}
								color="secondary"
								variant="outlined"
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Grid>
			))}
			{state?.length ? <Grid item>
				<Button color="primary" variant="contained" onClick={() => handleSubmit(state)}>
					Submit
				</Button>
			</Grid> : null}
		</Grid>
	);
}

export default CustomiseForm;
