import { colors } from '../../assets/css/colors';
import React from 'react';
import { Table, Row, Col } from 'reactstrap/lib';
import LoadingAnimation from '../../views/pages/examples/LoadingAnimation';

function StaffTable({
  columns,
  data,
  handleDelete,
  setModalData,
  setIsOpen,
  isLoading,
}) {

  return (
    <Table className='align-items-center' responsive>
      <thead className='thead-light'>
        <tr>
          {columns.map((column) => {
            return <th scope='col'>{column.name}</th>;
          })}
          <th scope='col'>Edit Profile</th>
        </tr>
      </thead>
      {isLoading && (
        <tbody>
          <tr>
            <td colSpan={6}>
              <LoadingAnimation></LoadingAnimation>
            </td>
          </tr>
        </tbody>
      )}
      <tbody>
        {!isLoading && data?.length === 0 ? (
          <tr>
            <td colSpan='6' style={{ textAlign: 'center' }}>
              <h3>No staff found.</h3>
            </td>
          </tr>
        ) : (
          !isLoading &&
          data?.map((row, index) => {
            return (
              <tr>
                {columns.map((column) => {
                  return (
                    <td>
                      {column.prepend ? column.prepend : null}
                      {row[column.id]}
                      {column.append ? column.append : null}
                    </td>
                  );
                })}
                <td>
                  <Row>
                    <Col xs={6}>
                      <i
                        onClick={() => {
                          setModalData({ ...row });
                          setIsOpen(true);
                        }}
                        className='fas fa-edit'
                        aria-hidden='true'
                        style={{
                          color: colors.primary,
                          cursor: 'pointer',
                          marginLeft: '20%'
                        }}></i>
                    </Col>
                    <Col xs={6}>
                      {' '}
                      <i
                        className='fa fa-trash-alt'
                        onClick={() => {
                          handleDelete(index);
                        }}
                        style={{
                          color: 'orangered',
                          cursor: 'pointer',
                        }}></i>
                    </Col>
                  </Row>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
}

export default StaffTable;
