/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import { Button, Form, Input, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//React Toast
import "react-toastify/dist/ReactToastify.css";
import Label from "reactstrap/lib/Label";
import { registerUser } from "../../../../redux/actions/auth.actions";
import LoadingAnimation from "../../examples/LoadingAnimation";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";

function Register({ state, setState }) {
  const genderOptions = ["Male", "Female", "Other"];

  const isLoading = useSelector((state) => state.auth.isLoading);

  const dispatch = useDispatch();
  const handleSignUp = (state) => {
    dispatch(
      registerUser({
        ...state,
        subStart: moment(),
        subEnd: moment().add("30", "days"),
      })
    );
  };

  return (
    <Row>
      <h1 className='display-2 mb-0'>Create an account</h1>
      <p>Sign up using Email address and Phone number</p>

      <Form role='form'>
        <Row>
          <Col className='col-md-12 mt-2'>
            <InputGroup>
              <InputGroupAddon type='prepend'>Dr.</InputGroupAddon>
              <Input
                className=' p-3 input-group-merge input-group-merge input-group-merge input-group-alternative '
                placeholder='Name'
                value={state.name}
                onChange={(e) => {
                  setState({
                    ...state,
                    name: e.target.value,
                  });
                }}
                type='text'
              />
            </InputGroup>
          </Col>
          {/* <Col className='col-md-12 mt-2'>
            <Input
              className=' p-3 input-group-merge input-group-merge input-group-merge input-group-alternative '
              placeholder='Phone'
              value={state.phoneNo}
              disabled
              onChange={(e) => {
                setState({
                  ...state,
                  phoneNo: e.target.value,
                });
              }}
              type='text'
            />
          </Col> */}

          <Col className='col-md-6 mt-2'>
            <Label>Gender</Label>
            <Input
              type='select'
              className='input-group-merge input-group-alternative'
              name='select'
              id='exampleSelect'
              value={state.gender}
              onChange={(e) => {
                setState({
                  ...state,
                  gender: e.target.value,
                });
              }}>
              {genderOptions.map((ele) => (
                <option style={{ textTransform: "capitalize" }}>{ele}</option>
              ))}
            </Input>
          </Col>
          <Col className='col-md-6 mt-2'>
            <Label>Date of Birth</Label>
            <Input
              className='input-group-merge input-group-alternative p-3'
              placeholder='Date of Birth'
              type='date'
              value={state.birthDate}
              onChange={(e) => {
                setState({
                  ...state,
                  birthDate: e.target.value,
                });
              }}
            />
          </Col>
          {/* <Col className='col-md-12 mt-2'>
            <Input
              className='input-group-merge input-group-alternative p-3'
              placeholder='Email'
              type='email'
              disabled
              value={state.email}
              onChange={(e) => {
                setState({
                  ...state,
                  email: e.target.value,
                });
              }}
            />
          </Col> */}
          <Col className='col-md-12 mt-2'>
            <Input
              placeholder='Password'
              className='input-group-merge input-group-alternative p-3'
              type='password'
              value={state.password}
              onChange={(e) => {
                setState({
                  ...state,
                  password: e.target.value,
                });
              }}
            />
          </Col>
        </Row>

        <div className='text-center mt-2 mb-2'>
          {isLoading && <LoadingAnimation></LoadingAnimation>}
          {!isLoading && (
            <Button
              className='my-4 p-3'
              color='primary'
              type='button'
              block
              onClick={() => handleSignUp(state)}>
              Create account
            </Button>
          )}
        </div>
      </Form>
    </Row>
  );
}

export default Register;
