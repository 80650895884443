import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Avatar,
  styled,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { colors } from "../../../assets/css/colors"
import dashboardSocket from "../../../socket/dashboardSocket"
import { getRandomColor } from "../../helper"
import moment from "moment"
import ChatWindow from "../../../components/ChatDashboard/ChatWindow"
import "./index.css"
import ChatSidebar from "../../../components/ChatDashboard/ChatSidebar"
import ChatDefaultScreen from "../../../components/ChatDashboard/ChatDefaultScreen"
import LoaderFullpage from "../../../components/Common/LoaderFullpage"
import AddPatientModal from "../dashboards/AddPatientModal"
import AddAppointmentDialog from "../../../components/Dashboard/Home/AddAppointmentDialog"
import customAxios from "../../../axios"
import ChatDrawer from "../../../components/ChatDashboard/ChatDrawer"
import whatsappBg from "./../../../assets/img/chatWindow/whatsappBg.png"


const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: 300,
  // Enable scrolling
  overflowY: 'scroll',
  // Hide the scrollbar
  // scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 1, // Adjust to desired thickness
    backgroundColor: "transparent",
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'black', // Adjust to desired color
    position: "absolute"
  },
});



function ChatDashboard() {
  const docId = useSelector((state) => state.user.user._id)
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [messageLoading, setMessageLoading] = useState(false)
  const [patient, setPatient] = useState(null)

  const [openDrawer, setOpenDrawer] = useState(false)

  const [newChatModalOpen, setNewChatModalOpen] = useState(false)
  const [appointmentData, setAppointmentData] = useState(null)
  const getAppointmentDetails = (id) => {
    setLoading(true)
    customAxios.get(`/appointments/${id}`).then((res) => {
      if (res.data.success) {
        setLoading(false)

        setAppointmentData(res.data.data)
        setPatient(res.data.data.patient)
      }
    })
  }
  useEffect(() => {
    if (location?.state?.appointmentId) {
      getAppointmentDetails(location?.state?.appointmentId)
    }

    if(location?.state?.patient)
      setPatient({...location?.state?.patient})

  }, [location])
  const [isConsultation, setIsConsultation] = useState(false)
  useEffect(() => {
    if (
      location?.state?.appointmentId &&
      appointmentData?.patient?._id === patient?._id
    )
      setIsConsultation(true)
    else setIsConsultation(false)
  }, [patient, appointmentData])

  const intializeSocketUser = (docId, isDoctor) => {
    dashboardSocket.emit("initialize_user", {
      userId: docId,
      from: "web",
      userType: isDoctor ? "doctor" : "staff",
    })
  }

  useEffect(() => {
    intializeSocketUser(docId, isDoctor)
  }, [docId, patient])

  const [modalData, setModalData] = useState({ gender: "male" })
  const [open, setOpen] = useState(false)
  return (
    <div style={{}} className="chatDashboard">
      <Grid container>
        {/* <LoaderFullpage loading={messageLoading ? messageLoading : loading} />   */}
        <Grid
          item
          xs={12}
          md={3}
          container
          style={{
            height: "88vh",
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "10px",
            overflowY: "auto",
            width: "22vh"
          }}
        >
          <Grid item xs={12} container>
            <ChatSidebar
              isConsultation={isConsultation}
              appointmentData={appointmentData}
              setLoading={setLoading}
              setPatient={setPatient}
              isDoctor={isDoctor}
              newChatModalOpen={newChatModalOpen}
              setNewChatModalOpen={setNewChatModalOpen}
              patient={patient}
              docId={docId}
              intializeSocketUser={intializeSocketUser}
            />
          </Grid>
        </Grid>
        {open ? (
          <AddAppointmentDialog
            defaultTabValue={1}
            open={open}
            setOpen={setOpen}
            modalData={modalData}
            setModalData={setModalData}
          />
        ) : null}
        <CustomGrid
          item
          xs={12}
          md={openDrawer ? 6 : 9}
          container
          style={{
            minHeight: "88vh",
            height: "88vh",
            position: "relative",
            // backgroundColor: "transparent",
            borderRadius: "1px",
            width: "100%",
            overflowY: "auto",

            backgroundImage: `url(${whatsappBg})`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          }}
        >
          <Grid item xs={12} container>
            {patient?._id ? (
              <ChatWindow
                key={patient._id}
                setMessageLoading={setMessageLoading}
                messageLoading={messageLoading}
                appointmentData={appointmentData}
                docId={docId}
                isConsultation={isConsultation}
                setModalData={setModalData}
                setOpen={setOpen}
                patient={patient}
                isDoctor={isDoctor}
                setOpenDrawer={setOpenDrawer}
                openDrawer={openDrawer}
              />
            ) : (
              <ChatDefaultScreen
                newChatModalOpen={newChatModalOpen}
                setNewChatModalOpen={setNewChatModalOpen}
              />
            )}
          </Grid>
        </CustomGrid>
        {patient?._id && openDrawer &&
          <Grid
          item
          xs={12}
          md={3}
          style={{maxHeight: "88vh", overflowY: "hidden", overflowX: "hidden"}}
          container
        >
            <ChatDrawer
              key={patient._id}
              setLoading={setLoading}
              userId={patient?._id}
              setModalData={setModalData}
              setOpen={setOpen}
              patient={patient}
              setPatient={setPatient}
              setOpenDrawer={setOpenDrawer}
              openDrawer={openDrawer}
              isDoctor={isDoctor}
              />
        </Grid>}
      </Grid>
    </div>
  )
}

export default ChatDashboard
