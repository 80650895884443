import React, { useState, useEffect } from "react";
import GenericCardTable from "../GenericCardTable";
import { Typography, Grid, Button } from "@material-ui/core";
import customAxios from "../../../axios";
import { useSelector } from "react-redux";

function Exercises({ state, setState }) {
  const cardTableHeaders = [
    {
      label: "Name",
      key: "name",
      placeholder: "eg. Running",
      type: "text",

      required: true,
      width: "25%",
    },
    {
      label: "Frequency",
      key: "frequence",
      placeholder: "None",
      type: "select",
      width: "15%",
      onChange: (value, state, setState) => {
        var reg = new RegExp("^[0-9-]*$");
        if (reg.test(value)) {
          console.log("90", value);
          const temp = { ...state };
          temp["frequence"] = value;
          setState({ ...temp });
        }
      },
      required: true,
      freeSolo: true,
      dependencyKey: "name",
      options: ["1-1-1", "1-0-1", "1-1-0", "0-1-1", "1-0-0", "0-1-0", "0-0-1"],
    },
    {
      label: "Duration",
      key: "duration",
      placeholder: "None",
      type: "text",
      width: "15%",
      dependencyKey: "name",
    },
    {
      label: "No. of Reps",
      key: "numberOfReps",
      placeholder: "None",
      type: "text",
      width: "15%",
      dependencyKey: "name",
    },
    {
      label: "No. of Sets",
      key: "numberOfSets",
      placeholder: "None",
      type: "text",
      width: "15%",
      dependencyKey: "name",
      freeSolo: true,
      options: ["Before Meal", "After Meal"],
    },
  ];

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Exercise:"}
        suggestedTab={false}
        tableHeaders={cardTableHeaders}
        tableData={state}
        cardKey="exercises"
        setTableData={setState}
      />
    </React.Fragment>
  );
}

export default Exercises;
