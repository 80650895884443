/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react"
import ReactGA from "react-ga4"
// react library for routing
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom"
// bootstrap rtl for rtl support page
import "./assets/vendor/bootstrap-rtl/bootstrap-rtl.scss"
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css"
import "react-perfect-scrollbar/dist/css/styles.css"
// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css"
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css"
import "./assets/vendor/select2/dist/css/select2.min.css"
import "./assets/vendor/quill/dist/quill.core.css"
import "react-toastify/dist/ReactToastify.css"

import "./assets/vendor/nucleo/css/nucleo.css"
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss"
import mixpanel from "mixpanel-browser"
import AdminLayout from "./layouts/Admin.js"
import AuthLayout from "./layouts/Auth.js"

import MeetLayout from "./layouts/Meet"
import { toast, ToastContainer } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import PendingVerification from "./components/PendingVerification"
import dashboardSocket from "./socket/dashboardSocket"
// import ProductTour from "./components/ProductTour/index";
import { Button } from "reactstrap"
import { logout } from "./redux/actions/auth.actions"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core"
import GenericDialogForm from "./components/CreatePrescription/GenericDialog"
import notify from "./assets/audios/iphone_sound.mp3"
import CustomSnackbar from "./components/Dashboard/CustomizeSnackbar"
import RouteChat, { RouteToPatient } from "./views/pages/Consultation/route-rx"
// import IndexView from "views/Index.js";
//import User from "views/pages/user/User";

function usePageViews(prevLocation, setprevLocation) {
  let location = useLocation()

  useEffect(() => {
    ReactGA.initialize("G-CDV3NDWRN4")
    ReactGA.pageview(window.location.pathName)
    // ReactGA.set({page:location.pathname})
    // ReactGA.event({
    //   category: location.pathname,
    //   action: location.pathname,
    //   label: location.pathname+"HELLO"
    //   });
    // ReactGA.send({ hitType: location.pathname, page: location.pathname });
    //eslint-disable-next-line
  }, [location])
}

function App() {
  const [run, setRun] = useState(false)
  mixpanel.init("fffa94ab913677a6f8412f0eb5d3fddf", {
    ignore_dnt: true,
  })
  const history = useHistory()
  let location = useLocation()

  const dispatch = useDispatch()
  const userId = useSelector((state) => state.user.user._id)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  if (userId) {
    mixpanel.identify(userId)
  }
  //Hello this is a change

  const online = window.navigator.onLine
  if (!online) {
    toast.error(
      "Offline. Please check your internet connection and reload the application"
    )
  }
  const [prevLocation, setprevLocation] = useState(null)
  usePageViews(prevLocation, setprevLocation)

  const docId = useSelector((state) => state.user.user._id)
  const isDoctor = useSelector((state) => state.auth.isDoctor)

  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const textComponent = useRef(null)
  const chatPatient = useRef(null)

  const intializeSocketUser = (docId, isDoctor) => {
    dashboardSocket.emit("initialize_user", {
      userId: docId,
      from: "web",
      userType: isDoctor ? "doctor" : "staff",
    });

    dashboardSocket.on("get_messages", (args) => {  
      if (args.check === "new_message") {
        playAudio();

        let argData = args?.data?.[0];
        textComponent.current = (
          <><Typography variant="subtitle1" style={{fontWeight: "bold"}}>
              {argData?.patient?.name} {argData?.contentType === "text" ? ":" : " sent a"}
          </Typography>
          <Typography variant="subtitle1" style={{marginLeft: "10px", maxWidth: "10rem", overflowX: "hidden", maxHeight: "4rem", overflowY: "ellipsis", textOverflow: "ellipsis"}}>
              {getLatestChatMessage(argData)}
          </Typography></>
        );
        chatPatient.current = {
          ...argData?.patient,
          "_id": argData?.from
        }
        setSnackbarOpen(true)

      }});

  }

  const getLatestChatMessage = (chat) => {
    const contentType = chat?.["contentType"]
    switch (contentType) {
      case "patient_interact":
        return <>Patient has interacted with your WhatsApp Clinic.</>
      case "text":
        if (typeof chat?.content === "object")
          return (
            <>
              Unsupported Document{" "}
              <i
                class="fas fa-exclamation-circle"
                style={{ color: "red", marginLeft: "0.2rem", fontSize: "16px" }}
              ></i>{" "}
            </>
          )
        return (
          <>
            {/* {chat?.content?.toString().length > 15
              ? chat?.content.substring(0, 15) + "..." */}
              {/* : chat["content"]} */}
              {chat["content"]}
          </>
        )

      case "wa_link":
        return (
          <>
            <i class="fas fa-link" style={{ marginRight: "0.4rem" }}></i>{" "}
            Booking Link Sent
          </>
        )

      default:
        const mimeType = contentType.split("/")[0]
        switch (mimeType) {
          case "image":
            return (
              <>
                <i class="fas fa-image" style={{ marginRight: "0.4rem" }}></i>{" "}
                Photo
              </>
            )
          case "video":
            return (
              <>
                <i class="fas fa-video" style={{ marginRight: "0.4rem" }}></i>{" "}
                Video
              </>
            )
          case "invoice":
            return (
              <>
                <i
                  class={
                    chat.content.status !== "paid"
                      ? "fas fa-receipt"
                      : "fas fa-check-circle"
                  }
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Payment{" "}
                {chat.content.status !== "paid" ? "Requested" : "Received"}
              </>
            )

          case "audio":
            return (
              <>
                <i
                  class="fas fa-microphone"
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Audio
              </>
            )

          default:
            return (
              <>
                <i
                  class="fas fa-file-alt"
                  style={{ marginRight: "0.4rem" }}
                ></i>{" "}
                Document
              </>
            )
        }
    }
  }

  const playAudio =() => {
    const audio = new Audio(notify)
    audio.autoplay = true;
    const promise = audio.play()
    if (promise !== undefined) {
      // On older browsers play() does not return anything, so the value would be undefined.
      promise
        .then(() => {
          // Audio is playing.
        })
        .catch((error) => {
          console.log(error, 'DOM ERROR')
        })
    }
  }

  useEffect(() => {
    intializeSocketUser(docId, isDoctor);
  }, [docId, location])

  return (
    <>
    <div id="interact">
      <Switch>
      <Route exact path="/admin/route-rx/:id" render={(props) => <RouteChat {...props}/>} />
      <Route exact path="/admin/route-patients/:id" render={(props) => <RouteToPatient {...props}/>} />
        <Route
          path="/admin"
          render={(props) => <AdminLayout {...props} setRun={setRun} />}
        />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route
          path="/verificationPending"
          render={(props) => <PendingVerification />}
        />
        <Route path="/meet" render={(props) => <MeetLayout {...props} />} />

        {/* <Route path='/' render={(props) => <IndexView {...props} />} /> */}
        <Redirect from="/" to="/admin/dashboard" />
        <Redirect from="*" to="/admin/dashboard" />
      </Switch>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        limit={1}
        pauseOnFocusLoss={false}
        hideProgressBar={true}
      />
      <CustomSnackbar isSnackbarOpen={isSnackbarOpen} setSnackbarOpen={setSnackbarOpen} textComponent={textComponent} onButtonClick={() => {
        history.push(`/admin/chat`, { patient: chatPatient?.current })
        }}/>
      </div>
    </>
  )
}

export default App
