import React, { useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, makeStyles, IconButton, InputAdornment, Grid, Typography, Select, MenuItem } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import customAxios from '../../../axios';
import CustomAutocomplete from './CustomAutocomplete';

const useStyles = makeStyles({
  textField: {
    '& .MuiOutlinedInput-root': {
    //   border: 'none',
    //   padding: 0,
    //   '& input': {
    //     padding: '12px 12px',
    //   },
    },
  },
  table: {
    // border: '1px solid #000',
    // '& th, & td': {
    //   border: '1px solid #000',
    //   padding: 8,
    // },
  },
});

const filter = createFilterOptions();

const BillingTable = ({rows, setRows, appointmentAmount, docId}) => {
  const classes = useStyles();
  const regex = /^\d+$/;

  const defaultServiceOptions = [
    {"name": 'Consultation', "amount": appointmentAmount},
    {"name": 'Service', "amount": 100},
    {"name": 'X-Ray', "amount": 200}]

  const [serviceOptions, setServiceOptions] = useState([...defaultServiceOptions]); // Replace with your service options

  useEffect(() => {
    customAxios.get(`/doctor/billing-item?docId=${docId}`)?.then(res => {
      if(res?.data?.success){
        let serviceOptions = [...res?.data?.data];

        setServiceOptions([...serviceOptions, ...defaultServiceOptions.filter(f => !serviceOptions?.some(v => v?.name === f?.name))])
      }
    })
  }, [])

  const handleChange = (e, index, column) => {
    const updatedRows = [...rows];
    updatedRows[index][column] = e.target.value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { serviceName: '', amount: 0, quantity: 1, discount: 0, total: '' }]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleAddNewService = (value) => {
    if (value && !serviceOptions.includes(value)) {
      const updatedOptions = [...serviceOptions];
      updatedOptions.push({"name": value, "amount": 0});
      setServiceOptions(updatedOptions);

      customAxios.put(`/doctor/billing-item?docId=${docId}`, {"name": value, "amount": 0})?.then(res => {
        if(res?.data?.success)
          console.log("ADDED LINE ITEM")
      })
    }
  };

  const textFieldsRefs = useRef([]);

  function handleKeyDown(event, index, fieldType = null, rowIndex) {
    if ((event?.key === "Enter")) {
      if(event?.target?.value && !serviceOptions?.includes(event?.target?.value?.trim()) && fieldType === 'autocomplete') {
        handleAddNewService(event?.target?.value)
      }
      if (index < textFieldsRefs.current?.[rowIndex]?.length - 1) {
        if(!fieldType)
          textFieldsRefs.current?.[rowIndex][index + 1].focus();
        else{
          textFieldsRefs.current?.[rowIndex][2].focus();
        }
      }

      else {
        if (rows?.[rowIndex+1])
        textFieldsRefs.current?.[rowIndex+1]?.[0].focus();
        else{
          handleAddRow()
        }
        
      }
    }
  }

  return (
    <React.Fragment>
      {/* <CustomAutocombplete /> */}
      <TableContainer style={{marginTop: '10px'}}>
        <Table className={classes.table}>
          <TableHead style={{backgroundColor: "#EBF5FB"}}>
            <TableRow>
              <TableCell style={{width: "30%"}}>Service</TableCell>
              <TableCell style={{width: "10%"}}>Quantity</TableCell>
              <TableCell style={{width: "15%"}}>Amount</TableCell>
              <TableCell style={{width: "15%"}}>Discount</TableCell>
              <TableCell style={{width: "15%"}}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody> 
            {rows.map((row, index) => (
                <>
              <TableRow key={index}>
                <TableCell style={{paddingTop: 5, paddingBottom: 5}}>
                    {/* <Autocomplete
                        value={row.serviceName}
                        onChange={(e, newValue) => handleChange({ target: { value: newValue } }, index, 'serviceName')}
                        options={serviceOptions}
                        renderInput={(params) => {
                            const isCreateOption = params.inputProps.value.startsWith('Create "');
                            const inputValue = isCreateOption ? params.inputProps.value.slice(8, -1) : params.inputProps.value;
                            return (
                                <TextField
                                    {...params}
                                    className={classes.textField}
                                    variant="outlined"
                                    fullWidth
                                    onBlur={(e) => handleChange(e, index, 'serviceName')}
                                    value={inputValue}
                                    placeholder={isCreateOption ? inputValue : ''}
                                />
                            )
                        }}
                        onInputChange={(e, newInputValue) => handleAddNewService(newInputValue)}
                    /> */}
                    <Autocomplete
                        value={row?.serviceName}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                handleChange({ target: { value: newValue } }, index, 'serviceName')
                            } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                                handleChange({ target: { value: newValue.inputValue } }, index, 'serviceName')
                                handleAddNewService(newValue.inputValue)
                            } else {
                                handleChange({ target: { value: newValue?.name } }, index, 'serviceName')
                                handleChange({ target: { value: newValue?.amount } }, index, 'amount')
                            }

                            if(newValue)
                              textFieldsRefs?.current?.[index]?.[2]?.focus()
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                title: `Add "${params.inputValue}"`,
                            });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        openOnFocus={!row?.serviceName}
                        options={serviceOptions}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                            return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                            return option.inputValue;
                            }
                            // Regular option
                            return option?.name;
                        }}
                        renderOption={(option) => {
                            if (typeof option === 'string') {
                            return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                            return option.title;
                            }
                            // Regular option
                            return (<Grid container justifyContent='space-between'>
                              <Grid item>
                                {option?.name}
                              </Grid>
                              {option?.amount ? <Grid item>
                                Rs. {option?.amount}
                              </Grid> : null}
                            </Grid>);
                        }}
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" size={"small"}  autoFocus={true} placeholder="Start Typing To Add A Service..."
                              inputRef={(ref) => {
                                if(textFieldsRefs.current?.[index]?.length)
                                  textFieldsRefs.current[index][0] = ref
                                else {
                                  textFieldsRefs.current[index] = []
                                  textFieldsRefs.current[index][0] = ref
                                }
                              }}

                              onKeyDown={(e) => {
                                handleKeyDown(e, 0, "autocomplete", index)
                              }}
                            />
                        )}
                        />
                </TableCell>
                <TableCell style={{paddingTop: 5, paddingBottom: 5}}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    size={"small"}
                    type="number"
                    value={row.quantity}
                    onChange={(e) => {
                      if(regex.test(e?.target?.value) || !e?.target?.value)
                      handleChange(e, index, 'quantity')
                    }}
                    fullWidth
                    inputRef={(ref) => {
                      if(textFieldsRefs.current?.[index]?.length)
                        textFieldsRefs.current[index][1] = ref
                      else {
                        textFieldsRefs.current[index] = []
                        textFieldsRefs.current[index][1] = ref
                      }
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e, 1, null, index)
                    }}
                  />
                </TableCell>
                <TableCell style={{paddingTop: 5, paddingBottom: 5}}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    size={"small"}
                    type="number"
                    value={row.amount}
                    InputProps={{
                        startAdornment: <InputAdornment>₹</InputAdornment>
                    }}
                    onChange={(e) => {
                      if(regex.test(e?.target?.value) || !e?.target?.value)
                      handleChange(e, index, 'amount')
                    }}
                    fullWidth
                    inputRef={(ref) => {
                      if(textFieldsRefs.current?.[index]?.length)
                        textFieldsRefs.current[index][2] = ref
                      else {
                        textFieldsRefs.current[index] = []
                        textFieldsRefs.current[index][2] = ref
                      }
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e, 2, null, index)
                    }}
                  />
                </TableCell>
                <TableCell style={{paddingTop: 5, paddingBottom: 5}}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    size={"small"}
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment>₹</InputAdornment>
                    }}
                    value={row.discount}
                    onChange={(e) => {
                      if((regex.test(e?.target?.value) || !e?.target?.value)){

                        if(Number(e?.target?.value) <= Number(row?.amount))
                        handleChange(e, index, 'discount')
                      }
                    }}
                    fullWidth
                    inputRef={(ref) => {
                      if(textFieldsRefs.current?.[index]?.length)
                        textFieldsRefs.current[index][3] = ref
                      else {
                        textFieldsRefs.current[index] = []
                        textFieldsRefs.current[index][3] = ref
                      }
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e, 3, null, index)
                    }}
                  />
                </TableCell>
                <TableCell style={{paddingTop: 5, paddingBottom: 5}}>
                    ₹ {((Number(row?.amount)*Number(row?.quantity)) - Number(row?.discount))}
                    {rows.length > 1 && (
                        <IconButton variant="outlined" onClick={() => handleDeleteRow(index)}>
                        <Delete />
                        </IconButton>
                    )}
                </TableCell>
              </TableRow>
                </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="text" color='primary' onClick={handleAddRow} style={{marginBottom: "60px"}}>
        + Add Another
      </Button>
    </React.Fragment>
  );
};

export default BillingTable;


export const PaymentComponent = ({rows, newPaymentMode, setNewPaymentMode, additionalDiscount, setAdditionalDiscount}) => {
  const [lineItemDiscount, setLineItemDiscount] = useState(0);
  const [paymentModes, setPaymentModes] = useState([]);
  const [newPaymentAmount, setNewPaymentAmount] = useState(0);

  const handleAdditionalDiscountChange = (event) => {
    if(Number(event?.target?.value) <= (calculateTotalAmount()-Number(lineItemDiscount)))
    setAdditionalDiscount(event.target.value);
  };

  const handlePaymentModeChange = (event) => {
    setNewPaymentMode(event.target.value);
  };

  const handlePaymentAmountChange = (event) => {
    setNewPaymentAmount(event.target.value);
  };

  const handleAddPaymentMode = () => {
    if (newPaymentMode && newPaymentAmount > 0) {
      const paymentMode = {
        mode: newPaymentMode,
        amount: newPaymentAmount,
      };
      setPaymentModes([...paymentModes, paymentMode]);
      setNewPaymentMode('');
      setNewPaymentAmount(0);
    }
  };

  const handleRemovePaymentMode = (index) => {
    const updatedPaymentModes = paymentModes.filter((_, i) => i !== index);
    setPaymentModes(updatedPaymentModes);
  };

  const calculateTotalAmount = () => {
    // Calculate the total amount based on your logic
    // Example: Here, we are summing all the payment amounts
    const paymentAmounts = rows?.map((mode) => Number(mode?.amount)*Number(mode?.quantity));
    const totalAmount = paymentAmounts?.reduce((sum, amount) => sum + amount, 0);
    return totalAmount;
  };

  const calculateGrandTotal = () => {
    const totalAmount = calculateTotalAmount();
    const grandTotal = totalAmount - additionalDiscount - lineItemDiscount;
    return grandTotal;
  };

  const [addOnPaymode, setAddOnPaymode] = useState(false)

  useEffect(() => {
    let totalLineItemDiscount = 0
    rows?.map(row => {
      if(Number(row?.discount)){
        totalLineItemDiscount += Number(row.discount);
      }
    });

    setLineItemDiscount(totalLineItemDiscount);
  }, [rows])

  return (
    <Grid container spacing={2} style={{backgroundColor: "#EBEDEF", padding: 5}}>
      <Grid item container xs={8} spacing={1}>
        <Grid item container spacing={1} xs={12} alignItems="center">
          <Grid item xs={2}>
            <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Additional Discount:</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              type="number"
              value={additionalDiscount}
              onChange={handleAdditionalDiscountChange}
              variant="outlined" size="small"
              InputProps={{
                startAdornment: <InputAdornment>₹</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={12} alignItems="center">
          <Grid item xs={2}>
            <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Paymode:</Typography>
          </Grid>
          <Grid item container xs={9} alignItems="center" spacing={1}>
            <Grid item>
              <TextField
                  fullWidth
                  select
                  variant="outlined"
                  size="small"
                  value={newPaymentMode}
                  onChange={(e) => setNewPaymentMode(e?.target?.value)}
                >
                  <MenuItem key={"#cash"} value={"Cash"}>
                    Cash
                  </MenuItem>
                  <MenuItem key={"#credit-card"} value={"Credit Card"}>
                    Credit Card
                  </MenuItem>
                  <MenuItem key={"#debit-card"} value={"Debit Card"}>
                    Debit Card
                  </MenuItem>
                  <MenuItem key={"#paytm"} value={"Paytm"}>
                    Paytm
                  </MenuItem>
                  <MenuItem key={"#gpay"} value={"GPay"}>
                    GPay
                  </MenuItem>
                  <MenuItem key={"#upi"} value={"UPI"}>
                    UPI
                  </MenuItem>
                  <MenuItem key={"#online"} value={"Online"}>
                    Online (Paperplane)
                  </MenuItem>
                  <MenuItem key={"#other"} value={"Other"}>
                    Other
                  </MenuItem>
                </TextField>
            </Grid>
            {/* <Grid item>
              <TextField
                type="number"
                value={newPaymentAmount}
                onChange={handlePaymentAmountChange}
                variant="outlined" size="small"
              />
            </Grid>
            <Grid item>
              {addOnPaymode ? null : <IconButton onClick={() => setAddOnPaymode(true)}>
                <Add />
              </IconButton>}
            </Grid> */}
          </Grid>
          {addOnPaymode ? <><Grid item xs={2}></Grid>
          <Grid item container xs={9} alignItems="center" spacing={1}>
            <Grid item>
              <TextField
                  select
                  variant="outlined"
                  size="small"
                >
                  <MenuItem key={"#card"} value={"Card"}>
                    Card
                  </MenuItem>
                  <MenuItem key={"#online"} value={"Online"}>
                    Online
                  </MenuItem>
                </TextField>
            </Grid>
            <Grid item>
              <TextField
                type="number"
                value={newPaymentAmount}
                onChange={handlePaymentAmountChange}
                variant="outlined" size="small"
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => setAddOnPaymode(false)}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid></> : null}
        </Grid>

        {addOnPaymode ? <Grid item container spacing={1} xs={12} alignItems="center">
          <Grid item xs={2}>
            {/* <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Paymode:</Typography> */}
          </Grid>
          
        </Grid> : null}

        {/* <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Payment Modes:</Typography> */}
        {/* <ul>
          {paymentModes.map((mode, index) => (
            <li key={index}>
              {mode.mode}: {mode.amount}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleRemovePaymentMode(index)}
              >
                Remove
              </Button>
            </li>
          ))}
        </ul> */}
      </Grid>
      <Grid item container xs={4}>
        <Grid item>
          <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Total Amount: </Typography>
        </Grid>
        <Grid item style={{marginLeft: "auto"}}>
          <Typography variant="subtitle1" style={{fontWeight: "bold"}}>₹ {calculateTotalAmount()}</Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item>
          <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Line Item Discount: </Typography>
        </Grid>
        <Grid item style={{marginLeft: "auto"}}>
          <Typography variant="subtitle1" style={{fontWeight: "bold"}}>₹ {lineItemDiscount}</Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item>
          <Typography variant="subtitle1" style={{fontWeight: "bold"}}>Additional Discount: </Typography>
        </Grid>
        <Grid item style={{marginLeft: "auto"}}>
          <Typography variant="subtitle1" style={{fontWeight: "bold"}}>₹ {additionalDiscount}</Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item>
          <Typography variant="subtitle1" style={{fontWeight: "bold", "color": "green"}}>Grand Total: </Typography>
        </Grid>
        <Grid item style={{marginLeft: "auto"}}>
          <Typography variant="subtitle1" style={{fontWeight: "bold", "color": "green"}}>₹ {calculateGrandTotal()}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
