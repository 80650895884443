import React, { useEffect, useState } from "react";
import { Button } from "reactstrap/lib";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";

import customAxios from "../../axios";
import moment from "moment";
import GenericModal from "./GenericModal";
import PreviousVitals from "./PreviousVitals";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Avatar, TextField, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import GenericDialogForm from "../CreatePrescription/GenericDialog";

function AddVitals({ id, setLoading }) {

  const suggestedVitals1 = [
    {
      metricName: "Pulse Rate",
      metricUnit: "/min",
      icon: <i class="fas fa-heart"></i>
    },
    {
      metricName: "Peripheral oxygen saturation",
      metricUnit: "%",
      icon: <i class="fas fa-lungs"></i>
    },
    {
      metricName: "Blood pressure",
      metricUnit: "mmHg",
      icon: <i class="fas fa-tint"></i>
    },
    {
      metricName: "Respiratory rate",
      metricUnit: "/min",
      icon: <i class="fas fa-lungs"></i>
    },
    {
      metricName: "Body temperature",
      metricUnit: "C",
      icon: <i class="fas fa-thermometer"></i>
    },
    {
      metricName: "Body height",
      metricUnit: "Cms",
      icon: <i class="fas fa-arrows-v"></i>
    },
    {
      metricName: "Body weight",
      metricUnit: "Kgs",
      icon: <i class="fas fa-weight"></i>
    },
    {
      metricName: "Body mass index",
      metricUnit: "kg/m2",
      icon: <i class="fas fa-divide"></i>
    },
    {
      metricName: "Systolic blood pressure",
      metricUnit: "mmHg",
      icon: <i class="fas fa-tint"></i>
    },
    {
      metricName: "Diastolic blood pressure",
      metricUnit: "mmHg",
      icon: <i class="fas fa-tint"></i>
    },
  ]

  const [dummyCounter, setDummyCounter] = useState(0);
  const handleSubmit = (vital) => {
    const temp = [...state];
    temp.push({...vital});
    setState([...temp]);
    setModalData({});
    setIsOpen(false);

    setDialogContent([
      {
        label: "Label",
        key: "metricName",
        placeholder: "eg. Respiration",
        md: 12,
        type: "select-new",
        options: [...suggestedVitals1?.filter(v => temp ? !temp?.find(f => f?.metricName === v?.metricName) : true)]?.map(v => v?.metricName),
        freeSolo: true,
        required: true,
        focus: true,
        onChange: (value, state, setState, isSelected) => {
          let tempState = {...state}
          tempState['metricName'] = value
          if(isSelected){
            let vital = suggestedVitals1?.find(f => f?.metricName === value)
            if(vital){
              tempState['metricUnit'] = vital?.metricUnit
            }
          }
          setState({...tempState})
        }
      },
      ...defaultDialogContent.slice(1, 3)
    ])
  };

  const doctorId = useSelector((state) => state?.user?.user?._id);
  const [defaultState, setDefaultState] = useState([])

  useEffect(() => {
    setLoading(false)
    customAxios.get(`/doctor/get-custom-vitals/${doctorId}`).then((res) => {
      if (res.data.success) {
        setDefaultState([...res.data.data])
        setState([...res.data.data])

        const temp = [...res?.data?.data];

        setDialogContent([
          {
            label: "Label",
            key: "metricName",
            placeholder: "eg. Respiration",
            md: 12,
            type: "select-new",
            options: [...suggestedVitals1?.filter(v => temp ? !temp?.find(f => f?.metricName === v?.metricName) : true)]?.map(v => v?.metricName),
            freeSolo: true,
            required: true,
            focus: true,
            onChange: (value, state, setState, isSelected) => {
              let tempState = {...state}
              tempState['metricName'] = value
              if(isSelected){
                let vital = suggestedVitals1?.find(f => f?.metricName === value)
                if(vital){
                  tempState['metricUnit'] = vital?.metricUnit
                }
              }
              setState({...tempState})
            }
          },
          ...defaultDialogContent.slice(1, 3)
        ])
      }
    });
    // eslint-disable-next-line
  }, []);
  const [state, setState] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  // const modalHeaders = [
  //   {
  //     label: "Label",
  //     key: "metricName",
  //     placeholder: "eg. Pulse Rate",
  //     limit: 30,
  //   },
  //   { label: "Value", key: "metricQuantity", placeholder: "eg. 72" },
  //   { label: "Unit", key: "metricUnit", placeholder: "eg. /min", limit: 10 },
  // ];

  const defaultDialogContent = [
    {
      label: "Label",
      key: "metricName",
      placeholder: "eg. Respiration",
      md: 12,
      type: "select-new",
      options: [...suggestedVitals1?.filter(v => state ? !state?.find(f => f?.metricName === v?.metricName) : true)]?.map(v => v?.metricName),
      freeSolo: true,
      required: true,
      focus: true,
      onChange: (value, state, setState, isSelected) => {
        let tempState = {...state}
        tempState['metricName'] = value
        if(isSelected){
          let vital = suggestedVitals1?.find(f => f?.metricName === value)
          if(vital){
            tempState['metricUnit'] = vital?.metricUnit
          }
        }
        setState({...tempState})
      }
    },
    {
      label: "Unit",
      key: "metricUnit",
      placeholder: "eg. BPM",
      md: 12,
      type: "text",
      required: true,
    },
    {
      label: "Value",
      key: "metricQuantity",
      placeholder: "eg. 78",
      type: "text",
      fullWidth: true,
      md: 12,
    },
  ]

  const [dialogContent, setDialogContent] = useState([...defaultDialogContent])

  const saveVitals = (state) => {
    const temp = [...state];
    const finalArr = temp.filter((ele) => ele?.metricQuantity);

    if (finalArr.length) {
      const temp = [...state];
      const finalArr = temp.filter((ele) => ele?.metricQuantity)?.map(v => {
        return {
          metricQuantity: v?.metricQuantity,
          metricName: v?.metricName,
          metricUnit: v?.metricUnit,
        }
      });

      const objectToSend = {};
      const objInViatls = {
        date: moment.now(),
        data: [...finalArr],
      };
      objectToSend.vitals = [];
      objectToSend.vitals.push(objInViatls);

      customAxios
        .put(`/vital/${id}`, objectToSend)
        .then((res) => {
          if (res.data.success) {
            toast.success("Vitals added successfully");
            setState([...defaultState])
            setDummyCounter(Math.random());
          }
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Please add vitals before saving");
    }
  };

  const getIcon = (data) => {
    let vital = suggestedVitals1?.find(f => f?.metricName?.toLowerCase()?.includes(data?.metricName?.toLowerCase()))
    if (vital){
      return vital?.icon
    } else  <i class="fas fa-vial"></i>
  }


  return (
    <Row className="p-3 " style={{ marginRight: "0px" }}>
      <div className="row col-md-12 m-1">
        <Typography style={{fontWeight: "600", fontSize: "16px"}}>Add Vitals</Typography>
      </div>
      <div className="row col-md-12 m-1">
        {state?.map((ele, index) => {
          return (
            <div
              className=" row col-md-4 p-1"
              style={{ alignItems: "center", marginRight: 10 }}
              key={index}
            >
              <Label className="col-md-6" style={{display: "flex", alignItems: "center"}}>
                <Avatar style={{
                    fontSize: 12,
                    width: 30,
                    height: 30, 
                    marginRight: 10, 
                    color: "grey", 
                    backgroundColor: "whitesmoke",
                  }}>{getIcon(ele)}</Avatar>
                <Typography>
                  {ele.metricName}
                </Typography>
              </Label>

              <InputGroup className="mb-4 col-md-6">
                <TextField
                    variant="outlined"
                    fullWidth
                    // size="small"
                    // inputRef={(ref) => (textFieldsRefs.current[i] = ref)}
                    // onKeyDown={(e) => handleKeyDown(e, i)}
                    // autoFocus={v?.focus || i === 0}
                    InputProps={{
                      endAdornment: (
                        <>
                          <Typography color="secondary">
                            {ele?.metricUnit}
                          </Typography>
                          <Delete
                            // color="error"
                            style={{ cursor: "pointer", fontSize: "18px", marginLeft: 10, opacity: "30%" }}
                            onClick={() => {
                              // const temp = [...state.vitals]
                              // temp.splice(i, 1)
                              // setState({ ...state, vitals: [...temp] })
                              const temp = [...state]
                              let deletedItem = temp.splice(index, 1)

                              customAxios.put(`/vital/update/${id}`, {"metricName": deletedItem?.[0]?.metricName}).then((res) => {
                                if(res.data?.success){
                                  console.log("vital deleted")
                                }
                              })

                              setState([...temp])
                              setDialogContent([
                                {
                                  label: "Label",
                                  key: "metricName",
                                  placeholder: "eg. Respiration",
                                  md: 12,
                                  type: "select-new",
                                  options: [...suggestedVitals1?.filter(v => temp ? !temp?.find(f => f?.metricName === v?.metricName) : true)]?.map(v => v?.metricName),
                                  freeSolo: true,
                                  required: true,
                                  focus: true,
                                  onChange: (value, state, setState, isSelected) => {
                                    let tempState = {...state}
                                    tempState['metricName'] = value
                                    if(isSelected){
                                      let vital = suggestedVitals1?.find(f => f?.metricName === value)
                                      if(vital){
                                        tempState['metricUnit'] = vital?.metricUnit
                                      }
                                    }
                                    setState({...tempState})
                                  }
                                },
                                ...defaultDialogContent.slice(1, 3)
                              ])
                            }}
                          />
                        </>
                      ),
                    }}
                    //   onKeyDown={(e) => {
                    //   if (e.key === "Enter" &&(e.target.value === "" || re.test(e.target.value)) ){

                    //   }
                    // }}
                    value={ele?.["metricQuantity"]}
                    placeholder="0.0"
                    onChange={(e) => {
                      const re = new RegExp("^[0-9-/.]*$")
                      if (e.target.value === "" || re.test(e.target.value)) {
                        // const temp = [...state.vitals]
                        // temp[i]["metricQuantity"] = e.target.value
                        // setState({ ...state, vitals: [...temp] })

                        const temp = [...state]
                        temp[index]["metricQuantity"] = e.target.value
                        setState([...temp])
                      }
                    }}
                  />
                {/* <Input
                  type="number"
                  value={ele.metricQuantity}
                  onChange={(e) => {
                    const newState = [...state];
                    newState[index]["metricQuantity"] = e.target.value;
                    setState([...newState]);
                  }}
                ></Input>
                {
                  <InputGroupAddon addonType="append">
                    {ele.metricUnit && (
                      <InputGroupText>{ele?.metricUnit}</InputGroupText>
                    )}

                    {!ele.constant && (
                      <InputGroupText
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const temp = [...state];
                          temp.splice(index, 1);
                          setState([...temp]);
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </InputGroupText>
                    )}
                  </InputGroupAddon>
                } */}
              </InputGroup>
            </div>
          );
        })}
        <div className="row col-md-12 mt-4">
          <button
            class="btn btn-icon btn-primary  "
            type="button"
            onClick={() => setIsOpen(true)}
          >
            <span class="btn-inner--icon">
              <i class="fa fa-plus"></i>
            </span>
            <span class="btn-inner--text">Add new</span>
          </button>

          <Button color="primary" onClick={() => saveVitals(state)} style={{marginLeft: "auto"}}>
            Save Vital
          </Button>
        </div>
        <hr style={{ borderTop: "2px solid black" }}></hr>
        {/* <div
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          className="row col-md-6 m-1"
        >
          
        </div> */}
      </div>
      <PreviousVitals id={id} dummyCounter={dummyCounter} />
      <GenericDialogForm
        // modalData={modalData}
        // setModalData={setModalData}
        open={isOpen}
        handleClose={setIsOpen}
        // headers={modalHeaders}
        dialogTitle="Add New Vital"
        handleSubmit={handleSubmit}

        dialogContent={dialogContent}
        handleCancel={() => {
          setModalData({})
          setIsOpen(false)
        }}
        submitButtonText={"Add"}
      />
    </Row>
  );
}
export default AddVitals;
