import React from 'react';
import animationData from '../../../../assets/Phone.json';
import Lottie from 'react-lottie';
function PhoneAnimation({ height, width }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    renderer: 'svg',
  };
  return (
    <Lottie
      options={defaultOptions}
      height={height ? height : 200}
      width={width ? width : 200}
      isStopped={false}
      isPaused={false}
    />
  );
}

export default PhoneAnimation;
