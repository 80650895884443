/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// core components

import { useSelector } from "react-redux";
import routes from "../routes.js";

function Auth(props) {
  //const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      const { component: Component } = route;
      if (route.collapse) {
        return getRoutes(route.views);
      }
      if (route.layout === "/auth") {
        return (
          <Route
            path={route.layout + route.path}
            render={(props) =>
              !isAuthenticated ? (
                <Component {...props} />
              ) : (
                <Redirect to='/admin/dashboard' />
              )
            }
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <div className='main-content'>
        <Switch>{getRoutes(routes)}</Switch>
      </div>
    </div>
  );
}

export default Auth;
