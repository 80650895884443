import React, { useState, useEffect } from "react"
import GenericCardTable from "../GenericCardTable"
import { Typography, Grid, Button, Avatar } from "@material-ui/core"
import GenericDialogForm from "../GenericDialog"
import customAxios from "../../../axios"
import prescriptionSocket from "../../../socket"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { v4 as uuidv4 } from 'uuid';


function Drugs({ state, setState, docId: doctorId, previousState, setActiveIndex, activeIndex, padIndex, frequentData }) {
  
  const [options, setOptions] = useState([])
  const [suggestedDrugs, setSuggestedDrugs] = useState([])

  const user = useSelector((state) => state.user)
  useEffect(() => {
    const testsLength = state?.diagnosis?.length
    if (testsLength) {
      let suggestedState = []
      let querytests = state.diagnosis.map((ele) => ele.name)

      customAxios
        .put(`/ai/drugs`, {
          names: querytests.reverse(),
          doc_id: doctorId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            const newSuggested = res.data.data.map((ele) => ({ name: ele }))

            suggestedState = [...newSuggested, ...suggestedState]

            // suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState?.filter(
              (symp) =>
                !state?.tests?.find(
                  (f) => f?.name?.toLowerCase() === symp?.name?.toLowerCase()
                )
            )
            setSuggestedDrugs([...suggestedState])
          }
        })
    }
  }, [state?.diagnosis])

  const defaultHeaderData = [
    {
      label: "Drug",
      key: "name",
      placeholder: "eg. Crocin",
      type: "select",
      options: [...new Set([...(frequentData?.frequentDrugs ? Object.values(frequentData?.frequentDrugs) : []), ...options])],      
      // onChange: (value, state, setState) => {
      //   console.log("798", state)
      //   const temp = { ...state }

      //   if (temp?.composition) {
      //     temp["shortComposition"] = null
      //     temp["composition"] = null
      //   }
      //   temp["name"] = value.toUpperCase()
      //   setState({ ...temp })
      // },
      fetchOptionManually: true,
      required: true,
      width: "40%",
      optionKey: "name",
      freeSolo: true,
      medicines: true,
    },
    {
      label: "Dose Quantity",
      key: "doseQuantity",
      placeholder: "e.g 2 tablet",
      type: "unit-autocomplete",
      unitOptions: ["tablet", "capsule", "drop", "ml", "vial", "unit", "tube", "teaspoon", "tablespoon", "strip", "stick", "spray", "sponge", "scoop", "sachet", "puff", "pill", "pallet", 'patch', 'packet', 'package', 'kit', 'iu', 'gum', 'granules', 'fingertip', 'can', 'box', 'bottle', 'bar', 'ampule', 'application'],
      freeSolo: true,
      width: "14%",
      limit: 25,
      dependencyKey: "name",
    },
    {
      label: "Frequency",
      key: "frequence",
      placeholder: "e.g 1-1-1",
      type: "select",
      freeSolo: true,
      width: "16%",
      onChange: (value, state, setState) => {
        var reg = new RegExp("^[0-9-/]*$")
        if (reg.test(value)) {
          const temp = { ...state }
          temp["frequence"] = value
          setState({ ...temp })
        }
      },
      options: ["SOS", "Stat", "1-1-1", "1-0-1", "1-1-0", "0-1-1", "1-0-0", "0-1-0", "0-0-1", "Once Every 1 Day", "Once Every 1 Hour", "Once Every 1 Week", "Once Every 1 Year", "1 time a day",
    "1 time a week", "1 time a month", "1 time an hour", '0-0-1/2',
    '0-1/2-0',
    '0-1/2-1/2',
    '1/2-1/2-1/2',
    '1/2-0-0',
    '1/2-0-1/2',
    '1/2-1/2-0',
    '0-0-2',
    '0-2-0',
    '0-2-2',
    '2-2-2',
    '2-0-0',
    '2-0-2',
    '2-2-0',
    '0-0-3',
    '0-3-0',
    '0-3-3',
    '3-3-3',
    '3-0-0',
    '3-0-3',
    '3-3-0',
    'Once Every 2 Days',
    'Once Every 2 Weeks',
    'Once Every 2 Hours',
    'Once Every 2 Months',
    'Once Every 3 Days',
    'Once Every 3 Weeks',
    'Once Every 3 Hours',
    'Once Every 3 Months',
    'Once Every 4 Days',
    'Once Every 4 Weeks',
    'Once Every 4 Hours',
    'Once Every 4 Months',
    'Once Every 5 Days',
    'Once Every 5 Weeks',
    'Once Every 5 Hours',
    'Once Every 5 Months',
    'Once Every 6 Days',
    'Once Every 6 Weeks',
    'Once Every 6 Hours',
    'Once Every 6 Months',
    "2 times a day",
    '2 times a week',
    '2 times a month',
    '2 times an hour',
    "3 times a day",
    '3 times a week',
    '3 times a month',
    '3 times an hour',
    "4 times a day",
    '4 times a week',
    '4 times a month',
    '4 times an hour',
    "5 times a day",
    '5 times a week',
    '5 times a month',
    '5 times an hour',
    "6 times a day",
    '6 times a week',
    '6 times a month',
    '6 times an hour',
    "7 times a day",
    '7 times a week',
    '7 times a month',
    '7 times an hour',
  ],
      dependencyKey: "name",
    },
    {
      label: "Dose Timing",
      key: "timing",
      placeholder: "e.g Before Meal",
      type: "select",
      freeSolo: true,
      width: "15%",
      dependencyKey: "name",
      options: ["Before Meal", "After Meal", "With Meal", "Empty Stomach", "Before Breakfast", "After Breakfast", "Before Lunch", 
    "After Lunch", "Before Dinner", "After Dinner", "along with food", "on bedtime", 'on waking up', '30 minutes before food', 'not related to food intake', 'before bath', 'after bath',
  "Before sun exposure", 'After sun exposure', 'before breakfast, lunch and dinner', "after breakfast, lunch and dinner", 'before breakfast and dinner', 'after breakfast and dinner',
  "before breakfast and lunch", 'atfer breakfast and lunch', 'before lunch and dinner', 'after lunch and dinner'],
    },
    {
      label: "Duration",
      key: "duration",
      placeholder: "e.g 1 Week",
      unitOptions: ["Day", "Week", "Month"],
      freeSolo: true,
      type: "unit-autocomplete",
      width: "15%",
      dependencyKey: "name",
    },
    {
      label: "Advice",
      key: "instructions",
      placeholder: "e.g Take with milk",
      type: "text",
      width: "17%",
      limit: 28,
      dependencyKey: "name",
    },
  ]

  const [cardTableHeaders, setCardTableHeaders] = useState([...defaultHeaderData])
  useEffect(() => {
    let tempHeaderData = [...defaultHeaderData]
    tempHeaderData[0] = {
      label: "Drug",
      key: "name",
      placeholder: "eg. Crocin",
      type: "select",
      options: [...new Set([...(frequentData?.frequentDrugs ? Object.values(frequentData?.frequentDrugs) : []), ...options])],      
      fetchOptionManually: true,
      required: true,
      width: "16%",
      optionKey: "name",
      freeSolo: true,
      medicines: true,
    }
    setCardTableHeaders([...tempHeaderData])
  }, [options])

  useEffect(() => {
    getRxGroup()
  }, [])

  const [openDialog, setOpenDialog] = useState({})
  const [selectedRxGroup, setSelectedRxGroup] = useState({})
  const [doctorRxGroup, setDoctorRxGroup] = useState([])
  const getRxGroup = () => {
    customAxios.get("doctor/rx-group?type=drugs").then((res) => {
      if (res?.data?.success) setDoctorRxGroup([...res?.data?.data])
    })
  }

  const addRxDialogContent = [
    {
      label: "Name",
      key: "name",
      placeholder: "eg. Dengue(Advanced)",
      type: "text",
      required: true,
      md: 12,
    },
    {
      errorKey: "Atleast one option",
      key: "rxMeds",
      type: "checkbox",
      options: [...state.medicines],
      optionKey: "name",
      required: true,
    },
  ]

  const selectMedsDialogContent = [
    {
      label: selectedRxGroup?.["name"],
      key: "rxMeds",
      type: "checkbox",
      options: selectedRxGroup?.["rxMeds"]
        ? [...selectedRxGroup?.["rxMeds"]]
        : [],
      optionKey: "name",
    },
  ]

  const addRxGroup = (rxData, isEdit = false) => {
    customAxios.put("doctor/rx-group?type=drugs", rxData).then((res) => {
      setOpenDialog({})
      toast.success("Template Saved Successfuly!")
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const removeRxGroup = (rxData) => {
    customAxios.put("doctor/delete-rx-group?type=drugs", rxData).then((res) => {
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const chooseRxGroup = (rxData) => {
    setSelectedRxGroup({ ...rxData })
    setOpenDialog({ selectMeds: true })
  }

  const handleSelectMeds = (rxData) => {
    if (rxData?.rxMeds && rxData?.rxMeds?.length) {
      const temp = [...state.medicines]
      rxData?.rxMeds?.map((f) => {
        const check = state.medicines.findIndex((val) => val?.name === f?.name)
        if (check === -1) temp.push({ ...f })
        else temp.splice(check, 1, { ...f })
      })
      setState({ ...state, medicines: [...temp] });
      setOpenDialog({});
    }
  }

  const autoCompleteDrug = (cardState, setCardState) => {
    if (
      !cardTableHeaders.find((f) =>
        f.key !== "name" ? cardState?.[f?.key] : false
      ) &&
      cardState["name"] &&
      !cardState["prefilled"]
    ) {
      prescriptionSocket.emit("drug_autocomplete", {
        name: cardState["name"],
        docId: doctorId,
      })

      let test = true

      prescriptionSocket.once("drug_autocomplete", (args) => {
        if (args && test) {
          setCardState({
            ...cardState,
            ...args,
            name: args?.name?.toUpperCase(),
            prefilled: true,
            prev: {...args}
          })
        }
      })
    }

    if (cardState?.name) {
      prescriptionSocket.emit("get_meds", {
        search: cardState.name?.trim(),
      })

      prescriptionSocket.once("get_meds", (args) => {
        if (args) {
          setOptions([...args])
        }
      })
    }
  }

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Drug Details:"}
        cardName={"Drug Name"}
        suggestedTab={options.length}
        tabSwitching={true}
        suggestedData={suggestedDrugs}
        onSuggestedHoverLabel={"shortComposition"}
        suggestedDataLabel="name"
        suggestedTitle={"Suggested Drugs"}
        tableHeaders={cardTableHeaders}
        tableData={state}
        cardKey="medicines"
        setTableData={setState}
        addOnFunction={autoCompleteDrug}

        onSelectTemplate={handleSelectMeds}
        onDeleteTemplate={removeRxGroup}
        onUpdateTemplate={addRxGroup}
        selectIconText={"Mx"}
        savedTemplates={doctorRxGroup}

        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        padIndex={padIndex}
        frequentOptions={frequentData?.frequentDrugs ? Object.values(frequentData?.frequentDrugs) : []}
        doctorId={doctorId}
        headerIcon={(<Avatar style={{
          fontSize: 18,
          width: 30,
          height: 30, 
          marginRight: 10, 
          color: "#5e72e4", 
          backgroundColor: "white",
        }}>
        <i className="fas fa-pills"></i>
      </Avatar>)}
      />
      {/* {previousState?.medicines?.length ? (
        <Grid
          item
          xs={12}
          style={{ display: "flex", flex: "flex-wrap", alignItems: "center" }}
        >
          <Typography variant="body2">
            *{""} Copy drugs from patient's previous prescription
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                medicines: [...previousState?.medicines],
              }))
            }
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>
      ) : null} */}

      {/* <Grid container>
        <Grid
          item
          xs={6}
          style={{ display: "flex", flex: "flex-wrap", alignItems: "center" }}
        >
          <Typography variant="body2">
            * In order to choose from existing Rx groups
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setOpenDialog({ chooseRx: true })}
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>

        <Grid item container xs={6} justifyContent="flex-end">
          {state.medicines?.length ? (
            <Grid item>
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={() => setOpenDialog({ addRx: true })}
              >
                <Typography variant="body2">Create Rx Group</Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid> */}
      {openDialog?.chooseRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Choose Rx Group"}
          dialogContent={[...doctorRxGroup]}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={chooseRxGroup}
          showDialogAction={false}
          isList={true}
          editListItem={true}
          removeListItem={true}
          onEditItem={(data) =>
            setOpenDialog({ editRx: true, data: { ...data, rxMeds: data?.rxMeds?.map(v => ({...v, uuid: uuidv4()}))} })
          }
          onRemoveItem={removeRxGroup}
        />
      ) : null}
      {openDialog?.selectMeds ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Select Medicines"}
          dialogContent={selectMedsDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={handleSelectMeds}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{
            rxMeds: selectedRxGroup?.["rxMeds"]?.filter((f) => {
              const check = state?.medicines?.find(
                (val) => val?.name === f?.name
              )
              if (check) return false
              else return true
            })?.map(v => {
              const regex = /[^0-9]/;
              if(v?.duration && v?.duration?.length && !regex.test(v?.duration)) {
                v.duration = `${v?.duration} Day`
              }
              return {...v}
            }),
          }}
        />
      ) : null}
      {openDialog?.addRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Create Rx Group"}
          dialogContent={addRxDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={addRxGroup}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{ rxMeds: [...state?.medicines] }}
        />
      ) : null}
      {openDialog?.editRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Update Rx Group"}
          dialogContent={[
            { ...addRxDialogContent[0] },
            {
              ...addRxDialogContent[1],
              type: "checkbox_and_edit",
              options: [...openDialog?.data?.rxMeds],
            },
          ]}
          handleCancel={() => setOpenDialog({ chooseRx: true })}
          editOptions={(val, med, idx) => {
            let tempData = {...openDialog?.data}
            tempData.rxMeds[idx] = {...med, name: val}

            setOpenDialog({...openDialog, data: {...tempData}})
          }}
          handleSubmit={(data) =>
            addRxGroup({ ...data, prevName: openDialog?.data?.name }, true)
          }
          cancelButtonText={"Cancel"}
          submitButtonText={"Update"}
          formData={{ ...openDialog?.data }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Drugs
