import moment from "moment"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import Col from "reactstrap/lib/Col"
import Row from "reactstrap/lib/Row"
import customAxios from "../../axios"
import noMedicalHistory from "./../../assets/img/consultation/NoMedicalHistory.png"
import {
  AppBar,
  Box,
  Button,
  Chip,
  Grid,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core"
import OnlineAppointmentIcon from "../Dashboard/Home/OnlineAppointment.svg"
import OfflineAppointmentIcon from "../Dashboard/Home/Vector.svg"
import { toast } from "react-toastify"
import { useHistory } from "react-router"
import { Add, Send, Visibility } from "@material-ui/icons"
import { useSelector } from "react-redux"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import AppointmentHistory from "./History/AppointmentHistory"
import TreatmentHistory from "./History/TreatmentHistory"
import PaymentHistory from "./History/PaymentHistory"

function History({ id, setLoading, loading, patient }) {
  const isDoctor = useSelector((state) => state.auth.isDoctor)
  const [selectedTab, setSelectedTab] = useState(0)
  const [invoiceData, setInvoiceData] = useState([])
  const [headerMessage, setHeaderMessage] = useState("")
  const [tableData, setTableData] = useState([])
  const [paymentHistoryDialogOpen, setPaymentHistoryDialogOpen] =
    useState(false)
  const [currTableHeaders, setCurrTableHeaders] = useState([
    { label: "Date", key: "date" },
    { label: "Type", key: "type" },
    {
      label: "Actions",
    },
  ])
  const [viewFor, setViewFor] = useState("appointments")
  const viewForOptions = [
    {
      label: "Appointment History",
      Component: (
        <AppointmentHistory
          patientId={id}
          setLoading={setLoading}
          setHeaderMessage={setHeaderMessage}
          patientData={patient}
          setInvoiceData={setInvoiceData}
          setPaymentHistoryDialogOpen={setPaymentHistoryDialogOpen}
        />
      ),
    },
    {
      label: "Procedure History",
      Component: (
        <TreatmentHistory
          patientId={id}
          patientData={patient}
          setLoading={setLoading}
          setInvoiceData={setInvoiceData}
          setPaymentHistoryDialogOpen={setPaymentHistoryDialogOpen}
        />
      ),
    },
    {
      label: "Payment History",
      Component: (
        <PaymentHistory
          patientId={id}
          setLoading={setLoading}
          setInvoiceData={setInvoiceData}
          setPaymentHistoryDialogOpen={setPaymentHistoryDialogOpen}
        />
      ),
    },
  ]

  return (
    <Row className="no-gutters">
      <AppBar position="static" elevation={0} style={{ zIndex: "10" }}>
        <Tabs
          value={selectedTab}
          onChange={(event, value) => {
            setSelectedTab(value)
          }}
          aria-label="simple tabs example"
          centered
          textColor="primary"
          style={{ backgroundColor: "white" }}
          variant="fullWidth"
        >
          {viewForOptions?.map((ele, idx) => (
            <Tab label={ele.label} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </AppBar>
      {viewForOptions.map((ele, idx) => {
        return (
          <TabPanel value={selectedTab} index={idx} style={{ width: "100%" }}>
            {ele.Component}
          </TabPanel>
        )
      })}

      {paymentHistoryDialogOpen ? (
        <GenericDialogForm
          style={{ overflowY: "auto" }}
          footerMessage={() => {
            return (
              <Grid container item xs={12} style={{ marginTop: "1rem" }}>
                {invoiceData.length ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Bill For</TableCell>
                          <TableCell>Mode</TableCell>
                          <TableCell>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceData.map((ele) => (
                          <TableRow>
                            <TableCell>
                              {moment
                                .utc(ele.date)
                                .format("DD MMM YYYY, hh:MM A")}
                            </TableCell>
                            <TableCell>{ele.invoiceFor}</TableCell>
                            <TableCell>
                              {ele.modeOfPayment}
                              {ele.modeOfPayment === "Online(Paperplane)" ? (
                                ele.paid ? (
                                  <Chip
                                    label="Paid"
                                    style={{
                                      backgroundColor: "green",
                                      color: "white",
                                      borderRadius: "0.25em",
                                      marginLeft: "0.3rem",
                                    }}
                                  ></Chip>
                                ) : (
                                  <Chip
                                    label="Pending"
                                    style={{
                                      backgroundColor: "red",
                                      color: "white",
                                      borderRadius: "0.25em",
                                      marginLeft: "0.3rem",
                                    }}
                                  ></Chip>
                                )
                              ) : null}
                            </TableCell>
                            <TableCell>₹{ele.amount}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
                {headerMessage ? null : (
                  <Grid
                    item
                    xs={12}
                    style={{ margin: "1rem 0" }}
                    container
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          setLoading(true)
                          customAxios
                            .put(
                              `/consultation/generate-invoice/${invoiceData[0]?.appointmentId}`,
                              {}
                            )
                            .then((res) => {
                              setLoading(false)
                              if (res.data.success) {
                                window.open(res.data.data.url)
                              } else {
                                toast.error("Some error occured")
                              }
                            })
                            .catch((err) => {
                              setLoading(false)
                              toast.error("Some error occured")
                            })
                        }}
                        startIcon={<i class="fas fa-print"></i>}
                      >
                        Print Invoice
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        title="Send invoice to patient's WhatsApp"
                        startIcon={<Send />}
                        onClick={() => {
                          setLoading(true)
                          customAxios
                          .put(
                            `/consultation/generate-invoice/${invoiceData[0]?.appointmentId}`,
                            {}
                          )
                            .then((res) => {
                              setLoading(false)
                              if (res.data.success) {
                                customAxios
                                  .put(
                                    `/consultation/send-invoice/${null}?patient_id=${id}`,
                                    { url: res.data.data.url }
                                  )
                                  .then((res) => {
                                    if (res.data.success) {
                                      toast.success(
                                        "Invoice sent on patient's WhatsApp successfully"
                                      )
                                    } else {
                                      toast.error("Some error occured")
                                    }
                                  })
                                  .catch((err) => {
                                    toast.error("Some error occured")
                                  })
                              } else {
                                toast.error("Some error occured")
                              }
                            })
                            .catch((err) => {
                              setLoading(false)
                              toast.error("Some error occured")
                            })
                        }}
                      >
                        Send Invoice
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )
          }}
          fullWidth
          maxWidth="md"
          message={headerMessage}
          showSubmitButton={false}
          handleClose={() => {
            setHeaderMessage("")
            setInvoiceData([])
            setPaymentHistoryDialogOpen(false)
          }}
          cancelButtonText="Close"
          dialogTitle={headerMessage ? "Self notes" : "Payment history"}
          open={paymentHistoryDialogOpen}
          setOpen={paymentHistoryDialogOpen}
        />
      ) : null}
    </Row>
  )
}

export default History
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
