import io from "socket.io-client";

const prescriptionSocket = io(
  // "https://loadbalancer.paperplane.health:8000/prescription",
  "https://pplane-socket-prod.paperplane.health/prescription",
   {
    transports: ["websocket", "polling"]
  });
prescriptionSocket.on("connect", () => {
  console.log(prescriptionSocket.id);
});
prescriptionSocket.on("disconnect", () => {
  console.log(prescriptionSocket.id);
});

export default prescriptionSocket;
