import React from "react"
import { Card, CardTitle, CardText } from "reactstrap"
import process from "./process.svg"
import transfer from "./transfer.svg"
import collected from "./collected.svg"
import { colors } from "../../../assets/css/colors"
import { Typography } from "@material-ui/core"
require("./RevenueGenerated.css")
function RevenueGenerated(props) {
  let icon
  if (props.Value === 1) {
    icon = collected
  } else if (props.Value === 2) {
    icon = transfer
  } else if (props.Value === 3) {
    icon = process
  }

  return (
    <div
      className="revenue_icon"
      style={{
        textAlign: "center",
        background: colors.primary,
        padding: "1rem",
        borderRadius: "18px",
      }}
    >
      <img
        src={icon}
        alt=""
        style={{ width: "30px", objectFit: "contain", height: "30px" }}
      />
      <div className="revenue_details">
        <Typography style={{ color: "white" }} variant="h5">
          {props.Name}
        </Typography>
        <Typography variant="h4" style={{ color: "white" }}>
          Rs. {props.Rs}
        </Typography>
      </div>
    </div>
  )
}

export default RevenueGenerated
