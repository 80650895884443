import moment from "moment"
import React, { useEffect, useState } from "react"
import mixpanel from "mixpanel-browser"
// reactstrap components
import {
  FormGroup,
  Form,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Container,
  Table,
} from "reactstrap"
import Button from "reactstrap/lib/Button"
import { useDispatch, useSelector } from "react-redux"
import whatsappLogo from "./../../../assets/img/icons/common/whatsapp.png"
import customAxios from "../../../axios"
import Header from "../../../components/Dashboard/Home/Header"
import GenericModal from "../../../components/AllPatients/GenericModal"
import { toast } from "react-toastify"
import AddOrganisationModal from "./AddOrganisationModal"
import {
  clearAppointmentDetails,
  clearMedicalHistory,
  clearPrescriptionDetails,
  clearVitals,
} from "../../../redux/actions/consultation.actions"
import UpdateOrganizationModal from "./UpdateOrganizationModal"
import SendPhoneOtpModal from "./SendPhoneOtpModal"
import SubmitPhoneOtpModal from "./SubmitPhoneOtp"
import SendEmailOtpModal from "./SendEmailOtpModal"
import SubmitEmailOtpModal from "./SubmitEmailOtp"
import ResetPasswordModal from "./ResetPassword"
import { loadDoctor } from "../../../redux/actions/user.actions"
import googleLogo from "../../../assets/img/brand/googleLogo.png"


function Userprofile(props) {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.user.user)

  const [user, setUser] = useState(() => ({
    ...userData,
    subEnd: moment(userData.subEnd).format("YYYY-MM-DD"),
  }))
  const [updateOrgData, setUpdateOrgData] = useState({})
  const [isAddOrgOpen, setIsAddOrgOpen] = useState(false)
  const [modalData, setModalData] = useState({ isOnline: false })
  const organisations = useSelector((state) => state.user.organisations)
  const isDoctor = useSelector((state) => state.auth.isDoctor)

  const [isOpen, setIsOpen] = useState(false)
  const [resetPasswordIsOpen, setResetPasswordIsOpen] = useState(false)
  const [updateUserPhoneNoIsOpen, setUpdateUserPhoneNoIsOpen] = useState({
    getOtpStep: false,
    submitOtpStep: false,
  })
  const [updateUserEmailNoIsOpen, setUpdateUserEmailNoIsOpen] = useState({
    getOtpStep: false,
    submitOtpStep: false,
  })
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)
  const addNumberModalHeaders = [
    { label: "Phone", key: "phoneNo", fullWidth: true },
  ]
  const [isAddNumberOpen, setAddNumberOpen] = useState(false)
  const [addNumberModalData, setAddNumberModalData] = useState({})
  const columns = [
    { id: "locationName", name: "Clinic Name" },
    { id: "address", name: "Address" },
    { id: "fees", name: "Fee", prepend: <i className="fas fa-rupee-sign"></i> },
    {
      id: "followUp",
      name: "Follow Up",
      prepend: <i className="fas fa-rupee-sign"> </i>,
    },
    { id: "isOnline", name: "Type" },
    { id: "edit", name: "Edit" },
  ]
  const handleSubmitProfile = (user) => {
    setUser({ ...user })
    setIsOpen(false)

    customAxios
      .put("/auth/", user)
      .then((res) => {
        if (res.data.success) {
          dispatch({ type: "SET_USER", payload: res.data.data })
          toast.success("User data updated")
        } else {
          toast.error("User data upload failed")
        }
        console.log(res)
      })
      .catch((error) => {
        toast.error("User data upload failed")
        console.log(error)
      })
  }

  const handleEnterMobile = (phoneNo) => {
    console.log("EMAIL", phoneNo)
    phoneNo = "+91" + phoneNo
    console.log()
    customAxios
      .get(`auth/enter-phone/${phoneNo}?new_user=false`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res)
          if (!res.data.success) {
            toast.error(res.data.message)
            return
          }
          toast.success(res.data.data)
          setUpdateUserPhoneNoIsOpen({
            getOtpStep: false,
            submitOtpStep: true,
          })
        }
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const submitMobileOtp = (otp, phoneNo, setOtp) => {
    let objToSend = {}
    objToSend.phoneNo = "+91" + phoneNo
    objToSend.otp = otp
    customAxios
      .put("auth/update-phone", objToSend)
      .then((res) => {
        console.log(res)
        if (res.data.success) {
          setUpdateUserPhoneNoIsOpen({
            getOtpStep: false,
            submitOtpStep: false,
          })
          dispatch({ type: "SET_USER", payload: res.data.data })
          toast.success("Phone number submitted successfully")
          setOtp("")
        } else {
          toast.error(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleEnterEmail = (email) => {
    console.log("EMAIL", email)
    customAxios
      .get(`auth/enter-email/${email}?new_user=false`)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.data)
          setUpdateUserEmailNoIsOpen({
            getOtpStep: false,
            submitOtpStep: true,
          })
        } else {
          toast.error(res.data.data)
        }
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const submitEmailOtp = (otp, email, setOtp) => {
    let objToSend = {}
    objToSend.email = email
    objToSend.otp = otp
    customAxios
      .put("auth/update-email", objToSend)
      .then((res) => {
        console.log(res)
        if (res.data.success) {
          setUpdateUserEmailNoIsOpen({
            getOtpStep: false,
            submitOtpStep: false,
          })
          dispatch({ type: "SET_USER", payload: res.data.data })
          setOtp("")
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const resetPassword = (
    currentPassword,
    newPassword,
    newPasswordConfirm,
    setPasswordState
  ) => {
    if (newPassword.length < 8) {
      toast.error("Password should be atleast 8 characters long")
      return
    }
    if (newPassword.localeCompare(newPasswordConfirm) !== 0) {
      toast.error("Not matching")
      return
    }
    let objToSend = {}
    objToSend.currPassword = currentPassword
    objToSend.newPassword = newPassword
    customAxios.put("auth/reset-password", objToSend).then((res) => {
      console.log(res)
      if (res.data.success) {
        setResetPasswordIsOpen(false)
        toast.success("Password reset successful")
        setPasswordState({
          currentPassword: "",
          password: "",
          confirmPassword: "",
          allowPassword: false,
        })
      } else {
        console.log(res)
        toast.error(res.data.message)
      }
    })
  }

  const handleAddNumberProfile = (modalData) => {
    customAxios
      .put("/doctor/clinic-phone/add", { ...modalData })
      .then((res) => {
        if (res.data.success) {
          dispatch(loadDoctor())
          setAddNumberOpen(false)
        } else {
          toast.error("Phone number already exists")
        }
      })
      .catch((err) => toast.error("Some error occured"))
  }
  const modalHeaders = [{ label: "Name", key: "name" }]
  useEffect(() => {
    dispatch(clearPrescriptionDetails())
    dispatch(clearMedicalHistory())
    dispatch(clearVitals())
    dispatch(clearAppointmentDetails())
    // eslint-disable-next-line
  }, [])

  const updateOrgFunction = (data) => {
    setUpdateOrgData(data)
    setIsOpenUpdateModal(true)
  }

  return (
    <>
      <Header parentName="Your profile" />
      <Container className="mt--6" fluid>
        <Col xl="10">
          <Card>
            <CardHeader>
              <Row className="pb-2" style={{ alignItems: "center" }}>
                <Col md="">
                  <h3 style={{ fontSize: "25px" }}>General Information:</h3>
                </Col>
                <Col md="3">
                  <Button
                    block
                    onClick={() => setResetPasswordIsOpen(true)}
                    color="primary"
                  >
                    Change Password
                  </Button>
                </Col>
                <Col md="2">
                  <Button
                    color="success"
                    block
                    onClick={() =>
                      navigator.clipboard
                        .writeText(user?.waLink)
                        .then((res) => {
                          mixpanel.people.increment("Copied WhatsApp Link")
                          toast.success("Link copied successfully.")
                        })
                    }
                  >
                    Copy Link{"  "}
                    <img
                      src={whatsappLogo}
                      style={{
                        width: "28px",
                        objectFit: "contain",
                      }}
                    />{" "}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form className="row">
                <FormGroup
                  className="row col-md-6"
                  style={{ justify: "center", alignItems: "center" }}
                >
                  <h3 className="col-md-4" style={{ fontWeight: "500" }}>
                    Name:
                  </h3>
                  <h3
                    className="col-md-6"
                    style={{ fontWeight: "600", color: "#5E5E5E" }}
                  >
                    {userData.name}
                  </h3>
                  <i
                    class="far fa-edit col-md-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsOpen(true)}
                  ></i>
                </FormGroup>

                <FormGroup
                  className=" row col-md-6 "
                  style={{ justify: "center", alignItems: "center" }}
                >
                  <h3 className="col-md-4" style={{ fontWeight: "500" }}>
                    Email:
                  </h3>

                  <h3
                    className="col-md-6"
                    style={{ fontWeight: "600", color: "#5E5E5E" }}
                  >
                    {userData.email}
                  </h3>
                  <i
                    class="far fa-edit col-md-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setUpdateUserEmailNoIsOpen({
                        getOtpStep: true,
                        submitOtpStep: false,
                      })
                    }}
                  ></i>
                </FormGroup>
                <FormGroup
                  className=" row col-md-6"
                  style={{ justify: "center", alignItems: "center" }}
                >
                  <h3 className="col-md-4" style={{ fontWeight: "500" }}>
                    Phone:
                  </h3>
                  <h3
                    className="col-md-6"
                    style={{ fontWeight: "600", color: "#5E5E5E" }}
                  >
                    {userData.phoneNo}
                  </h3>
                  <i
                    class="far fa-edit col-md-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setUpdateUserPhoneNoIsOpen({
                        getOtpStep: true,
                        submitOtpStep: false,
                      })
                    }}
                  ></i>
                </FormGroup>
              </Form>
              <hr style={{ margin: "10px 0px", width: "90%" }} />
              <br></br>
              <Row className="pb-2  " style={{ alignItems: "center" }}>
                <Col md="8">
                  <h3 style={{ fontSize: "25px" }}>Active Locations:</h3>
                </Col>
                <Col md="4">
                <Button variant='outlined' color="primary" onClick={() => {
                    customAxios
                    .post("/auth/authorize-user")
                    .then((res) => {
                      if (res.data.success) {
                        window.open(res.data.data)
                      } else {
                        toast.error("Some error occured")
                      }
                    })
                    .catch((err) => {
                      toast.error("Some error occured")
                    })
                }}>
                     <i className="fa-brands fa-google"></i> {"Link Google account"}
                </Button>
                  <Button
                    color="primary"
                    // block
                    onClick={() => setIsAddOrgOpen(true)}
                  >
                    <i className="fas fa-plus "></i> Add Location
                  </Button>
                </Col>
              </Row>
              <Row>
                <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      {columns.map((column) => {
                        return <th scope="col">{column.name}</th>
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {organisations?.length === 0 ? (
                      <tr>
                        <td colSpan="2">
                          <h3>Data Not found</h3>
                        </td>
                      </tr>
                    ) : (
                      organisations?.map((row, index) => {
                        return (
                          <tr key={index}>
                            {columns.map((column) => {
                              if (column.id === "isOnline") {
                                return !row[column.id] ? (
                                  <td>
                                    <i
                                      class="fas fa-walking "
                                      style={{ color: "#4d63dd" }}
                                    ></i>
                                  </td>
                                ) : (
                                  <td>
                                    <i
                                      class="fas fa-circle "
                                      style={{ color: "#49cc3d" }}
                                    ></i>
                                  </td>
                                )
                              } else if (column.id === "edit") {
                                return (
                                  <td>
                                    <i
                                      class="far fa-edit"
                                      onClick={() => {
                                        updateOrgFunction(row)
                                      }}
                                    ></i>
                                  </td>
                                )
                              } else {
                                return (
                                  <td>
                                    {column.prepend ? column.prepend : null}
                                    {row[column.id]}
                                    {column.append ? column.append : null}
                                  </td>
                                )
                              }
                            })}
                            {/* <td>
                          <Row>
                            <Col xs={6}>
                              <i
                                onClick={() => setModalData({ open: true, ...row })}
                                className='fas fa-edit'
                                aria-hidden='true'
                                style={{
                                  color: colors.primary,
                                  cursor: 'pointer',
                                }}></i>
                            </Col>
                            <Col xs={6}>
                              {' '}
                              <i
                                className='fa fa-trash-alt'
                                onClick={() => {
                                  handleDelete(index);
                                  console.log('in clicking');
                                }}
                                style={{
                                  color: colors.primary,
                                  cursor: 'pointer',
                                }}></i>
                            </Col>
                          </Row>
                        </td> */}
                          </tr>
                        )
                      })
                    )}
                  </tbody>
                </Table>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <UpdateOrganizationModal
          state={updateOrgData}
          setState={setUpdateOrgData}
          isOpen={isOpenUpdateModal}
          setIsOpen={setIsOpenUpdateModal}
        />

        <AddOrganisationModal
          state={modalData}
          isOpen={isAddOrgOpen}
          setIsOpen={setIsAddOrgOpen}
          setState={setModalData}
        />
        <SendEmailOtpModal
          state={user}
          setState={setUser}
          setIsOpen={setUpdateUserEmailNoIsOpen}
          isOpen={updateUserEmailNoIsOpen.getOtpStep}
          handleSubmit={handleEnterEmail}
        />
        <SubmitEmailOtpModal
          state={user}
          setState={setUser}
          setIsOpen={setUpdateUserEmailNoIsOpen}
          isOpen={updateUserEmailNoIsOpen.submitOtpStep}
          handleSubmit={submitEmailOtp}
        />
        <SendPhoneOtpModal
          state={user}
          setState={setUser}
          setIsOpen={setUpdateUserPhoneNoIsOpen}
          isOpen={updateUserPhoneNoIsOpen.getOtpStep}
          handleSubmit={handleEnterMobile}
        />
        <SubmitPhoneOtpModal
          state={user}
          setState={setUser}
          setIsOpen={setUpdateUserPhoneNoIsOpen}
          isOpen={updateUserPhoneNoIsOpen.submitOtpStep}
          handleSubmit={submitMobileOtp}
        />
        <SubmitPhoneOtpModal
          state={user}
          setState={setUser}
          setIsOpen={setUpdateUserPhoneNoIsOpen}
          isOpen={updateUserPhoneNoIsOpen.submitOtpStep}
          handleSubmit={submitMobileOtp}
        />
        <ResetPasswordModal
          setIsOpen={setResetPasswordIsOpen}
          isOpen={resetPasswordIsOpen}
          handleSubmit={resetPassword}
        />
        <GenericModal
          isOpen={isAddNumberOpen}
          setIsOpen={setAddNumberOpen}
          headers={addNumberModalHeaders}
          title="Add Phone Number"
          modalData={addNumberModalData}
          setModalData={setAddNumberModalData}
          handleSubmit={handleAddNumberProfile}
        ></GenericModal>

        <GenericModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headers={modalHeaders}
          title="Edit Profile"
          modalData={{
            name: user.name,
            phoneNo: user.phoneNo,
            email: user.email,
          }}
          setModalData={setUser}
          handleSubmit={handleSubmitProfile}
        ></GenericModal>
      </Container>
    </>
  )
}

export default Userprofile
