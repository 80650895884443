import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import { Button } from 'reactstrap'
import Modal from 'reactstrap/lib/Modal'
import Row from 'reactstrap/lib/Row'

export default function SendPhoneOtpModal({
	state,
	setState,
	setIsOpen,
	isOpen,
	handleSubmit,
}) {
	return (
		<Modal
			className="modal-dialog-centered modal-md"
			isOpen={isOpen}
			toggle={() => setIsOpen(false)}
			style={{ textAlign: 'center' }}>
			<div className="modal-header">
				<h5 className="modal-title" id="exampleModalLabel">
					Enter your new phone number
				</h5>
				<button
					aria-label="Close"
					className="close"
					data-dismiss="modal"
					type="button"
					onClick={() => setIsOpen(false)}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body">
				<Row className="p-3">
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								value={state.phoneNo}
								variant="outlined"
								fullWidth
								label="Phone Number"
								onChange={e =>
									setState({
										...state,
										phoneNo: e.target.value,
									})
								}></TextField>
						</Grid>
						<Grid item xs={6}></Grid>
					</Grid>
				</Row>

				<Row className="p-3">
					{' '}
					<Button
						className="default mt-2"
						onClick={() => {
							console.log(state.phoneNo)
							handleSubmit(state.phoneNo)
						}}>
						Send OTP
					</Button>
				</Row>
			</div>
		</Modal>
	)
}
