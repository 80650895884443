import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Paper,
  Select,
  FormControl,
  MenuItem,
  Typography,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import { Row } from "reactstrap";
import DeleteIcon from "@material-ui/icons/Delete";

import { connect } from "react-redux";

import { toast } from "react-toastify";

import AddIcon from "@material-ui/icons/Add";
import customAxios from "../../axios";

import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

function WebsiteForm({ user }) {
  const classes = useStyles();
  const defaultForm = {
    sections: [
      {
        name: "Your photo",
        data: [
          {
            label: "Doctor Profile",
            key: "doctor_profile",
            type: "add_image",
            parentKey: "header",
            addButtonText: "Add Doctor Profile",
            maxImage: 1,
            fullWidth: true,
          },
        ],
      },
      {
        name: "General Information",
        data: [
          // {
          // 	label: 'Clinic Logo',
          // 	key: 'clinic_logo',
          // 	parentKey: 'header',

          // 	type: 'add_image',
          // 	addButtonText: 'Add Clinic Logo',
          // 	maxImage: 1,
          // 	fullWidth: false
          // },
          {
            label: "Doctor Name",
            key: "doctor_name",
            type: "text",
            parentKey: "header",

            prefixes: ["Dr.", "Mr.", "Ms.", "Mrs."],
            defaultPrefix: "Dr.",
            fixValue: true,
          },
          {
            label: "Clinic Name",
            key: "clinic_name",
            parentKey: "header",

            type: "text",
          },
          {
            label: "Years of Experience",
            key: "experience_years",
            parentKey: "body",

            type: "number",
          },
          {
            label: "Estimated No. of Patients",
            key: "estimated_no_of_patients",
            parentKey: "body",

            type: "number",
          },
          {
            label: "Specialization",
            key: "specialization",
            parentKey: "header",

            type: "text",
          },
          {
            label: "Areas of Expertise",
            key: "six_obj",
            parentKey: "body",

            type: "array",
            limit: 6,
          },

          {
            label: "About",
            key: "about_doctor",
            type: "text",
            parentKey: "body",
            fullWidth: true,
            multiline: true,
            rows: 10,
            limit: 750,
          },
          {
            label: "Why Choose Us?",
            key: "why_choose_us",
            type: "text",
            parentKey: "body",
            multiline: true,
            rows: 10,
            optional: true,
            fullWidth: true,
            limit: 750,
          },
          {
            label: "Personal Note",
            key: "personal_note",
            type: "text",
            parentKey: "body",
            optional: true,
            fullWidth: true,
            multiline: true,
            rows: 10,
            limit: 750,
          },
        ],
      },
      {
        name: "On the web",
        data: [
          {
            label: "Facebook Link",
            key: "facebook_link",
            parentKey: "footer",
            placeholder: "Enter URL",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <i class="fab fa-facebook" style={{ color: "#6C7BFF" }}></i>
                </InputAdornment>
              ),
            },
            type: "text",
            optional: true,
          },
          {
            label: "Instagram Link",
            placeholder: "Enter URL",
            key: "instagram_link",
            parentKey: "footer",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <i class="fab fa-instagram" style={{ color: "#6C7BFF" }}></i>
                </InputAdornment>
              ),
            },
            type: "text",
            optional: true,
          },
        ],
      },
      {
        name: "Location Link",
        help: (
          <i
            className="fas fa-info-circle"
            style={{ cursor: "pointer", marginLeft: "10px", fontSize: "20px" }}
            onClick={() => {
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.href =
                "https://www.notion.so/Getting-Started-5cc4581ca75d4031b37e0d34c3fb37b1";
              a.rel = "noreferrer";
              a.target = "_blank";
              a.click();
            }}
          ></i>
        ),

        data: [
          {
            label: "Location Link",
            key: "location_link",
            type: "text",

            parentKey: "gallery",
            placeholder: "Enter location URL",
            htmlLink: true,
            multiline: true,
            fullWidth: true,
            rows: 10,
          },
        ],
      },
      {
        name: "Gallery",
        data: [
          {
            label: "Images",
            key: "images",
            parentKey: "gallery",
            type: "add_image",
            heading: "Add Gallery Images",
            addButtonText: "Add Images",
            maxImage: 6,
            fullWidth: true,
            optional: true,
          },
        ],
      },
    ],
  };

  const defaultState = {
    header: {
      doctor_profile: [],
      doctor_name: { val: user?.name },
      specialization: user?.additionalDetail?.qualification,
      whats_app_link: `https://api.whatsapp.com/send/?phone=918707699762&text=${user?.phoneNo}%20press%20send%20to%20book%20appointment%20with%20Dr.%20${user?.name}%20%F0%9F%91%89`,
    },
    footer: {},
    feedback: {},
    body: { six_obj: [] },
    gallery: { images: [], location_link: { val: " " } },
  };

  const handleSubmit = (state) => {
    let error = "";
    let temp = { ...state };
    defaultForm.sections?.map((v) => {
      if (!error)
        v.data?.map((field) => {
          if (!error && field?.key && !field?.optional)
            if (
              !state?.[field?.parentKey]?.[field?.key] ||
              state?.[field?.parentKey]?.[field?.key] === "" ||
              (field?.type === "add_image" &&
                !state?.[field?.parentKey]?.[field?.key]?.length)
            ) {
              error = `${field?.label} is required`;
              toast.error(error);
            }

          if (
            field?.type === "text" &&
            field?.prefixes &&
            !state?.[field?.parentKey]?.[field?.key]?.["pre"]
          ) {
            temp[field?.parentKey][field?.key]["pre"] = field?.defaultPrefix;
          }

          if (
            !error &&
            field?.type === "array" &&
            state?.[field?.parentKey]?.[field?.key]?.find(
              (f) => f === "" || f === " "
            )
          ) {
            error = `${field?.label} cannot be empty`;
            toast.error(error);
          }
        });
    });

    if (!error) {
      setLoading(true);

      customAxios
        .put("marketing/website-data", {
          ...temp,
        })
        .then((res) => {
          if (res?.data?.success) {
            getData();
            toast.success("Submitted Successfuly");
          } else {
            setLoading(false);
            toast.error("Some Error Occured, Please try again");
          }
        });
    }
  };
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    customAxios
      .get(`marketing/website-data?doc_id=${user?._id}`)
      .then((res) => {
        if (res?.data?.success) {
          setState(res?.data?.data);
          setLoading(false);
        } else {
          setState(defaultState);
          setLoading(false);
        }
      })
      .catch((err) => console.log("error", err));
  };

  return (
    <Grid container xs={12} style={{ padding: "30px 10px 30px 60px" }}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2} item xs={12} style={{ marginBottom: "2%" }}>
        <Grid item xs={6}>
          <h1>Website Details:</h1>
          {user.link ? (
            <h4 style={{ opacity: "0.4" }}>
              Your website link:{" "}
              <a
                href={`https://doctor.paperplane.health/${user?.name}`}
                target="_blank"
                rel="noreferrer"
              >{`https://doctor.paperplane.health/${user?.name}`}</a>
            </h4>
          ) : (
            <h4>Please fill the form to generate the website link.</h4>
          )}
        </Grid>
        <Grid container item xs={6} spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" size="large" color="primary">
              Preview
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => handleSubmit(state)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={4}>
        {defaultForm?.sections?.map((section) => {
          return (
            <Grid
              container
              item
              xs={12}
              spacing={3}
              style={{ backgroundColor: "#fff", marginBottom: "40px" }}
            >
              <Grid item xs={12}>
                <Typography variant="h5">
                  {" "}
                  {section.name.toUpperCase()}{" "}
                  {section.help ? section.help : null}
                </Typography>
              </Grid>
              {section?.data?.map((field) => {
                return (
                  <Grid item xs={field.fullWidth ? 12 : 6}>
                    {GetActiveTextfield(
                      field,
                      field.parentKey,
                      state,
                      setState
                    )}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={2}
        justifyContent="flex-end"
        style={{ marginTop: "2%" }}
      >
        <Grid item>
          <Button variant="outlined" size="large" color="primary">
            Preview
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => handleSubmit(state)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(WebsiteForm);

const GetActiveTextfield = (field, parentKey, state, setState) => {
  const [areas, setAreas] = useState("");

  const imageRef = useRef();
  switch (field.type) {
    case "text":
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {field?.prefixes ? (
            <>
              <FormControl style={{ width: "100px" }} variant="outlined">
                <Select
                  defaultValue={field?.defaultPrefix}
                  onChange={(e) => {
                    let temp = { ...state };
                    if (temp[parentKey][field.key])
                      temp[parentKey][field.key] = {
                        ...temp[parentKey][field.key],
                        pre: e.target.value,
                      };
                    else temp[parentKey][field.key] = { pre: e.target.value };
                    setState({ ...temp });
                  }}
                >
                  {field?.prefixes?.map((v, i) => (
                    <MenuItem value={v}>{v}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : null}
          <TextField
            inputProps={{ maxLength: field?.limit }}
            type={field.type}
            fullWidth
            InputProps={{ ...field?.InputProps }}
            value={
              field?.prefixes || field?.htmlLink
                ? state?.[parentKey]?.[field?.key]?.val.toString()
                : state?.[parentKey]?.[field?.key]
                ? state?.[parentKey]?.[field?.key]
                : ""
            }
            multiline={field.multiline ? true : false}
            rows={field?.rows ? field.rows : 0}
            onChange={(e) => {
              const temp = { ...state };
              if (!field?.fixValue) {
                if (field?.htmlLink) {
                  const parser = new DOMParser();
                  const parsedIframe = parser.parseFromString(
                    e.target.value,
                    "text/html"
                  );
                  let iFrame = parsedIframe.getElementsByTagName("iframe");

                  let src = iFrame?.[0]?.src;
                  temp[parentKey][field.key] = {
                    val: e.target.value,
                    link: src,
                  };
                } else {
                  if (field?.prefixes) {
                    if (temp[parentKey][field.key])
                      temp[parentKey][field.key] = {
                        ...temp[parentKey][field.key],
                        val: e.target.value,
                      };
                    else temp[parentKey][field.key] = { val: e.target.value };
                  } else temp[parentKey][field.key] = e.target.value;
                }
                setState({ ...temp });
              }
            }}
            variant={"outlined"}
            placeholder={field.placeholder ? field.placeholder : ""}
            required={!field?.optional}
            label={field.label}
          ></TextField>
        </div>
      );

    case "number":
      return (
        <TextField
          type={field.type}
          fullWidth
          required={!field?.optional}
          value={
            state?.[parentKey]?.[field?.key]
              ? state?.[parentKey]?.[field?.key]
              : ""
          }
          onChange={(e) => {
            const temp = { ...state };
            temp[parentKey][field.key] =
              Number(e.target.value) > 0 ? e.target.value : 0;
            setState({ ...temp });
          }}
          variant={"outlined"}
          label={field.label}
        ></TextField>
      );

    case "image":
      return (
        <div>
          <h4 style={{ opacity: "0.4" }}>{field?.heading}</h4>
          <img src={field?.src} />
        </div>
      );

    case "add_image":
      return (
        <div>
          <input
            type="file"
            multiple
            hidden
            accept="image/*"
            ref={imageRef}
            onChange={(e) => {
              const temp = { ...state };
              let reqLength =
                (field?.maxImage ? field?.maxImage : 6) -
                temp[parentKey][field.key]?.length;

              for (let i = 0; i < reqLength; i++) {
                if (e.target.files[i]?.type?.split("/")?.[0] === "image") {
                  const url = URL.createObjectURL(e.target.files[i]);
                  const tempObj = e.target.files[i];

                  let reader = new FileReader();

                  reader.onload = function (e) {
                    temp[parentKey][field.key].push({
                      file: e.target.result,
                      name: tempObj.name,
                      extension: tempObj.type,
                      url: url,
                    });
                    setState({ ...temp });
                  };
                  reader.readAsDataURL(tempObj);
                }
              }
            }}
          ></input>

          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{
              marginTop: !field?.heading ? "-2%" : 0,
              marginBottom: "1%",
            }}
          >
            {field?.heading ? (
              <Grid item xs={12}>
                <Typography variant="h6">{field?.heading}</Typography>
              </Grid>
            ) : null}
            {state?.[parentKey]?.[field.key]?.length ? (
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ marginTop: "10px" }}
              >
                {state?.[parentKey]?.[field.key]?.map((file, idx) => (
                  <Grid item md={2} xs={6}>
                    <Paper
                      elevation={2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "0.5rem",
                      }}
                    >
                      <img
                        src={file.url}
                        onClick={() => window.open(file.url, "_blank")}
                        alt="Uploaded"
                        style={{
                          height: "75px",

                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                      />
                      <Button
                        color="secondary"
                        fullWidth
                        style={{ fontSize: "12px" }}
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          const temp = { ...state };
                          temp[parentKey][field.key].splice(idx, 1);
                          setState({ ...temp });
                        }}
                      >
                        Delete
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="outlined"
                disabled={
                  field?.maxImage === state?.[parentKey]?.[field.key]?.length
                }
                onClick={() =>
                  field?.maxImage === state?.[parentKey]?.[field.key]?.length
                    ? null
                    : imageRef.current.click()
                }
                startIcon={<AddIcon />}
              >
                {field.addButtonText ? field.addButtonText : "Add Value"}
              </Button>
            </Grid>
          </Grid>
        </div>
      );

    case "array":
      return (
        <div>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                color="primary"
                fullWidth
                value={areas}
                disabled={field?.limit === state[parentKey][field.key]?.length}
                placeholder={field.label}
                InputProps={{
                  endAdornment: (
                    <Button
                      size="large"
                      onClick={() => {
                        if (areas) {
                          const obj = { ...state };
                          const temp = state[parentKey][field.key]
                            ? state[parentKey][field.key]
                            : [];

                          temp.push(areas);
                          setAreas("");
                          obj[parentKey][field.key] = [...temp];

                          setState({ ...obj });
                        }
                      }}
                      color="primary"
                    >
                      +
                    </Button>
                  ),
                }}
                helperText="Press enter to add value"
                onChange={(e) => setAreas(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && areas) {
                    if (areas.length > 30) {
                    } else {
                      const obj = { ...state };
                      const temp = state[parentKey][field.key]
                        ? state[parentKey][field.key]
                        : [];
                      temp.push(areas);
                      setAreas("");
                      obj[parentKey][field.key] = [...temp];

                      setState({ ...obj });
                    }
                  }
                }}
              ></TextField>
            </Grid>
            <Grid
              container
              item
              spacing={2}
              style={{ marginTop: "5px" }}
              alignItems="center"
            >
              {state[parentKey][field.key]?.map((chip, idx) => {
                return (
                  <Grid item>
                    <Chip
                      style={{ borderRadius: "5px" }}
                      label={chip}
                      clickable
                      variant={"default"}
                      onDelete={() => {
                        const temp = { ...state };
                        temp[parentKey][field.key]?.splice(idx, 1);
                        setState({ ...temp });
                      }}
                      color="primary"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </div>
      );

    default:
      return null;
  }
};
