export const stepOneHeaders = [
  {
    label: "Contact / Patient Code*",
    key: "searchBy",
    addOnType: "prepend",
    type: "tel",
    required: true,
    fullWidth: true,
  },
]
export const stepWaHeaders = [
  {
    label: "Contact *",
    key: "searchBy",
    addOnType: "prepend",
    type: "tel",
    required: true,
    fullWidth: true,
  },
]
export const stepTwoHeaders = [
  { label: "Name", key: "name", required: true },
  {
    label: "Contact",
    key: "phoneNo",
    addOnType: "prepend",
    addOn: "+91",
    required: true,
  },
  {
    key: "ageString",
    label: "Age",
    unitOptions1: ['Years', "Months", "Days"],
    unitOptions: ['Year', 'Month', 'Day'],
    placeholder: "e.g. 10 Years",
    freeSolo: true,
    required: false,
    type: "unit-autocomplete"
  },
  {
    label: "Gender",
    key: "gender",
    type: "select",
    options: ["male", "female", "other"],
    required: true,
  },
  { label: "Address", key: "address" },
  { label: "Referred By", key: "referredBy" },
]
export const stepThreeHeaders = [
  { label: "Name", key: "name", disabled: true },
  {
    label: "Contact",
    key: "phoneNo",
    addOnType: "prepend",
    addOn: "+91",
    disabled: true,
  },
  { label: "Age", key: "ageString", disabled: true },
  {
    label: "Gender",
    disabled: true,
    key: "gender",
    type: "select",
    options: ["male", "female", "other"],
  },
]
