import { Avatar, Button, Grid, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import customAxios from "../../../axios"
import GenericCardTable from "../GenericCardTable"
import GenericDialogForm from "../GenericDialog"
import { symptomsValuesForSearch } from "./../Constants/index"
import { v4 as uuidv4 } from 'uuid';


function Symptoms({ state, setState, docId, activeIndex, setActiveIndex, padIndex, frequentData }) {
  const [suggestedSymptoms, setSuggestedSymptoms] = useState([])
  const user = useSelector((state) => state.user)
  useEffect(() => {
    const symptomsLength = state?.symptoms?.length
    if (symptomsLength) {
      let suggestedState = []
      let querySymptoms = state.symptoms.map((ele) => ele.name)
      console.log("resd", user.user)
      customAxios
        .put(`/ai/symptoms`, {
          names: querySymptoms.reverse(),
          doc_id: docId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            console.log("resd", res.data.data)
            suggestedState = suggestedState.concat(res?.data?.data)
            suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState.filter(
              (symp) =>
                !state?.symptoms?.find(
                  (f) => f?.name?.toLowerCase() === symp.toLowerCase()
                )
            )
            setSuggestedSymptoms([...suggestedState])
          }
        })
    }
  }, [state?.symptoms])

  const cardTableHeaders = [
    {
      label: "Symptom",
      key: "name",
      placeholder: "eg. Fever",
      type: "select",
      freeSolo: true,
      options: [...new Set([...(frequentData?.frequentSymptoms ? Object.values(frequentData?.frequentSymptoms) : []), ...symptomsValuesForSearch])],
      required: true,
      width: "25%",
    },
    {
      label: "Since",
      key: "duration",
      placeholder: "e.g 1 Week",
      endAdornment: "days",
      unitOptions: ["Hour", "Day", "Week", "Month", "Year"],
      freeSolo: true,
      type: "unit-autocomplete",
      width: "20%",
      dependencyKey: "name",
    },
    {
      label: "Severity",
      key: "severity",
      placeholder: "e.g Mild",
      type: "select",
      width: "20%",
      dependencyKey: "name",
      freeSolo: true,
      options: ["Mild", "Moderate", "Severe"],
    },
    {
      label: "Onset Mode",
      key: "onset",
      placeholder: "e.g Gradual",
      type: "select",
      width: "20%",
      dependencyKey: "name",
      freeSolo: true,
      options: ["Sudden", "Gradual", "Continous", "Constant"],
    },
  ]

  useEffect(() => {
    getRxGroup()
  }, [])

  const [openDialog, setOpenDialog] = useState({})
  const [selectedRxGroup, setSelectedRxGroup] = useState({})
  const [doctorRxGroup, setDoctorRxGroup] = useState([])
  const getRxGroup = () => {
    customAxios.get("doctor/rx-group?type=symptoms").then((res) => {
      if (res?.data?.success) setDoctorRxGroup([...res?.data?.data])
    })
  }

  const addRxDialogContent = [
    {
      label: "Name",
      key: "name",
      placeholder: "eg. Fever",
      type: "text",
      required: true,
      md: 12,
    },
    {
      errorKey: "Atleast one option",
      key: "rxMeds",
      type: "checkbox",
      options: [...state.symptoms],
      optionKey: "name",
      required: true,
    },
  ]

  const selectMedsDialogContent = [
    {
      label: selectedRxGroup?.["name"],
      key: "rxMeds",
      type: "checkbox",
      options: selectedRxGroup?.["rxMeds"]
        ? [...selectedRxGroup?.["rxMeds"]]
        : [],
      optionKey: "name",
    },
  ]

  const addRxGroup = (rxData, isEdit = false) => {
    customAxios.put("doctor/rx-group?type=symptoms", rxData).then((res) => {
      setOpenDialog({})
      toast.success("Template Saved Successfuly!")
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const removeRxGroup = (rxData) => {
    customAxios.put("doctor/delete-rx-group?type=symptoms", rxData).then((res) => {
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const chooseRxGroup = (rxData) => {
    setSelectedRxGroup({ ...rxData })
    setOpenDialog({ selectMeds: true })
  }

  const handleSelectMeds = (rxData) => {
    if (rxData?.rxMeds && rxData?.rxMeds?.length) {
      const temp = [...state.symptoms]
      rxData?.rxMeds?.map((f) => {
        const check = state.symptoms.findIndex((val) => val?.name === f?.name)
        if (check === -1) temp.push({ ...f })
        else temp.splice(check, 1, { ...f })
      })
      setState({ ...state, symptoms: [...temp] });
      setOpenDialog({});
    }
  }

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Symptom Details"}
        tabSwitching={true}
        suggestedTitle={"Suggested Symptoms"}
        suggestedData={suggestedSymptoms}
        tableHeaders={cardTableHeaders}
        suggestedTab={suggestedSymptoms.length}
        tableData={state}
        cardKey={"symptoms"}
        cardName={"Symptoms"}

        onSelectTemplate={handleSelectMeds}
        onDeleteTemplate={removeRxGroup}
        onUpdateTemplate={addRxGroup}
        selectIconText={"Sx"}
        savedTemplates={doctorRxGroup}

        setTableData={setState}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        padIndex={padIndex}
        frequentOptions={frequentData?.frequentSymptoms ? Object.values(frequentData?.frequentSymptoms) : []}
        headerIcon={(<Avatar style={{
          fontSize: 18,
          width: 30,
          height: 30, 
          marginRight: 10, 
          color: "#5e72e4", 
          backgroundColor: "white",
        }}>
        <i className="fas fa-virus-covid"></i>
      </Avatar>)}
      />
      {/* <Grid container>
        <Grid
          item
          xs={6}
          style={{ display: "flex", flex: "flex-wrap", alignItems: "center" }}
        >
          <Typography variant="body2">
            * Choose from templates
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setOpenDialog({ chooseRx: true })}
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>

        <Grid item container xs={6} justifyContent="flex-end">
          {state.symptoms?.length ? (
            <Grid item>
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={() => setOpenDialog({ addRx: true })}
              >
                <Typography variant="body2">Create template</Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid> */}
      {openDialog?.chooseRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Choose Template"}
          dialogContent={[...doctorRxGroup]}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={chooseRxGroup}
          showDialogAction={false}
          isList={true}
          editListItem={true}
          removeListItem={true}
          onEditItem={(data) =>
            setOpenDialog({ editRx: true, data: { ...data, rxMeds: data?.rxMeds?.map(v => ({...v, uuid: uuidv4()}))} })
          }
          onRemoveItem={removeRxGroup}
        />
      ) : null}
      {openDialog?.selectMeds ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Select symptoms"}
          dialogContent={selectMedsDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={handleSelectMeds}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{
            rxMeds: selectedRxGroup?.["rxMeds"]?.filter((f) => {
              const check = state?.symptoms?.find(
                (val) => val?.name === f?.name
              )
              if (check) return false
              else return true
            }),
          }}
        />
      ) : null}
      {openDialog?.addRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Create Template"}
          dialogContent={addRxDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={addRxGroup}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{ rxMeds: [...state?.symptoms] }}
        />
      ) : null}
      {openDialog?.editRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Update Template"}
          dialogContent={[
            { ...addRxDialogContent[0] },
            {
              ...addRxDialogContent[1],
              type: "checkbox_and_edit",
              options: [...openDialog?.data?.rxMeds],
            },
          ]}
          handleCancel={() => setOpenDialog({ chooseRx: true })}
          handleSubmit={(data) =>
            addRxGroup({ ...data, prevName: openDialog?.data?.name }, true)
          }
          editOptions={(val, med, idx) => {
            let tempData = {...openDialog?.data}
            tempData.rxMeds[idx] = {...med, name: val}

            setOpenDialog({...openDialog, data: {...tempData}})
          }}
          cancelButtonText={"Cancel"}
          submitButtonText={"Update"}
          formData={{ ...openDialog?.data }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Symptoms
