import { Chip, Grid, Typography, TextField, IconButton, FormControlLabel, Checkbox, useTheme, Divider, Collapse, Avatar } from "@material-ui/core"
import { CheckCircle, ExpandLess, ExpandMore, RadioButtonChecked, RadioButtonUnchecked, RemoveCircle } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import { diseasesValueForSearch } from "../Constants"
import GenericCardTable from "../GenericCardTable"

import yesIcon from "../../../assets/img/icons/common/yes.png"
import noIcon from "../../../assets/img/icons/common/decline.png"
import { useRef } from "react"

function PreviousHistory({ state, setState, previousHistory }) {

  const theme = useTheme()

  const [prevHistoryExpand, setPrevHistoryExpand] = useState(true)
  const [noKnownHistory, setNoKnownHistory] = useState(false)
  const [existCondExpand, setExistCondExpand] = useState(false)
  const [currentMedExpand, setCurrentMedExpand] = useState(false)

  const [cardState, setCardState] = useState({
    existConditions: [],
    currentMeds: []
  })

  const defaultKeyPoint = [
    {"label": "Alcohol", "checked": false, "value": "", "state": null},
    {"label": "Smoke", "checked": false, "value": "", "state": null},
    {"label": "Tobacco", "checked": false, "value": "", "state": null}
  ];

  const [historyKey, setHistoryKey] = useState([...defaultKeyPoint])

  const updateOnce = useRef(0)
  useEffect(() => {
    if(previousHistory?.prescription && updateOnce.current < 5){
      setCardState({
        existConditions: previousHistory?.prescription?.existConditions ? previousHistory?.prescription?.existConditions : cardState?.existConditions,
        currentMeds: previousHistory?.prescription?.currentMeds ? previousHistory?.prescription?.currentMeds : cardState?.currentMeds
      })

      if(previousHistory?.prescription?.habits?.length)
        setHistoryKey([...previousHistory?.prescription?.habits])
      
      if(previousHistory?.prescription?.noKnownHistory)
        setNoKnownHistory(true)

      updateOnce.current += 1
    }
  }, [previousHistory?.prescription])

  const cardTableHeaders = [
    [
      {
        // label: "Diagnosis",
        key: "name",
        placeholder: "Type property",
        type: "select",
        options: [...diseasesValueForSearch],
        required: true,
        width: "25%",
      },
      {
        // label: "Since",
        key: "duration",
        placeholder: "Since",
        endAdornment: "days",
        unitOptions: ["Hour", "Day", "Week", "Month", "Year"],
        freeSolo: true,
        type: "unit-autocomplete",
        width: "20%",
        dependencyKey: "name",
      },
      {
        // label: "Status",
        key: "status",
        // helperText: "Use  ⬅️  ➡️ to select value",
        placeholder: "active/inactive",
        type: "select",
        width: "20%",
        dependencyKey: "name",
        options: ["Active", "Inactive"],
      },
      {
        // label: "Details",
        key: "details",
        // helperText: "Use  ⬅️  ➡️ to select value",
        placeholder: "Details",
        type: "text",
        width: "30%",
        dependencyKey: "name",
      },
    ],
    [
      {
        // label: "Diagnosis",
        key: "name",
        placeholder: "Type property",
        type: "select",
        options: [],
        fetchOptionManually: true,
        required: true,
        width: "25%",
        optionKey: "name"
      },
      {
        // label: "Since",
        key: "since",
        placeholder: "Since",
        endAdornment: "days",
        unitOptions: ["Hour", "Day", "Week", "Month", "Year"],
        freeSolo: true,
        type: "unit-autocomplete",
        width: "20%",
        dependencyKey: "name",
      },
      {
        // label: "Since",
        key: "duration",
        placeholder: "times a day",
        endAdornment: "days",
        unitOptions: ["time in a Hour", "time in a Day", "time in a Week", "time in a Month", "time in a Year"],
        freeSolo: true,
        type: "unit-autocomplete",
        width: "20%",
        dependencyKey: "name",
      },
      {
        // label: "Status",
        key: "status",
        // helperText: "Use  ⬅️  ➡️ to select value",
        placeholder: "active/inactive",
        type: "select",
        width: "20%",
        dependencyKey: "name",
        options: ["Active", "Inactive"],
      },
      {
        // label: "Details",
        key: "details",
        // helperText: "Use  ⬅️  ➡️ to select value",
        placeholder: "Details",
        type: "text",
        width: "30%",
        dependencyKey: "name",
      },
    ]
  ];


  const getAutocomplete = (val, idx) => {

    let units = ["time in a Hour", "time in a Day", "time in a Week", "time in a Month", "time in a Year"];

    let unitOptions = val['value'] && ![...units]?.find(f => val?.["value"]?.toLowerCase()?.includes(f?.toLowerCase())) ? [...units].map(v => `${val["value"]} ${v}`) : [];

    return (
      <Autocomplete
        options={unitOptions}
        getOptionLabel={(option) => option}
        // fullWidth
        freeSolo={true}
        autoFocus
        
        value={val["value"] }
        style={{minWidth: "10rem", marginTop: "2px"}}
        onChange={(e, val) => {
          let tempKeys = [...historyKey];
          tempKeys[idx].value = val;
          setHistoryKey([...tempKeys])
        }}
        renderOption={(option) => (
          <div style={{fontSize: "12px"}}>
            {option}
          </div>
        )}
        // disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            // helperText={
            //   <Typography style={{ fontSize: "14px" }}>
            //     Use arrow keys <i class="fas fa-angle-down"></i> &{" "}
            //     <i class="fas fa-angle-up"></i>
            //   </Typography>
            // }
            {...params}
            // fullWidth
            onChange={(e) => {
              const re = new RegExp("^[0-9/.]*$")
              if(re.test(e?.target?.value)) {
                let tempKeys = [...historyKey];
                tempKeys[idx].value = e?.target?.value;
                setHistoryKey([...tempKeys])
              }                
            }}
            // focused
            autoFocus={!val?.['value']}
            variant="outlined"
            // onChange={(e) => {
            //   if (field.onChange) field.onChange(e.target.value, state, setState)
            // }}
            placeholder="Since"
          inputProps={{ ...params?.inputProps, style: { fontSize: "12px" } }}

          />
          )}
        />
    )
  }


  const getExistingConditions = () => {
    let text = "";

    cardState?.existConditions?.map((v, i) => {
      if(typeof(v) === "object" && Object?.keys(v).length > 0) {
        ["name", "status", "duration"]?.map(key => {
          if(v?.[key] && key === "name"){
            text += `${v?.[key]} `
          } else if(v?.[key] && key === 'status'){
            text += `(${v?.[key]})`
          } else if(v?.[key] && key === 'duration'){
            text += ` - ${v?.[key]}`
          }
        })
      }
      if(i < cardState?.existConditions?.length - 1){
        text += ", "
      }
    });

    return text
  }

  const getCurrentMedications = () => {
    let text = "";

    cardState?.currentMeds?.map((v, i) => {
      if(typeof(v) === "object" && Object?.keys(v).length > 0) {
        ["name", "status", "since", "duration"]?.map(key => {
          if(v?.[key] && key === "name"){
            text += `${v?.[key]} `
          } else if(v?.[key] && key === 'status'){
            text += `(${v?.[key]})`
          } else if(v?.[key] && key === 'since'){
            text += ` - ${v?.[key]}`
          } else if(v?.[key] && key === 'duration'){
            text += `: ${v?.[key]}`
          }
        })
      }
      if(i < cardState?.currentMeds?.length - 1){
        text += ", "
      }
    });

    return text
  }


  const getLifestyleHabits = () => {
    let text = "";

    historyKey?.map((v, i) => {
      if(v?.checked){
        if(typeof(v) === "object" && Object?.keys(v)?.length > 0) {
          ["label", "state", "value"]?.map(key => {
            if(v?.[key] && key === "label"){
              text += `${v?.[key]} `
            } else if(v?.[key] && key === 'state'){
              text += `(${v?.[key]})`
            } else if(v?.[key] && key === 'value'){
              text += ` - ${v?.[key]}`
            }
          })
        }
        if(historyKey?.[i+1]?.checked){
          text += ", "
        }
      }
    });

    return text
  }

  useEffect(() => {
    setState({...state, otherData: {
      ...(state?.otherData ? state.otherData : {}),
      ...cardState,
      habits: [...historyKey],
      noKnownHistory: noKnownHistory
    }})
  }, [cardState, historyKey, noKnownHistory])

  
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        // style={{ marginTop: "1rem" }}
        alignItems="center"
      >
        {/* <Grid item> */}
        <Grid item style={{display: "flex", alignItems: "center"}}>
            <Avatar style={{
                fontSize: 18,
                width: 30,
                height: 30, 
                marginRight: 10, 
                color: theme.palette.primary.main, 
                backgroundColor: "white",
              }}>
            <i className="fas fa-book-medical"></i>
          </Avatar>
          <Typography variant="h6" style={{fontWeight: "600"}}>Patient Medical History</Typography>
        </Grid>

        <Grid item>
          <IconButton onClick={() => setPrevHistoryExpand(!prevHistoryExpand)}>
            {prevHistoryExpand ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>

        <Grid item style={{marginLeft: "auto", display: "flex"}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={noKnownHistory || false}
                onChange={(e) => setNoKnownHistory(e?.target?.checked)}
                color="primary"
              />
            }
            label="No known medical history"
          />
        </Grid>

      </Grid>
      
      {!noKnownHistory ? <Grid item xs={12}>
        <Collapse in={prevHistoryExpand} style={{width: "100%"}}>
            <Grid container style={{width: "100%"}}>
            <Grid item xs={12} container alignItems="center" style={{paddingLeft: "15px"}}>
              {historyKey?.map((val, idx) => (
                <Grid item xs={4} style={{display: "flex"}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={val?.checked}
                        onChange={(event) => {
                            let tempKeys = [...historyKey];
                            if(tempKeys[idx]?.checked){
                              if(tempKeys[idx]?.state === "active") {
                                tempKeys[idx].state = "inactive";
                              } else if(tempKeys[idx]?.state === "inactive"){
                                tempKeys[idx].state = null;
                                tempKeys[idx].checked = false;
                              }
                            } else {
                              tempKeys[idx].state = "active";
                              tempKeys[idx].checked = true;
                            }
                            setHistoryKey([...tempKeys])
                        }}
                        name={val?.label}
                        style={{color: val?.checked ? (val?.state === "inactive" ? theme.palette.error.main : theme.palette.primary.main) : null}}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={(val?.state === "active" ? <img src={yesIcon} height={"25px"} width={"25px"} /> : <img src={noIcon} height={"25px"} width={"25px"} />)}
                        
                      />
                    }
                    label={val?.label}
                  />
                  
                  {val?.state === 'active' ? getAutocomplete(val, idx) : null}

                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12} container alignItems="center">
                <Grid item>
                  <IconButton onClick={() => setExistCondExpand(!existCondExpand)}>
                    {existCondExpand ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography>
                    Existing Conditions
                  </Typography>
                </Grid>
              </Grid>

                <Grid item container xs={12}>
                  <Collapse in={existCondExpand} style={{width: "100%"}}>
                  <GenericCardTable
                    cardTitle={"Diagnosis Details"}
                    suggestedTitle={"Suggested Diagnosis"}
                    tableHeaders={cardTableHeaders[0]}
                    tableData={cardState}
                    tabSwitching={true}
                    suggestedTab={0}
                    suggestedData={[]}
                    cardKey="existConditions"
                    cardName={"a parameter"}
                    setTableData={setCardState}
                    // addOnFunction={autoCompleteDiagnose}
                    frequentOptions={[]}
                    headerReq={false}
                  />
                  </Collapse>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{marginTop: existCondExpand ? "10px" : "0px"}}>
              <Divider />
            </Grid>
            
            <Grid item container xs={12}>
              <Grid item xs={12} container alignItems="center">
                <Grid item>
                  <IconButton onClick={() => setCurrentMedExpand(!currentMedExpand)}>
                    {currentMedExpand ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography>
                    Current Medications
                  </Typography>
                </Grid>
              </Grid>

                <Grid item container xs={12}>
                  <Collapse in={currentMedExpand} style={{width: "100%"}}>
                  <GenericCardTable
                    cardTitle={"Diagnosis Details"}
                    suggestedTitle={"Suggested Diagnosis"}
                    tableHeaders={cardTableHeaders[1]}
                    tableData={cardState}
                    tabSwitching={true}
                    suggestedTab={0}
                    suggestedData={[]}
                    cardKey="currentMeds"
                    cardName={"a parameter"}
                    setTableData={setCardState}
                    // addOnFunction={autoCompleteDiagnose}
                    frequentOptions={[]}
                    headerReq={false}
                  />
                  </Collapse>
                </Grid>
            </Grid>


          </Grid>
        </Collapse>

        <Collapse in={!prevHistoryExpand} style={{width: "100%"}}>
          <Grid container spacing={2}>
          {historyKey?.length && historyKey?.find(f => f?.checked) ? <Grid item xs={12}>
              <Typography>
                <b>Lifestyle Habits: </b>
                {getLifestyleHabits()}
              </Typography>
            </Grid> : null}
            {cardState?.existConditions?.length ? <Grid item xs={12}>
              <Typography>
                <b>Existing Conditions: </b>
                {getExistingConditions()}
              </Typography>
            </Grid> : null}
            {cardState?.currentMeds?.length ? <Grid item xs={12}>
              <Typography>
                <b>Current Medications: </b>
                {getCurrentMedications()}
              </Typography>
            </Grid> : null}
          </Grid>
        </Collapse>
      </Grid> : 
      <Grid item xs={12}>
        <Typography style={{opacity: "70%"}}>
          Uncheck "no known medical history" to add patient medical history
        </Typography>
      </Grid>}

    </Grid>
  )
}
export default PreviousHistory
