import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import noMedicalHistory from "./../../../assets/img/consultation/NoMedicalHistory.png"
function PatientAssessment({ patientData, setLoading }) {
  const [patientHistory, setPatientHistory] = useState(null)
  const doctor = useSelector((state) => state?.user?.user?._id)
  useEffect(() => {
    setLoading(true)
    const response = patientData?.patientCharacterstic?.find((ele) => {
      return ele.docId === doctor
    })
    console.log("console", response)
    if (Array.isArray(response?.data)) setPatientHistory(response)
    setLoading(false)
    // eslint-disable-next-line
  }, [])
  const getTextField = (type, ele, idx) => {
    console.log("type", type)
    switch (type) {
      case "text":
        return (
          <Grid item xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              value={ele.answer}
              type="text"
              disabled
              variant="outlined"
              color="primary"
            ></TextField>
          </Grid>
        )
      case "date":
        return (
          <Grid item xs={12}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              variant="outlined"
              type="date"
              disabled
              color="primary"
              value={ele.answer}
            ></TextField>
          </Grid>
        )
      case "checkbox":
        const answer = ele?.answer ? JSON.parse(ele.answer) : []
        console.log("answer", answer)
        return (
          <Grid item xs={12}>
            {ele.options.map((option, optionIndex) => {
              return (
                <FormControlLabel
                  disabled
                  label={option}
                  checked={
                    answer.findIndex((element) => element === optionIndex) >= 0
                      ? true
                      : false
                  }
                  control={
                    <Checkbox color="primary" inputProps={{ disabled: true }} />
                  }
                />
              )
            })}
          </Grid>
        )
      case "radio":
        return (
          <Grid item xs={12}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={ele.answer}
              disabled
            >
              {ele?.options?.map((option, optionIndex) => (
                <FormControlLabel
                  label={option}
                  disabled
                  control={
                    <Radio inputProps={{ readOnly: true }} color="primary" />
                  }
                  value={option}
                />
              ))}
            </RadioGroup>
          </Grid>
        )
      default:
        return (
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Short text"
              type="text"
              color="primary"
            ></TextField>
          </Grid>
        )
    }
  }

  return (
    <Grid
      container
      style={{
        padding: "1rem",
        backgroundColor: "#fff",
        borderRadius: "0px 40px 0px 40px",
        minHeight: "90vh",
      }}
    >
      <Grid item xs={12}>
        {patientHistory ? (
          <>
            <Grid item xs={12} style={{ marginBottom: "2rem" }}>
              <Typography variant="h6" align="center" color="primary">
                Last updated by patient on{" "}
                {moment.utc(patientHistory?.date).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
            {patientHistory?.data?.map((ele, idx) => (
              <Grid
                item
                xs={12}
                container
                spacing={1}
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={12}>
                  <Typography>{ele.question}</Typography>
                </Grid>
                <Grid xs={12}>{getTextField(ele.type, ele, idx)}</Grid>
              </Grid>
            ))}
          </>
        ) : (
          <Grid style={{ textAlign: "center", marginTop: "2rem" }}>
            <img src={noMedicalHistory} alt="No medical history"></img>
            <Typography variant="h5">No Records Found.</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default PatientAssessment
