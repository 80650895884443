import React from "react";
import Col from "reactstrap/lib/Col";
import { Row, Button } from "reactstrap/lib";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import LoadingAnimation from "../../../examples/LoadingAnimation";
import Label from "reactstrap/lib/Label";
import { toast } from "react-toastify";
import displayAge from "../../../../../assets/functions/displayAge";
function AddByPhoneNo({
  modalData,
  setModalData,
  isLoading,
  patientList,
  handleBookAppointment,
  setActiveStep,
}) {
  console.log("987", modalData);
  const buttons = [
    {
      label: "Book Appointment",
      onClick: (modalData) => handleBookAppointment(modalData),
    },
    {
      label: "Schedule Appointment for Later",
      onClick: (modalData) => {
        if (!modalData.patientId) toast.error("Please select a patient!");
        else setActiveStep(4);
      },
    },
    {
      label: "Add New Patient",
      onClick: (modalData) => setActiveStep(2),
      color: "secondary",
    },
  ];
  return (
    <>
      <div className="modal-body">
        <Row>
          <Col md="12" className={"pb-4"}>
            <Label className="md-2">Patient Name</Label>

            <Autocomplete
              options={patientList}
              getOptionLabel={(option) => `${option?.name}, ${displayAge(option)}`}
              fullWidth
              openOnFocus={true}
              freeSolo
              // onInputChange={(e, value, reason) => {
              //   if (reason === "input")
              //     setHolders({ ...holders, symptoms: value });
              //   if (reason === "clear") setHolders({ ...holders, symptoms: "" });
              // }}
              onChange={(e, value, reason) => {
                setModalData({
                  ...modalData,
                  ...value,
                  patientId: value ? value?._id : null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  placeholder="Search for Patient"
                  {...params}
                  variant="outlined"
                />
              )}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        {buttons.length
          ? buttons.map((ele) => (
              <>
                {" "}
                <Col md="12" className="row text-center">
                  {!isLoading && (
                    <Button
                      color={ele.color ? ele.color : "primary"}
                      type="button"
                      block
                      onClick={() => {
                        ele.onClick(modalData);
                      }}
                    >
                      {ele.label}
                    </Button>
                  )}
                </Col>
              </>
            ))
          : null}
        <Col md="12" className="row text-center">
          {isLoading && (
            <LoadingAnimation height={35} width={35}></LoadingAnimation>
          )}
        </Col>
      </div>
    </>
  );
}

export default AddByPhoneNo;
