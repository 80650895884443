import React from "react"
import { FormGroup, Input, Col, Row, Modal } from "reactstrap"
import Button from "reactstrap/lib/Button"
import InputGroup from "reactstrap/lib/InputGroup"
import InputGroupAddon from "reactstrap/lib/InputGroupAddon"
import Label from "reactstrap/lib/Label"
import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"

function GenericModal({
  isOpen,
  setIsOpen,
  modalData,
  setModalData,
  headers,
  handleSubmit,
  title,
  loading = false,
  message,
  saveButtonLabel,
  discardButtonLabel,
}) {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {title ? title : "Add New Info"}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setIsOpen(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {message && (
          <Row>
            <Col md="12">{message ? message : null}</Col>
          </Row>
        )}

        <Row className="mt-4">
          {headers?.map((ele, key) => (
            <Col md={ele.fullWidth ? "12" : "6"} key={key}>
              <FormGroup>
                <Label className="md-2">{ele.label}</Label>
                <InputGroup>
                  {ele.addOnType && ele.addOnType === "prepend" && (
                    <InputGroupAddon addonType={ele.addOnType}>
                      {ele.addOn}
                    </InputGroupAddon>
                  )}
                  <Input
                    id={key}
                    key={key}
                    onChange={(e) => {
                      const temp = modalData
                      if (ele?.type === "text" || !ele?.type)
                        temp[ele.key] =
                          e.target.value.length <
                          (ele?.limit ? ele?.limit : 100)
                            ? e.target.value
                            : temp[ele.key]
                      else temp[ele.key] = e.target.value

                      setModalData({ ...temp })
                    }}
                    value={modalData[ele.key]}
                    type={ele.type ? ele.type : "text"}
                    placeholder={ele.placeholder ? ele.placeholder : ""}
                  >
                    {ele?.type
                      ? ele?.type === "select"
                        ? ele?.options?.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))
                        : null
                      : null}
                  </Input>
                  {ele.addOnType && ele.addOnType === "append" && (
                    <InputGroupAddon addonType={ele.addOnType}>
                      {ele.addOn}
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
          ))}
        </Row>
      </div>
      <div className="modal-footer">
        {loading && (
          <Col md="12" className="text-center">
            <LoadingAnimation />
          </Col>
        )}
        {!loading && (
          <>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              {discardButtonLabel ? discardButtonLabel : "Discard"}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => handleSubmit(modalData)}
            >
              {saveButtonLabel ? saveButtonLabel : "Save"}
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default GenericModal
