import React, { useEffect, useState } from "react"
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
  FormControlLabel,
  Switch,
  TextField,
  Divider,
  Avatar,
  CircularProgress,
} from "@material-ui/core"
import "./index.css"
import GenericDialogForm from "./GenericDialog/index"
import { ArrowDropDown, DeleteForeverOutlined, Save, Settings, VerticalSplit } from "@material-ui/icons"
import Vitals from "./Vitals"
import Symptoms from "./Symptoms"
import Diagnosis from "./Diagnosis"
import Drugs from "./Drugs"
import Tests from "./Tests"
import FollowUp from "./followUp"
import customAxios from "../../axios"
import { toast } from "react-toastify"
import Exercises from "./Exercises"
import AdditionalDocs from "./AdditionalDocs"
import PreviousHistory from "./PreviousHistory"
import SystemExamination from "./SystemExamination"
import EndConsultation from "../Consultation(new)/EndConsultation"
import SettingModal from "./SettingModal"
import moment from "moment"
import { Autocomplete } from "@material-ui/lab"
import LabResults from "./LabResults"
import EditRxTemplate from "./EditRxTemplate"
import { useSelector } from "react-redux"


const useStyles = makeStyles({
  card: {
    background: "#FFFFFF",
    padding: "0.8rem !important",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
    marginTop: "0.6rem",
  },
})

function CreatePrescription({
  docId,
  previousState,
  historyColumnFreeze,
  setHistoryColumnFreeze,
  state,
  setState,
  setActiveStep,
  setLoading,
  requiredKeys = [],
  preferences,
  patientData = null,
  handleEndConsultation,
  loading,
  appointmentData,
  printModalData,
  setPrintModalData,
  loader,
  templateList,
  setTemplateList
}) {
  const classes = useStyles()
  // console.log("docId", docId)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openTemplate, setOpenTemplate] = useState(false)
  const [clearCounter, setClearCounter] = useState(0)
  const [deleteTemplate, setDeleteTemplate] = useState(false)

  const defaultPadList = [
    { id: 1, text: 'Vitals', checked: true },
    { id: 2, text: 'System Examination', checked: true },
    { id: 3, text: 'Symptoms', checked: true },
    { id: 4, text: 'Diagnosis', checked: true },
    { id: 5, text: 'Previous History', checked: true },
    { id: 6, text: 'Drug Details', checked: true },
    // { id: 7, text: 'Exercise', checked: false },
    { id: 8, text: 'Upload Health Records', checked: true },
    { id: 9, text: 'Test Details', checked: true },
    { id: 10, text: 'Lab Results', checked: true },
    { id: 11, text: 'Advice', checked: true },
    { id: 12, text: 'Follow Up', checked: true },
  ]

  const isOH = useSelector((state) => state.user?.user?.ohID)


  const [settingModal, setSettingModal] = useState(false)
  const [rxTemplateModal, setRxTemplateModal] = useState(false)
  const [settingList, setSettingList] = useState(defaultPadList)

  const getSettingPadList = () => {
    customAxios.get("doctor/prescription-config").then((res) => {
      if(res.data?.data){
        setSettingList([...res?.data?.data?.padList])
      }
    })
  }

  // useEffect(() => {
  //   const handleNavigation = (event) => {
  //     const isBackButtonPressed = event.state && event.state.backButtonPressed;
  //     alert("yo")
  //     if (isBackButtonPressed) {
  //       // Your custom logic when the back button is pressed
  //       console.log('Back button pressed');
  //     }
  //   };

  //   window.addEventListener('popstate', handleNavigation);

  //   return () => {
  //     window.removeEventListener('popstate', handleNavigation);
  //   };
  // }, []);

  const [frequentData, setFrequentData] = useState({})
  const getFrequentData = () => {
    customAxios.get(`ai/RxFrequents?doc_id=${docId}`).then((res) => {
      if(res.data){
        setFrequentData({...res?.data})
      }
    })
  }

  useEffect(() => {
    getFrequentData()
  }, [docId])

  useEffect(() => {
    getSettingPadList();
  }, [])

  const handleSettingSave = (dataList) => {
    customAxios.put("doctor/prescription-config", {"padList": [...dataList]}).then((res) => {
      if(res?.data?.success){
        getSettingPadList();
        toast.success("Updated Successfuly!")
      }
    })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  // console.log("previousState", previousState)
  const handleSave = (state) => {
    let error = {}

    requiredKeys.map((v) => {
      if (!state[v.key] || !state[v.key].length) {
        error = { check: true, message: `${v.label} is required` }
      }
    })
    if (error?.check) {
      toast.error(error.message)
    } else {
      return true
    }
    return false
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [adviceList, setAdviceList] = useState([])
  useEffect(() => {
    customAxios
      .get(`consultation/filter?doc_id=${docId}&is_template=true`)
      .then((res) => {
        if (res.data.success) {
          setTemplateList(res?.data?.data)
          setAdviceList(res?.data?.advice_list)
        }
      })
  }, [docId])
  // console.log(state, "state")
  const dialogContent = [
    {
      label: "Template Name",
      key: "templateName",
      placeholder: "eg. Fever",
      type: "text",
      md: 12,
      maxLength: "15",
      required: true,
    },
  ]

  const [activeIndex, setActiveIndex] = useState({"index": 0, "from": -1});


  function handleKeyDown(event, padIndex) {
   if (event.key === "ArrowUp" && padIndex > 0) {
      setActiveIndex({"index": padIndex - 1, "from": padIndex})
    } else if (event.key === "ArrowDown")
      setActiveIndex({"index": padIndex + 1, "from": padIndex})
  }

  const getExistingConditions = () => {
    let text = "";

    if(previousState?.prescription?.existConditions?.length)
    previousState?.prescription?.existConditions?.map((v, i) => {
      if(typeof(v) === "object" && Object?.keys(v).length > 0) {
        ["name", "status", "duration"]?.map(key => {
          if(v?.[key] && key === "name"){
            text += `${v?.[key]} `
          } else if(v?.[key] && key === 'status'){
            text += `(${v?.[key]})`
          } else if(v?.[key] && key === 'duration'){
            text += ` - ${v?.[key]}`
          }
        })
      }
      if(i < previousState?.prescription?.existConditions?.length - 1){
        text += ", "
      }
    });

    return text
  }

  const getCurrentMedications = () => {
    let text = "";

    if(previousState?.prescription?.currentMeds?.length)
    previousState?.prescription?.currentMeds?.map((v, i) => {
      if(typeof(v) === "object" && Object?.keys(v).length > 0) {
        ["name", "status", "since", "duration"]?.map(key => {
          if(v?.[key] && key === "name"){
            text += `${v?.[key]} `
          } else if(v?.[key] && key === 'status'){
            text += `(${v?.[key]})`
          } else if(v?.[key] && key === 'since'){
            text += ` - ${v?.[key]}`
          } else if(v?.[key] && key === 'duration'){
            text += `: ${v?.[key]}`
          }
        })
      }
      if(i < previousState?.prescription?.currentMeds?.length - 1){
        text += ", "
      }
    });

    return text
  }


  const getLifestyleHabits = () => {
    let text = "";

    if(previousState?.prescription?.habits?.length)
    previousState?.prescription?.habits?.map((v, i) => {
      if(v?.checked){
        if(typeof(v) === "object" && Object?.keys(v)?.length > 0) {
          ["label", "state", "value"]?.map(key => {
            if(v?.[key] && key === "label"){
              text += `${v?.[key]} `
            } else if(v?.[key] && key === 'state'){
              text += `(${v?.[key]})`
            } else if(v?.[key] && key === 'value'){
              text += ` - ${v?.[key]}`
            }
          })
        }
        if(previousState?.prescription?.habits?.[i+1]?.checked){
          text += ", "
        }
      }
    });

    return text
  }


  return (
    <Grid
      container
      spacing={1}
      id="prescriptionContainer"
      className="createPrescription"
    >
      <Grid item xs={12} md={6} style={{ display: "flex", flex: "flex-wrap", alignItems: "center", position:"relative", marginBottom: "-1rem" }}>

        <Typography variant="h5" style={{ fontWeight: "600" }}>
          Create
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          style={{ marginLeft: 5, fontWeight: "600", marginRight: 10 }}
        >
          Prescription
        </Typography>
        <IconButton style={{padding: 5}} onClick={() => setSettingModal(true)} title="Configure Your Prescription Pad">
           <Settings color="primary"/>
        </IconButton>
        {/* <Button variant="text" size="small" startIcon={<Settings color="primary"/>} onClick={() => setSettingModal(true)} color="primary" title="Configure Your Prescription Pad">
          Configure Rx Section
        </Button> */}

        {isOH ? 
        <IconButton style={{padding: 5}} onClick={() => setRxTemplateModal(true)} title="Edit Prescription Template">
           <VerticalSplit color="primary"/>
        </IconButton>
        // <Button variant="text" size="small" startIcon={<VerticalSplit color="primary"/>} style={{marginLeft: "10px"}} color="primary" onClick={() => setRxTemplateModal(true)} title="Edit Prescription Template">
        //   Change Rx Layout
        // </Button>
         : null}

        {/* <div style={{position: "absolute", bottom: -10, left: 10, fontSize: "11px"}}>
          Powered By 
          <span style={{fontWeight: "600", marginLeft: "4px", fontSize: "13px"}}>  
            Paper<span style={{
              color: "#4D63DD",
              // background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
              // WebkitBackgroundClip: 'text',
              // WebkitTextFillColor: 'transparent', 
              // fontWeight: "bold",
              // marginLeft: 3,
              // fontSize: "16px"
              }}>plane</span>
          </span>
        </div> */}
      </Grid>
      <Grid item container xs={12} md={6} spacing={1} justifyContent="flex-end">
        <Grid item xs={6} md={"auto"}>
          <Button
            fullWidth
            variant={"outlined"}
            color="primary"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                saveTemplate: false,
                medicines: [],
                diagnosis: [],
                symptoms: [],
                tests: [],
                vitals: [],
                vitalUpdate: true,
                exercises: [],
              }))
            }
          >
            Clear Prescription
          </Button>
        </Grid>
        <Grid item xs={6} md={"auto"}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            fullWidth
            disabled={!templateList.length}
            endIcon={<ArrowDropDown />}
            variant={"contained"}
            color="primary"
            onClick={handleClick}
          >
            Use Template
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {templateList?.map((template) => (
              <MenuItem
                onClick={() => {
                  toast.success("Template applied successfully!")
                  setState((prev) => ({
                    ...prev,
                    medicines: template.prescription.medicines
                      ? template.prescription.medicines
                      : [],
                    diagnosis: template.prescription.diagnosis
                      ? template.prescription.diagnosis
                      : [],
                    symptoms: template.prescription.symptoms
                      ? template.prescription.symptoms
                      : [],
                    exercises: template.prescription.exercises
                      ? template.prescription.exercises
                      : [],
                    tests: template.prescription.tests
                      ? template?.prescription?.tests?.map((ele) => ({
                          name: ele,
                        }))
                      : [],
                    otherData: prev?.otherData ? {...prev?.otherData, templateUsed: true, templateName: template?.templateName} : {templateUsed: true, templateName: template?.templateName}
                  }))
                  handleClose()
                }}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>{template?.templateName?.slice(0, 15)} </Typography>
                {/* <IconButton>
                  <DeleteForeverOutlined color="error" />
                </IconButton> */}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      {/* {previousState ? (
        <Grid
          item
          container
          xs={12}
          style={{
            background: "#fff",
            padding: "1rem",
            marginTop: "1rem",
            minHeight: "50px",
            opacity: "1",
            zIndex: "1",
            position: historyColumnFreeze ? "sticky" : "inherit",
            top: historyColumnFreeze ? "0" : "inherit",

            borderRadius: historyColumnFreeze ? "12px 12px 0px 0px" : "12px",
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Previous Diagnosis Details:</Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Freeze this section"
                control={
                  <Switch
                    color="primary"
                    checked={historyColumnFreeze}
                    onChange={(e) => {
                      setHistoryColumnFreeze(e.target.checked)
                    }}
                  />
                }
              ></FormControlLabel>
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "24px",
            }}
          ></div>
          <Grid
            container
            item
            xs={12}
            style={{
              background: "#f3f3f3",
              padding: "0.5rem",
              borderRadius: "12px",
            }}
          >
            <Grid item xs={12}>
              <Typography>
                Consulted at{" "}
                {moment.utc(previousState?.date).format("hh:MM A, DD MMM YYYY")}
              </Typography>
            </Grid>
            
            {previousState?.vitals?.length ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Vitals:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  {previousState?.vitals?.map((ele) => (
                    <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {ele.metricName}
                        </span>
                        : <span>{ele.metricQuantity}</span> {ele.metricUnit}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}

            {previousState?.prescription?.habits?.length ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Lifestyly Habits:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                  {getLifestyleHabits()}
                      </Typography></Grid>
                </Grid>
              </Grid>
            ) : null}

            {previousState?.prescription?.existConditions?.length ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Existing Conditions:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                  {getExistingConditions()}
                      </Typography></Grid>
                </Grid>
              </Grid>
            ) : null}

            {previousState?.prescription?.currentMeds?.length ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Current Medications:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                  {getCurrentMedications()}
                  </Typography></Grid>
                </Grid>
              </Grid>
            ) : null}


            {previousState?.symptoms?.length ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Symptoms:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  {previousState?.symptoms?.map((ele) => (
                    <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {ele.name}
                        </span>
                        :{" "}
                        <span>
                          {ele.severity ? ele.severity : null}{" "}
                          {ele.onset ? "-" + ele.onset : null}{" "}
                        </span>
                        {ele.duration ? "-" + ele.duration + " days" : null}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}

            {previousState?.diagnosis?.length ? (
              <Grid item xs={12} container style={{ marginTop: "0.5rem" }}>
                <Grid item xs={12}>
                  <Typography style={{ textDecoration: "underline" }}>
                    Diagnosis:
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  style={{ marginLeft: "1rem" }}
                >
                  {previousState?.symptoms?.map((ele) => (
                    <Grid item style={{ fontSize: "13px", color: "#000D85" }}>
                      <Typography>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {ele.name}
                        </span>
                        <span>{ele.status ? ele.status : null} </span>{" "}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null} */}

      <Grid container item xs={12}>
        {historyColumnFreeze ? (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        ) : null}

          {settingList?.filter(f => f?.checked)?.map((padData, padIndex) => (
            <React.Fragment>

              {preferences.includes("Vitals") && padData?.text === "Vitals" && padData?.checked ? (
                <Grid item xs={12} className={classes.card}>
                  <Vitals
                    docId={docId}
                    clearCounter={clearCounter}
                    state={state}
                    setState={setState}
                    padIndex={padIndex}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    patientId={appointmentData?.patientId}
                    loading={loading}
                  />
                </Grid>
              ) : null}

              {padData?.text === "System Examination" && padData?.checked ? <Grid item xs={12} className={classes.card}>
                <SystemExamination state={state} setState={setState} docId={docId} padIndex={padIndex}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}/>
              </Grid> : null}

              {padData?.text === "Previous History" && padData?.checked ? <Grid item xs={12} className={classes.card}>
                <PreviousHistory
                  state={state}
                  setState={setState}
                  docId={docId}
                  preferences={preferences}
                  padIndex={padIndex}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  previousHistory={previousState}
                />
              </Grid> : null}
              
              {preferences.includes("Symptoms") && padData?.text === "Symptoms" && padData?.checked ? (
                <Grid item xs={12} className={classes.card}>
                  <Symptoms state={state} setState={setState} docId={docId} padIndex={padIndex}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    frequentData={frequentData}
                    />
                </Grid>
              ) : null}

              {preferences.includes("Diagnosis") && padData?.text === "Diagnosis" && padData?.checked ? (
                <Grid item xs={12} className={classes.card}>
                  {preferences.includes("GynacVitals") ? (
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                      style={{ marginTop: "1rem" }}
                    >
                      <Grid item container xs={6}>
                        <Grid item xs={12}>
                          <Typography color="textPrimary">LMP</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            value={state?.otherData?.LMP}
                            fullWidth
                            variant="outlined"
                            placeholder="None"
                            onChange={(e) => {
                              setState({
                                ...state,
                                otherData: {
                                  ...state?.otherData,
                                  LMP: e.target.value,
                                },
                              })
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={6}>
                        <Grid item xs={12}>
                          <Typography color="textPrimary">EDD</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            value={state?.otherData?.EDD}
                            fullWidth
                            placeholder="None"
                            variant="outlined"
                            onChange={(e) => {
                              setState({
                                ...state,
                                otherData: {
                                  ...state?.otherData,
                                  EDD: e.target.value,
                                },
                              })
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Diagnosis state={state} setState={setState} docId={docId} padIndex={padIndex}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  frequentData={frequentData}
                  />
                </Grid>
              ) : null}


              {preferences.includes("Drugs") && padData?.text === "Drug Details" && padData?.checked ? (
                <Grid item xs={12} className={classes.card}>
                  <Drugs
                    state={state}
                    setState={setState}
                    docId={docId}
                    previousState={previousState}
                    padIndex={padIndex}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    frequentData={frequentData}
                  />
                </Grid>
              ) : null}

              {preferences.includes("Exercise") ? (
                <Grid item xs={12} className={classes.card}>
                  <Exercises state={state} setState={setState} docId={docId} padIndex={padIndex}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}/>
                </Grid>
              ) : null}

              {preferences.includes("Tests") && padData?.text === "Test Details" && padData?.checked ? (
                <Grid item xs={12} className={classes.card}>
                  <Tests state={state} setState={setState} docId={docId} padIndex={padIndex}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    frequentData={frequentData}
                    />
                </Grid>
              ) : null}
              {/* {preferences.includes("VaccinationChart") ? <div>Helo</div> : null}
            <Grid item xs={12} className={classes.card}>
              <VaccinationChart
                state={state}
                patientData={patientData}
                setState={setState}
              />
            </Grid> */}

            {padData?.text === "Lab Results" && padData?.checked ? (
              <Grid item xs={12} className={classes.card}>
                <LabResults state={state} setState={setState} docId={docId} padIndex={padIndex}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  frequentData={frequentData}
                  patientData={patientData}
                  />
              </Grid>
            ) : null}

            {padData?.text === "Advice" && padData?.checked ?
              <Grid item xs={12} container className={classes.card}>
                <Grid item xs={12} style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                  <Avatar style={{
                    fontSize: 18,
                    width: 30,
                    height: 30, 
                    marginRight: 10, 
                    color: "#5e72e4", 
                    backgroundColor: "white",
                  }}>
                    <i className="fa-regular fa-note-sticky"></i>
                  </Avatar>
                  <Typography variant="h6" style={{fontWeight: "600"}}>Advice</Typography>
                </Grid>
                <Autocomplete
                  options={adviceList}
                  getOptionLabel={(option) => option}
                  freeSolo={true}
                  openOnFocus
                  fullWidth
                  value={state?.otherData?.advice}
                  onChange={(e, val) => {
                    setState({
                      ...state,
                      otherData: { ...state?.otherData, advice: val },
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder={"e.g Take meds with milk"}
                      variant="outlined"
                      // onChange={(e) => {
                      //   if (field.onChange) field.onChange(e.target.value, state, setState)
                      // }}
                      multiline
                      onChange={(e) => {
                        setState({
                          ...state,
                          otherData: { ...state?.otherData, advice: e?.target?.value },
                        })
                      }}

                      // onKeyDown={(e) => handleKeyDown(e, padIndex)}
                      
                      // inputRef={(ref) => {}}
                    />
                  )}
                />
              </Grid> : null}

              {padData?.text === "Follow Up" && padData?.checked ? <Grid item xs={12} className={classes.card}>
                <FollowUp state={state} setState={setState} docId={docId} padIndex={padIndex}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}/>
              </Grid> : null}


              {(padData?.text === "Upload Health Records" || padData?.text === "Supporting Documents") && padData?.checked ? <Grid item xs={12} className={classes.card}>
                <AdditionalDocs state={state} setState={setState} docId={docId} padIndex={padIndex}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}/>
              </Grid>: null}

            </React.Fragment>
          ))}

        <Grid item xs={6} style={{ marginTop: "2rem" }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={state.saveTemplate ? state?.saveTemplate : false}
                onChange={(e) => {
                  if (e.target.checked) setOpenTemplate(true)
                  else
                    setState({
                      ...state,
                      saveTemplate: e.target.checked,
                      templateName: null,
                    })
                }}
                name="checkedA"
              />
            }
            label={
              state?.templateName
                ? `Saving template as ${state?.templateName}`
                : `Save this prescription as a template`
            }
          />
        </Grid>
        <EndConsultation
          appointmentData={appointmentData}
          setLoading={setLoading}
          handleEndConsultation={handleEndConsultation}
          loading={loading}
          patientData={patientData}
          requiredKeys={requiredKeys}
          state={state}
          setState={setState}
          setActiveStep={setActiveStep}
          printModalData={printModalData}
          setPrintModalData={setPrintModalData}
          loader={loader}
        />
      </Grid>
      {/* <Grid item container justifyContent="flex-start">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave(state)}
        >
          Save & Continue
        </Button>
      </Grid>{" "} */}
      <GenericDialogForm
        open={openTemplate}
        handleClose={() => {
          setOpenTemplate(false)

          setState({ ...state, saveTemplate: false })
        }}
        dialogTitle={"Save Template"}
        dialogContent={dialogContent}
        handleCancel={() => {
          setOpenTemplate(false)
          setState({ ...state, saveTemplate: false })
        }}
        handleSubmit={(data) => {
          setState({
            ...state,
            templateName: data.templateName,
            saveTemplate: true,
          });
          setOpenTemplate(false)
        }}
        submitButtonText={"Save"}
      ></GenericDialogForm>
      <SettingModal settingModal={settingModal} setSettingModal={setSettingModal} onSubmit={handleSettingSave} dataList={[...settingList]} />
      <EditRxTemplate openModal={rxTemplateModal} setopenModal={setRxTemplateModal} />
    </Grid>
  )
}

export default CreatePrescription
