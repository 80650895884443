import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Card, makeStyles, FormControlLabel, Slider, TextField, Divider, CircularProgress } from '@material-ui/core';
import { BorderHorizontal, CheckBoxTwoTone, Close, DragHandle, History, Print, Refresh, TextRotateUp, TextRotationDown } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _, { defaults, update } from "lodash";
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import { Document, Page, pdfjs } from 'react-pdf';
import { range } from "lodash"
import customAxios from '../../../axios';

const useStyles = makeStyles((theme) => ({
    hoverAppointmentCard: {
      width: "100%", 
      padding: "10px", 
    //   cursor: "pointer", 
      marginBottom: 15, 
      borderRadius: 20,
      '&:hover': {
        background: "#EBF5FB",
      },
    },
  }));

export default function EditRxTemplate({openModal, setopenModal}) {

    const classes = useStyles()

    const handleClose = () => {
        setopenModal(false);
        setData({...resetState})
        // setRows(_.cloneDeep(dataList))
    };

    const handleSubmit = () => {
        setopenModal(false);
        updatePDFUrl({...data, toSave: true});
    };

    const [numPages, setNumPages] = useState(null);

    const defaultState = {
        noHeader: false,
        headerHeight: 5,
        noFooter: false,
        footerHeight: 6,
        fontSize: "medium",
        leftMargin: 2,
        rightMargin: 0,
    }

    const [resetState, setResetState] = useState({...defaultState})
    const [data, setData] = useState({...defaultState});

    useEffect(() => {
        if(openModal)
        customAxios.get('doctor/get-rx-template').then(res => {
            if(res?.data?.success && res?.data?.data){
                setData({...res?.data?.data})
                setResetState({...res?.data?.data})
            } else {
                setData({...defaultState})
            }
        })
    }, [(openModal)])

    const [printUrl, setPrintUrl] = useState(null)
    const [printUrlLoader, setPrintUrlLoader] = useState(false)

    useEffect(() => {
        if(printUrl){
            pdfjs.getDocument(printUrl).promise.then((pdf) => {
                setNumPages(pdf.numPages);
              });
        }
    }, [printUrl])

    const updatePDFUrl = (data) => {
        setPrintUrlLoader(true)
        customAxios.put('doctor/update-rx-template', {...data}).then(res => {
            if(res?.data?.success) {
                setPrintUrl(res?.data?.data);
            }
            setPrintUrlLoader(false)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        updatePDFUrl({...resetState})
    }, [resetState])

  const pdfRef = useRef(null);

  const [printLoad, setPrintLoad] = useState(false)

  const handlePrint = () => {

    setPrintLoad(true)
    customAxios.put('ai/print-pdf', {"url": printUrl}, {
      responseType: 'blob', // To receive binary data
    }).then(response => {
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      
      const iframe = document.getElementById('pdf-url-print-1');
      iframe.src = pdfUrl;

      setPrintLoad(false);
      iframe.onload = () => {
        if(iframe?.contentWindow && iframe?.contentWindow?.print)
        iframe.contentWindow.print();
        // Clean up: remove the iframe after printing is done
      };
    })

  }


  return (
    <React.Fragment>
      <Dialog open={openModal} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography style={{ fontSize: "24px" }}>
                        Edit {" "}
                        <span
                            style={{
                            // background: colors.gradient,
                            color: "white",
                            padding: "0.3rem",
                            borderRadius: "4px",

                            background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                            }}
                        >
                            Prescription Template
                        </span>{" "}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton aria-label="close" onClick={handleClose} stye={{padding: 5}}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={2} style={{overflow: "hidden"}}>
                {/* <Grid item xs={12}>
                    <Typography style={{fontWeight: "bold"}}>Order of Elements on PAD</Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                    <Typography variant='caption'>
                        You can enable/disable any section <Checkbox color="primary" checked={true} style={{padding: 0}}/> and change the order of writing by dragging <DragHandle />. 
                    </Typography>
                </Grid> */}
                <Grid item xs={12}>
                    <Typography>
                        * unit - A "unit" is an approximation of "cm" which can change as per printers / printing methods.  
                    </Typography>
                </Grid>
                <Grid item xs={4} spacing={2}>
                    <Grid container>
                        <Grid item container xs={12} spacing={1} alignItems='center'>
                            <Grid item style={{display: "flex", alignItems: "center"}}>
                                <TextRotateUp color='primary' />
                                <Typography variant={'h6'} style={{marginLeft: 5, fontWeight: "bold"}}>
                                    Header
                                </Typography>
                            </Grid>
                            <Grid item style={{marginLeft: "auto", display: "flex", alignItems: "center", marginTop: 10}}>
                                <FormControlLabel
                                    value={data?.noHeader}
                                    control={<Checkbox color="primary" checked={data?.noHeader} />}
                                    onChange={(e, checked) => {
                                        updatePDFUrl({...data, noHeader: checked})
                                        setData({...data, noHeader: checked})
                                    }}
                                    label="No Header"
                                    labelPlacement="start"
                                    />
                            </Grid>
                            <Grid item xs={12} container alignItems='center' spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Header Height
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{paddingLeft: 20}}>
                                    <Slider
                                        value={data?.headerHeight ?? 0}
                                        onChange={(e, value) => {
                                            setData({...data, headerHeight: value})
                                        }}
                                        onChangeCommitted={(e, val) => updatePDFUrl({...data, headerHeight: val})}
                                        max={20}
                                        aria-labelledby="input-slider"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField variant='outlined' size='small' 
                                        value={data?.headerHeight ?? 0}
                                        onBlurCapture={(e) => updatePDFUrl(data)}
                                        onChange={(e) => {
                                            const re = new RegExp("^[0-9\\-/.]*$")
                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                setData({...data, headerHeight: (e?.target?.value) })
                                            }
                                        }}
                                               inputProps={{maxLength: 4}}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography>unit</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={1} alignItems='center'>
                            <Grid item style={{display: "flex", alignItems: "center"}}>
                                <TextRotationDown color='primary' />
                                <Typography variant={'h6'} style={{marginLeft: 5, fontWeight: "bold"}}>
                                    Footer
                                </Typography>
                            </Grid>
                            <Grid item style={{marginLeft: "auto", display: "flex", alignItems: "center", marginTop: 10}}>
                                <FormControlLabel
                                    value={data?.noFooter}
                                    onChange={(e, checked) => {
                                        updatePDFUrl({...data, noFooter: checked})
                                        setData({...data, noFooter: checked})
                                    }}
                                    control={<Checkbox color="primary" checked={data?.noFooter} />}
                                    label="No Footer"
                                    labelPlacement="start"
                                    />
                            </Grid>
                            <Grid item xs={12} container alignItems='center' spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Footer Height
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{paddingLeft: 20}}>
                                    <Slider
                                        value={data?.footerHeight ?? 0}
                                        onChangeCommitted={(e, val) => updatePDFUrl({...data, footerHeight: val})}
                                        onChange={(e, value) => {
                                            setData({...data, footerHeight: value})
                                        }}
                                        max={20}
                                        aria-labelledby="input-slider"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField variant='outlined' size='small' 
                                        onBlurCapture={(e) => updatePDFUrl(data)}
                                        value={data?.footerHeight ?? 0}
                                        onChange={(e) => {
                                            const re = new RegExp("^[0-9\\-/.]*$")
                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                setData({...data, footerHeight: (e?.target?.value) })
                                            }
                                        }}
                                               inputProps={{maxLength: 4}}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography>unit</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={1} alignItems='center'>
                            <Grid item style={{display: "flex", alignItems: "center"}}>
                                <BorderHorizontal color='primary' />
                                <Typography variant={'h6'} style={{marginLeft: 5, fontWeight: "bold"}}>
                                    Layout
                                </Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12} container alignItems='center' spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Font Size
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ToggleButtonGroup
                                        // exclusive
                                        value={data?.fontSize}
                                        onChange={(e, value) => {
                                            // if (value !== null)
                                            setData({...data, fontSize: e?.target?.innerText?.toLowerCase()})

                                            updatePDFUrl({...data, fontSize: e?.target?.innerText?.toLowerCase()})
                                        }}
                                        aria-label="text alignment"
                                        color="primary"
                                        size="small"
                                        style={{paddingLeft: "50px"}}
                                        >
                                        <ToggleButton value="small" aria-label="left aligned">
                                            <Typography style={{textTransform: "none", marginLeft: 10, marginRight: 10, color: 'black'}}>
                                                Small
                                            </Typography>
                                        </ToggleButton>
                                        <ToggleButton value="medium" aria-label="centered">
                                            <Typography style={{textTransform: "none", marginLeft: 10, marginRight: 10, color: 'black'}}>
                                                Medium
                                            </Typography>                                    </ToggleButton>
                                        <ToggleButton value="large" aria-label="right aligned">
                                            <Typography style={{textTransform: "none", marginLeft: 10, marginRight: 10, color: 'black'}}>
                                                Large
                                            </Typography>                                    </ToggleButton>
                                        {/* <ToggleButton value="last_month" aria-label="right aligned">
                                            Last Month
                                        </ToggleButton> */}
                                        </ToggleButtonGroup>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Page Spacing (unit)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={11} container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Left Margin
                                        </Typography>
                                    </Grid>
                                    <Grid item container xs={12} alignItems='center' spacing={1}>
                                        <Grid item xs={8} style={{paddingLeft: "20px"}}>
                                            <Slider
                                                value={data?.leftMargin}
                                                onChangeCommitted={(e, val) => updatePDFUrl({...data, leftMargin: val})}
                                                onChange={(e, value) => {
                                                    console.log(e)
                                                    setData({...data, leftMargin: value})
                                                }}
                                                max={10}
                                                aria-labelledby="input-slider"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField variant='outlined' size='small' 
                                                value={data?.leftMargin ?? 0}
                                                onBlurCapture={e => updatePDFUrl(data)}
                                                onChange={(e) => {
                                                    const re = new RegExp("^[0-9\\-/.]*$")
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        setData({...data, leftMargin: (e?.target?.value) })
                                                    }
                                                }}
                                                       inputProps={{maxLength: 4}}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography>unit</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Right Margin
                                        </Typography>
                                    </Grid>
                                    <Grid item container xs={12} alignItems='center' spacing={1}>
                                        <Grid item xs={8} style={{paddingLeft: "20px"}}>
                                            <Slider
                                                value={data?.rightMargin}
                                                onChangeCommitted={(e, val) => updatePDFUrl({...data, rightMargin: val})}
                                                onChange={(e, value) => {
                                                    console.log(e)
                                                    setData({...data, rightMargin: value})
                                                }}
                                                max={10}
                                                aria-labelledby="input-slider"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField variant='outlined' size='small' 
                                                value={data?.rightMargin ?? 0}
                                                onBlurCapture={e => updatePDFUrl(data)}
                                                onChange={(e) => {
                                                    const re = new RegExp("^[0-9\\-/.]*$")
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        setData({...data, rightMargin: (e?.target?.value) })
                                                    }
                                                }}
                                                       inputProps={{maxLength: 4}}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography>unit</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} style={{position: "relative"}}>
                    <Grid container style={{display: "flex", justifyContent: 'center', alignItems: "center", maxHeight: "70vh", overflowY: "auto"}}>
                    <iframe
                        id="pdf-url-print-1"
                        ref={pdfRef}
                        // src={printUrl}
                        title="PDF Viewer"
                        style={{ display: 'none' }} // Hide the iframe
                    />
                    {!printUrlLoader && printUrl ?
                        <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '100%', minHeight: "100%", maxWidth: "100%", overflowX: "auto", overflowY: "hidden"}}>
                        <Document file={printUrl}>
                            {numPages && range(0, numPages)?.map((v, i) => (
                                <div style={{marginBottom: 10, border: "1px solid"}}><Page pageNumber={i+1} width={window.innerWidth*0.25} renderTextLayer={false} renderAnnotationLayer={false}/></div>
                            ))}
                        </Document></div> : (printUrlLoader ? (
                            <CircularProgress color='primary' style={{position: "absolute", top: "40%", right: "50%"}} />
                        ) : null)}
                    </Grid>
                {/* <Divider orientation='vertical' /> */}

                    <div style={{position: "absolute", top: "40%", right: "10%"}}>
                        <div>
                            <Button color='primary' startIcon={<Refresh />} onClick={() => updatePDFUrl(data)}>
                                Refresh
                            </Button>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <Button color='primary' startIcon={!printLoad ? <Print /> : <CircularProgress size={22} color='primary'/>} onClick={() => handlePrint()}>
                                Print
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button startIcon={<History />} onClick={() => {
                setData({...resetState})
                updatePDFUrl({...resetState})
                }} style={{marginRight: "auto"}}>Reset</Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
