import { Grid, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import customAxios from "../../axios";

const PreviousVitals = ({ id, dummyCounter }) => {
  const [vitals, setVitals] = useState([]);
  useEffect(() => {
    customAxios
      .get(`/vital/${id}`)
      .then((res) => {
        if (res.data.success) {
          const temp = res.data.data.vitals;
          temp.reverse();
          setVitals([...temp]);
        }
      })
      .catch((err) => console.log(err));
  }, [dummyCounter, id]);

  return (
    <Grid container style={{ padding: "2rem" }} spacing={3}>
      <Grid item xs={12}>
        {vitals.length ? (
        <Typography style={{fontWeight: "600", fontSize: "16px"}}>Vitals History</Typography>
          // <h2>Vitals History:</h2>
        ) : null}
      </Grid>
      {vitals?.length ? vitals?.filter(v => v?.data?.find(f => f?.metricName && f?.metricName !== ""))?.map((ele) => (
        <Grid
          item
          // container
          md={4}
          style={{
            background: "#fff",
            padding: "1rem",
            height: 'auto',
            width: "30%",
            maxWidth: "30%",
            borderRadius: "10px",
            marginRight: "2%",
            marginBottom: "10px",
            boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid item xs={12} style={{ margin: "0.5rem 0rem" }}>
            <Typography variant="h6" style={{ fontWeight: "500" }}>
              {moment(ele.date).format("DD MMMM YYYY")}
            </Typography>
          </Grid>
          {ele?.data?.length ? ele.data.map((vital) => (
            <Grid
              item
              container
              spacing={1}
              xs={12}
              alignItems="center"
              style={{ marginTop: "0.5rem" }}
            >
              <Grid item>
                <Typography>{vital.metricName}</Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ maxWidth: "100px" }}
                  value={vital.metricQuantity}
                  variant="outlined"
                ></TextField>
              </Grid>
              <Grid item>{vital.metricUnit ? vital.metricUnit : null}</Grid>
            </Grid>
          )) : null}
        </Grid>
      )) : null}
    </Grid>
  );
};

export default PreviousVitals;
