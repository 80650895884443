import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Button, Grid, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import { FormatBold, FormatItalic, KeyboardReturn } from '@material-ui/icons';
import QuillTextField from './quillTextField';
import quickAnimation from "../../../assets/animationData/115064-electric-shock-quick.json" 
import GenericAnimation from '../../animation';

export default function CustomTextField ({ handleSendMessage, patient, menuActions, onFileUpload }) {
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const anchorRef = useRef(null);

  const handleInputChange = (event) => {
    if(event?.nativeEvent?.inputType !== "insertLineBreak")
      setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if(event.keyCode === 13 && event?.shiftKey){
      setInputValue(inputValue+"\n")
    }
    else if (event.key === 'Enter') {
      // Handle sending the message
      // For example, you can call a function to send the message
    //   sendMessage();
        if(showOptions) {
            setShowOptions(false);
            setInputValue(options?.[selectedOptionIndex]?.value)
            anchorRef?.current?.focus(); // Set focus on the input field
            anchorRef?.current?.setSelectionRange(inputValue?.length - 1, inputValue?.length - 1);
        } else if(inputValue?.trim() && inputValue !== " ") {
          handleSendMessage(inputValue, "text", false)
          setInputValue("");
        }
    } else if (event.key === '/' && (event?.target?.value === '')) {
      // Show options
      setShowOptions(true);
      setSelectedOptionIndex(0);
    } else if (event.key === 'ArrowUp' && inputValue?.startsWith('/')) {
      event.preventDefault();
        setShowOptions(true);
      setSelectedOptionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : options.length - 1));
    } else if (event.key === 'ArrowDown' && inputValue?.startsWith('/')) {
      event.preventDefault();
        setShowOptions(true);
      setSelectedOptionIndex((prevIndex) => (prevIndex < options.length - 1 ? prevIndex + 1 : 0));
    } else {
        setShowOptions(false)
    }
  };

  const handleClose = () => {
    setShowOptions(false);
  };

  const handleBoldClick = () => {
    document.execCommand('bold', false, null);
    anchorRef.current.focus();
  };

  const handleOptionClick = (option) => {
    setInputValue(option?.value);
    setShowOptions(false);
  };

  const sendMessage = () => {
    // Handle sending the message
    // For example, you can send the message to a chat API
    console.log('Message sent:', inputValue);
    setInputValue('');
  };

  const options = [
    {label: "Ask to Book an Appointment", value: `Dear ${patient['name']}, I prioritize your well-being and encourage you to book an appointment with me for your personalized care 🧑‍⚕️. Book an appointment by clicking button below at your convenient time instantly ⚡!`},
    {label: "Check well being", value: `Hey ${patient['name']}. Just dropping a message to see how you've been doing since we last connected. I genuinely care about your well-being, so please share any updates or concerns you may have 💬, simply by clicking button below 👇`},
    {label: "Ask for follow up", value: `Hi ${patient['name']}! Scheduling a visit will allow us to discuss your progress, address any concerns, and make any necessary adjustments to your treatment plan. Please reach out to my clinic by clicking the button below and find a convenient time for you. Remember doors to my clinic are always open for your needs 🤝.`},
    {label: "Ask effect of medication", value: `Hey ${patient['name']}! I'm curious to know how you've been responding to the medications prescribed 💊. Have you noticed any positive changes in your symptoms or any unexpected side effects? Your feedback will help me evaluate the effectiveness of the treatment and make any necessary adjustments for your well-being. Please take a moment to share your experience 💬.`},
    {label: "Remind to take medicines", value: `Hey there, ${patient['name']}! Just wanted to give you a friendly nudge to remember 🧠 taking your prescribed medications 💊. They play a key role in your treatment, so let's stay on top of it. If you have any doubts or need assistance, don't hesitate to hit message me. Your health is important, so keep up the good work 👍🏻!`},
    {label: "Follow-up online", value: `Hey ${patient['name']}! If you're unable to make it to the clinic for a follow-up appointment, no worries! We've got you covered with online consultations. Just give us a heads-up if you'd like to schedule a virtual follow-up 🧑‍💻, and we'll make sure to accommodate your needs. Your well-being matters, so let's find a convenient way to stay connected 🔁.`},
    ]; // Replace with your saved options

  return (
    <React.Fragment>
      <Grid item xs={11}>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          fullWidth
          autoFocus
          inputRef={anchorRef}
          style={{backgroundColor: 'white', borderRadius: "30px"}}

          placeholder={"Press / for quick replies ⚡"}
          //   Press / for quick replies
          //   <GenericAnimation animationData={quickAnimation} height={"40px"} width={"40px"} />
          // </div>}
          InputProps={{
            startAdornment: (
                <Grid container style={{position: 'absolute', bottom: 0}} alignItems="center">
                    {/* <Grid item>
                        <IconButton onClick={handleBoldClick}>
                            <FormatBold />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <FormatItalic />
                        </IconButton>
                    </Grid> */}
                    {menuActions?.map(v => (
                      <Grid item>
                        <Tooltip title={v?.label}>
                          <IconButton onClick={v?.onClick}>
                            <i className={v?.icon} style={{fontSize: 18, opacity: "90%"}}></i>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ))}
                    <Grid item>
                      <Tooltip title="Upload File">
                        <IconButton onClick={onFileUpload} title="Upload File">
                            <AttachFileIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{marginLeft: "auto", marginRight: "40px", opacity: "60%"}}>
                        {//<Button variant="contained" color="primary" onClick={() => handleSendMessage(inputValue)} endIcon={<KeyboardReturn />}>
                            //Send 
                        //</Button>
                      } 
                      Press Enter to send message
                        {/* <div style={{display: "flex", alignItems: "center"}}>
                          Press / for quick replies
                          <GenericAnimation animationData={quickAnimation} height={"30px"} width={"30px"} />
                        </div> */}
                    </Grid>
                </Grid>
            ),
            style: { position: 'relative', borderRadius: "30px", paddingBottom: "5%" },
            // inputComponent: CustomInput
          }}
          // InputProps={{
          //   // readOnly: true,
          //   multiline: true,
          //   rows: 6,
          //   startAdornment: (
          //     <Typography variant="body1" dangerouslySetInnerHTML={{ __html: inputValue }} />
          //   ),
          // }}
        />

        {/* <QuillTextField /> */}

        {showOptions && (
          <Popper open={showOptions} anchorEl={anchorRef.current} placement="top-start" style={{width: "55%", marginBottom: "1%"}}>
            <Paper style={{width: "100%", opacity: "90%"}} elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <List>
                  {options.map((option, index) => (
                    <ListItem
                      key={option?.label}
                      button
                      selected={selectedOptionIndex === index}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option?.label}
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Popper>
        )}
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="primary"
          style={{marginLeft: "1%", position: "absolute", bottom: 50}}
          onClick={() => {
            if (inputValue?.trim()) {
              handleSendMessage(inputValue, "text", true)
              setInputValue("")
            }
          }}
          title="Send Message"
        >
          <SendIcon fontSize='large'/>
        </IconButton>
      </Grid>
      </React.Fragment>
  );
};

