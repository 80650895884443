import { Avatar, Button, Grid, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import customAxios from "../../../axios"
import GenericCardTable from "../GenericCardTable"
import GenericDialogForm from "../GenericDialog"
import { labResultConstantList, labResultConstantData } from "./labResultConstants"
import { v4 as uuidv4 } from 'uuid';


function LabResults({ state, setState, docId, activeIndex, setActiveIndex, padIndex, frequentData, patientData }) {
  const user = useSelector((state) => state.user)

  const cardTableHeaders = [
    {
      label: "Investigation Name",
      key: "name",
      placeholder: "Type property",
      type: "select",
      options: [...labResultConstantList],
      required: true,
      width: "20%",
      labResult: "name"
    },
    {
      label: "Unit",
      key: "unit",
      placeholder: "Unit",
      type: "select",
      labResult: "unit",
      options: [],
      width: "10%",
      dependencyKey: "name"
    },
    {
      label: "Reading/Observations",
      key: "reading",
      placeholder: "Reading",
      type: "text",
      width: "11%",
      dependencyKey: "name",
    },
    {
      label: "Interpretation",
      key: "interpretation",
      placeholder: "e.g High",
      type: "select",
      width: "16%",
      dependencyKey: "name",
      options: ["High", "Normal", "Low", "Critically High", "Borderline High", "Very High", "Borderline Low", "Very Low", "Critically Low", "Abnormal"],
    },
    {
      label: "Date",
      key: "date",
      placeholder: "e.g. 01/01/2021",
      type: "date",
      width: "11%",
      dependencyKey: "name",
    },
    {
      label: "Additional Notes",
      key: "notes",
      placeholder: "Add notes here",
      type: "text",
      width: "20%",
      dependencyKey: "name",
    }
  ]

  useEffect(() => {
    getRxGroup()
  }, [])

  const [openDialog, setOpenDialog] = useState({})
  const [selectedRxGroup, setSelectedRxGroup] = useState({})
  const [doctorRxGroup, setDoctorRxGroup] = useState([])
  const getRxGroup = () => {
    customAxios.get("doctor/rx-group?type=lab_results").then((res) => {
      if (res?.data?.success) setDoctorRxGroup([...res?.data?.data])
    })
  }

  const addRxDialogContent = [
    {
      label: "Name",
      key: "name",
      placeholder: "eg. Complete Blood Count",
      type: "text",
      required: true,
      md: 12,
    },
    {
      errorKey: "Atleast one option",
      key: "rxMeds",
      type: "checkbox",
      options: [...state.labResults],
      optionKey: "name",
      required: true,
    },
  ]

  const selectMedsDialogContent = [
    {
      label: selectedRxGroup?.["name"],
      key: "rxMeds",
      type: "checkbox",
      options: selectedRxGroup?.["rxMeds"]
        ? [...selectedRxGroup?.["rxMeds"]]
        : [],
      optionKey: "name",
    },
  ]

  const addRxGroup = (rxData, isEdit = false) => {
    customAxios.put("doctor/rx-group?type=lab_results", rxData).then((res) => {
      setOpenDialog({})
      toast.success("Template Saved Successfuly!")
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const removeRxGroup = (rxData) => {
    customAxios.put("doctor/delete-rx-group?type=lab_results", rxData).then((res) => {
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const chooseRxGroup = (rxData) => {
    setSelectedRxGroup({ ...rxData })
    setOpenDialog({ selectMeds: true })
  }

  const [historicalData, setHistoricalData] = useState(null)

  useEffect(() => {
    customAxios.get(`consultation/get-lab-results/${patientData?._id}`).then((res) => {
      if (res?.data?.success) {
        setHistoricalData(res?.data?.data)
      }
    })
  }, [patientData])

  const handleSelectMeds = (rxData) => {
    if (rxData?.rxMeds && rxData?.rxMeds?.length) {
      const temp = [...state.labResults]
      rxData?.rxMeds?.map((f) => {
        const check = state.labResults.findIndex((val) => val?.name === f?.name)
        if (check === -1) temp.push({ ...f })
        else temp.splice(check, 1, { ...f })
      })
      setState({ ...state, labResults: [...temp] });
      setOpenDialog({});
    }
  }

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Lab Results"}
        tabSwitching={true}
        suggestedTitle={""}
        suggestedData={[]}
        tableHeaders={cardTableHeaders}
        suggestedTab={0}
        tableData={state}
        cardKey={"labResults"}
        cardName={"Lab Results"}
        setTableData={setState}

        onSelectTemplate={handleSelectMeds}
        onDeleteTemplate={removeRxGroup}
        onUpdateTemplate={addRxGroup}
        selectIconText={"lnv"}
        savedTemplates={doctorRxGroup}

        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        padIndex={padIndex}
        frequentOptions={frequentData?.frequentLabResults ? Object.values(frequentData?.frequentLabResults) : []}
        historicalLabTests={true}
        historicalData={historicalData}
        headerIcon={(<Avatar style={{
          fontSize: 18,
          width: 30,
          height: 30, 
          marginRight: 10, 
          color: "#5e72e4", 
          backgroundColor: "white",
        }}>
        <i className="fas fa-microscope"></i>
      </Avatar>)}
      />
      {/* <Grid container>
        <Grid
          item
          xs={6}
          style={{ display: "flex", flex: "flex-wrap", alignItems: "center" }}
        >
          <Typography variant="body2">
            * Choose from templates
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setOpenDialog({ chooseRx: true })}
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>

        <Grid item container xs={6} justifyContent="flex-end">
          {state.labResults?.length ? (
            <Grid item>
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={() => setOpenDialog({ addRx: true })}
              >
                <Typography variant="body2">Create template</Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid> */}
      {openDialog?.chooseRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Choose Template"}
          dialogContent={[...doctorRxGroup]}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={chooseRxGroup}
          showDialogAction={false}
          isList={true}
          editListItem={true}
          removeListItem={true}
          onEditItem={(data) =>
            setOpenDialog({ editRx: true, data: { ...data, rxMeds: data?.rxMeds?.map(v => ({...v, uuid: uuidv4()}))} })
          }
          onRemoveItem={removeRxGroup}
        />
      ) : null}
      {openDialog?.selectMeds ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Select Lab Results"}
          dialogContent={selectMedsDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={handleSelectMeds}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{
            rxMeds: selectedRxGroup?.["rxMeds"]?.filter((f) => {
              const check = state?.labResults?.find(
                (val) => val?.name === f?.name
              )
              if (check) return false
              else return true
            }),
          }}
        />
      ) : null}
      {openDialog?.addRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Create Template"}
          dialogContent={addRxDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={addRxGroup}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{ rxMeds: [...state?.labResults] }}
        />
      ) : null}
      {openDialog?.editRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Update Template"}
          dialogContent={[
            { ...addRxDialogContent[0] },
            {
              ...addRxDialogContent[1],
              type: "checkbox_and_edit",
              options: [...openDialog?.data?.rxMeds],
            },
          ]}
          handleCancel={() => setOpenDialog({ chooseRx: true })}
          handleSubmit={(data) =>
            addRxGroup({ ...data, prevName: openDialog?.data?.name }, true)
          }
          editOptions={(val, med, idx) => {
            let tempData = {...openDialog?.data}
            tempData.rxMeds[idx] = {...med, name: val}

            setOpenDialog({...openDialog, data: {...tempData}})
          }}
          cancelButtonText={"Cancel"}
          submitButtonText={"Update"}
          formData={{ ...openDialog?.data }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default LabResults
