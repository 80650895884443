import { Button } from "@material-ui/core"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Modal } from "reactstrap"
import Col from "reactstrap/lib/Col"

const JoinMeetModal = (props) => {
  const history = useHistory()

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const startConsultationwithoutMeet = () => {
    history.push(`/admin/consultation/${props.appointment._id}`)
  }

  const startConsultation = () => {
    window.open(`/meet/VideoConsultation/${props.appointment._id}`, "")
    history.push(`/admin/consultation/${props.appointment._id}`)
  }

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<i class="fas fa-play"></i>}
        color="primary"
        onClick={() => {
          toggle()
        }}
      >
        Start Consultation
      </Button>

      <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
        <div className="modal-body">
          <Col className="text-center">
            <h2>Join meet with {props.patientName} now?</h2>
          </Col>
        </div>
        <div
          className="modal-footer"
          style={{ display: "flex", columnGap: "0.5rem" }}
        >
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={startConsultationwithoutMeet}
          >
            Not Now
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={startConsultation}
          >
            Join Now
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default JoinMeetModal
