import React from "react"
import { Grid, Typography, Button } from "@material-ui/core"
import defaultImage from "./../../../assets/img/chatWindow/defaultWindow.png"
function ChatDefaultScreen({ setNewChatModalOpen }) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{ position: "relative", minHeight: "100%", textAlign: "center" }}
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <img
        src={defaultImage}
        alt="default"
        style={{ objectFit: "contain", width: "250px", marginLeft: "-1rem" }}
      />
      <Typography
        color="secondary"
        style={{ marginTop: "1rem" }}
        variant="body1"
      >
        Press the button below to chat with your patients on WhatsApp
      </Typography>
      <Button
        startIcon={<i class="fas fa-user-plus"></i>}
        color="primary"
        size="small"
        style={{ marginTop: "2rem" }}
        variant="outlined"
        onClick={() => {
          setNewChatModalOpen(true)
          // history.push("/admin/allPatients");
        }}
      >
        {" "}
        New Chat
      </Button>
      {/* <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Communicate with your patients directly on whatsapp!
        </Typography>
      </Grid> */}
    </Grid>
  )
}

export default ChatDefaultScreen
