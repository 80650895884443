import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core"
import React, { useEffect } from "react"
import loadingAnimation from "./../../../assets/animationData/loading.json"
import defaultAudio from "./../../../assets/audios/success.mp3"
import GenericAnimation from "../../animation"
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1400,
    color: "#fff",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
}))

function LoaderFullpage({
  loading,
  message = "",
  animationData,
  animationList = animationData ? [{ ...animationData }] : [],
  enableSound = false,
  audio: sound = defaultAudio,
}) {
  useEffect(() => {
    if (enableSound && loading) {
      const audio = new Audio(sound)
      const promise = audio.play()
      if (promise !== undefined) {
        // On older browsers play() does not return anything, so the value would be undefined.
        promise
          .then(() => {
            // Audio is playing.
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }, [loading])
  const classes = useStyles()
  return (
    <Backdrop open={loading} className={classes.backdrop}>
      <div style={{ textAlign: "center" }}>
        {animationList.length ? (
          animationList.map((ele) => (
            <GenericAnimation {...ele}></GenericAnimation>
          ))
        ) : (
          <CircularProgress></CircularProgress>
        )}
        <Typography variant="h5" style={{ zIndex: "1400", color: "white" }}>
          {message}
        </Typography>
      </div>
    </Backdrop>
  )
}

export default LoaderFullpage
