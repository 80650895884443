import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Card, CardHeader, Col, Row, Button, Table } from "reactstrap"
import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"
import customAxios from "../../axios"
import moment from "moment"
import OfflineAppointmentIcon from "./Vector.svg"
import ActionableDropdown from "../Dashboard/Home/ActionableDropDown"
import displayAge from "../../assets/functions/displayAge"

const AllAppointmentsTable = () => {
	const history = useHistory()

	const [isLoading, setIsLoading] = useState(true)
	const [appointmentDetails, setappointmentDetails] = useState([])
	useEffect(() => {
		setIsLoading(true)
		customAxios
			.get(`/appointments/day/all?is_psuedo=true&status=upcoming`)
			.then(res => {
				const sortedData = res.data.data.sort(function (a, b) {
					return a.batch.start < b.batch.start
						? -1
						: a.batch.start > b.batch.start
						? 1
						: 0
				})
				setappointmentDetails(sortedData)
				setIsLoading(false)
			})
	}, [])

	return (
		<div>
			<Card>
				<CardHeader className="border-0">
					<Row className="p-3" style={{ alignItems: "center" }}>
						<Col md="11">
							<h1 style={{ fontSize: "25px" }}>Expected Appointments</h1>
						</Col>
						<Col md="1">
							<Button
								color="danger"
								size="sm"
								onClick={() => window.location.reload()}>
								<i class="fas fa-redo-alt"></i>
							</Button>
						</Col>
						<Col md="12"></Col>
					</Row>
				</CardHeader>
				<Table className="align-items-center" responsive>
					<thead className="thead-light">
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Symptoms</th>
							<th scope="col">Date</th>
							<th scope="col">Time</th>
							<th scope="col">Payment Status</th>
							<th scope="col">Type</th>
							<th scope="col">Action</th>
							<th scope="col"></th>
						</tr>
					</thead>
					{isLoading && (
						<tbody>
							<tr>
								<td colSpan={6}>
									<LoadingAnimation></LoadingAnimation>
								</td>
							</tr>
						</tbody>
					)}{" "}
					{!isLoading && appointmentDetails.length === 0 && (
						<tbody>
							<tr>
								<td colSpan={6} style={{ textAlign: "center" }}>
									<h3>No patient found </h3>
								</td>
							</tr>
						</tbody>
					)}
					<tbody>
						{!isLoading &&
							appointmentDetails &&
							appointmentDetails.map((appointment, index) => (
								<React.Fragment key={index}>
									<tr>
										<td>
											{appointment.patient.name}, <br />
											{displayAge(appointment.patient)},{" "}
											{appointment.patient.gender}
											<br />
										</td>
										<td
											style={{
												minWidth: "40vh",
												display: "flex",
												flexWrap: "wrap",
											}}>
											{appointment?.problems?.map((ele, index) => (
												<span
													key={index}
													className="badge-md  m-1 "
													style={{
														color: "#ffffff",
														borderRadius: "5px",
														backgroundColor: "#93D2E0 ",
													}}>
													{ele}
												</span>
											))}
										</td>
										<td>
											{moment
												.utc(appointment?.batch?.start)
												.format("D/MM/YYYY")}
										</td>

										<td>
											{moment.utc(appointment?.batch?.start).format("hh:mm A")}
										</td>
										<td>
											{appointment.paymentStatus ? (
												<div className="text-success">Paid</div>
											) : (
												<div className="text-error" style={{ color: "red" }}>
													Pending
												</div>
											)}
										</td>
										<td>
											<img alt="something" src={OfflineAppointmentIcon}></img>
										</td>
										<td>
											<div
												style={{
													display: "flex",
													justifyContent: "evenly-spaced",
													alignItems: "center",
												}}>
												<Button
													color="primary"
													outline
													type="button"
													className="p-2"
													onClick={() => {
														history.push(
															`/admin/patientProfile/${appointment.patient._id}`
														)
													}}>
													View Profile
												</Button>
												<ActionableDropdown
													disable={false}
													appointment={appointment}
												/>
											</div>
										</td>
									</tr>
								</React.Fragment>
							))}
						<tr>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</Table>
			</Card>
		</div>
	)
}

export default AllAppointmentsTable
