import {
  TablePagination,
  Button,
  IconButton,
  Typography,
  Grid,
  useTheme,
  TextField,
  Popover,
  Chip,
  CircularProgress,
  Tooltip,
  withStyles,
} from "@material-ui/core"
import moment from "moment"

import React, { useEffect, useState } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { Card, CardHeader, Table, Row, Col } from "reactstrap"

import Input from "reactstrap/lib/Input"

import customAxios from "../../axios"
import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"
import FileUploadModal from "../Common/FileUploadModal"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import PersonAddIcon from "./../../assets/img/icons/common/personadd1.png"

import { isWithinInterval, parseISO, startOfDay, endOfDay, startOfWeek, endOfWeek, subWeeks } from 'date-fns';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; 

import PastAppointmentModal from "./PastAppointmentModal"
import displayAge from "../../assets/functions/displayAge"
import { useDispatch, useSelector } from "react-redux"
import { loadPatients } from "../../redux/actions/user.actions"
import GenericDialogFormNew1 from "../CreatePrescription/GenericDialog/newIndex"
import { PATIENT_LOADER } from "../../redux/constants"
import { Autocomplete } from "@material-ui/lab"
import { FilterList } from "@material-ui/icons"
import ManagePaymentDialog from "../Dashboard/ManagePaymentDialog"
import AddAppointmentDialog from "../Dashboard/Home/AddAppointmentDialog"


const CustomTooltip = withStyles({
  tooltip: {
    color: 'black', // Change the text color
    backgroundColor: 'white', // Change the background color
  },
})(Tooltip);


function AllPatinetsTable({ data, ...props }) {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const userPatients = useSelector(state => state?.user?.patients)

  const [dialogLoading, setDialogLoading] = useState(false)
  const [open, setIsOpen] = useState(false)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [pastAppointmentOpen, setPastAppointmentOpen] = useState(false)
  const [csvFile, setCsvFile] = useState([])
  const [locations, setLocations] = useState([])

  const currOrganisation = useSelector((state) => state.user.currOrganisation)
  const patientSearch = useSelector((state) => state.user.patientSearch)

  useEffect(() => {
    let patientIds = []
    let tempData = [...tableData].filter(val => {
      if(!patientIds?.includes(val?._id)){
        patientIds.push(val?._id)
        return true
      } else return false
    });
    
    if(patientSearch){
      tempData = [...tempData]?.filter((option) =>
        option?.['name']?.toString()?.toLowerCase()?.includes(patientSearch?.toLowerCase()) || option?.['phoneNo']?.toString()?.toLowerCase()?.includes(patientSearch?.toLowerCase()) || option?.['code']?.toString()?.toLowerCase()?.includes(patientSearch?.toLowerCase())
      )
    }
    setFilterTableData([...tempData])
  }, [patientSearch])
  
  const customStaticRanges = createStaticRanges([
    {
      label: 'This Week',
      range: () => ({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      }),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
        endDate: endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
      }),
    },
  ]);

  const [currentLocation, setCurrentLocation] = useState("")
  const [tableData, setTableData] = useState([])
  const [filterTableData, setFilterTableData] = useState([])
  const isLoading = useSelector(state => state?.user?.patientLoader)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  useEffect(() => {
    let patientIds = []
    const tempData = [...tableData].filter(val => {
      if(!patientIds?.includes(val?._id)){
        patientIds.push(val?._id)
        return true
      } else return false
    });
    
    setFilterTableData([...tempData])
  }, [tableData])


  useEffect(() => {
    dispatch({type: PATIENT_LOADER, payload: true})
    dispatch(loadPatients()).then(() => {
      dispatch({type: PATIENT_LOADER, payload: false})
    })
  }, [])

  function NumberWithOrdinal(n) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  useEffect(() => {
    if(userPatients && userPatients?.length){
      let tempData = [...userPatients]?.reverse();
      tempData = tempData.sort((a, b) => {
        if (!a?.lastVisited) return 1;
        if (!b?.lastVisited) return -1;
        return new Date(b?.lastVisited) - new Date(a?.lastVisited);
      })
      if(upcomingAppointment?.length && isOH){
        let upcomingData = upcomingAppointment.map(v => ({...v?.patient, appointment: v}))
        tempData = [...upcomingData, ...tempData]
      }

      setTableData([...tempData])
      // setIsLoading(false)
    }
  }, [userPatients])

  const isOH = useSelector((state) => state.user?.user?.ohID)

  const handleBulkUpload = (files) => {
    setDialogLoading(true)
    customAxios
      .put("/patients/upload-csv", { ...files[0] })
      .then((res) => {
        if (res.data.success) {
          setUploadOpen(false)
          toast.success("File uploaded successfully. ")
          toast.info("It might take upto 5 minutes to update the patient list.")
        } else {
          setDialogLoading(false)

          toast.error(
            "File upload failed. Please check the format of the sample file for reference."
          )
        }
        setCsvFile([])
      })
      .catch((err) => {
        setDialogLoading(false)
        toast.error("Some error occured")
      })
  }
  const getAllPatients = () => {
    if(currentLocation){
      // setIsLoading(true)
      dispatch({type: PATIENT_LOADER, payload: true})
  
      customAxios
        .put(`/patients/filter?org_id=${currentLocation}`,
          {}
        )
        .then((res) => {
          // setIsLoading(false)
          dispatch({type: PATIENT_LOADER, payload: false})
          const temp = res.data.data.reverse()
          setTableData([...temp])
        })
        .catch((err) => console.log(err))
    } else if(isLoading){
    dispatch(loadPatients())
    }
  }
  const [filterType, setFilterType] = useState(null)
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);

  const [upcomingAppointment, setUpcomingAppointment] = useState([])
  
  const getUpcomingAppointments = () => {
    
    dispatch({type: PATIENT_LOADER, payload: true})
    customAxios
    .get(
        `/appointments/day/month?&status=all`
    )

    .then((res) => {
      if (res) {
        let upcomingData = [...res?.data?.data];
        upcomingData.reverse()
        setUpcomingAppointment([...upcomingData])
        dispatch({type: PATIENT_LOADER, payload: false})
      }
    })
  }

  useEffect(() => {
    if(upcomingAppointment?.length && isOH) {
      let upcomingData = upcomingAppointment?.map(v => {
        return {...v?.patient, appointment: v}
      })
      
      let tempData = [...upcomingData, ...tableData];
      setTableData([...tempData])
    }
  }, [upcomingAppointment])

  useEffect(() => {
    customAxios
      .get(`/organisation/all`)
      .then((res) => {
        if (res.data.data.length !== 0) {
          setLocations(res.data.data)
        }
      })
      .catch((err) => console.log(err))

    if(isOH) {
      getUpcomingAppointments()
    }

    //eslint-disable-next-line
  }, [currentLocation])
  useEffect(() => {
    getAllPatients()
    //eslint-disable-next-line
  }, [currentLocation])

  useEffect(() => {
    if(filterType?.[0] && filterType?.[0]?.filterOn){
      let dateRange = filterType?.[0];

      const filtered = tableData.filter((obj) => {
        if (!obj?.lastVisited) return false; // Skip null visitedDate
  
        const visitedDate = parseISO(obj?.lastVisited);
        if (!visitedDate) return false; // Skip invalid dates

      // Check if startDate and endDate are valid and start is before end
        if (!dateRange?.startDate || !dateRange?.endDate || dateRange?.startDate > dateRange?.endDate) {
          return false;
        }
        return isWithinInterval(visitedDate, {
          start: startOfDay(dateRange?.startDate),
          end: endOfDay(dateRange?.endDate)
        });
      });

      let patientIds = []
      const tempData = [...filtered].filter(val => {
        if(!patientIds?.includes(val?._id)){
          patientIds.push(val?._id)
          return true
        } else return false
      });

      setFilterTableData([...tempData])

    } else if(filterType?.[0] && !filterType?.[0]?.checked){
      let patientIds = []
      const tempData = [...tableData].filter(val => {
        if(!patientIds?.includes(val?._id)){
          patientIds.push(val?._id)
          return true
        } else return false
      });
      setFilterTableData([...tempData])
    }
  }, [filterType])

  const [buttonLoading, setButtonLoading] = useState(false)
  const [invoiceButtonLoading, setInvoiceButtonLoading] = useState(false)

  const startConsultation = (patient) => {
    setButtonLoading(patient?._id)
    
    if(!patient?.appointment){
      customAxios
        .put("/appointments/book-patient ", {
          ...patient,
          docId: locations?.[0]?.admin,
          orgId: locations?.[0]?._id,
          patientId: patient?._id,
          isLocal: true
        })
        .then((res) => {
          setButtonLoading(false)
  
          if (res.data.success) {
              history.push(`/admin/consultation/${res?.data?.data?._id}`, {redirectTo: "allPatients"})
            // window.location.reload();
          } else {
           toast.error(res?.data?.message)
          }
        })
        .catch((err) => toast.error("Some error occured"))
    } else {
      setButtonLoading(false)
      history.push(`/admin/consultation/${patient?.appointment?._id}`, {redirectTo: "allPatients"})
    }
  }

  const openInvoiceModal = (patient) => {
    setInvoiceButtonLoading(patient?._id)

    if(!patient?.appointment) {
      customAxios
        .put("/appointments/book-patient ", {
          ...patient,
          docId: locations?.[0]?.admin,
          orgId: locations?.[0]?._id,
          patientId: patient?._id,
          isLocal: true
        })
        .then((res) => {
          setInvoiceButtonLoading(false)
  
          if (res.data.success) {
              setModalData({
                appointmentId:res?.data?.data?._id,
                treatmentId: null,
                patientId: patient?._id,
                patient: patient,
                invoiceData: [],
                docId: locations?.[0]?.admin,
              })
              setManagePaymentDialogOpen(true)
          } else {
           toast.error(res?.data?.message)
          }
        })
    } else {
      setInvoiceButtonLoading(false)

      setModalData({
        appointmentId:patient?.appointment?._id,
        treatmentId: null,
        patientId: patient?._id,
        patient: patient,
        invoiceData: [],
        docId: locations?.[0]?.admin,
        alreadyAppointment: true
      })
      setManagePaymentDialogOpen(true)
    }
  }

  const [managePaymentDialogOpen, setManagePaymentDialogOpen] = useState(false)
  const [modalData, setModalData] = useState(null)

  return (
    <Col className="mt-2">
      <Card>
        <CardHeader className="border-0">
          <Row style={{ alignItems: "center" }}>
            <Col md="auto">
              <h3 className="mb-0" style={{fontSize: "18px"}}>All Patients</h3>
              <Typography style={{fontSize: "12px", color: "grey"}}>
                {filterTableData?.length} Patients Found
              </Typography>
            </Col>
            {!isOH ? <Col md="3">
              <Input
                type="select"
                value={currentLocation}
                onChange={(e) => {
                  setCurrentLocation(e.target.value)
                }}
              >
                {[{ locationName: "All", value: "" }, ...locations]?.map(
                  (location, index) => (
                    <option
                      key={index}
                      label={location.locationName}
                      value={location._id}
                    ></option>
                  )
                )}
              </Input>
            </Col> : (
              <React.Fragment>
                {/* {filterType === "Custom" ? (
                  
                ) : null} */}
                <Col md={2}>
                  {/* <Autocomplete 
                  options={['None', "Today", "Yesterday", "This Week", "Last Week", "This Month", "Last Month", "Custom"]}
                  onChange={(e, val) => {
                    setFilterType(val ?? "None")
                  }}
                  value={filterType}
                  getOptionLabel={option => option}
                  fullWidth
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} label="Filter by Visit Date" />
                  )}
                  /> */}
                  <Button variant="contained" color='primary' fullWidth onClick={(event) => {
                    setFilterAnchorEl(event.currentTarget);
                    setFilterType([{
                      startDate: new Date(),
                      endDate: new Date(),
                      ...(filterType?.[0] ? (filterType?.[0]) : {}),
                      key: 'selection',
                      filterOn: false,
                      clicked: true
                    }])
                  }}
                  startIcon={<FilterList /> }
                  >
                    Filter By Visit Date  
                  </Button> 
                  {filterType?.[0]?.clicked ? (
                  <Popover
                    // id={id}
                    open={filterType}
                    anchorEl={filterAnchorEl}
                    onClose={() => setFilterType(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <DateRangePicker
                      onChange={(item) => {
                        setFilterType([{...(filterType?.[0] ? (filterType?.[0]) : {}), ...item?.selection}])
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={[...filterType]}
                      direction="horizontal"
                      staticRanges={[...defaultStaticRanges.filter(f => !f?.label?.includes("Week")), ...customStaticRanges]}
                    />
                    <div style={{display: 'flex', alignItems: "center", margin: 10}}>
                      <Button variant="contained" color='primary' onClick={() => {
                        setFilterType([{...(filterType?.[0] ? (filterType?.[0]) : {}), filterOn: true, clicked: false}])
                        }}>
                        Submit
                      </Button>
                      <Button variant="outlined" color='primary' style={{marginLeft: "10px"}} onClick={() => {
                        setFilterType([{...(filterType?.[0] ? (filterType?.[0]) : {}), clicked: false}])
                        }}>
                        Close
                      </Button>
                    </div>
                  </Popover>
                  ) : null}
                </Col>
              </React.Fragment>
            )}

            {filterType?.[0]?.filterOn ? (
              <Col md={1}>
                <Chip 
                style={{cursor: "pointer"}}
                variant="outlined"
                color="primary"
                onDelete={() => setFilterType([{filterOn: false, clicked: false, startDate: new Date(), endDate: new Date()}])} 
                onClick={() => setFilterType([{filterOn: false, clicked: false, startDate: new Date(), endDate: new Date()}])} 
                  label={"Remove Filter"}
                />
              </Col>
            ) : null }

            <Col md="2" style={{marginLeft: "auto"}}>
              <Button
                color="primary"
                startIcon={<i class="fas fa-file-import"></i>}
                fullWidth
                variant="contained"
                onClick={() => {
                  setUploadOpen(true)
                }}
              >
                Import Patients
              </Button>
            </Col>
            {!isOH ? <Col md="2">
              <Button
                color="primary"
                startIcon={<i className="fas fa-plus "></i>}
                fullWidth
                variant="contained"
                onClick={() => {
                  setIsOpen(true)
                }}
              >
                Add new patient
              </Button>
            </Col>: null}{" "}
             <Col md="2">
              <Button
                color="primary"
                startIcon={<i class="fa fa-plus"></i>}
                fullWidth
                variant="contained"
                onClick={() => {
                  setPastAppointmentOpen(true)
                  // setModalData(prev => {
                  //   return {

                  //   }
                  // })
                }}
              >
                Add Appointment
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Patient Details</th>
              {isOH ? <th scope="col">Slot</th> : null}

              <th scope="col">Contact</th>
              <th scope="col">Last Visit</th>
              {/* <th scope="col">Payments</th> */}

              <th scope="col">Action</th>
            </tr>
          </thead>
          {isLoading && (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <LoadingAnimation></LoadingAnimation>
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && filterTableData?.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={6} style={{ textAlign: "center" }}>
                  <h3>No patient found.</h3>
                </td>
              </tr>
            </tbody>
          )}
          <tbody className="list">
            {!isLoading &&
              filterTableData &&
              filterTableData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th scope="row" style={{display: "flex", alignItems: "center"}}>
                        <div className="">
                          <div style={{padding: 0, color: theme.palette.primary.main, cursor: "pointer", fontSize: "15px", display: "flex"}} onClick={() => {
                            history.push(`/admin/patientProfile/${data._id}`)
                          }}>{data?.name}{" "}

                          </div>
                          {/* <br /> */}
                          {data.age ? displayAge(data) + "," : null}{" "}
                          {data?.gender}
                          <br />
                          {/* {data?.lastVisited && isOH ? <span style={{color: "grey"}}>{`Last Visit: ${moment
                            ?.utc(data?.lastVisited)
                            ?.format("ddd, DD MMM")}`}</span> : null} */}
                          
              
                        </div>
                        
                      </th>
                      {/* <th>{data.code}</th> */}

                      {isOH ? <td>
                      {data?.appointment ? <div style={{fontWeight: "bold", marginLeft: "auto", opacity: '0.6' }}>
                          {moment.utc(data?.appointment?.start).format("hh:mm A")}
                          <br />
                          <span style={{color: "grey"}}>{moment(data?.appointment?.start).format("D/MM/YYYY") ===
                              moment().format("D/MM/YYYY")
                                ? "Today"
                                : moment
                                    .utc(data?.appointment?.start)
                                    .format("DD/MM/YYYY") ===
                                  moment.utc().add(1, "days").format("DD/MM/YYYY")
                                ? "Tomorrow"
                                : moment
                                    .utc(data?.appointment?.start)
                                    .format("ddd, DD MMM")}</span>
                        </div> : "-"}
                      </td> : null}

                      <td>{data.phoneNo}</td>
                       <td>{data?.lastVisited ? moment
                        ?.utc(data?.lastVisited)
                        ?.format("ddd, DD MMM YYYY") : "-"}</td>
                        {/* <td>
                         
                        </td> */}
                      <td>
                        {/* <IconButton
                          color="textPrimary"
                          title="View Profile"
                          onClick={() => {
                            history.push(`/admin/patientProfile/${data._id}`)
                          }}
                        >
                            <img src={PersonAddIcon} style={{height: 30, width: 30}}/>
                        </IconButton>
                        <IconButton
                          color="primary"
                          title="Call Patient"
                          onClick={() => {
                            var a = document.createElement("a")
                            document.body.appendChild(a)
                            a.style = "display: none"
                            a.href = `tel:${data.phoneNo}`
                            a.click()
                          }}
                        >
                          <i class="fas fa-phone-alt fa-flip-horizontal fa-0.75x"></i>{" "}
                        </IconButton> */}

                        {!isOH ? <IconButton
                          style={{ color: "green" }}
                          title="Chat"
                          onClick={() => {
                            history.push(`/admin/chat`, { patient: data })
                          }}
                        >
                          <img
                            src={whatsappLogo}
                            style={{
                              width: "30px",
                              objectFit: "contain",
                            }}
                          />{" "}
                        </IconButton> : (
                          <React.Fragment>
                            <Button variant="outlined" color='primary' 
                                startIcon={buttonLoading === data?._id ? <CircularProgress size={14} /> : <i class="fas fa-play"></i>}
                                onClick={() => startConsultation(data)}
                                >
                              Start Consultation
                            </Button>
                             <Button
                              variant="outlined"
                              style={{ marginLeft: "10px",
                              // color: "green",
                              // borderColor: "green"
                              }}
                              color="primary"
                              startIcon={invoiceButtonLoading === data?._id ? <CircularProgress size={14} /> : <i class="fas fa-indian-rupee-sign"></i>}
                              onClick={() => {
                                openInvoiceModal(data)
                              }}
                            >
                              Manage Payments
                            </Button>
                            {data?.appointment ? <div><span style={{color: 'grey', marginLeft: "220px", textAlign: 'center'}}>Patient is </span> 
                              {data?.appointment?.invoiceCount ? <span style={{color: "#0B5345"}}><em>"Billed"</em></span> : <span style={{color: "red"}}><em>"Un-billed"</em></span>}
                            </div> : null}
                            
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            <tr>
              <td colSpan={8}>
                <TablePagination
                  align="center"
                  rowsPerPageOptions={[
                    5,
                    10,
                    20,
                    50,
                    { label: "All", value: filterTableData?.length },
                  ]}
                  component="div"
                  count={filterTableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, page) => {
                    setPage(page)
                  }}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(e.target.value)
                    setPage(0)
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
      <GenericDialogFormNew1
        dialogContent={
          [
            {
              label: "Name",
              key: "name",
              type: "text",
              md: 10
            },
            {
              label: "Phone",
              key: "phoneNo",
              // disabled: true,
              type: "number",
              md: 10
        
            },
            {
              key: "ageString",
              label: "Age",
              unitOptions1: ['Years', "Months", "Days"],
              unitOptions: ['Year', 'Month', 'Day'],
              placeholder: "e.g. 10 Years",
              freeSolo: true,
              type: "unit-autocomplete",
              md: 10
        
            },
            {
              label: "Gender",
              key: "gender",
              type: "select-chip",
              options: [
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ],
              md: 12
            },
          ]}
        showRequired={true}
        open={open}
        handleClose={() => setIsOpen(false)}
        handleSubmit={(data) => {
          customAxios
            .post("/patients/create", { ...data })
            .then((res) => {
              if (res.data.success) {
                toast.success("Patient added successfully!")
                dispatch(loadPatients())
                setIsOpen(false)
              } else {
                toast.error("Some error occured")
                setIsOpen(false)
              }
            })
            .catch((err) => {
              toast.error("Some error occured")
              setIsOpen(false)
            })
        }}
        dialogTitle="Add New Patient"
      ></GenericDialogFormNew1>
      <FileUploadModal
        multiple={false}
        allowedFileTypes={".csv"}
        message={
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <Typography>
              <ul>
                <li>
                  Upload a <strong>.csv</strong> file to import exisiting
                  patients from your database
                </li>
                <li>
                  <strong>Phone number</strong> is a <strong>required</strong>{" "}
                  field
                </li>
                <li>
                  First row should be the same as mentioned in the file below
                </li>
                <li>
                  It might take <strong>upto 5 minutes</strong> to update the
                  patient list.
                </li>
              </ul>
              Download a sample csv from
              <span
                onClick={() => [
                  window.open(
                    "https://storage.googleapis.com/website-paperplane/Bulk_Upload_Sample.csv"
                  ),
                ]}
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                {" "}
                here
              </span>
            </Typography>
          </Grid>
        }
        open={uploadOpen}
        setOpen={setUploadOpen}
        state={csvFile}
        loading={dialogLoading}
        dialogTitle="Upload .csv"
        setState={setCsvFile}
        onSubmit={() => handleBulkUpload(csvFile)}
      ></FileUploadModal>
      {/* <PastAppointmentModal 
       open={pastAppointmentOpen}
       currOrg={currentLocation}
      //  modalData={modalData}
      //  setModalData={setModalData}
       setOpen={setPastAppointmentOpen}
       setUpdater={null}
       defaultTabValue={0}
     /> */}
     {pastAppointmentOpen && (
        <AddAppointmentDialog
          open={pastAppointmentOpen}
          currOrg={currOrganisation}
          modalData={{...modalData, startKey: moment(new Date()).add(10, 'minutes'), start: moment(new Date()).add(340,'minutes').toISOString()}}
          onlyTab={1}
          setModalData={setModalData}
          setOpen={setPastAppointmentOpen}
          setUpdater={(val) => {
            getUpcomingAppointments()
          }}
          setAllPatients={(data) => {
            console.log(data, 'all patients ?? set')
          }}
          allPatients={userPatients}
          defaultTabValue={0}
        />
      )}
     {managePaymentDialogOpen ? (
        <ManagePaymentDialog
          managePaymentDialogOpen={managePaymentDialogOpen}
          setManagePaymentDialogOpen={setManagePaymentDialogOpen}
          modalData={modalData}
          setUpdater={() => modalData?.alreadyAppointment ? getUpcomingAppointments() : null}
        />
      ) : null}
    </Col>
  )
}

export default withRouter(AllPatinetsTable)
