import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Typography, Grid, MenuItem, Select, FormControl, InputLabel, TextField, Tooltip, Button, CardActions, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import customAxios from '../../axios';
import { InfoOutlined, Launch } from '@material-ui/icons';
import impressionIcon from "../../assets/img/icons/common/impression.png"
import callIcon from "../../assets/img/icons/common/call.png"
import locationIcon from "../../assets/img/icons/common/placeholder.png"
import clickIcon from "../../assets/img/icons/common/tap.png"
import wwwIcon from "../../assets/img/icons/common/world-wide-web.png"

import googleLogo from "../../assets/img/brand/googleLogo.png"
import { toast } from 'react-toastify';

import animationData from "../../assets/animationData/my-business.json"
import GenericAnimation from '../animation';



const MarketingAnalytics = () => {

    const user = useSelector(state => state?.user?.user)

    const [rangeValue, setRangeValue] = useState({value: "this_month", label: "This Month"})

    const [analyticsData, setAnalyticsData] = useState(null)

    const [metricLoader, setMetricLoader] = useState(null)
    const [businessLoader, setBusinessLoader] = useState(null)

    const [updater, setUpdater] = useState(null)

    const getMarketingAnalytics = () => {
        customAxios
        .get(`analytics/google-analytics?range=${rangeValue?.value}`)
        .then((res) => {
          if (res.data.success) {
            setAnalyticsData(res?.data)
          }
          setMetricLoader(false)
          setBusinessLoader(false)
          setUpdater(1)
        })
    }    

    useEffect(() => {
        setMetricLoader(true)

        if(!updater)
            setBusinessLoader(true)
        getMarketingAnalytics()
        // setMetricLoader(false)
        //   setBusinessLoader(false)
    }, [rangeValue])

    const getAnalyticsData = (dataType) => {
        let metricValue = 0
        let tempData = analyticsData?.data

        if(!metricLoader){
            switch(dataType) {
                case "impression": {
                    if(tempData) {
                        metricValue = Number(tempData?.['BUSINESS_IMPRESSIONS_DESKTOP_SEARCH']) + Number(tempData?.['BUSINESS_IMPRESSIONS_MOBILE_SEARCH']) + Number(tempData?.['BUSINESS_IMPRESSIONS_MOBILE_MAPS']) + Number(tempData?.['BUSINESS_IMPRESSIONS_DESKTOP_MAPS'])
                    }
                    return metricValue
                }
    
                case "click": {
                    if(tempData) {
                        metricValue = Number(tempData?.['WEBSITE_CLICKS'])
                    }
                    return metricValue
                }
    
                case "location": {
                    if(tempData) {
                        metricValue = Number(tempData?.['BUSINESS_DIRECTION_REQUESTS'])
                    }
                    return metricValue
                }
    
                case "call": {
                    if(tempData) {
                        metricValue = Number(tempData?.['CALL_CLICKS'])
                    }
                    return metricValue
                }
            }
        } else {
            return (
                <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "20px", width: "40px", opacity: "40%"}} />
            )
        }

    }

  return (
    <Grid container style={{margin: 20, maxWidth: "98%"}} spacing={2}>
        <Grid item container xs={12} alignItems='center'>
            <Grid item>
                <Typography variant="h6" style={{fontWeight: "bold"}}>
                    Business Profile
                </Typography>
            </Grid>

            {analyticsData?.locations_data?.length ?  <Grid item style={{marginLeft: "auto"}}>
                <Button variant='outlined' startIcon={<img
                      src={googleLogo}
                      style={{
                        objectFit: "contain",
                        width: "25px",
                        marginRight: "0.2rem",
                      }}
                      alt="Google Logo"
                    />} color="primary" onClick={() => {
                    customAxios
                    .post("/auth/authorize-user")
                    .then((res) => {
                      if (res.data.success) {
                        window.open(res.data.data)
                      } else {
                        toast.error("Some error occured")
                      }
                    })
                    .catch((err) => {
                      toast.error("Some error occured")
                    })
                }}>
                    {"Change Google account"}
                </Button>
            </Grid> : null}

        </Grid>
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} container alignItems='center'>
                            {!businessLoader ? <Grid item style={{display: "flex", alignItems: "center", maxWidth: "70%"}}>
                                <Typography variant="h5" style={{fontWeight: "bold"}} color="primary">
                                    {analyticsData?.locations_data?.[0]?.title ? analyticsData?.locations_data?.[0]?.title : "No Location" }
                                </Typography>
                                <Tooltip title={analyticsData?.locations_data?.[0]?.profile?.description ? analyticsData?.locations_data?.[0]?.profile?.description : "No Location Found"} arrow>
                                    <InfoOutlined style={{fontSize: "18px", marginLeft: "6px", opacity: "30%"}}/>
                                </Tooltip>
                            </Grid> : <Grid item xs={4}>
                                    <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "25px", width: "60%", opacity: "40%"}} />    
                                </Grid>}
                            <Grid item style={{marginLeft: "auto"}} xs={4} md={2} >
                                {analyticsData?.locations_data?.length ? <Autocomplete
                                options={[{value: "this_month", label: "This Month"}, {value: "last_week", label: "Last 7 days"}, {value: "last_month", label: "Last Month"}]}
                                getOptionLabel={(option) => option?.label}
                                value={rangeValue}
                                fullWidth
                                color='primary'
                                
                                //   value={state?.otherData?.advice}
                                onChange={(e, val) => {
                                    if(val)
                                    setRangeValue(val);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    // onChange={(e) => {
                                    //   if (field.onChange) field.onChange(e.target.value, state, setState)
                                    // }}

                                    //   onChange={(e) => {
                                    //     setState({
                                    //       ...state,
                                    //       otherData: { ...state?.otherData, advice: e.target.value },
                                    //     })
                                    //   }}
                                    />
                                )}
                                /> : null}
                            </Grid>
                        </Grid>
                        {analyticsData?.locations_data?.length || businessLoader ? <Grid item xs={12} container style={{display: 'flex'}} alignItems='center'>
                            <Grid item style={{width: "20%"}}>
                                <DividerWithContent>
                                    {!businessLoader ? <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <img src={impressionIcon} style={{height: "60px", width: "60px"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%", fontWeight: "bold"}}>
                                                Impressions
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h4'>
                                                {getAnalyticsData("impression")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%"}}>
                                                How often users sees your business
                                            </Typography>
                                        </Grid>
                                    </Grid> : <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "30px", width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "80px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, width: "190px", opacity: "40%"}} />
                                        </Grid>
                                        </Grid>}
                                </DividerWithContent>
                            </Grid>
                            <Grid item style={{width: "20%"}}>
                                <DividerWithContent>
                                    {!businessLoader ? 
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <img src={clickIcon} style={{height: "60px", width: "60px"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%", fontWeight: "bold"}}>
                                                Clicks
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h4'>
                                                {getAnalyticsData("click")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%"}}>
                                                Actions to your website
                                            </Typography>
                                        </Grid>
                                    </Grid> : <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "30px", width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "80px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, width: "190px", opacity: "40%"}} />
                                        </Grid>
                                        </Grid>}
                                </DividerWithContent>
                                
                            </Grid>
                            <Grid item style={{width: "20%"}}>
                                <DividerWithContent>
                                    {!businessLoader ? 
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <img src={locationIcon} style={{height: "60px", width: "60px"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%", fontWeight: "bold"}}>
                                                Local Actions
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h4'>
                                                {getAnalyticsData("location")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%"}}>
                                                Actions showing intent to visit
                                            </Typography>
                                        </Grid>
                                    </Grid> : <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "30px", width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "80px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, width: "190px", opacity: "40%"}} />
                                        </Grid>
                                        </Grid>}
                                </DividerWithContent>
                                
                            </Grid>
                            <Grid item style={{width: "20%"}}>
                                <DividerWithContent>
                                    {!businessLoader ? 
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <img src={callIcon} style={{height: "60px", width: "60px"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%", fontWeight: "bold"}}>
                                                Calls
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h4'>
                                                {getAnalyticsData("call")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%"}}>
                                                Calls to your business
                                            </Typography>
                                        </Grid>
                                    </Grid> : <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "30px", width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "80px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, width: "190px", opacity: "40%"}} />
                                        </Grid>
                                        </Grid>}
                                </DividerWithContent>
                                
                            </Grid>

                            <Grid item style={{width: "20%"}}>
                                <DividerWithContent>
                                    {!businessLoader ? 
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <img src={wwwIcon} style={{height: "60px", width: "60px"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%", fontWeight: "bold"}}>
                                                    Your Website
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" style={{fontWeight: "bold"}} onClick={() => {
                                                if(analyticsData?.locations_data?.[0]?.websiteUri){
                                                    window.open(analyticsData.locations_data?.[0].websiteUri)
                                                }
                                            }}>
                                                View Your Website
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{opacity: "60%"}}>
                                                View your Google Business Website
                                            </Typography>
                                        </Grid>
                                    </Grid> : <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, height: "30px", width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "180px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10,  width: "80px", opacity: "40%"}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton animation="wave" variant="rect" style={{marginTop: 10, marginBottom: 10, width: "190px", opacity: "40%"}} />
                                        </Grid>
                                        </Grid>}
                                </DividerWithContent>
                                
                            </Grid>
                            </Grid> : 
                            <Grid item xs={12} container style={{display: "flex", height: "220px"}} alignItems='center' justifyContent='center'>
                                <Grid item>
                                    <GenericAnimation
                                    initialSegment={[40, 90]}
                                    animationData={animationData}
                                    height={150}
                                    width={150}
                                    ></GenericAnimation>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item>
                                    <Button variant='outlined' startIcon={<img
                                        src={googleLogo}
                                        style={{
                                            objectFit: "contain",
                                            width: "25px",
                                            marginRight: "0.2rem",
                                        }}
                                        alt="Google Logo"
                                        />} color="primary" onClick={() => {
                                        window.open("https://go.paperplane.health/pplane-gmb")
                                    }} style={{fontWeight: "bold"}}>
                                        Link Your Google Business account
                                    </Button>
                                </Grid>
                            </Grid>}
                    </Grid>
                </CardContent>
                <Divider />
                {analyticsData?.locations_data?.[0]?.metadata?.newReviewUri ? <CardActions divider style={{
                    cursor: "pointer",
                    padding: "10px"
                }} onClick={() => {
                    if(analyticsData?.locations_data?.[0]?.metadata?.newReviewUri){
                            window.open(analyticsData.locations_data?.[0].metadata?.newReviewUri)
                        }
                    }}>
                    <Grid container>
                        
                        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                            <Launch color='primary' style={{fontSize: "14px"}}/>
                            <Typography variant="h6" color='primary' style={{marginLeft: 10, fontWeight: "bold", fontSize: "14px", alignItems: "center"}}>
                                Visit Your Profile
                                <span style={{marginLeft: 10, fontWeight: "bold", opacity: "60%", fontSize: "13px"}}>
                                    Manage & View your Google profile on Google Search
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions> : null}
                </Card>
        </Grid>
    </Grid>
  );
};

const DividerWithContent = ({ children }) => (
  <div style={{ borderRight: '1px solid #ccc', padding: '8px', paddingLeft: "25px" }}>
    {children}
  </div>
);

export default MarketingAnalytics;
