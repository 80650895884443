import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Card, makeStyles } from '@material-ui/core';
import { CheckBoxTwoTone, Close, DragHandle } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    hoverAppointmentCard: {
      width: "100%", 
      padding: "10px", 
    //   cursor: "pointer", 
      marginBottom: 15, 
      borderRadius: 20,
      '&:hover': {
        background: "#EBF5FB",
      },
    },
  }));

export default function SettingModal({settingModal, setSettingModal, dataList, onSubmit}) {

    const classes = useStyles()

    const handleClose = () => {
        setSettingModal(false);
        setRows(_.cloneDeep(dataList))
    };

    const handleSubmit = () => {
        setSettingModal(false);
        if(onSubmit){
            onSubmit(rows)
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(rows);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setRows([...items]);
    };

    const handleCheckboxChange = (id) => {
        const items = [...rows];
        const index = items.findIndex((item) => item.id === id);
        items[index].checked = !items[index].checked;
        setRows([...items]);
    };

    const [update, setUpdate] = useState(-1);
 
    const [rows, setRows] = useState(_.cloneDeep(dataList));
    useEffect(() => {
        if(update === 1)
        setRows(_.cloneDeep(dataList))
        setUpdate(update+1)
    }, [dataList])

  return (
    <React.Fragment>
      <Dialog open={settingModal} onClose={handleClose} maxWidth="sm">
        <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography style={{ fontSize: "24px" }}>
                        Configure Your{" "}
                        <span
                            style={{
                            // background: colors.gradient,
                            color: "white",
                            padding: "0.3rem",
                            borderRadius: "4px",

                            background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                            }}
                        >
                            Prescription Pad
                        </span>{" "}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton aria-label="close" onClick={handleClose} stye={{padding: 5}}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent dividers>
            <Grid container alignItems="center" spacing={2}>
                {/* <Grid item xs={12}>
                    <Typography style={{fontWeight: "bold"}}>Order of Elements on PAD</Typography>
                </Grid> */}
                <Grid item xs={12}>
                    <Typography variant='caption'>
                        You can enable/disable any section <Checkbox color="primary" checked={true} style={{padding: 0}}/> and change the order of writing by dragging <DragHandle />. 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {/* <Table>
                        <TableHead>
                        <TableRow style={{ backgroundColor: "aliceblue" }}>
                            <TableCell>Field</TableCell>
                            <TableCell>Enable/Disable</TableCell>
                        </TableRow>
                        </TableHead> */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="rows">
                            {(provided) => (
                            <Grid container {...provided.droppableProps} ref={provided.innerRef}>
                                {rows.map((row, index) => (
                                <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                                    {(provided) => (
                                        // <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <React.Fragment>
                                        <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <Card className={classes.hoverAppointmentCard}>
                                                <Grid container alignItems='center' spacing={2}>
                                                    <Grid item>
                                                        <IconButton {...provided.dragHandleProps} style={{padding: 0}}>
                                                            <DragHandle />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography style={{fontSize: 12, fontWeight: "bold"}}>{row.text}</Typography>
                                                    </Grid>
                                                    <Grid item style={{marginLeft: "auto"}}>
                                                        <Checkbox color='primary' checked={row.checked} onChange={() => handleCheckboxChange(row.id)} style={{padding: 0}}/>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    <Grid item xs={12}></Grid>
                                    </React.Fragment>
                                    // </TableRow>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                            )}
                        </Droppable>
                        </DragDropContext>
                    {/* </Table> */}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
