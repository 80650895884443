import React from 'react';
import animationData from './../../../assets/loading.json';
import Lottie from 'react-lottie';
function LoadingAnimation(height, width) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    renderer: 'svg',
  };
  return (
    <Lottie
      options={defaultOptions}
      height={40}
      width={40}
      isStopped={false}
      isPaused={false}
    />
  );
}

export default LoadingAnimation;