import { FormControlLabel, Grid, Switch, Typography ,Button} from "@material-ui/core"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

import { isBreakStatement } from "typescript"
import customAxios from "../../axios"
import { setCurrDateTypeValue } from "../../redux/actions/user.actions"

export const handleBlockSlots = (val, onSuccess) => {
  console.log("val87")
  const date = new Date(val.date)
  console.log("val969", val, date, val.date)

  const isoDateStart = moment.utc(val.startDate, "HH:mm")
  var startTimeAndDate = moment
    .utc(val.date)
    .add(moment.duration(val.startDate))
  var endTimeAndDate = moment.utc(val.date).add(moment.duration(val.endDate))
  const isoDateEnd = moment.utc(val.endDate, "HH:mm")

  let temp = {}
  temp.startDate = startTimeAndDate.toISOString()
  temp.endDate = endTimeAndDate.toISOString()

  const start = moment(val.startDate, "HH:mm")
  const end = moment(val.endDate, "HH:mm")
  console.log("432", startTimeAndDate.toISOString())
  if (!val.date || !val.startDate || !val.endDate) {
    toast.error("Please fill in the date, start and end time")
  }
  if (moment.utc(val.date).diff(moment.now(), "days") < 0) {
    toast.error("Please choose a future date.")
  } else if (end?.isBefore(start)) {
    toast.error(`The end time cannot be greater than start time.`)
  } else {
    customAxios
      .put("slots/block-slots", { ...temp, orgId: val.orgId })
      .then((res) => {
        if (res.data.success) {
          toast.success("Slots blocked")
          onSuccess(res.data.data)
        } else {
          toast.error("Some error occured")
        }
      })
      .catch((err) => {
        console.log(err, "val969")
        toast.error("Some error occured")
      })
  }
}
export const handleUnblockSlot = (slotId, onSuccess = () => {}) => {
  customAxios.get(`slots/unblock-slot/${slotId}`).then((res) => {
    if (res.data.success) {
      toast.success("Slot unblocked ")
      onSuccess(res.data.data)
    }
  })
}
export function SlotBlock({ data,setUpdater = () => {} ,onAddAppointment=()=>{}}) {
  const [blocked,setBlocked]=useState(()=>data.type==='blocked_slot')

 

 
  const [slotId, setSlotId] = useState(() =>
    data.blockSlotId ? data.blockSlotId : null
  )
 
  const onChange = (value, block = true, add = false) => {
    if (block) {
      handleBlockSlots(
        {
          date: moment.utc(data.start).format("YYYY-MM-DD"),
          startDate: moment.utc(data.start).format("HH:mm"),
          endDate: moment.utc(data.end).format("HH:mm"),
          orgId: data.orgId,
        },
        (data) => {
          setUpdater()
          setSlotId(data._id)

          // setUpdater()
        }
      )
    } else if (add) alert("Hello")
    else {
      handleUnblockSlot(slotId, () => {
        setUpdater()
      })
    }
  }

  return data.type === "empty_slot" ? (
    <Grid
      item
      container
      alignItems="center"
      xs={12}
      justifyContent="space-between"
      style={{
        padding: "0.2rem 0.5rem",
        background: "#f3f3f3",
        borderRadius: "12px",
        fontSize: "12px",
        maxHeight: "70px",
      }}
    >
      <Grid
        container
        item
        xs={12}
        style={{ padding: "0.2rem 0.5rem" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item >
          <Typography style={{ fontSize: "12px", fontWeight: "600" }}>
            {moment.utc(data.start).format("hh:mm A") +
              " - " +
              moment.utc(data.end).format("hh:mm A")}{" "}
          </Typography>
          <Grid item xs={12}>
                      <Button
                       
                        color="primary"
                        size='small'
                        startIcon={<i class="fas fa-plus"></i>}
                        onClick={()=>onAddAppointment()}
                      >
                        Add Appointment
                      </Button>
                    </Grid>
        </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={
            <Switch
            style={{color:blocked?"red":'initital'}}
              color={blocked ? "primary" : "default"}
              checked={blocked}
              onChange={(e) => {
                // setBlocked(e.target.checked)
              setBlocked(e.target.checked)
                if(e.target.checked)
                onChange(data)
                else onChange(data,false)
              }}
            />
          }
          label="Block"
        ></FormControlLabel>
      </Grid>
      </Grid>
    </Grid>
  ) : data.type === "booked_slot" ? (
    <Grid
      item
      xs={12}
      container
      style={{
        background: "#f3f3f3",
        borderRadius: "12px",
        maxHeight: "70px",
      }}
    >
      <div
        style={{
          minHeight: "20px",
          height: "20px",
          background: "blue",
          color: "white",
          width: "100%",
          borderRadius: "12px 12px 0px 0px",
        }}
      ></div>
      <Grid
        container
        item
        xs={12}
        style={{ padding: "0.2rem 0.5rem" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item >
          <Typography style={{ fontSize: "12px", fontWeight: "600" }}>
            {moment.utc(data.start).format("hh:mm A") +
              " - " +
              moment.utc(data.end).format("hh:mm A")}{" "}
          </Typography>
          <Typography style={{ fontSize: "10px" }}>
            {" "}
            Upcoming | {data.patient.name}
          </Typography>
        </Grid>
       
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Switch
                style={{color:blocked?"red":'initital'}}
                  color={blocked ? "primary" : "default"}
                  checked={blocked}
                  onChange={(e) => {
                    // setBlocked(e.target.checked)
                  setBlocked(e.target.checked)
                    if(e.target.checked)
                    onChange(data)
                    else onChange(data,false)
                  }}
                />
              }
              label="Block"
            ></FormControlLabel>
        
        </Grid>
      </Grid>
    </Grid>
  ) : data.type === "blocked_slot" ? (
    <Grid
      item
      xs={12}
      container
      style={{
        background: "#f3f3f3",
        borderRadius: "12px",
        maxHeight: "70px",
      }}
    >
     
        <div
          style={{
            minHeight: "20px",
            height: "20px",
            background:  "red" ,
            color: "white",
            width: "100%",
            borderRadius: "12px 12px 0px 0px",
          }}>
      </div>
      <Grid
        container
        item
        xs={12}
        style={{ padding: "0.2rem 0.5rem" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={6}>
          <Typography style={{ fontSize: "12px", fontWeight: "600" }}>
            {moment.utc(data.start).format("hh:mm A") +
              " - " +
              moment.utc(data.end).format("hh:mm A")}{" "}
          </Typography>
          <Typography style={{ fontSize: "10px" }}>
            {" "}
            Blocked | {data?.reason}
          </Typography>
        </Grid>
        <Grid item>
        
            <FormControlLabel
              control={
                <Switch
                style={{color:blocked?"red":'initital'}}
                  color={blocked ? "primary" : "default"}
                  checked={blocked}
                  onChange={(e) => {
                    // setBlocked(e.target.checked)
                  setBlocked(e.target.checked)
                    if(e.target.checked)
                    onChange(data)
                    else onChange(data,false)
                  }}
                />
              }
              label="Block"
            ></FormControlLabel>
        
        </Grid>
      </Grid>
    </Grid>
    
  ) : null
}
