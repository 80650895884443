import React from "react";
import Header from "../../../components/Dashboard/Home/Header";
import { Container, Row, Col, Alert } from "reactstrap";
import JitsiMeetComponent from "../../../components/Dashboard/Home/JitsiMeet";

const Jitsy = () => {
  return (
    <>
      <Header parentName="Video Confrencing" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="4">
            <Alert color="danger">
              <strong>
                Attention! Please refer the previous tab for Prescription.{" "}
              </strong>
            </Alert>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <JitsiMeetComponent />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Jitsy;
