import {
    Grid,
    MenuItem,
    TextField,
    Button,
    Chip,
    Typography,
    TableContainer,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    IconButton,
    Checkbox,
    Switch,
    FormControlLabel,
    CircularProgress,
  } from "@material-ui/core"
  import { Close, DeleteForeverOutlined, GetApp, Print, Send } from "@material-ui/icons"
  import { Autocomplete, createFilterOptions } from "@material-ui/lab"
  import { uniqueId } from "lodash"
  import moment from "moment"
  import React, { useEffect, useState } from "react"
  import { toast } from "react-toastify"
  import { Table } from "reactstrap"
  import customAxios from "../../../axios"
  import GenericAnimation from "../../animation"
  import CustomAutocomplete from "../../Common/AutoComplete"
  import GenericDialogForm from "../../CreatePrescription/GenericDialog"
  import treatmentAnimation from "./../../../assets/animationData/treatment.json"
import whatsappLogo from "./../../../assets/img/icons/common/whatsapp.png"
import success from "./../../../assets/audios/confirm.mp3"


  import BillingTable, { PaymentComponent } from "./BillingTable"
  import { Document, Page, pdfjs } from 'react-pdf';
  import AWS from "aws-sdk"
  import { range } from "lodash"


  const paymentOptions = ["Cash", "UPI", "Card", "Online(Paperplane)"]
  
const filter = createFilterOptions();

  const defaultPaymentData = {
    invoiceFor: "Consultation",
    amount: 0,
    mode: "Cash",
  }
  
  function ManagePaymentDialog({
    initialTreatment = null,
    linkTreatmentProp = false,
    managePaymentDialogOpen,
    setManagePaymentDialogOpen,
    modalData,
    setUpdater,
    setModalData,
  }) {
    const { invoiceData } = modalData
    const [previousPaymentsOpen, setPreviousPaymentsOpen] = useState(false)
    const [treatmentList, setTreatmentList] = useState(false)
    const [paymentsData, setPaymentsData] = useState(() => [
      {
        ...defaultPaymentData,
        amount: modalData?.appointmentAmount ? modalData?.appointmentAmount : 0,
        key: uniqueId(),
      },
    ]);
    const [treatmentDialogOpen, setTreatmentDialogOpen] = useState(false)
    const [sendInvoice, setSendInvoice] = useState(true)
    const [linkTreatment, setLinkTreatment] = useState(false)
    const [selectedTreatment, setSelectedTreatment] = useState(null)
  
    useEffect(() => {
      let totalConsultationAmount = 0;
      paymentsData
          .map((ele) => {
            if(!ele.treatmentId){
              totalConsultationAmount += Number(ele.amount);
            }
          });
    }, [paymentsData])
  
    const managePayments = (data) => {
      let error = 1
      if (selectedTreatment) {
        customAxios
          .put(
            `/treatment/link/${selectedTreatment}?appointment_id=${data.appointmentId}`
          )
          .then((res) => {
            if (res.data.success) {
              if (paymentsData.length === 0) {
                setManagePaymentDialogOpen(false)
                if(setUpdater)
                setUpdater((prev) => !prev)
  
                toast.success("Procedure added successfully")
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
      if (!selectedTreatment && data.paymentsData.length === 0) {
        toast.error("Please add at least one invoice item")
        error = 0
      }
      if (paymentsData.length) {
        for (const i of data.paymentsData) {
          if (!i.mode || !i.invoiceFor) {
            error = 0
            toast.error("Please fill all details properly")
            break
          } else if (!i.amount) {
            error = 0
            toast.error("Amount for all invoice items must be greater than 0")
            break
          }
        }
      }
  
      if (error && paymentsData.length) {
        customAxios
          .put(
            `/consultation/new-generate-invoice/${data.appointmentId}?whatsapp_invoice=${sendInvoice}`,
            data.paymentsData
          )
          .then((res) => {
            if (res.data.success) {
              if(sendInvoice)
              toast.success("Invoice sent to patient on WhatsApp")
              setManagePaymentDialogOpen(false)
              if(setUpdater)
                setUpdater((prev) => !prev)
            } else {
              toast.error("Some error occured")
            }
          })
          .catch((err) => toast.error("Some error occured"))
      }
    }
    useEffect(() => {
      if (modalData.treatmentId) {
        setLinkTreatment(true)
        setSelectedTreatment(modalData.treatmentId)
        const curr = paymentsData.slice()
        curr.push({
          ...defaultPaymentData,
          treatmentId: modalData.treatmentId,
          invoiceFor: "Service",
        })
        setPaymentsData(curr.slice())
      }
    }, [modalData])
    const getOngoingTreatments = (patientId) => {
      customAxios
        .get(`/patients/treatments/${patientId}`)
        .then((res) => {
          if (res.data.success) {
            setTreatmentList((prev) => [
              ...res.data.data.map((ele) => ({
                label: ele.treatment.name,
                value: ele._id,
                ...ele,
              })),
            ])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    useEffect(() => {
      getOngoingTreatments(modalData.patientId)
    }, [modalData.patientId])

    const [rows, setRows] = useState([{ serviceName: '', amount: 0, quantity: 1, discount: 0}]);

    // useEffect(() => {
    //   const tempRows = rows.map((row) => {
    //     return {...row, total: ((Number(row?.amount)*Number(row?.quantity)) - Number(row?.discount))}
    //   })
    //   setRows([...tempRows])
    // }, [rows])

    const [switchToPrint, setSwitchToPrint] = useState(false)
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false)
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
    const [newPaymentMode, setNewPaymentMode] = useState('Cash');
    const [printUrl, setPrintUrl] = useState(null);
    const [printData, setPrintData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [infoData, setInfoData] = useState({"name": modalData?.patient?.name, "phoneNo": modalData?.patient?.phoneNo});
    const [additionalDiscount, setAdditionalDiscount] = useState(0);

    useEffect(() => {
      let error = false
      rows?.map(row => {
        if(!row?.serviceName || !row?.amount || !row?.quantity)
          error = true
      })

      if(!error){
        setSubmitButtonDisabled(false)
      } else {
        setSubmitButtonDisabled(true)
      }
    }, [rows])

    const createReceipt = (onlyPrint = true) => {
      let paymentData = rows?.map(row => {
        return {
          "mode": newPaymentMode,
          ...row,
          "invoiceFor": row['serviceName'],
          ...infoData
        }
      })
      customAxios.put(`/consultation/new-generate-invoice/${modalData.appointmentId}?only_print=${onlyPrint}&additional_discount=${additionalDiscount}&name=${infoData?.name}&phone_no=${infoData?.phoneNo}`, paymentData).then(res => {
        if(res?.data?.success){
          setPrintData(res?.data?.data)
          updateInvoiceUrl(res?.data?.data)
        }
      })
    }

    const submitInvoice = () => {
      let paymentData = rows?.map(row => {
        return {
          "mode": newPaymentMode,
          ...row,
          "invoiceFor": row['serviceName']
        }
      })
      customAxios.put(`/consultation/new-generate-invoice/${modalData.appointmentId}?additional_discount=${additionalDiscount}&whatsapp_invoice=${true}&name=${infoData?.name}&phone_no=${infoData?.phoneNo}`, paymentData).then(res => {
        setSubmitButtonLoading(false)
        if(res?.data?.success){
          toast.success("Invoice sent successfuly");
          const audio = new Audio(success)
          const promise = audio.play()
          if (promise !== undefined) {
            // On older browsers play() does not return anything, so the value would be undefined.
            promise
              .then(() => {
                // Audio is playing.
              })
              .catch((error) => {
                console.log(error)
              })
          }
          setManagePaymentDialogOpen(false)
          if(setUpdater)
            setUpdater((prev) => !prev)
        }
      })
    }

    const updateInvoiceUrl = (
      data, download = false
    ) => {

      // AWS.config.update({
      //   accessKeyId: "AKIAUSXZZ7HZK47LXEVL",
      //   secretAccessKey: "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",
      //   region: "ap-south-1"
      // })
      // var s3 = new AWS.S3({
      //   signatureVersion: 'v4',
      // });
  
      // let params = {
      //   Bucket: "paperplane-s3",
      //   Key: `${data?.location + data._id}`,
      // }

      if(!download){
        // s3.getSignedUrl('getObject', params, (error, url) => {
        //   if (error) {
        //     console.error('Error generating S3 signed URL:', error);
        //   } else {
        //     setPrintUrl(url);
        //     setNumPages(null);
    
        //     pdfjs.getDocument(url).promise.then((pdf) => {
        //       setNumPages(pdf.numPages);
        //     });
        //     setSwitchToPrint(true)
        //     setSubmitButtonLoading(false)
        //   }
        // });

        let url = data?.url

        setPrintUrl(url);
        setNumPages(null);

        pdfjs.getDocument(url).promise.then((pdf) => {
          setNumPages(pdf.numPages);
        });
        setSwitchToPrint(true)
        setSubmitButtonLoading(false)

      } else {
        var a = document.createElement("a")
        document.body.appendChild(a)
        a.style = "display: none"

        fetch(data?.url)
        .then((response) => {
          if (response.ok) {
            let blob = response.blob();
            if (download) {
              a.href = window.URL.createObjectURL(blob)
              a.download = `${modalData?.patient?.name}_(#${modalData?.patient?.code})_Receipt`
              a.click()
              window.URL.revokeObjectURL(data?.url)
            }
          }})
        // s3.getObject(params, (err, res) => {
        //   let blob = new Blob([res.Body], { type: res.ContentType })
        //   if (download) {
        //     a.href = window.URL.createObjectURL(blob)
        //     a.download = `${modalData?.patient?.name}_(#${modalData?.patient?.code})_Receipt`
        //     a.click()
        //     window.URL.revokeObjectURL(data?.url)
        //   }
        // })
      }

    }
  
    return (
      <div>
        {managePaymentDialogOpen ? (
          <GenericDialogForm
            setIsOpen={setManagePaymentDialogOpen}
            fullWidth
            maxWidth="md"
            style={{ overflowY: "auto" }}
            open={managePaymentDialogOpen}
            handleClose={() => {
              setManagePaymentDialogOpen(false)
              if(setModalData)
              setModalData(null)
            }}
            dialogTitle="Manage Payments"
            tabs={[
              {
              label: switchToPrint ? "Print & Submit" : "Create Receipt",
              value: 0,
              footerMessage: () => {
                return !switchToPrint ? (
                  <Grid container item xs={12}>
                    {invoiceData?.length ? (
                      <Grid item xs={12} style={{ margin: "0.5rem 0" }}>
                        <Button
                          color="primary"
                          variant="outlined"
                          fullWidth
                          onClick={() => {
                            setPreviousPaymentsOpen(true)
                          }}
                        >
                          View payment history for this appointment
                        </Button>
                      </Grid>
                    ) : null}
  
  
                    <BillingInfo 
                      data={infoData}
                      setData={setInfoData}
                      procedureList={treatmentList?.length ? treatmentList : []}
                      selectedTreatment={selectedTreatment}
                      setSelectedTreatment={setSelectedTreatment}
                      linkTreatment={linkTreatment}
                      setTreatmentDialogOpen={setTreatmentDialogOpen}
                      invoiceData={invoiceData}
                      />
                    <BillingTable rows={rows} setRows={setRows} appointmentAmount={modalData?.appointmentAmount} docId={modalData?.docId} />
  
                    <PaymentComponent
                      rows={rows}
                      newPaymentMode={newPaymentMode} 
                      setNewPaymentMode={setNewPaymentMode} 
                      additionalDiscount={additionalDiscount} 
                      setAdditionalDiscount={setAdditionalDiscount}
                     />
                  </Grid>
                ) : (
                  <PrintReceipt
                   printUrl={printUrl} 
                   numPages={numPages} 
                   handleDownload={() => updateInvoiceUrl(printData, true)} 
                   rows={rows} 
                   modalData={modalData} 
                   newPaymentMode={newPaymentMode}
                   additionalDiscount={additionalDiscount}
                   infoData={infoData}
                   setUpdater={setUpdater}
                   setManagePaymentDialogOpen={setManagePaymentDialogOpen}
                   
                   />
                )}
              }
            ]}
            // footerMessage={}
            submitButtonLoading={submitButtonLoading}
            submitButtonText={!switchToPrint ? "Create Invoice" : "Send to patient's Whatsapp"}
            submitButtonColor={switchToPrint ? "#239B56" : null}
            submitButtonTextColor={switchToPrint ? "white": null}
            cancelButtonText={!switchToPrint ? "Cancel" : "Edit"}
            handleCancel={() => {
              if(!switchToPrint)
                setManagePaymentDialogOpen(false)
              else {
                setSwitchToPrint(false)
              }
            }}

            autoClose={false}
            submitButtonDisabled={submitButtonDisabled}
            handleSubmit={(formData) => {
              // managePayments({ ...modalData, paymentsData: paymentsData })
              if(!switchToPrint){
                // setSwitchToPrint(true)
                setSubmitButtonLoading(true)
                createReceipt()
              } else {
                setSubmitButtonLoading(true)
                submitInvoice()
              }
            }}
          />
        ) : null}
        <GenericDialogForm
          style={{ overflowY: "auto" }}
          footerMessage={() => {
            return (
              <Grid container item xs={12} style={{ marginTop: "1rem" }}>
                {invoiceData.length ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Bill For</TableCell>
                          <TableCell>Mode</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Discount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceData.map((ele) => (
                          <TableRow>
                            <TableCell>
                              {moment
                                .utc(ele.date)
                                .format("DD MMM YYYY, hh:MM A")}
                            </TableCell>
                            <TableCell>{ele.invoiceFor}</TableCell>
                            <TableCell>
                              {ele.modeOfPayment}
                              {ele.modeOfPayment === "Online(Paperplane)" ? (
                                ele.paid ? (
                                  <Chip
                                    label="Paid"
                                    style={{
                                      backgroundColor: "green",
                                      color: "white",
                                      borderRadius: "0.25em",
                                      marginLeft: "0.3rem",
                                    }}
                                  ></Chip>
                                ) : (
                                  <Chip
                                    label="Pending"
                                    style={{
                                      backgroundColor: "red",
                                      color: "white",
                                      borderRadius: "0.25em",
                                      marginLeft: "0.3rem",
                                    }}
                                  ></Chip>
                                )
                              ) : null}
                            </TableCell>
                            <TableCell>₹{ele.amount}</TableCell>
                            <TableCell>{ele?.discount ? `₹ ${ele?.discount}` : "-"}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </Grid>
            )
          }}
          showDialogAction={false}
          fullWidth
          maxWidth="md"
          showSubmitButton={false}
          cancelButtonText="Close"
          dialogTitle="Payment history"
          open={previousPaymentsOpen}
          setOpen={setPreviousPaymentsOpen}
        />
        {treatmentDialogOpen ? (
          <GenericDialogForm
            dialogTitle="Add New Treatment"
            open={treatmentDialogOpen}
            setOpen={setTreatmentDialogOpen}
            handleSubmit={(modalData) => {
              customAxios
                .put("/treatment/", modalData)
                .then((res) => {
                  if (res.data.success) {
                    const curr = paymentsData.slice()
                    setTreatmentList((prev) => [
                      ...prev,
                      {
                        label: modalData.name,
                        value: res.data.data._id,
                        treatment: res.data.data,
                        _id: res.data.data._id,
                      },
                    ])
                    setSelectedTreatment(res.data.data._id)
                    curr.splice(curr.length, 0, {
                      treatmentId: res.data.data._id,
                      key: uniqueId(),
                      amount: 0,
                      mode: "Cash",
                      invoiceFor: "Service",
                    })
                    setPaymentsData(curr.slice())
                    toast.success("Procedure added succesfully")
                  } else toast.error("Some error occured")
                })
                .catch((err) => {
                  console.log(err)
                  toast.error("Some error occured")
                })
            }}
            message={
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <GenericAnimation
                  animationData={treatmentAnimation}
                  height={200}
                  width={200}
                ></GenericAnimation>
              </Grid>
            }
            dialogContent={[
              {
                key: "name",
                label: "Name",
                required: true,
                otherProps: { inputProps: { maxLength: 30 } },
              },
              { key: "totalAmount", required: true, label: "Total Amount" },
            ]}
          />
        ) : null}
      </div>
    )
  }
  
  export default ManagePaymentDialog
  


  const BillingInfo = ({data, setData, invoiceData, procedureList}) => {

    const [isProcedure, setIsProcedure] = useState(false)

    return (
      <Grid container spacing={2} style={{marginTop: 10}} alignItems="center">
        <Grid item container spacing={1} xs={12} md={3}>
          <Grid item xs={12}>
            Name
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth variant="outlined" size="small" value={data?.name} onChange={(e) => setData({...data, "name": e?.target?.value})} />
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={12} md={3}>
          <Grid item xs={12}>
            Phone/Whatsapp Number
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth variant="outlined" size="small" value={data?.phoneNo} onChange={(e) => {
              const regex = /^\d+$/;
              if(regex?.test(e?.target?.value) || !e?.target?.value)
              setData({...data, "phoneNo": e?.target?.value})
              }} />
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={12} md={3}>
          <Grid item xs={12}>
            Payment Status
          </Grid>
          <Grid item xs={12}>
            <Button disabled={true} variant="contained">
              {invoiceData?.length ? "Billed" : "Unbilled"}
            </Button>
          </Grid>
        </Grid>
        {/* <Grid item container xs={12} md={3} alignItems="center"> 
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={9}>
              Procedure
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                  style={{padding: 0}}
                  control={
                    <Switch
                      color="primary"
                      checked={isProcedure}
                      onChange={(e) => {
                        setIsProcedure(e.target.checked)
                      }}
                    />
                  }
                ></FormControlLabel>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                disabled={!isProcedure}
                openOnFocus
                style={{marginBottom: "10px", marginTop: "-5px"}}
                // value={row.serviceName}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        // handleChange({ target: { value: newValue } }, index, 'serviceName')
                    } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                        // handleChange({ target: { value: newValue.inputValue } }, index, 'serviceName')
                        // handleAddNewService(newValue.inputValue)
                    } else {
                        // handleChange({ target: { value: newValue } }, index, 'serviceName')
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // openOnFocus
                options={procedureList.length ? procedureList : [] }
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                    return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                    return option.inputValue;
                    }
                    // Regular option
                    return option?.label;
                }}
                renderOption={(option) => {
                    if (typeof option === 'string') {
                    return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                    return option.title;
                    }
                    // Regular option
                    return option?.label;
                }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" size={"small"}  autoFocus={true} placeholder="Add Procedure"/>
                )}
                />
          </Grid>
        </Grid> */}
      </Grid>
    );
  };  



const PrintReceipt = ({printUrl, numPages, handleDownload, rows, modalData, newPaymentMode, additionalDiscount, setManagePaymentDialogOpen, setUpdater, infoData}) => {

  const handlePrint = () => {
    window.open(printUrl, "PRINT", "height=900,width=1300");
  };

  const [sendLoader, setSendLoader] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [additionalNumber, setAditionalNumber] = useState()

  const [modalDialogTitle, setModalDialogTitle] = useState("")
  const [modalButtonLoading, setModalButtonLoading] = useState(false)
  const [modalDialogMessage, setModalDialogMessage] = useState("")
  
  const modalDialogSubmit = () => {
    setModalButtonLoading(true)
    submitInvoice()
  }


  const sendInvoiceToNumber = () => {
    setSendLoader(true)
    let paymentData = rows?.map(row => {
      return {
        "mode": newPaymentMode,
        ...row,
        "invoiceFor": row['serviceName']
      }
    })
    customAxios.put(`/consultation/new-generate-invoice/${modalData.appointmentId}?only_print=${true}&add_on_wa=${additionalNumber}&name=${infoData?.name}&phone_no=${infoData?.phoneNo}`, paymentData).then(res => {
      setSendLoader(false)
      if(res?.data?.success){
        toast.success("Send Successfuly")
      }
    })
  }

  const submitInvoice = () => {
    let paymentData = rows?.map(row => {
      return {
        "mode": newPaymentMode,
        ...row,
        "invoiceFor": row['serviceName']
      }
    })
    customAxios.put(`/consultation/new-generate-invoice/${modalData.appointmentId}?additional_discount=${additionalDiscount}&whatsapp_invoice=${false}&name=${infoData?.name}&phone_no=${infoData?.phoneNo}`, paymentData).then(res => {
      setModalButtonLoading(false)
      toast.success("Invoice sent successfuly");
      const audio = new Audio(success)
      const promise = audio.play()
      if (promise !== undefined) {
        // On older browsers play() does not return anything, so the value would be undefined.
        promise
          .then(() => {
            // Audio is playing.
          })
          .catch((error) => {
            console.log(error)
          })
      }
      if(setUpdater)
        setUpdater((prev) => !prev)
      setManagePaymentDialogOpen(false)
    })
  }

  return (
    <React.Fragment>
      <Grid item container xs={12}>
        <Grid item container xs={6}>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{fontWeight: "bold", color: "grey"}}>Receipt Actions</Typography>
              <Grid container spacing={2} style={{marginTop: 5}}>
                <Grid item container xs={12} style={{padding: 10, cursor: "pointer"}} alignItems="center" onClick={() => {
                  setModalButtonLoading("print")
                  setTimeout(() => {
                    handlePrint()
                    setModalButtonLoading(false)
                  }, 1000)
                }}>
                  <Grid item xs={1}>
                    <Print />
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item container xs={12} alignItems="center">
                      <Grid item>
                        <Typography variant="body" style={{fontWeight: "bold"}}>Print</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body" style={{color: "grey"}}>View and print the receipt</Typography>
                    </Grid>
                  </Grid>
                  {modalButtonLoading === "print" ? <Grid item xs={1}>
                    <CircularProgress size={24} color="primary"></CircularProgress>
                  </Grid> : null}
                </Grid>
                <Grid item container xs={12} style={{padding: 10, cursor: "pointer"}} alignItems="center" onClick={() => {
                  setModalButtonLoading("download")
                  setTimeout(() => {
                    handleDownload()
                    setModalButtonLoading(false)
                  }, 1000)
                }}>
                  <Grid item xs={1}>
                    <GetApp />
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item container xs={12}>
                      <Grid item>
                        <Typography variant="body" style={{fontWeight: "bold"}}>Download</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body" style={{color: "grey"}}>Get a pdf version of receipt</Typography>
                    </Grid>
                  </Grid>
                  {modalButtonLoading === "download" ? <Grid item xs={1}>
                    <CircularProgress size={24} color="primary"></CircularProgress>
                  </Grid> : null}
                </Grid>
                <Grid item container xs={12} style={{padding: 10, cursor: "pointer"}} alignItems="center" onClick={() => {
                  setModalButtonLoading("save")
                  submitInvoice()
                }}>
                  <Grid item xs={1}>
                    <Close />
                  </Grid>
                  <Grid item container xs={10}>
                    <Grid item container xs={12}>
                      <Grid item>
                        <Typography variant="body" style={{fontWeight: "bold"}}>Save & Close</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body" style={{color: "grey"}}>This will not send the invoice to the patient, but will save it for future reference</Typography>
                    </Grid>
                  </Grid>
                  {modalButtonLoading === "save" ? <Grid item xs={1}>
                    <CircularProgress size={24} color="primary"></CircularProgress>
                  </Grid> : null}
                </Grid>
                <Grid item container xs={12} spacing={1} style={{padding: 10, cursor: "pointer"}} alignItems="center">
                  <Grid item xs={1}>
                    <img
                        src={whatsappLogo}
                        style={{
                          width: "28px",
                          objectFit: "contain",
                        }}
                      />
                  </Grid>
                  <Grid item container xs={9}>
                    <Grid item container xs={12} style={{marginBottom: "5px"}}>
                      <Grid item style={{marginTop: "15px"}}>
                        <Typography variant="body" style={{fontWeight: "bold"}}>Send Invoice on whatsapp</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField 
                        variant="outlined" 
                        size="small"
                        // label="Additional Number"
                        placeholder="Enter phone number"
                        value={additionalNumber}
                        helperText="Add any other phone number you want to send invoice to on whatsapp"
                        onChange={(e) => {
                          setAditionalNumber(e.target?.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <img
                              src={whatsappLogo}
                              style={{
                                width: "22px",
                                objectFit: "contain",
                              }}
                            />
                          ),
                        }} 
                        />
                    </Grid>
                  </Grid>
                  {/* {modalButtonLoading === "save" ? <Grid item xs={1}>
                    <CircularProgress size={24} color="primary"></CircularProgress>
                  </Grid> : null} */}
                  <Grid item xs={2}>
                    {sendLoader ? (
                      <CircularProgress size={24} color="primary"></CircularProgress>
                    ) : <IconButton onClick={() => sendInvoiceToNumber()}>
                      <Send />
                    </IconButton>}
                  </Grid>
                  {/* <Grid item container xs={11}>
                    <Grid item xs={12}>
                      <Typography variant="body" style={{fontWeight: "bold"}}>Download</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body" style={{color: "grey"}}>Get a pdf version of receipt</Typography>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {printUrl ?
          <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '550px', minHeight: "550px", maxWidth: "100%", overflowX: "auto", overflowY: "hidden", border: "1px solid" }}>
          <Document file={printUrl}>
            {numPages && range(0, numPages)?.map((v, i) => (
              <div style={{marginBottom: 20}}><Page pageNumber={i+1} width={window.innerWidth*0.25} renderTextLayer={false} renderAnnotationLayer={false}/></div>
              
              ))}
          </Document></div> : null}

        </Grid>
      </Grid>
      {/* <GenericDialogForm
        open={modalOpen}
        dialogTitle={modalDialogTitle}
        handleClose={() => setModalOpen(false)}
        submitButtonLoading={modalButtonLoading}
        submitButtonText={"Yes"}
        cancelButtonText={"No"}
        message={modalDialogMessage}
        handleSubmit={modalDialogSubmit}
      /> */}
    </React.Fragment>
  )
}