import { Avatar, Button, Grid, Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import customAxios from "../../../axios"
import GenericCardTable from "../GenericCardTable"
import GenericDialogForm from "../GenericDialog"
import { diseasesValueForSearch } from "./../Constants/index"
import { v4 as uuidv4 } from 'uuid';


function Diagnosis({ state, setState, docId, setActiveIndex, activeIndex, padIndex, frequentData }) {
  const [suggestedDiagnosis, setSuggestedDiagnosis] = useState([])
  const user = useSelector((state) => state.user)
  useEffect(() => {
    const diagnosisLength = state?.diagnosis?.length
    if (diagnosisLength) {
      let suggestedState = []
      let querydiagnosis = state.diagnosis.map((ele) => ele.name)
      console.log("resd", user.user)
      customAxios
        .put(`/ai/co-diagnosis`, {
          names: querydiagnosis.reverse(),
          doc_id: docId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            suggestedState = [...res.data.data, ...suggestedState]
            suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState.filter(
              (symp) =>
                !state?.diagnosis?.find(
                  (f) => f?.name?.toLowerCase() === symp.toLowerCase()
                )
            )
            setSuggestedDiagnosis([...suggestedState])
          }
        })
    }
  }, [state?.diagnosis])
  useEffect(() => {
    const diagnosisLength = state?.diagnosis?.length
    if (diagnosisLength) {
      let suggestedState = []
      let querydiagnosis = state?.diagnosis?.map((ele) => ele?.name)
      console.log("resd", user.user)
      customAxios
        .put(`/ai/diagnosis`, {
          names: querydiagnosis.reverse(),
          doc_id: docId,
          spec: user?.user?.additionalDetail?.qualification
            ? user?.user?.additionalDetail?.qualification
            : "",
        })
        .then((res) => {
          if (res?.data?.success) {
            console.log("resd", res.data.data)
            suggestedState = [...res.data.data, ...suggestedState]
            suggestedState = [...new Set(suggestedState)]
            suggestedState = suggestedState.filter(
              (symp) =>
                !state?.diagnosis?.find(
                  (f) => f?.name?.toLowerCase() === symp.toLowerCase()
                )
            )
            setSuggestedDiagnosis([...suggestedState])
          }
        })
    }
  }, [state?.diagnosis])

  const autoCompleteDiagnose = (cardState, setCardState) => {

    if (cardState?.name) {
      let similarDiagnose = diseasesValueForSearch.filter((v) => v?.toLowerCase()?.includes(cardState?.name?.trim()?.toLowerCase()));

      setSuggestedDiagnosis([...similarDiagnose]);
    } else {
      setSuggestedDiagnosis([...diseasesValueForSearch])
    }
  }

  const cardTableHeaders = [
    {
      label: "Diagnosis",
      key: "name",
      placeholder: "eg. Influenza",
      type: "select",
      options: [...new Set([...(frequentData?.frequentDiagnosis ? Object.values(frequentData?.frequentDiagnosis) : []), ...diseasesValueForSearch])],
      required: true,
      width: "25%",
    },
    {
      label: "Since",
      key: "duration",
      placeholder: "e.g 1 Week",
      endAdornment: "days",
      unitOptions: ["Hour", "Day", "Week", "Month", "Year"],
      freeSolo: true,
      type: "unit-autocomplete",
      width: "20%",
      dependencyKey: "name",
    },
    {
      label: "Status",
      key: "status",
      // helperText: "Use  ⬅️  ➡️ to select value",
      placeholder: "e.g Confirmed",
      type: "select",
      width: "20%",
      dependencyKey: "name",
      options: ["Ruled Out", "Suspected", "Confirmed"],
    },
    {
      label: "Details",
      key: "details",
      // helperText: "Use  ⬅️  ➡️ to select value",
      placeholder: "Details",
      type: "text",
      width: "30%",
      dependencyKey: "name",
    },
  ]

  useEffect(() => {
    getRxGroup()
  }, [])

  const [openDialog, setOpenDialog] = useState({})
  const [selectedRxGroup, setSelectedRxGroup] = useState({})
  const [doctorRxGroup, setDoctorRxGroup] = useState([])
  const getRxGroup = () => {
    customAxios.get("doctor/rx-group?type=diagnosis").then((res) => {
      if (res?.data?.success) setDoctorRxGroup([...res?.data?.data])
    })
  }

  const addRxDialogContent = [
    {
      label: "Name",
      key: "name",
      placeholder: "eg. Malaria",
      type: "text",
      required: true,
      md: 12,
    },
    {
      errorKey: "Atleast one option",
      key: "rxMeds",
      type: "checkbox",
      options: [...state.diagnosis],
      optionKey: "name",
      required: true,
    },
  ]

  const selectMedsDialogContent = [
    {
      label: selectedRxGroup?.["name"],
      key: "rxMeds",
      type: "checkbox",
      options: selectedRxGroup?.["rxMeds"]
        ? [...selectedRxGroup?.["rxMeds"]]
        : [],
      optionKey: "name",
    },
  ]

  const addRxGroup = (rxData, isEdit = false) => {
    customAxios.put("doctor/rx-group?type=diagnosis", rxData).then((res) => {
      setOpenDialog({})
      toast.success("Template Saved Successfuly!")
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const removeRxGroup = (rxData) => {
    customAxios.put("doctor/delete-rx-group?type=diagnosis", rxData).then((res) => {
      if (res?.data?.success) {
        getRxGroup()
      }
    })
  }

  const chooseRxGroup = (rxData) => {
    setSelectedRxGroup({ ...rxData })
    setOpenDialog({ selectMeds: true })
  }

  const handleSelectMeds = (rxData) => {
    if (rxData?.rxMeds && rxData?.rxMeds?.length) {
      const temp = [...state.diagnosis]
      rxData?.rxMeds?.map((f) => {
        const check = state.diagnosis.findIndex((val) => val?.name === f?.name)
        if (check === -1) temp.push({ ...f })
        else temp.splice(check, 1, { ...f })
      })
      setState({ ...state, diagnosis: [...temp] });
      setOpenDialog({});
    }
  }

  return (
    <React.Fragment>
      <GenericCardTable
        cardTitle={"Diagnosis Details"}
        suggestedTitle={"Suggested Diagnosis"}
        tableHeaders={cardTableHeaders}
        tableData={state}
        tabSwitching={true}
        suggestedTab={suggestedDiagnosis.length}
        suggestedData={suggestedDiagnosis}
        cardKey="diagnosis"
        cardName={"Diagnosis"}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        padIndex={padIndex}
        setTableData={setState}

        onSelectTemplate={handleSelectMeds}
        onDeleteTemplate={removeRxGroup}
        onUpdateTemplate={addRxGroup}
        selectIconText={"Dx"}
        savedTemplates={doctorRxGroup}

        // addOnFunction={autoCompleteDiagnose}
        frequentOptions={frequentData?.frequentDiagnosis ? Object.values(frequentData?.frequentDiagnosis) : []}
        headerIcon={(<Avatar style={{
          fontSize: 18,
          width: 30,
          height: 30, 
          marginRight: 10, 
          color: "#5e72e4", 
          backgroundColor: "white",
        }}>
        <i className="fas fa-head-side-virus"></i>
      </Avatar>)}
      />
      {/* <Grid container>
        <Grid
          item
          xs={6}
          style={{ display: "flex", flex: "flex-wrap", alignItems: "center" }}
        >
          <Typography variant="body2">
            * Choose from templates
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setOpenDialog({ chooseRx: true })}
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>

        <Grid item container xs={6} justifyContent="flex-end">
          {state.diagnosis?.length ? (
            <Grid item>
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={() => setOpenDialog({ addRx: true })}
              >
                <Typography variant="body2">Create template</Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid> */}
      {openDialog?.chooseRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Choose Template"}
          dialogContent={[...doctorRxGroup]}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={chooseRxGroup}
          showDialogAction={false}
          isList={true}
          editListItem={true}
          removeListItem={true}
          onEditItem={(data) =>
            setOpenDialog({ editRx: true, data: { ...data, rxMeds: data?.rxMeds?.map(v => ({...v, uuid: uuidv4()}))} })
          }
          onRemoveItem={removeRxGroup}
        />
      ) : null}
      {openDialog?.selectMeds ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Select diagnosis"}
          dialogContent={selectMedsDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={handleSelectMeds}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{
            rxMeds: selectedRxGroup?.["rxMeds"]?.filter((f) => {
              const check = state?.diagnosis?.find(
                (val) => val?.name === f?.name
              )
              if (check) return false
              else return true
            }),
          }}
        />
      ) : null}
      {openDialog?.addRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Create Template"}
          dialogContent={addRxDialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={addRxGroup}
          cancelButtonText={"Cancel"}
          submitButtonText={"Add"}
          formData={{ rxMeds: [...state?.diagnosis] }}
        />
      ) : null}
      {openDialog?.editRx ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Update Template"}
          dialogContent={[
            { ...addRxDialogContent[0] },
            {
              ...addRxDialogContent[1],
              type: "checkbox_and_edit",
              options: [...openDialog?.data?.rxMeds],
            },
          ]}
          handleCancel={() => setOpenDialog({ chooseRx: true })}
          handleSubmit={(data) =>
            addRxGroup({ ...data, prevName: openDialog?.data?.name }, true)
          }
          editOptions={(val, med, idx) => {
            let tempData = {...openDialog?.data}
            tempData.rxMeds[idx] = {...med, name: val}

            setOpenDialog({...openDialog, data: {...tempData}})
          }}
          cancelButtonText={"Cancel"}
          submitButtonText={"Update"}
          formData={{ ...openDialog?.data }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Diagnosis
