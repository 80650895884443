import React, { useState } from "react";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import LoginArt from "../../../../assets/img/login/LoginArt.svg";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import Logo from "./../../../../assets/logos/Logo Light.png";
import VerifyOtp from "./VerifyOtp";
function ForgetPassword() {
  const [state, setState] = useState({ gender: "Male" });
  const [activeStep, setActiveStep] = useState(0);
  const getActiveStep = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <ForgotPassword
            state={state}
            setState={setState}
            setActiveStep={setActiveStep}
          ></ForgotPassword>
        );
      case 1:
        return (
          <VerifyOtp
            state={state}
            setState={setState}
            setActiveStep={setActiveStep}
          ></VerifyOtp>
        );
      case 2:
        return (
          <ChangePassword
            state={state}
            setState={setState}
            setActiveStep={setActiveStep}
          ></ChangePassword>
        );
      default:
        return <h1>Hey, you seem lost. Please refresh the page </h1>;
    }
  };
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col
            md="5"
            sm="1"
            style={{
              height: "100vh",
              backgroundColor: "#ffffff",
              borderRadius: "3rem",
            }}
          >
            <Container>
              <img
                src={Logo}
                alt="logo"
                className="m-5"
                style={{ width: "50%" }}
              ></img>
            </Container>

            <img
              src={LoginArt}
              alt="art"
              style={{
                position: "relative",
                bottom: "0px",
                right: "0px",
                marginLeft: "2rem",
                overflow: "visible",
                width: "100%",
                paddingTop: "4vh",
              }}
            />
          </Col>
          <Col md="1" style={{ backgroundColor: "#F9F9FF" }}></Col>
          <Col md="6" style={{ backgroundColor: "#F9F9FF" }}>
            <div style={{ paddingTop: "10rem" }}>
              <Row>
                <Col md="9">{getActiveStep(activeStep)}</Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ForgetPassword;
