import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Divider,
  TextField,
  Typography,
  useTheme,
  makeStyles,
  Avatar,
  CircularProgress,
  MenuItem,
  Menu,
} from "@material-ui/core"
import { Add, Delete, DeleteForever, ExpandLess, ExpandMore, Settings } from "@material-ui/icons"
import customAxios from "../../../axios"
import { useDispatch, useSelector } from "react-redux"
import GenericDialogForm from "../GenericDialog"
import EditTextField from "./EditTextField"
import { useRef } from "react"
import { setCustomVitals } from "../../../redux/actions/user.actions"


const useStyles = makeStyles((theme) => ({
  hoverChip: {
  //   width: "100%", 
  //   padding: "10px", 
  // //   cursor: "pointer", 
  //   marginBottom: 15, 
    borderRadius: 20,
    '&:focus': {
      border: "solid",
      borderColor: theme.palette.primary.main,
    },
  },
}));



function Vitals({ state, setState, docId, padIndex, activeIndex, setActiveIndex, patientId, loading }) {
  const re = new RegExp("^[0-9-/.]*$")
  const dispatch = useDispatch()
  
  const suggestedVitals1 = [
    {
      metricName: "Body temperature",
      metricUnit: "C",
      icon: <i class="fas fa-thermometer"></i>
    },
    {
      metricName: "Peripheral oxygen saturation/SpO2",
      metricUnit: "%",
      icon: <i class="fas fa-lungs"></i>
    },
    {
      metricName: "Blood pressure",
      metricUnit: "mmHg",
      icon: <i class="fas fa-tint"></i>
    },
    {
      metricName: "Blood Sugar",
      metricUnit: "mg",
      icon: <i class="fas fa-tint"></i>
    },
    {
      metricName: "Heart Rate",
      metricUnit: "BPM",
      icon: <i class="fas fa-heart"></i>
    },
    {
      metricName: "Respiratory rate",
      metricUnit: "/min",
      icon: <i class="fas fa-lungs"></i>
    },
    {
      metricName: "Pulse Rate",
      metricUnit: "/min",
      icon: <i class="fas fa-heart"></i>
    },
    {
      metricName: "Body height",
      metricUnit: "Cms",
      icon: <i class="fas fa-arrows-v"></i>
    },
    {
      metricName: "Body weight",
      metricUnit: "Kgs",
      icon: <i class="fas fa-weight"></i>
    },
    {
      metricName: "Body mass index",
      metricUnit: "kg/m2",
      icon: <i class="fas fa-divide"></i>
    },
    {
      metricName: "Systolic blood pressure",
      metricUnit: "mmHg",
      icon: <i class="fas fa-tint"></i>
    },
    {
      metricName: "Diastolic blood pressure",
      metricUnit: "mmHg",
      icon: <i class="fas fa-tint"></i>
    },
  ]

  const customVitals = useSelector((state) => state?.user?.customVitals)

  const [suggestedVitals, setSuggestedVitals] = useState([...(customVitals ? customVitals : suggestedVitals1)])


  const defaultDialogContent = [
    {
      label: "Label",
      key: "metricName",
      placeholder: "eg. Respiration",
      md: 12,
      type: "select-new",
      options: [...suggestedVitals1?.filter(v => suggestedVitals ? !suggestedVitals?.find(f => f?.metricName === v?.metricName) : true)]?.map(v => v?.metricName),
      freeSolo: true,
      required: true,
      focus: true
    },
    {
      label: "Unit",
      key: "metricUnit",
      placeholder: "eg. BPM",
      md: 12,
      type: "text",
      required: true,
    },
    {
      label: "Value",
      key: "metricQuantity",
      placeholder: "eg. 78",
      type: "text",
      fullWidth: true,
      md: 12,
    },
  ]

  const [dialogContent, setDialogContent] = useState([...defaultDialogContent])

  const [editMode, setEditMode] = useState(false)
  const [openDialog, setOpenDialog] = useState({})
  
  const theme = useTheme()  
  const updateOnce = useRef(0)
  


  useEffect(() => {
    // if (docId && updateOnce.current < 2){
    //   let tempVitals = customVitals?.map(v => {
    //     let vital = state?.vitals?.find(f => v?.metricName?.toLowerCase()?.includes(f?.metricName?.toLowerCase()))
    //     if(vital){
    //       return {
    //         metricName: v?.metricName,
    //         metricUnit: v?.metricUnit,
    //         metricQuantity: vital?.metricQuantity
    //       } 
    //     } else return v
    //   })
    //   setSuggestedVitals([...(tempVitals ? tempVitals : [])])
    //   updateOnce.current += 1
    // }
    if(state?.vitalUpdate && suggestedVitals) {
      let tempVitals = suggestedVitals?.map(v => {
          let vital = state?.vitals?.find(f => v?.metricName?.toLowerCase()?.includes(f?.metricName?.toLowerCase()))
          if(vital){
            return {
              metricName: v?.metricName,
              metricUnit: v?.metricUnit,
              metricQuantity: vital?.metricQuantity
            } 
          } else return {
            metricName: v?.metricName,
            metricUnit: v?.metricUnit
          }
        });

      setSuggestedVitals([...(tempVitals ? tempVitals : [])])
    }

    if(!customVitals){
      customAxios.get(`/doctor/get-custom-vitals/${docId}`).then((res) => {
        if(res?.data?.data?.length && res?.data?.success){
          let customVitals = [...res?.data?.data]
          dispatch(setCustomVitals(customVitals))
          setSuggestedVitals([...customVitals])
        }
      })
    }
  }, [state?.vitals])

  useEffect(() => {
    if(suggestedVitals?.length){

      let tempVitals = [...suggestedVitals]

      const weightVital = tempVitals.find((vital) => vital && (vital?.metricUnit?.toLowerCase() === 'kg' || vital?.metricUnit?.toLowerCase() === "kgs"));
      const heightVital = tempVitals.find((vital) => vital && (vital?.metricUnit?.toLowerCase() === 'cm' || vital?.metricUnit?.toLowerCase() === "cms"));

      const bmiVital = tempVitals.find((vital) =>   vital?.metricUnit?.toLowerCase() === 'kg/m2');

      if(Number(weightVital?.metricQuantity) && Number(heightVital?.metricQuantity) && bmiVital){
        bmiVital.metricQuantity = (Number(weightVital?.metricQuantity) / Math.pow(Number(heightVital?.metricQuantity) / 100, 2)).toFixed(2)
      } 
      // else {
      //   if (bmiVital) {
      //     bmiVital.metricQuantity = 0
      //   }
      // }

      setState({...state, vitals: [...(state?.vitalUpdate ? [] : tempVitals)], vitalUpdate: false});
      setDialogContent([
        {
          label: "Label",
          key: "metricName",
          placeholder: "eg. Respiration",
          md: 12,
          type: "select-new",
          options: [...suggestedVitals1?.filter(v => suggestedVitals ? !suggestedVitals?.find(f => f?.metricName === v?.metricName) : true)]?.map(v => v?.metricName),
          freeSolo: true,
          required: true,
          focus: true,
          onChange: (value, state, setState, isSelected) => {
            let tempState = {...state}
            tempState['metricName'] = value
            if(isSelected){
              let vital = suggestedVitals1?.find(f => f?.metricName === value)
              if(vital){
                tempState['metricUnit'] = vital?.metricUnit
              }
            }
            setState({...tempState})
          }
        },
        {
          label: "Unit",
          key: "metricUnit",
          placeholder: "eg. BPM",
          md: 12,
          type: "text",
          required: true,
        },
        {
          label: "Value",
          key: "metricQuantity",
          placeholder: "eg. 78",
          type: "text",
          fullWidth: true,
          md: 12,
        },
      ]
    )
    };
  }, [suggestedVitals])

  const addVital = (data) => {
    // const temp = [...state.vitals]
    // temp.push({ ...data, focus: true })
    // setState({ ...state, vitals: [...temp] })

    const temp = [...suggestedVitals]
    temp.push({ ...data, focus: true })
    setSuggestedVitals([...temp])
    setOpenDialog({})
  }

  const removeVital = () => {
    const temp = [...suggestedVitals]
    let deletedItem = temp.splice(openDialog?.index, 1)
    setSuggestedVitals([...temp])

    customAxios.put(`/vital/update/${patientId}`, {"metricName": deletedItem?.[0]?.metricName}).then((res) => {
      if(res.data?.success){
        console.log("vital deleted")
      }
    })

    setOpenDialog({})
  }

  const updateVital = (data) => {
    // const temp = [...state.vitals]
    // temp[openDialog?.index] = { ...data }
    // setState({ ...state, vitals: [...temp] })

    const temp = [...suggestedVitals]
    let updatedItem = temp?.[openDialog?.index]
    temp[openDialog?.index] = { ...data }
    setSuggestedVitals([...temp]);

    customAxios.put(`/vital/update/${patientId}?type=edit`, {"prevMetricName": updatedItem?.metricName, "metricName": data?.metricName, "metricUnit": data?.metricUnit}).then((res) => {
      if(res.data?.success){
        console.log("vital updated")
      }
    })

    setEditMode(false)
  }

  const textFieldsRefs = useRef([]);
  const suggestedChipRefs = useRef([]);

  function handleKeyDown(event, index, type = "textField") {
      if (type === "textField"){
        if ((event.key === "Enter" || event.key === "ArrowRight")) {
          if (index < textFieldsRefs.current.length - 1)
            textFieldsRefs.current[index + 1].focus();
        } else if (event.key === "ArrowLeft" && index > 0) {
          textFieldsRefs.current[index - 1].focus();
        } else if (event.key === "ArrowUp" && padIndex > 0) {
          setActiveIndex({"index": padIndex - 1, "from": padIndex})
        } else if (event.key === "ArrowDown") {
          if (suggestedChipRefs?.current?.[index])
            suggestedChipRefs.current[index].focus();
          else
            setActiveIndex({"index": padIndex + 1, "from": padIndex})
        }
      } else if (type === "chip") {
        if (event.key === "ArrowRight") {
          if (suggestedChipRefs?.current?.[index + 1])
            suggestedChipRefs.current[index + 1].focus();
          
          else {
            setOpenDialog({ dialog: "addVital" })
            // suggestedChipRefs.current[index + 1].focus();
          }
        } else if (event.key === "ArrowLeft" && index > 0) {
          suggestedChipRefs.current[index - 1].focus();
          if((index - 1) < 5)
            setOpenDialog({})

        } else if (event.key === "Enter" || event.key === "ArrowUp") {
          if (event.key === "ArrowUp") {
            if (textFieldsRefs?.current?.[0])
              textFieldsRefs.current[0].focus()
            else
              setActiveIndex({"index": padIndex - 1, "from": padIndex})
          }
          else if (event.key === "Enter") {
            if (textFieldsRefs?.current?.[1])
              textFieldsRefs.current[1].focus()
            else
              textFieldsRefs.current[0].focus()
          }
          setOpenDialog({})
        } else if (event.key === "ArrowDown")
          setActiveIndex({"index": padIndex + 1, "from": padIndex})
      }
  }

  useEffect(() => {
    if(activeIndex?.index === padIndex) {
      if (textFieldsRefs.current?.[0])
        textFieldsRefs.current[0].focus();
      
      else if(suggestedChipRefs.current?.[0])
        suggestedChipRefs.current[0].focus();
    }
  }, [activeIndex])

  const classes = useStyles()

  const getIcon = (data) => {
    let vital = suggestedVitals1?.find(f => f?.metricName?.toLowerCase()?.includes(data?.metricName?.toLowerCase()))
    if (vital){
      return vital?.icon
    } else  <i class="fas fa-vial"></i>
  }

  const vitalUnitOptions = [
    {"name": "temperature", "units": ['C', "F"]},
    {"name": "height", "units": ['Cms', "Feet"]},
    {"name": "weight", "units": ['Kgs', "Lbs"]},
  ]
  const [anchorEl, setAnchorEl] = useState()

  const [expandUnit, setExpandUnit] = useState(null)

  const getVitalUnitOptions = (vital, vIdx) => {
    let unitOption = vitalUnitOptions?.find(f => vital?.metricName?.toLowerCase()?.includes(f?.name));
    
    return unitOption ? <>
    <IconButton size="small" style={{padding: 0, color: "grey"}} onClick={(event) => {
      setAnchorEl(event?.currentTarget)
      if(expandUnit === vIdx)
        setExpandUnit(null) 
      else setExpandUnit(vIdx)
      }} >
      {expandUnit === vIdx ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
    {expandUnit === vIdx && (
        <Menu open={true} anchorEl={anchorEl} anchorPosition={{vertical: "bottom", horizontal:"left"}}  transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }} onClose={() => {setExpandUnit(false); setAnchorEl()}} style={{marginTop: "2%"}} elevation={1}>
          {unitOption?.units?.filter(f => f !== vital?.metricUnit)?.map((option) => (
            <MenuItem key={option} value={option} onClick={() => {
              const temp = [...suggestedVitals]
              temp[vIdx]["metricUnit"] = option
              setSuggestedVitals([...temp]);
              setExpandUnit(false); setAnchorEl()
            }}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      )}
  </> : null
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item style={{display: "flex", alignItems: "center"}}>
            {/* {loading ? <CircularProgress size={28} style={{marginRight: 10}} /> : */}
             {/* <Avatar style={{
                fontSize: 16,
                width: 30,
                height: 30, 
                marginRight: 10, 
                color: "white", 
                backgroundColor: "#2874A6"
              }}> */}
                <Avatar style={{
                fontSize: 18,
                width: 30,
                height: 30, 
                marginRight: 10, 
                color: theme.palette.primary.main, 
                backgroundColor: "white",
              }}>
              <i className="fas fa-heart-pulse"></i>
            </Avatar>
            {/* } */}
            <Typography variant="h6" style={{fontWeight: "600"}}>Vitals</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" endIcon={<Add />} color="primary" size='small' onClick={() => setOpenDialog({ dialog: "addVital" })}>
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {[...suggestedVitals]?.map((v, i) => {
            let metricQuantity = v?.metricQuantity ?? "";

            return (
            <Grid item container xs={6} md={6} alignItems="center">
              <Grid item xs={7} style={{display: "flex", alignItems: "center"}}>
                <Avatar style={{
                    fontSize: 12,
                    width: 25,
                    height: 25, 
                    marginRight: 10, 
                    color: "grey", 
                    backgroundColor: "whitesmoke",
                  }}>{getIcon(v)}</Avatar>
                <Typography>{v?.metricName}</Typography>
              </Grid>
              <Grid item xs={5}>
                {!editMode ? (
                  <TextField
                    variant="outlined"
                    fullWidth

                    value={metricQuantity}
                    // style={{minWidth: "14rem"}}
                    // size="small"
                    inputRef={(ref) => (textFieldsRefs.current[i] = ref)}
                    onKeyDown={(e) => handleKeyDown(e, i)}
                    // autoFocus={v?.focus || i === 0}
                    InputProps={{
                      endAdornment: (
                        <>
                          <Typography color="secondary" style={{display: "flex", alignItems: "center"}}>
                            {v?.metricUnit}
                            {getVitalUnitOptions(v, i)}
                          </Typography>
                          <Delete
                            // color="error"
                            style={{ cursor: "pointer", fontSize: "18px", marginLeft: 10, opacity: "30%" }}
                            onClick={() => {
                              // const temp = [...state.vitals]
                              // temp.splice(i, 1)
                              // setState({ ...state, vitals: [...temp] })

                              const temp = [...suggestedVitals]
                              let deletedItem = temp.splice(i, 1)

                              customAxios.put(`/vital/update/${patientId}`, {"metricName": deletedItem?.[0]?.metricName}).then((res) => {
                                if(res.data?.success){
                                  console.log("vital deleted")
                                }
                              })

                              setSuggestedVitals([...temp])
                            }}
                          />
                        </>
                      ),
                    }}
                    //   onKeyDown={(e) => {
                    //   if (e.key === "Enter" &&(e.target.value === "" || re.test(e.target.value)) ){

                    //   }
                    // }}
                    placeholder="0.0"
                    onChange={(e) => {
                      if (e.target.value === "" || re.test(e.target.value)) {
                        // const temp = [...state.vitals]
                        // temp[i]["metricQuantity"] = e.target.value
                        // setState({ ...state, vitals: [...temp] })

                        const temp = [...suggestedVitals]
                        temp[i]["metricQuantity"] = e.target.value
                        setSuggestedVitals([...temp])
                      }
                    }}
                  />
                ) : (
                  <EditTextField
                    value={v?.["metricQuantity"]}
                    placeholder={"0.0"}
                    onClick={() =>
                      setOpenDialog({
                        dialog: "editVital",
                        data: { ...v },
                        index: i,
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
          )})}
        </Grid>
        {/* <Grid
          item
          container
          xs={12}
          style={{ marginTop: "0.5rem" }}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography style={{ opacity: 0.7 }}>Suggested Vitals</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            // style={{ marginTop: "0.5rem" }}
            spacing={1}
            alignItems="center"
          >
            {suggestedVitals
              .filter(
                (ele) =>
                  !state?.vitals?.find(
                    (res) => res.metricName === ele.metricName
                  )
              )
              .map((v, idx) => (
                <Grid item>
                  <Chip label={v?.metricName} onClick={() => addVital(v)} 
                    innerRef={(ref) => (suggestedChipRefs.current[idx] = ref)}
                    className={classes.hoverChip}
                    onKeyDown={(e) => {
                      handleKeyDown(e, idx, "chip")
                      if(e?.key === "Enter")
                        addVital(v)
                    }}
                  />
                </Grid>
              ))}
            <Grid item xs={"auto"}>
              <IconButton
                color="primary"
                style={{
                  // backgroundColor: theme.palette.primary.main,
                  // color: "white",
                  fontSize: "30px",
                }}
                onClick={() => setOpenDialog({ dialog: "addVital" })}
              >
                <i class="fa-solid fa-circle-plus"></i>{" "}
              </IconButton>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flex: "flex-wrap",
            alignItems: "center",
            marginTop: "0.5rem",
          }}
        >
          <Typography variant="body2">
            {!editMode
              ? "* In order to Edit existing vitals"
              : "* In order to stop editing vitals"}
          </Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setEditMode(!editMode)}
          >
            <Typography variant="body2">Click Here</Typography>
          </Button>
        </Grid>
      </Grid>
      {openDialog?.dialog === "addVital" ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Add New Vital"}
          dialogContent={dialogContent}
          handleCancel={() => setOpenDialog({})}
          handleSubmit={addVital}
          submitButtonText={"Add"}
        />
      ) : null}
      {openDialog?.dialog === "editVital" ? (
        <GenericDialogForm
          open={true}
          handleClose={() => setOpenDialog({})}
          dialogTitle={"Edit Vital"}
          dialogContent={dialogContent}
          handleCancel={removeVital}
          handleSubmit={updateVital}
          cancelButtonText={"Remove"}
          submitButtonText={"Save Changes"}
          formData={openDialog?.data}
        />
      ) : null}
    </>
  )
}

export default Vitals
