import React, { useState, useEffect } from "react";
// import ProgressComponent from '@material-ui/core/CircularProgress';
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Card, CardBody } from "reactstrap";

import customAxios from "../../../axios";

function JitsiMeetComponent() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const jitsiContainerStyle = {
    display: loading ? "none" : "block",
    width: "100%",
    height: "100%",
  };

  const [docName, setdocName] = useState("N");

  useEffect(() => {
    customAxios.get(`/appointments/${id}`).then((res) => {
      setdocName(res.data.data.doctor.name);
    });
  }, [id]);

  function startConference(props) {
    try {
      const domain = "meet.paperplane.health";
      const options = {
        roomName: id,
        height: 600,
        parentNode: document.getElementById("jitsi-container"),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false,
        },
        configOverwrite: {
          disableSimulcast: false,
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      api.addEventListener("videoConferenceJoined", () => {
        setLoading(false);
        api.executeCommand("displayName", docName);
      });
    } catch (error) {
      console.error("Failed to load Jitsi API", error);
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference();
    else toast.error("Jitsi Meet API script not loaded");
    //eslint-disable-next-line
  }, []);

  return (
    <Card>
      <CardBody>
        {/* <div style={containerStyle}> */}
        {loading && <h1>Loading...</h1>}
        <div id="jitsi-container" style={jitsiContainerStyle} />
        {/* </div> */}
      </CardBody>
    </Card>
  );
}

export default JitsiMeetComponent;
