import React, { useRef } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  Typography,
  TextField,
  Button,
  withStyles,
  IconButton,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogTitle,
  Chip,
  CircularProgress,
} from "@material-ui/core"

import AddIcon from "@material-ui/icons/Add"
import { toast } from "react-toastify"
function FileUploadModal({
  open,
  setOpen,
  state,
  setState,

  handleUpload = async (files) => {
    const tempArr = [...state]
    if (files) {
      for (const file of files) {
        let reader = new FileReader()

        reader.onload = function (e) {
          tempArr.push({
            file: e.target.result,
            name: file.name,

            type: "Addtional Docs",
            extension: file.type,
          })

          setState([...tempArr])
        }

        reader.readAsDataURL(file)
      }
    }
  },

  onClose = () => setOpen(false),
  maxWidth = "lg",
  addFilesButtonStyle = {
    variant: "contained",
    startIcon: <AddIcon />,
    color: "primary",
  },
  addFilesButton = (text, styles, documentsRef) => (
    <Button
      {...styles}
      onClick={() => {
        documentsRef.current.click()
      }}
    >
      {text}
    </Button>
  ),
  loading = false,
  multiple = true,
  allowedFileTypes = "image/*",
  message = null,
  addFilesButtonText = "Add Files",
  dialogTitle = "Upload Files",
  cancelButtonText = "Discard",
  submitButtonText = "Submit",
  onSubmit,
  fullWidth,
}) {
  const documentsRef = useRef()
  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      onClose={onClose}
      maxWidth={maxWidth}
      PaperProps={{ style: { borderRadius: "15px", minWidth: "300px" } }}
    >
      <DialogTitle onClose={onClose}>{dialogTitle}</DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <Grid container spacing={1}>
          {message ? (
            <Grid item xs={12}>
              {message}
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {addFilesButton(
              addFilesButtonText,
              addFilesButtonStyle,
              documentsRef
            )}
          </Grid>
          <Grid item xs={12} container style={{ padding: "1rem" }}>
            {state?.map((file, idx) => {
              return (
                <div className="m-1">
                  <Chip
                    variant="outlined"
                    color="primary"
                    onDelete={() => {
                      const temp = [...state]

                      temp.splice(idx, 1)
                      setState([...temp])
                    }}
                    label={file.name}
                  ></Chip>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <input
        onChange={(e) => {
          handleUpload(e.target.files)
        }}
        hidden
        label="additionalFiles"
        ref={documentsRef}
        title="FilesUploader"
        type="file"
        multiple={multiple}
        accept={allowedFileTypes}
      ></input>

      <DialogActions>
        {loading ? (
          <Grid container justifyContent="center" style={{ padding: "1rem" }}>
            <CircularProgress color="primary"></CircularProgress>
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            style={{ padding: "1rem" }}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onClose("cancel")}
              >
                {cancelButtonText}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSubmit()}
                disabled={state?.length === 0}
              >
                {submitButtonText}
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default FileUploadModal
