

function extractValueAndUnit(text) {
    const match = text?.match(/(\d+)\s?(\w+)?/);
    if (match) {
      const value = parseInt(match[1]);
      const unit = match[2] || 'years';
      return [value, unit];
    } else {
      return [null, null];
    }
  }


const displayAge = (patientData) => {

    let ageString = "0 Y"

    if(patientData) {
        if(patientData?.ageParams) {
            const ageParams = patientData.ageParams
            if(ageParams?.years)
                ageString = `${ageParams?.years} Y`
            
            if(ageParams?.months)
                ageString += ` ${ageParams?.months} M`
            
            if(ageParams?.days)
                ageString += ` ${ageParams?.days} D`

        } else if(patientData?.ageString) {
            const [value, unit] = extractValueAndUnit(patientData.ageString?.toString());

            if (value !== null && unit !== null) {

                if (unit?.toLowerCase()?.includes("year")) {
                    ageString = `${value} Y`;
                }
                if (unit?.toLowerCase()?.includes("month")) {
                    const years = Math.floor(value / 12);
                    const months = value % 12;

                    ageString = `${years} Y`;

                    if (months !== 0) {
                        ageString += ` ${months} M`;
                    }
                } else if (unit?.toLowerCase()?.includes("day")) {
                    const years = Math.floor(value / 365);
                    const months = Math.floor((value % 365) / 30);
                    const days = Math.floor((value % 365) % 30);

                    ageString = `${years} Y`;

                    if (months !== 0) {
                        ageString += ` ${months} M`;
                        if (days !== 0) {
                        ageString += ``;
                        }
                    }

                    if (days !== 0) {
                        ageString += ` ${days} D`;
                    }
                }
            }
        } else if (patientData?.age) {
            ageString = `${patientData?.age} Y`;
        }

    }

    return ageString;
}

export default displayAge;