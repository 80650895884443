import React, { useState, useRef } from 'react';
import { TextField, Typography, Popper, Paper, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  dropdownContainer: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: theme.spacing(4),
    right: 0,
    zIndex: 1,
    padding: theme.spacing(1),
    minWidth: '150px',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.background.paper,
  },
  arrow: {
    position: 'absolute',
    top: theme.spacing(2),
    left: '-6px',
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderRight: `6px solid ${theme.palette.background.paper}`,
  },
}));

const CustomAutocomplete = ({ options = ["sfsd", 'sadasdasd', 'awewqe', 'qweqweqwd', 'sdsad'], onChange, value }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const anchorRef = useRef(null);
  const classes = useStyles();

  return (
    <div className={classes.dropdownContainer}>
      <TextField
        variant="outlined"
        value={value || ''}
        onClick={() => setMenuOpen(true)}
        inputRef={anchorRef}
        InputProps={{
          endAdornment: (
            <React.Fragment>
              <ArrowDropDownIcon />
            </React.Fragment>
          ),
        }}
      />
      <Popper
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        placement="bottom-start"
      >
        <div className={classes.dropdown}>
          <div className={classes.arrow}></div>
          <Autocomplete
            options={ ["sfsd", 'sadasdasd', 'awewqe', 'qweqweqwd', 'sdsad']}
            onChange={(event, newValue) => {
              onChange(newValue);
              setMenuOpen(false);
            }}
            value={value}
            onInputChange={(event, newInputValue) => {
              if (!newInputValue) {
                setMenuOpen(false);
              }
            }}
            onBlur={() => setMenuOpen(false)}
            renderInput={() => null}
            renderOption={(option) => (
              <Typography variant="body2">{option}</Typography>
            )}
          />
        </div>
      </Popper>
    </div>
  );
};

export default CustomAutocomplete;
